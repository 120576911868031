import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const TagSummary = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Module</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.module}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

TagSummary.propTypes = {
    record: PropTypes.object,
};

export default TagSummary;
