import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import EmailIcon from '@material-ui/icons/MailOutline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showNotification as showNotificationAction } from 'react-admin';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    userId: number;
    currency: string;
    amount: number;
    onClose: () => void;
    showNotification: (arg0: string, arg1: string) => void;
};

type State = {
    amount: number;
};

class PaymentLinkDialog extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            amount: 0,
        };

        this.handleClose = this.handleClose.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.amount === 0 && this.state.amount !== nextProps.amount) {
            this.setState({
                amount: nextProps.amount,
            });
        }
    }

    handleClose() {
        this.setState({ amount: 0 });
        this.props.onClose();
    }

    render() {
        const { currency, userId, showNotification, taskCreate } = this.props;
        const { amount } = this.state;
        const scaleFactor = 10000.0;

        const gateway = this.props.record?.countryId === 2 ? 'tap' : 'payfort';
        let paymentLink;

        if (!!amount) {
            if (process.env.REACT_APP_ENV === 'staging') {
                paymentLink = `https://app.staging.boxitstorage.com/payment/${gateway}/${userId}/${amount}?countryId=${this.props.record.countryId}`;
            } else {
                paymentLink = `https://app.boxitstorage.com/payment/${gateway}/${userId}/${amount}?countryId=${this.props.record.countryId}`;
            }
        }

        return (
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={!!this.props.open}
                onClose={this.handleClose}
            >
                <DialogTitle>Create Payment Link</DialogTitle>
                <DialogContent>
                    <TextField
                        name="amount"
                        label={`Amount (${currency})`}
                        onChange={e =>
                            this.setState({
                                amount: Math.round(
                                    Number(e.target.value) * scaleFactor
                                ),
                            })
                        }
                    />
                    <p>{paymentLink}</p>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <CopyToClipboard
                        text={paymentLink}
                        onCopy={() =>
                            showNotification(
                                'Payment link copied to Clipboard',
                                'success'
                            )
                        }
                    >
                        <Button
                            variant="outlined"
                            color="secondary"
                            disabled={!paymentLink}
                        >
                            Copy Link
                        </Button>
                    </CopyToClipboard>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!paymentLink}
                        startIcon={<EmailIcon />}
                        onClick={() => {
                            taskCreate({
                                type: 'SEND_PAYMENT_LINK_TO_CUSTOMER',
                                data: { userId: userId, amount: amount },
                            });
                            this.handleClose();
                        }}
                    >
                        Send by Email
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapState = (state, props) => {
    const record = props.record;
    const userId = props.record && props.record.id;
    const userStats =
        userId &&
        state.admin.resources['user-stats'] &&
        state.admin.resources['user-stats'].data[userId];
    const currency =
        userStats && userStats.billing && userStats.billing.currency;
    const amount =
        userStats && userStats.billing && userStats.billing.amountOutstanding;

    return {
        record,
        userId,
        currency,
        amount,
    };
};

const mapDispatch = {
    showNotification: showNotificationAction,
    taskCreate: taskCreateAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(PaymentLinkDialog);
