import React from 'react';
import { FormDataConsumer } from 'react-admin';
import { DeleteButton } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { SelectInput } from 'react-admin';
import { SaveButton } from 'react-admin';
import { Toolbar } from 'react-admin';
import { FunctionField } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { Edit, TextInput, SimpleForm } from 'react-admin';

const EmailPlaceholderEdit = props => {
    const validate = values => {
        const errors: any = {};

        if (!values.name) {
            errors.name = ['Name is required'];
        }

        return errors;
    };
    return (
        <Edit {...props}>
            <SimpleForm validate={validate} redirect="show">
                <TextInput label="Name" source="name" />
                <TextInput label="Description" source="description" />
                <ReferenceInput
                    label="Country"
                    source="countryId"
                    reference="country"
                    resource="country"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ record, ...rest }) => {
                        return (
                            !record.isGeneric && (
                                <ArrayInput label="Emails" source="emails">
                                    <SimpleFormIterator>
                                        <TextInput
                                            disabled={record.isGeneric}
                                            label="Email"
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        );
                    }}
                </FormDataConsumer>
                <BooleanInput disabled label="Is generic?" source="isGeneric" />
            </SimpleForm>
        </Edit>
    );
};

export default EmailPlaceholderEdit;
