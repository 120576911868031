import React from 'react';
import { Edit, TextInput, SimpleForm } from 'react-admin';
import { TitleProps, Box, ValidationErrors } from '../types';

const RequestLineTitle: React.FC<TitleProps<Box>> = ({ record }) => (
    <span>Request line #{record ? `${record.id}` : ''}</span>
);

const validate = values => {
    const errors: ValidationErrors = {};
    if (values.qtyDone > values.qty) {
        errors.qtyDone = ['Qty Done cannot be more than Qty Line'];
    }
    if (values.qtyInOut > values.qty) {
        errors.qtyInOut = ['Qty WH cannot be more than Qty Line'];
    }
    return errors;
};

const RequestLineEdit = props => {
    return (
        <Edit title={<RequestLineTitle />} undoable={false} {...props}>
            <SimpleForm variant="outlined" redirect="show" validate={validate}>
                <TextInput disabled source="id" />
                <TextInput source="qty" label="Qty Line" />
                <TextInput source="qtyDone" label="Qty Done" />
                <TextInput source="qtyInOut" label="Qty WH" />
            </SimpleForm>
        </Edit>
    );
};

export default RequestLineEdit;
