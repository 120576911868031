import feathersClient from '../rest/feathersClient';

export const modelView = (model: modelViewType, documentId: number) => {
    const modelViewService = feathersClient.service('model-views');
    modelViewService
        .create({
            model,
            documentId,
        })
        .then(res => {});
};

export enum modelViewType {
    INVOICES = 'invoice',
    ORDERS = 'order',
    PAYMENTS = 'payment',
    REQUEST = 'request',
    STORAGES = 'storage',
    CUSTOMERS = 'customer',
    USERS = 'user',
    CHARGE = 'charge',
}
