import * as React from 'react';

function SvgCoupon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.334 16.25a.75.75 0 01.672.418l.856 1.733 1.913.28a.75.75 0 01.415 1.28l-1.384 1.348.327 1.904a.75.75 0 01-1.089.79l-1.71-.899-1.711.9a.75.75 0 01-1.089-.79l.327-1.905-1.384-1.348a.75.75 0 01.415-1.28l1.913-.28.856-1.733a.75.75 0 01.672-.418zm0 2.444l-.358.725a.75.75 0 01-.564.41l-.8.117.578.563a.75.75 0 01.216.664l-.136.796.714-.376a.75.75 0 01.699 0l.714.376-.136-.796a.75.75 0 01.216-.664l.578-.563-.8-.117a.75.75 0 01-.564-.41l-.357-.725z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 13a1 1 0 00-1 1v12a1 1 0 001 1h18a1 1 0 001-1V14a1 1 0 00-1-1H11zm-3 1a3 3 0 013-3h18a3 3 0 013 3v12a3 3 0 01-3 3H11a3 3 0 01-3-3V14z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgCoupon;
