import { CREATE, UPDATE } from 'react-admin';

export const CAPTURE_CREATE = 'CAPTURE_CREATE';
export const CAPTURE_CREATE_LOADING = 'CAPTURE_CREATE_LOADING';
export const CAPTURE_CREATE_FAILURE = 'CAPTURE_CREATE_FAILURE';
export const CAPTURE_CREATE_SUCCESS = 'CAPTURE_CREATE_SUCCESS';

export const captureCreate = (mode, data) => ({
    type: CAPTURE_CREATE,
    payload: { data },
    meta: { resource: `${mode}-capture`, fetch: CREATE, cancelPrevious: false },
});

export const INVOICE_SYNC = 'INVOICE_SYNC';
export const INVOICE_SYNC_LOADING = 'INVOICE_SYNC_LOADING';
export const INVOICE_SYNC_FAILURE = 'INVOICE_SYNC_FAILURE';
export const INVOICE_SYNC_SUCCESS = 'INVOICE_SYNC_SUCCESS';

export const invoiceSync = (id, data) => ({
    type: INVOICE_SYNC,
    payload: { id, data: { ...data, status: 'SYNC_INVOICE' } },
    meta: { resource: 'invoices', fetch: UPDATE, cancelPrevious: false },
});
