import * as React from 'react';

function SvgShoppingCart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 29.89a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2zM25 29.89a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2zM8 9.89a1 1 0 011-1h2.252a3 3 0 012.97 2.61l.315 2.39H31a1 1 0 01.98 1.197l-1.68 8.388v.002a3 3 0 01-2.989 2.414H16.754a3 3 0 01-2.995-2.61m0 0l-1.52-11.52a1 1 0 00-.99-.87H9a1 1 0 01-1-1m6.8 6l.941 7.129c.066.5.495.875 1 .87H27.34a1 1 0 001-.804v-.002l1.44-7.193H14.8z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgShoppingCart;
