import { CREATE } from 'react-admin';

export const COMMENT_CREATE = 'COMMENT_CREATE';
export const COMMENT_CREATE_LOADING = 'COMMENT_CREATE_LOADING';
export const COMMENT_CREATE_FAILURE = 'COMMENT_CREATE_FAILURE';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';

export const commentCreate = (comment, model, documentId, userId) => ({
    type: COMMENT_CREATE,
    payload: { data: comment, model, documentId, userId },
    meta: { resource: 'comments', fetch: CREATE, cancelPrevious: false },
});
