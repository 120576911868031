import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { EmailPlaceholdertSummary } from './EmailPlaceholderSummary';

export const EmailPlaceholderTabs = props => (
    <TabbedShowLayout {...props}>
        <Tab label="Email placeholder">
            <EmailPlaceholdertSummary />
        </Tab>
    </TabbedShowLayout>
);

EmailPlaceholderTabs.propTypes = {
    record: PropTypes.object,
};

EmailPlaceholderTabs.defaultProps = {
    record: {},
};
