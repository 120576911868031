import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    CAPTURE_CREATE_SUCCESS,
    CAPTURE_CREATE_FAILURE,
    INVOICE_SYNC_SUCCESS,
    INVOICE_SYNC_FAILURE,
} from './invoiceActions';

export default function* invoiceSaga() {
    yield all([
        takeEvery(CAPTURE_CREATE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.invoices.notification.capture_success'
                )
            );
        }),
        takeEvery(CAPTURE_CREATE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.invoices.notification.capture_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(INVOICE_SYNC_SUCCESS, function* () {
            yield put(
                showNotification('resources.invoices.notification.sync_success')
            );
        }),
        takeEvery(INVOICE_SYNC_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.invoices.notification.sync_error',
                    'warning'
                )
            );
            console.error(error);
        }),
    ]);
}
