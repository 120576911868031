import React from 'react';
import Location from './Location';
import { FieldProps, Storage } from '../../types';

const StorageLocation: React.FC<FieldProps<Storage>> = ({ record }) => {
    const storageRecord =
        record &&
        record.storagerecords &&
        !!record.storagerecords.length &&
        record.storagerecords[0];

    if (storageRecord) {
        if (storageRecord.status === 'out') {
            return <span>OUT</span>;
        }

        if (storageRecord.status === 'in' && storageRecord.locationId) {
            return <Location record={storageRecord} label="Location" />;
        }

        return <span>IN</span>;
    }

    return null;
};

export default StorageLocation;
