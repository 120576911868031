import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { History } from '../../revisions';
import { Comments } from '../../comments';
import { CardSummary } from './CardSummary';

export const CardTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <CardSummary />
            </Tab>
            <Tab label="Comments">
                <Comments
                    {...props}
                    model="card"
                    documentId={record.id}
                    userId={record.userId}
                />
            </Tab>
            <Tab label="History">
                <History {...props} model="card" documentId={record.id} />
            </Tab>
        </TabbedShowLayout>
    );
};

CardTabs.propTypes = {
    record: PropTypes.object,
};

CardTabs.defaultProps = {
    record: {},
};
