import React from 'react';
import moment from 'moment';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
    List,
    Datagrid,
    downloadCSV,
    TextField,
    DateField,
    ShowButton,
    TextInput,
    Filter,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    DateInput,
} from 'react-admin';

import MoneyField from '../components/MoneyField';
import CustomSearchInput from '../components/CustomSearchInput';
import UserReferenceField from '../components/UserReferenceField';
import BoxitChipField from '../components/BoxitChipField';
import BoxitPagination from '../components/BoxitPagination';
import ChipField from '../components/ChipField';
import { InvoiceStatus, InvoiceType } from '../constants';
import InvoiceNo from './components/InvoiceNo';
import CountryField from '../components/CountryField';
import { formatMoney } from '../util/formatMoney';
import { SelectArrayInput } from 'react-admin';
import { FunctionField } from 'react-admin';

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'countryId', 'country').then(countries => {
        const data = records.map(record => ({
            ...record,
            amountBeforeTax:
                record.type === 'creditNote'
                    ? -formatMoney(record.amountBeforeTax, record.currency)
                    : formatMoney(record.amountBeforeTax, record.currency),
            taxAmount:
                record.type === 'creditNote'
                    ? -formatMoney(record.taxAmount, record.currency)
                    : formatMoney(record.taxAmount, record.currency),
            amount:
                record.type === 'creditNote'
                    ? -formatMoney(record.amount, record.currency)
                    : formatMoney(record.amount, record.currency),
            amountPaid:
                record.type === 'creditNote'
                    ? formatMoney(record.amountPaid, record.currency)
                    : formatMoney(record.amountPaid, record.currency),
            country:
                countries[record.countryId] && countries[record.countryId].name,
            name: record.user.name,
            email: record.user.email,
        }));
        const csv = convertToCSV({
            data,
            fields: [
                'id',
                'date',
                'status',
                'currency',
                'amountBeforeTax',
                'taxAmount',
                'amount',
                'amountPaid',
                'paidAt',
                'name',
                'email',
                'country',
            ],
        });
        downloadCSV(csv, `Invoices_${Date.now()}`);
    });
};

const InvoiceFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />

        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={Object.keys(InvoiceStatus).map(c => ({ id: c, name: c }))}
        />

        <SelectInput
            source="type"
            choices={Object.keys(InvoiceType).map(c => ({ id: c, name: c }))}
        />

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>

        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>

        <DateInput
            source="date[$gte]"
            label="Date From"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
        <DateInput
            source="date[$lte]"
            label="Date To"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
    </Filter>
);

const InvoiceList = props => (
    <List
        title="Invoices"
        bulkActionButtons={false}
        exporter={exporter}
        filters={<InvoiceFilter />}
        pagination={<BoxitPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <InvoiceNo label="No" />
            <DateField
                locales="en-IN"
                source="createdAt"
                showTime
                label="Created"
            />
            <DateField locales="en-IN" source="date" />
            <ReferenceField
                label="Partner"
                source="partnerId"
                reference="partners"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <UserReferenceField label="Customer" />
            <ChipField source="type" />
            <FunctionField
                label="Amount"
                render={record =>
                    record.type === 'creditNote' ? (
                        <>
                            <small style={{ display: 'block' }}>
                                {record.currency}
                            </small>
                            -{formatMoney(record.amount, record.currency)}
                        </>
                    ) : (
                        <>
                            <small style={{ display: 'block' }}>
                                {record.currency}
                            </small>
                            {formatMoney(record.amount, record.currency)}
                        </>
                    )
                }
            />
            <FunctionField
                label="Paid"
                render={record =>
                    record.type === 'creditNote' ? (
                        <>
                            <small style={{ display: 'block' }}>
                                {record.currency}
                            </small>
                            {formatMoney(record.amountPaid, record.currency)}
                        </>
                    ) : (
                        <>
                            <small style={{ display: 'block' }}>
                                {record.currency}
                            </small>
                            {formatMoney(record.amountPaid, record.currency)}
                        </>
                    )
                }
            />
            <BoxitChipField record source="status" list="invoice" />
            <DateField locales="en-IN" source="paidAt" label="Paid On" />
            <CountryField />
            <ShowButton />
        </Datagrid>
    </List>
);

InvoiceList.defaultProps = {
    resource: 'teams',
    location: { search: '' },
};

export default InvoiceList;
