import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ReferenceField } from 'react-admin';

const CountryField = ({ basePath, record, source }) => (
    <ReferenceField
        basePath={basePath}
        record={record}
        source={source}
        reference="country"
        link={false}
    >
        <TextField source="name" />
    </ReferenceField>
);

CountryField.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string,
    label: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
};

CountryField.defaultProps = {
    basePath: '/country',
    record: {},
    source: 'countryId',
    label: 'Country',
};

export default CountryField;
