import React, { useEffect, useRef, useState } from 'react';
import {
    AutocompleteInput,
    Create,
    NumberInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
} from 'react-admin';
import { ValidationErrors } from '../types';

const cbmValidation = (value, allValues) => {
    console.log(value);
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const transform = data => {
    const transformData = { ...data };

    if (!data.size && data.length && data.width && data.height) {
        transformData.size = (data.length * data.height * data.width).toFixed(
            8
        );
    }

    if (!data.consumedSize && data.length && data.width && data.height) {
        transformData.consumedSize = (
            data.length *
            data.height *
            data.width
        ).toFixed(8);
    }
    /*return {
        ...data,
        size:
            !data.size && data.length && data.width && data.height
                ? (data.length * data.height * data.width).toFixed(8)
                : data.size
                ? data.size
                : undefined,
        consumedSize:
            !data.consumedSize && data.length && data.width && data.height
                ? (data.length * data.height * data.width).toFixed(8)
                : data.consumedSize
                ? data.consumedSize
                : undefined,
    };*/

    return transformData;
};

const StorageTypeCreate = props => {
    const [isZeroEnabled, setIsZeroEnabled] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isStorageInventory, setIsStorageInventory] = useState(false);
    const [size, setSize] = useState<any>(null);
    const [length, setLength] = useState<any>(null);
    const [height, setHeight] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);

    const validator = values => {
        const errors: ValidationErrors = {};

        if (!values.code) {
            errors.code = ['resources.storagetypes.errors.code_required'];
        }

        if (!values.name) {
            errors.name = ['resources.storagetypes.errors.name_required'];
        }
        /*
    if (!values.size) {
        errors.size = ['resources.storagetypes.errors.size_required'];
    }*/

        return errors;
    };

    useEffect(() => {
        console.log({
            length,
            height,
            width,
            isStorageInventory,
        });
        if (length || height || width || size) {
            if (
                (parseFloat(length) === 0 ||
                    parseFloat(height) === 0 ||
                    parseFloat(width) === 0 ||
                    parseFloat(size) === 0) &&
                isStorageInventory
            ) {
                setIsConfirmationOpen(true);
            } else {
                setIsZeroEnabled(false);
            }
        }
    }, [length, height, width, size, isStorageInventory]);

    /*useEffect(() => {
        let sizeInput: any = document.querySelector(
            '.sizeInputClass .MuiInputBase-root .MuiInputBase-input'
        );
        sizeInput.focus();
        let currentValue = sizeInput.value;
        sizeInput.value = '';
        sizeInput.value = currentValue;
        sizeInput.blur();
    }, [isZeroEnabled]);*/

    const canBeZeroValidation = (value, allValues) => {
        if (!value && (!height || !width || !length)) {
            return 'Size must be added if height or width or length is not added';
        }
        if (value !== null && value !== '' && value <= 0 && !isZeroEnabled) {
            return 'Value must be more than 0 up to 10';
        }
        if (value !== null && value !== '' && value > 10 && !isZeroEnabled) {
            return 'Value must be more than 0 up to 10';
        }
        if (
            value !== undefined &&
            value !== null &&
            value !== '' &&
            value.toString().length > 0 &&
            value.toString().includes('.') &&
            value.toString().split('.')[1] &&
            value.toString().split('.')[1].length > 8
        ) {
            return 'Only 8 digits after the decimal points are allowed';
        }
        if (
            (value &&
                height &&
                width &&
                length &&
                parseFloat(value) - 0.000001 >
                    parseFloat(height) *
                        parseFloat(width) *
                        parseFloat(length)) ||
            parseFloat(value) + 0.000001 <
                parseFloat(height) * parseFloat(width) * parseFloat(length)
        ) {
            return `Size is not equal to height*width*length`;
        }
        return undefined;
    };

    const eightDigitsValidation = (value, allValues) => {
        if (value !== null && value <= 0) {
            return 'Value must be more than 0 up to 10';
        }
        if (value !== null && value > 10) {
            return 'Value must be more than 0 up to 10';
        }
        if (
            value !== undefined &&
            value !== null &&
            value.toString().length > 0 &&
            value.toString().includes('.') &&
            value.toString().split('.')[1] &&
            value.toString().split('.')[1].length > 8
        ) {
            return 'Only 8 digits after the decimal points are allowed';
        }
        return undefined;
    };

    const canBeZeroDimValidation = (value, allValues) => {
        if (value !== null && value <= 0 && !isZeroEnabled) {
            return 'Value must be more than 0 up to 10';
        }
        if (value !== null && value > 10 && !isZeroEnabled) {
            return 'Value must be more than 0 up to 10';
        }
        if (
            value !== undefined &&
            value !== null &&
            value.toString().length > 0 &&
            value.toString().includes('.') &&
            value.toString().split('.')[1] &&
            value.toString().split('.')[1].length > 3
        ) {
            return 'Only 3 digits after the decimal points are allowed';
        }
        return undefined;
    };
    return (
        <Create {...props} transform={transform}>
            <SimpleForm variant="outlined" validate={validator} redirect="show">
                <TextInput disabled source="partnerId" defaultValue="1" />
                <ReferenceInput
                    label="Customer"
                    source="userId"
                    reference="users"
                    resource="users"
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 1}
                        optionText="name"
                    />
                </ReferenceInput>
                <TextInput source="code" />
                <TextInput source="name" />
                <NumberInput
                    source="consumedSize"
                    validate={canBeZeroValidation}
                    className="sizeInputClass"
                />
                <NumberInput
                    source="size"
                    validate={canBeZeroValidation}
                    parse={value => {
                        setSize(value);
                        return value;
                    }}
                    className="sizeInputClass"
                />
                <NumberInput
                    source="length"
                    label="Length"
                    validate={canBeZeroDimValidation}
                    parse={value => {
                        setLength(value);
                        return value;
                    }}
                />
                <NumberInput
                    source="height"
                    label="Height"
                    validate={canBeZeroDimValidation}
                    parse={value => {
                        setHeight(value);
                        return value;
                    }}
                />
                <NumberInput
                    source="width"
                    label="Width"
                    validate={canBeZeroDimValidation}
                    parse={value => {
                        setWidth(value);
                        return value;
                    }}
                />
                <BooleanInput
                    source="inventory"
                    label="Inventory"
                    defaultValue={false}
                    parse={value => {
                        setIsStorageInventory(value);
                        if (value) {
                            setIsZeroEnabled(false);
                        }
                        return value;
                    }}
                />
                {isConfirmationOpen && isStorageInventory && !isZeroEnabled ? (
                    <div
                        style={{
                            backgroundColor: 'rgba(0,0,0,.5)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100vw',
                            height: '100vh',
                            zIndex: 100000,
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: '4px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '40rem',
                                padding: '1rem',
                                gap: '1rem',
                            }}
                        >
                            <p style={{ textAlign: 'center' }}>
                                Setting the size of this storage type to zero
                                will result in no charges for all storages
                                associated with this type; are you sure you want
                                to proceed?
                            </p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <button
                                    style={{
                                        backgroundColor: '#1791FF',
                                        padding: '.25rem 1rem',
                                        color: 'white',
                                        borderRadius: '3px',
                                    }}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsZeroEnabled(true);
                                        setIsConfirmationOpen(false);
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        setIsConfirmationOpen(false);
                                    }}
                                >
                                    cancel
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </SimpleForm>
        </Create>
    );
};

export default StorageTypeCreate;
