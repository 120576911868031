import React from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import {
    requestPickup as requestPickupAction,
    requestReceive as requestReceiveAction,
} from '../actions/requestActions';
import {
    StorageStatus,
    RequestType,
    RequestStatus,
    StorageType,
} from '../../constants';
import {
    Button,
    Dialog,
    DialogActions,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import { Request } from '../../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    record: Request;
    onClose: () => void;
};

type State = {
    emptyStorageIds: number[];
};

class BoxStatusForm extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            emptyStorageIds: [],
        };

        this.processRequest = this.processRequest.bind(this);
        this.toggleEmpty = this.toggleEmpty.bind(this);
    }

    processRequest() {
        const { requestPickup, requestReceive, record } = this.props;

        if (
            record.type === RequestType.pickup &&
            record.status === RequestStatus.approved
        ) {
            requestPickup(record.id, {
                ...record,
                emptyStorageIds: this.state.emptyStorageIds,
            });
        } else if (
            (record.type === RequestType.pickup &&
                record.status === RequestStatus.transit) ||
            (record.type === RequestType.drop &&
                record.status === RequestStatus.approved)
        ) {
            requestReceive(record.id, {
                ...record,
                emptyStorageIds: this.state.emptyStorageIds,
            });
        }

        this.props.onClose();
    }

    toggleEmpty(storageId, state) {
        let emptyStorageIds = this.state.emptyStorageIds;

        if (state === false) {
            emptyStorageIds.push(storageId);
        }
        if (state === true) {
            emptyStorageIds = emptyStorageIds.filter(s => s !== storageId);
        }

        this.setState({
            emptyStorageIds: _.uniq(emptyStorageIds),
        });
    }

    render() {
        const { record, open = false } = this.props;

        if (!record || !record.storages) {
            return null;
        }

        return (
            <Dialog
                title="Boxit Box Status"
                open={!!open}
                onClose={() => {
                    this.props.onClose();
                }}
            >
                <p>Select Filled Boxes</p>
                {record.storages
                    .filter(
                        s =>
                            s.status !== StorageStatus.closed &&
                            s.status !== StorageStatus.cancelled
                    )
                    .map(storage => (
                        <FormControlLabel
                            control={
                                <Switch
                                    key={storage.id}
                                    disabled={
                                        storage.storagetypeId !== StorageType.BB
                                    }
                                    onChange={(e, v) =>
                                        this.toggleEmpty(storage.id, v)
                                    }
                                />
                            }
                            label={`${storage.storagetypeId} - ${storage.code}`}
                        />
                    ))}
                <DialogActions>
                    <Button onClick={() => this.props.onClose()}>Cancel</Button>
                    <Button color="secondary" onClick={this.processRequest}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatch = {
    requestPickup: requestPickupAction,
    requestReceive: requestReceiveAction,
};

const connector = connect(null, mapDispatch);

export default connector(BoxStatusForm);
