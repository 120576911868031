import React, { useEffect } from 'react';

import { Show } from 'react-admin';
import { ChargeShowButtons } from './components/';
import { ChargeTabs } from './components/ChargeTabs';
import { modelView, modelViewType } from '../util/modelView';

const ChargeShow = props => {
    useEffect(() => {
        modelView(modelViewType.CHARGE, parseInt(props.id));
    }, [props.id]);
    return (
        <Show actions={<ChargeShowButtons />} {...props}>
            <ChargeTabs />
        </Show>
    );
};

export default ChargeShow;
