import React from 'react';
import moment from 'moment';
import {
    ChipField,
    List,
    Datagrid,
    EmailField,
    TextField,
    ShowButton,
    Filter,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    DateInput,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import BoxitPagination from '../components/BoxitPagination';
import BoxitChipField from '../components/BoxitChipField';
import MoneyField from '../components/MoneyField';
import CountryField from '../components/CountryField';
import { UserType, UserStatus, Source } from '../constants';
import { ListProps, User } from '../types';
import UserReferenceField from '../components/UserReferenceField';
import { SelectArrayInput } from 'react-admin';
import AndOrInput from '../components/AndOrInput';
import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from 'react-admin';
import { FunctionField } from 'react-admin';

const UserFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
            {/*<AndOrInput label="Tags" alwaysOn />*/}
            <ReferenceInput
                source="partnerId"
                reference="partners"
                resource="partners"
                label="Partner"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="countryId"
                reference="country"
                resource="country"
                label="Country"
            >
                <SelectInput />
            </ReferenceInput>

            <SelectArrayInput
                style={{ minWidth: '10rem' }}
                source="status"
                choices={Object.keys(UserStatus).map(c => ({ id: c, name: c }))}
            />
            <ReferenceArrayInput
                source="tagsand"
                reference="tags"
                label="Tags (And)"
            >
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <ReferenceArrayInput
                source="tagsor"
                reference="tags"
                label="Tags (Or)"
            >
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <SelectInput
                source="type"
                choices={Object.keys(UserType).map(c => ({ id: c, name: c }))}
            />
            <DateInput
                source="createdAt[$gte]"
                label="Created From"
                parse={v => v && moment(v).startOf('day').format()}
            />
            <DateInput
                source="createdAt[$lte]"
                label="Created To"
                parse={v => v && moment(v).endOf('day').format()}
            />
            <SelectInput
                source="source"
                choices={Object.keys(Source).map(c => ({
                    id: c,
                    name: Source[c],
                }))}
            />
            <ReferenceInput
                source="assigneeId"
                reference="users"
                resource="users"
                label="Assignee"
                filter={{ 'roles[$contains]': ['sales-staff'] }}
            >
                <SelectInput />
            </ReferenceInput>
        </Filter>
    );
};

const UserList: React.FC<ListProps<User>> = ({
    permissions = [],
    ...props
}) => (
    <List
        title="Users"
        bulkActionButtons={false}
        filters={<UserFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<BoxitPagination />}
        perPage={10}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <ReferenceField
                label="Partner"
                source="partnerId"
                reference="partners"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <ChipField source="type" />
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="phone" />
            <UserReferenceField source="assigneeId" label="Assignee" />
            <CountryField />
            {permissions.indexOf('partner') === -1 && (
                <MoneyField source="credits" showCurrency />
            )}
            <FunctionField
                label="Tags"
                render={record =>
                    record.tags
                        ? record.tags.length > 3
                            ? record.tags
                                  .map(item => item.name)
                                  .slice(0, 3)
                                  .join(', ') + '...'
                            : record.tags
                                  .map(item => item.name)
                                  .slice(0, 3)
                                  .join(', ')
                        : ''
                }
            />
            <BoxitChipField record source="status" list="user" />
            {props.hasShow && <ShowButton style={{ padding: 0 }} />}
        </Datagrid>
    </List>
);

UserList.defaultProps = {
    resource: 'users',
    location: { search: '' },
};

export default UserList;
