import React from 'react';

import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
} from 'react-admin';

import { OfferType } from '../constants';

const OfferCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="id" />

            <SelectInput
                source="type"
                choices={Object.keys(OfferType).map(c => ({ id: c, name: c }))}
            />
            <TextInput source="description" />
            <NumberInput source="discount" />
            <NumberInput source="percent" />
            <NumberInput source="limit" />
            <NumberInput source="reward" />

            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default OfferCreate;
