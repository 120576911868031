import React from 'react';

import {
    Create,
    SimpleForm,
    AutocompleteInput,
    ReferenceInput,
} from 'react-admin';

const StaffCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined" redirect="list">
            <ReferenceInput
                label="User"
                source="userId"
                reference="staff"
                resource="users"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default StaffCreate;
