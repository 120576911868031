import React, { useEffect } from 'react';
import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
} from 'react-admin';
import { ChargeType, CurrencyByCountryId } from '../constants';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';
import { CreateProps, Charge, ValidationErrors } from '../types';
import useLocalStorage from '../hooks/useLocalStorage';
import { useGetOne } from 'react-admin';
import { useSelector } from 'react-redux';
import { required } from 'react-admin';

const validate = values => {
    const errors: ValidationErrors = {};

    if (!values.countryId) {
        errors.countryId = ['Country is required'];
    }

    if (!values.userId) {
        errors.userId = ['User is required'];
    }

    if (!values.type) {
        errors.type = ['Type is required'];
    }

    if (
        values.type !== 'discount' &&
        values.type !== 'custom' &&
        !values.orderId
    ) {
        errors.orderId = ['Order is required'];
    }

    if (!values.currency) {
        errors.currency = ['Currency is required'];
    }

    if (isNaN(values.amount)) {
        errors.amount = ['Amount is required'];
    } else if (values.amount <= 0) {
        errors.amount = ['Amount must be a positive number'];
    }

    if (!values.year) {
        errors.year = ['Year is required'];
    }

    if (!values.month) {
        errors.month = ['Month is required'];
    }

    if (!values.startDate) {
        errors.startDate = ['Start Date is required'];
    }

    if (!values.endDate) {
        errors.endDate = ['End Date is required'];
    }

    if (!values.requestId) {
        errors.requestId = ['Request is required'];
    }

    if (['custom', 'discount', 'credit'].indexOf(values.type) !== -1) {
        if (!values.description) {
            errors.description = [`Description is required`];
        }
    }

    return errors;
};

const currentYear = new Date().getFullYear();
const yearChoices = [
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
];

const ChargeCreate: React.FC<CreateProps<Charge>> = ({
    permissions = [],
    ...props
}) => {
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);
    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.charges?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });

    useEffect(() => {
        localStorage.removeItem('chargeUserId');
        localStorage.removeItem('chargeRequestType');
        localStorage.removeItem('chargeRequestId');
        localStorage.removeItem('chargeRequestOrderId');
    }, []);
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" validate={validate} redirect="show">
                <ReferenceInput
                    label="Country"
                    source="countryId"
                    reference="country"
                    resource="country"
                    defaultValue={currentUser?.countryId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    label="User"
                    source="userId"
                    reference="users"
                    resource="users"
                    perPage={100}
                    defaultValue={
                        parseInt(localStorage.getItem('chargeUserId') as any) ||
                        selectedCustomer?.id
                    }
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                        defaultValue={
                            localStorage.getItem('chargeUserId') ||
                            selectedCustomer?.id
                        }
                    />
                </ReferenceInput>

                <SelectInput
                    source="type"
                    validate={required()}
                    /*defaultValue={
                        localStorage.getItem('chargeRequestType') as any
                    }*/
                    choices={Object.keys(ChargeType).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Order"
                                source="orderId"
                                reference="orders"
                                resource="orders"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                                defaultValue={parseInt(
                                    localStorage.getItem(
                                        'chargeRequestOrderId'
                                    ) as any
                                )}
                            >
                                <SelectInput
                                    source="orderId"
                                    optionValue="id"
                                    optionText={record => `Order #${record.id}`}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Invoice"
                                source="invoiceId"
                                reference="invoices"
                                resource="invoices"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                            >
                                <SelectInput
                                    source="invoiceId"
                                    optionValue="id"
                                    optionText={record =>
                                        `Invoice #${record.id}`
                                    }
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Request"
                                source="requestId"
                                reference="requests"
                                resource="requests"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                                defaultValue={parseInt(
                                    localStorage.getItem(
                                        'chargeRequestId'
                                    ) as any
                                )}
                            >
                                <SelectInput
                                    source="requestId"
                                    optionText={r =>
                                        `#${r.id} - ${r.type} on ${r.date}`
                                    }
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <CurrencyInput
                    defaultValue={CurrencyByCountryId[currentUser?.countryId]}
                />

                <MoneyInput source="amount" />

                <SelectInput
                    source="year"
                    choices={yearChoices.map(a => ({
                        id: a,
                        name: a,
                    }))}
                    translateChoice={false}
                    initialValue={currentYear ? currentYear : null}
                />

                <SelectInput
                    source="month"
                    choices={Array.from(Array(12).keys()).map(a => ({
                        id: a + 1,
                        name: a + 1,
                    }))}
                    translateChoice={false}
                />

                <SelectInput
                    source="startDate"
                    label="Start Date"
                    choices={Array.from(Array(31).keys()).map(a => ({
                        id: a + 1,
                        name: a + 1,
                    }))}
                    translateChoice={false}
                />

                <SelectInput
                    source="endDate"
                    label="End Date"
                    choices={Array.from(Array(31).keys()).map(a => ({
                        id: a + 1,
                        name: a + 1,
                    }))}
                    translateChoice={false}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        ['custom', 'discount', 'credit'].indexOf(
                            formData.type
                        ) !== -1 && (
                            <TextInput
                                source="description"
                                label="Description"
                            />
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default ChargeCreate;
