import React from 'react';
import { Show } from 'react-admin';
import EmailTemplateShowActions from './components/EmailTemplateShowActions';
import { EmailTemplateTabs } from './components/EmailTemplateTabs';

const EmailTemplateShow = props => (
    <Show actions={<EmailTemplateShowActions />} {...props}>
        <EmailTemplateTabs />
    </Show>
);

export default EmailTemplateShow;
