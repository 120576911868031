import React from 'react';
import {
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    ReferenceField,
} from 'react-admin';
import UserReferenceField from '../../components/UserReferenceField';
import CityField from '../../components/CityField';
import DeleteButton from '../../components/DeleteButton';

const AddressGrid = props => (
    <Datagrid {...props}>
        <TextField source="serial" sortable={false} />
        <TextField source="id" />
        <ReferenceField
            label="Partner"
            source="partnerId"
            reference="partners"
            link="show"
        >
            <TextField source="name" />
        </ReferenceField>
        <UserReferenceField label="Customer" />
        <TextField source="name" />
        <TextField source="line1" label="Line" />
        <TextField source="area" />
        <CityField />
        <DeleteButton undoable={false} />
        <ShowButton />
    </Datagrid>
);

export default AddressGrid;
