import React from 'react';
import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

const LocationCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm variant="outlined" redirect="show">
            <TextInput source="area" />
            <TextInput source="aisle" />
            <TextInput source="x" />
            <TextInput source="y" />
            <TextInput source="z" />
            <ReferenceInput
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                resource="warehouses"
                allowEmpty
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default LocationCreate;
