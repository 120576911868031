import React from 'react';
import PropTypes from 'prop-types';

const PhotoSummary = props => {
    const { record } = props;

    return (
        <div>
            <img src={props.record.photo} />
        </div>
    );
};

PhotoSummary.propTypes = {
    record: PropTypes.object,
};

PhotoSummary.defaultProps = {
    record: {},
};

export default PhotoSummary;
