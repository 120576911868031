import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceField, TextField } from 'react-admin';
import { FieldProps } from '../types';

const UserReferenceField: React.FC<FieldProps<any>> = ({
    record,
    source = '',
    link,
}) => {
    if (record && record[source]) {
        return (
            <ReferenceField
                record={record}
                source={source}
                reference="users"
                basePath="/users"
                link={link}
            >
                <TextField source="name" />
            </ReferenceField>
        );
    }
    return null;
};

UserReferenceField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string,
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    addLabel: PropTypes.bool,
};

UserReferenceField.defaultProps = {
    record: {},
    source: 'userId',
    link: 'show',
    addLabel: true,
};

export default UserReferenceField;
