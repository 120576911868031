import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import CityField from '../../components/CityField';
import CountryField from '../../components/CountryField';
import { ReferenceField } from 'react-admin';
import { TextField } from 'react-admin';

const WarehouseSummary = props => {
    const { record } = props;

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <label>Warehouse Id</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.id}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Name</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.name}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>City</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <CityField record={record} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Country</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <CountryField record={record} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Members</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <ReferenceArrayField
                            record={record}
                            label="Members"
                            reference="users"
                            source="users"
                            basePath="/users"
                            resource="users"
                        >
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Active</label>
                    </TableCell>
                    {record && record.active && (
                        <TableCell style={{ textAlign: 'right' }}>
                            Yes
                        </TableCell>
                    )}
                    {record && !record.active && (
                        <TableCell style={{ textAlign: 'right' }}>No</TableCell>
                    )}
                </TableRow>
                {record.inLocationId && (
                    <TableRow>
                        <TableCell>
                            <label>In location</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="In location"
                                source="inLocationId"
                                reference="locations"
                                link="show"
                            >
                                <TextField source="path" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                )}
                {record.outLocationId && (
                    <TableRow>
                        <TableCell>
                            <label>out location</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="In location"
                                source="outLocationId"
                                reference="locations"
                                link="show"
                            >
                                <TextField source="path" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

WarehouseSummary.propTypes = {
    record: PropTypes.object,
};

export default WarehouseSummary;
