import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';

const styles = theme => ({
    Button: {
        marginTop: theme.spacing(1),
    },
});

type Props = {
    invalid?: boolean;
    saving?: boolean;
    onSubmit: (values: object) => void;
};

type State = {
    comment: string;
};

class CommentCreate extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
        };
    }

    handleClick = e => {
        const { invalid, saving, onSubmit } = this.props;
        const { comment } = this.state;

        if (saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            if (invalid) {
                console.log('ra.message.invalid_form');
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault();
            }
            onSubmit({ comment });
        }
    };

    render() {
        return (
            <form noValidate>
                <div>
                    <TextField
                        name="comment"
                        placeholder="Type your comment here.."
                        multiline
                        fullWidth
                        rowsMax={4}
                        onChange={e =>
                            this.setState({ comment: e.target.value })
                        }
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.handleClick}
                    >
                        Add Comment
                    </Button>
                </div>
            </form>
        );
    }
}

export default CommentCreate;
