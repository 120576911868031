import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { RequestLineSummary } from './components/RequestLineSummary';
import { TitleProps, Storage } from '../types';

const RequestLineTitle: React.FC<TitleProps<Storage>> = ({ record }) => (
    <span>
        {record!.name ? `${record!.name}` : `Request line #${record!.id}`}
    </span>
);

const RequestLineShow = props => (
    <Show title={<RequestLineTitle />} {...props}>
        <SimpleShowLayout>
            <RequestLineSummary />
        </SimpleShowLayout>
    </Show>
);

export default RequestLineShow;
