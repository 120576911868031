import React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import CardIcon from './CardIcon';
import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    title: {
        padding: '0 16px',
    },
});

export default ({ value }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.main}>
            <CardIcon Icon={DollarIcon} bgColor="#31708f" />
            <Card className={classes.card}>
                <Typography
                    variant="body2"
                    className={classes.title}
                    color="textSecondary"
                >
                    {translate('pos.dashboard.monthly_revenue')}
                </Typography>
                <Typography variant="h5">{value}</Typography>
            </Card>
        </div>
    );
};
