import React, { useState, useEffect, FC, Fragment } from 'react';
import moment from 'moment';
import { Title, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PresetDateRangePicker from '../components/date/PresetDateRangePicker';
import isSameDay from '../components/date/utils/isSameDay';
import SurveyTable from './components/SurveyTable';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    icon: { width: '2em', height: '2em' },
    welcome: { marginBottom: '2em' },
    flex: { display: 'flex', marginBottom: '1em' },
    leftCol: { flex: 1, paddingRight: '1em' },
    rightCol: { flex: 1 },
    textField: {
        marginLeft: '1em',
        marginRight: '1em',
        width: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
}));

interface Props {
    needCountry?: boolean;
}

const SurveyAnalytics: FC<Props> = ({ needCountry }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const presets = [
        {
            text: 'Today',
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('day').subtract(1, 'day'),
            endPrev: moment().endOf('day').subtract(1, 'day'),
        },
        {
            text: 'This Month',
            start: moment().startOf('month'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('month').subtract(1, 'month'),
            endPrev: moment().endOf('day').subtract(1, 'month'),
        },
        {
            text: 'Last Month',
            start: moment().subtract(1, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
            startPrev: moment().subtract(2, 'month').startOf('month'),
            endPrev: moment().subtract(2, 'month').endOf('month'),
        },
        {
            text: 'This Year',
            start: moment().startOf('year'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('year').subtract(1, 'year'),
            endPrev: moment().endOf('day').subtract(1, 'year'),
        },
    ];
    const initialPreset =
        presets.find(i => i.text === 'This Month') || presets[0];

    const [startDate, setStartDate] = useState(initialPreset.start);
    const [endDate, setEndDate] = useState(initialPreset.end);
    const [startDatePrev, setStartDatePrev] = useState(initialPreset.startPrev);
    const [endDatePrev, setEndDatePrev] = useState(initialPreset.endPrev);
    const [countryId, setCountryId] = useState('1');
    const [exitSurveyReasons, setexitSurveyReason] = useState<any[]>([]);
    const [exitSurveyImprovements, setexitSurveyImprovement] = useState<any[]>(
        []
    );
    const [error, setError] = useState();

    useEffect(() => {
        if (startDate && endDate && endDate.isAfter(startDate)) {
            dataProvider
                .getList('analytics', {
                    filter: {
                        id: 'leadsurvey',
                        startDate: startDate.format(),
                        endDate: endDate.format(),
                    },
                })
                .then(({ data }) => {
                    setexitSurveyReason(data[0].data.exitSurveyReason);
                    setexitSurveyImprovement(
                        data[0].data.exitSurveyImprovement
                    );
                })
                .catch(error => {
                    setError(error);
                });
        }
    }, [
        dataProvider,
        startDate,
        endDate,
        startDatePrev,
        endDatePrev,
        countryId,
    ]);
    return (
        <Fragment>
            <Title title={'Survey'} />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <form className="flex justify-end" noValidate>
                            <div>
                                <div className="flex">
                                    <TextField
                                        select
                                        id="countryId"
                                        label="Country"
                                        size="small"
                                        variant="outlined"
                                        className={classes.textField}
                                        value={countryId}
                                        onChange={event => {
                                            setCountryId(event.target.value);
                                        }}
                                    >
                                        {!needCountry && (
                                            <MenuItem key={0} value={0}>
                                                All
                                            </MenuItem>
                                        )}
                                        <MenuItem key={1} value={1}>
                                            UAE
                                        </MenuItem>
                                        <MenuItem key={2} value={2}>
                                            Kuwait
                                        </MenuItem>
                                        <MenuItem key={3} value={3}>
                                            KSA
                                        </MenuItem>
                                    </TextField>
                                    <PresetDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        startDateId={'startDate'}
                                        endDateId={'endDate'}
                                        onDatesChange={({
                                            startDate,
                                            endDate,
                                        }) => {
                                            if (startDate) {
                                                setStartDate(
                                                    moment(startDate).startOf(
                                                        'day'
                                                    )
                                                );
                                            }
                                            if (endDate) {
                                                setEndDate(
                                                    moment(endDate).endOf('day')
                                                );
                                            }

                                            if (startDate && endDate) {
                                                const preset = presets.find(
                                                    i =>
                                                        isSameDay(
                                                            i.start,
                                                            startDate
                                                        ) &&
                                                        isSameDay(
                                                            i.end,
                                                            endDate
                                                        )
                                                );

                                                if (preset) {
                                                    setStartDatePrev(
                                                        preset.startPrev
                                                    );
                                                    setEndDatePrev(
                                                        preset.endPrev
                                                    );
                                                }
                                            }
                                        }}
                                        isOutsideRange={day =>
                                            day.isAfter(moment().endOf('day'))
                                        }
                                        presets={presets}
                                    />
                                </div>
                            </div>
                        </form>
                    </Grid>
                    <SurveyTable
                        title={'Lost Customers Survey - Reason'}
                        count={'Count'}
                        items={exitSurveyReasons}
                    />
                    <SurveyTable
                        title={'Lost Customers Survey - Comments'}
                        count={'Count'}
                        items={exitSurveyImprovements}
                    />
                </Grid>
            </div>
        </Fragment>
    );
};

export default SurveyAnalytics;
