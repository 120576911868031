import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import { RequestTabs } from './components/RequestTabs';
import RequestShowButtons from './components/RequestShowButtons';
import { modelView, modelViewType } from '../util/modelView';

const sanitizeRestProps: (any) => any = ({
    hasEdit,
    hasList,
    hasShow,
    hasCreate,
    ...rest
}) => rest;

const RequestShow = props => {
    const { permissions } = props;
    useEffect(() => {
        modelView(modelViewType.REQUEST, parseInt(props.id));
    }, [props.id]);
    return (
        <Show
            actions={<RequestShowButtons permissions={permissions} />}
            {...sanitizeRestProps(props)}
        >
            <RequestTabs />
        </Show>
    );
};

export default RequestShow;
