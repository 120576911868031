import React from 'react';
import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useQuery, DateField } from 'react-admin';
import CountryField from '../../components/CountryField';
import UserReferenceField from '../../components/UserReferenceField';
import ItemStats from '../../components/ItemStats';
import LinkToRelatedItems from './LinkToRelatedItems';
import { StorageIcon } from '../../storages';
import { RequestIcon } from '../../requests';
import { OrderIcon } from '../../orders';
import { CardIcon } from '../../cards';
import { ChargeIcon } from '../../charges';
import { PaymentIcon } from '../../payments';
import BillingCard from '../../components/BillingCard';

export const PartnerSummary = props => {
    const { record, permissions = [] } = props;
    const { data } = useQuery({
        type: 'getOne',
        resource: 'partner-stats',
        payload: { id: record.id },
    });
    var partnerStats = data;
    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>ID</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Logo</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.logoUrl}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Created On</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField record={record} source="createdAt" />
                        </TableCell>
                    </TableRow>
                </TableBody>
                <TableRow>
                    <TableCell>Admin</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <UserReferenceField {...props} label="Admin" />
                    </TableCell>
                </TableRow>
            </Table>
            <br />
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid container spacing={3}>
                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <BillingCard
                                billing={partnerStats && partnerStats.billing}
                            />
                        </Grid>
                    )}
                    <Grid item xs={6} sm={3}>
                        <LinkToRelatedItems
                            partnerId={record.id}
                            resource="storages"
                            icon={<StorageIcon />}
                        >
                            {partnerStats && partnerStats.storages && (
                                <ItemStats
                                    data={[
                                        ...partnerStats.storages,
                                        ...((!!partnerStats.size && [
                                            {
                                                status: 'Volume stored',
                                                count: `${partnerStats.size} CBM`,
                                            },
                                        ]) ||
                                            []),
                                    ]}
                                />
                            )}
                        </LinkToRelatedItems>
                    </Grid>

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                partnerId={record.id}
                                resource="requests"
                                icon={<RequestIcon />}
                            >
                                {partnerStats && partnerStats.requests && (
                                    <ItemStats data={partnerStats.requests} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    <Grid item xs={6} sm={3}>
                        <LinkToRelatedItems
                            partnerId={record.id}
                            resource="orders"
                            icon={<OrderIcon />}
                        >
                            {partnerStats && partnerStats.orders && (
                                <ItemStats data={partnerStats.orders} />
                            )}
                        </LinkToRelatedItems>
                    </Grid>

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                partnerId={record.id}
                                resource="charges"
                                icon={<ChargeIcon />}
                            >
                                {partnerStats && partnerStats.charges && (
                                    <ItemStats
                                        source="category"
                                        unit="currency"
                                        data={partnerStats.charges}
                                    />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                partnerId={record.id}
                                resource="payments"
                                icon={<PaymentIcon />}
                            >
                                {partnerStats && partnerStats.payments && (
                                    <ItemStats data={partnerStats.payments} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                partnerId={record.id}
                                resource="cards"
                                icon={<CardIcon />}
                            >
                                {partnerStats && partnerStats.cards && (
                                    <ItemStats data={partnerStats.cards} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};
