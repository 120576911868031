import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    ShowButton,
    EditButton,
    SelectInput,
    TextInput,
} from 'react-admin';

import CountryField from '../components/CountryField';

const LocationFilter = props => (
    <Filter variant="standard" {...props}>
        <TextInput label="Search" source="q" alwaysOn />

        <ReferenceInput
            source="warehouseId"
            reference="warehouses"
            resource="warehouses"
            label="Warehouse"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={choice => `${choice.name}`}
            />
        </ReferenceInput>
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput optionText={choice => `${choice.name}`} />
        </ReferenceInput>
    </Filter>
);

const LocationList = props => (
    <List
        title="Warehouse Locations"
        bulkActionButtons={false}
        filters={<LocationFilter />}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="path" />
            <TextField source="area" />
            <TextField source="aisle" />
            <TextField source="x" />
            <TextField source="y" />
            <TextField source="z" />
            <ReferenceField
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <CountryField />
            <ShowButton style={{ padding: 0 }} />
            <EditButton color="secondary" style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

LocationList.defaultProps = {
    resource: 'locations',
    location: { search: '' },
};

export default LocationList;
