import React from 'react';
import { Link } from 'react-router-dom';
import { translate } from 'react-admin';
import { stringify } from 'query-string';
import capitalize from 'capitalize';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
} from '@material-ui/core';

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
    },
    avatar: {
        backgroundColor: theme.palette.background.primary,
    },
});

const LinkToRelatedItems = ({
    classes,
    children,
    resource,
    icon,
    userId,
    translate,
}) => (
    <a
        className={classes.link}
        href={`/#/${resource}?${stringify({
            page: 1,
            perPage: 10,
            filter: JSON.stringify({ userId }),
        })}`}
    >
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        {React.cloneElement(icon)}
                    </Avatar>
                }
                title={
                    <Typography variant="h6" gutterBottom>
                        {capitalize(resource)}
                    </Typography>
                }
            />
            <CardContent>{children}</CardContent>
        </Card>
    </a>
);

export default translate(withStyles(styles)(LinkToRelatedItems));
