import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import {
    ChipField,
    DateField,
    ReferenceArrayField,
    SingleFieldList,
} from 'react-admin';
import Money from '../../components/Money';
import UserReferenceField from '../../components/UserReferenceField';
import {
    makeStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Paper,
} from '@material-ui/core';

const useStyles = makeStyles({
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

export const PaymentSummary = props => {
    const { record } = props;
    const classes = useStyles();

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Payment Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Created At</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Customer</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Created By</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                source="creatorId"
                                {...props}
                                label="Customer"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Payment Type</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="type" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Mode</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.mode}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Amount</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <Money
                                amount={record.amount}
                                currency={record.currency}
                                showCurrency
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="status" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Paid On</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="paidAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Invoices</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceArrayField
                                record={record}
                                label="Invoices"
                                reference="invoices"
                                source="invoiceIds"
                                basePath="/invoices"
                                resource="invoices"
                            >
                                <SingleFieldList linkType="show">
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </TableCell>
                    </TableRow>
                    <TableRow />
                </TableBody>
            </Table>
            {record.data && record.data.response && (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <strong>
                                    {capitalize(record.mode)} Response
                                </strong>
                            </TableCell>
                        </TableRow>
                        {Object.keys(record.data.response).map(k => (
                            <TableRow key={k}>
                                <TableCell>
                                    <label>{k}</label>
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {record.data.response[k]}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

PaymentSummary.propTypes = {
    record: PropTypes.object,
};
