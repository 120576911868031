import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Title } from 'react-admin';
import { useParams } from 'react-router';
import feathersClient from '../rest/feathersClient';
import ShowCategoryConfigs from './ShowCategoryConfig';
import { configCountries } from './types';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
}));

export default function CountryConfigs() {
    let { country } = useParams<any>();
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [configs, setConfigs] = useState<any[]>([]);
    const [categories, setCategories] = useState<any>([]);

    const getCountryConfigs = () => {
        const configService = feathersClient.service('configurations');
        configService
            .find({
                query: { countryId: country },
            })
            .then(res => {
                setConfigs(res.data);
                const categoriesArray = [
                    ...new Set(res.data.map(item => item.category)),
                ].sort();
                setCategories(categoriesArray);
            })
            .catch(err => {});
    };

    useEffect(() => {
        getCountryConfigs();
    }, [country]);

    useEffect(() => {
        if (configs) {
        }
    }, [configs]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Title title={`${configCountries[`${country}`]} configurations`} />
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    {categories.map((item, index) => (
                        <Tab label={item} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </AppBar>
            {categories.map((item, index) => (
                <ShowCategoryConfigs
                    value={value}
                    index={index}
                    categoryConfigs={configs.filter(
                        conf => conf.category === item
                    )}
                />
            ))}
        </div>
    );
}
