import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import Money from './Money';

const ItemStats = ({
    data,
    source,
    count = 'count',
    value = 'value',
    unit = 'unit',
}) => (
    <table style={{ width: '100%' }}>
        <tbody>
            {data.length === 0 && (
                <tr>
                    <td colSpan={2}>0 items found</td>
                </tr>
            )}
            {data.map(i => {
                const countValue = i[count];
                const valueValue = i[value];
                const unitValue = i[unit];

                return (
                    <tr key={i[source]}>
                        <td style={{ textAlign: 'left' }}>
                            {capitalize(i[source].replace('_', ' '))}
                        </td>
                        {valueValue ? (
                            <td style={{ textAlign: 'right' }}>
                                {unit === 'currency' ? (
                                    <Money
                                        amount={Number(valueValue)}
                                        currency={unitValue}
                                        showCurrency
                                        skipEmptyFraction
                                    />
                                ) : (
                                    valueValue
                                )}{' '}
                                ({countValue})
                            </td>
                        ) : (
                            <td style={{ textAlign: 'right' }}>{countValue}</td>
                        )}
                    </tr>
                );
            })}
        </tbody>
    </table>
);

ItemStats.propTypes = {
    data: PropTypes.array,
    source: PropTypes.string,
};

ItemStats.defaultProps = {
    data: [],
    source: 'status',
};

export default ItemStats;
