import * as React from 'react';

function SvgHeart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.353 11.96a6.5 6.5 0 0111.099 4.597 6.5 6.5 0 01-1.905 4.597l-8.84 8.84a1 1 0 01-1.414 0l-8.84-8.84a6.501 6.501 0 019.194-9.194l.353.353.353-.353zm4.597.095a4.5 4.5 0 00-3.183 1.32l-1.06 1.06a1 1 0 01-1.414 0l-1.06-1.06a4.501 4.501 0 10-6.366 6.365L20 27.873l8.133-8.133a4.5 4.5 0 00-3.183-7.685z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgHeart;
