import React from 'react';
import PropTypes from 'prop-types';

import ReactQuill from 'react-quill';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from 'react-html-parser';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

export const EmailTemplateBody = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ textAlign: 'right' }}>
                            <div>{ReactHtmlParser(record.body)}</div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

EmailTemplateBody.propTypes = {
    record: PropTypes.object,
};
