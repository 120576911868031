import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    USER_ACTIVATE_SUCCESS,
    USER_ACTIVATE_FAILURE,
    USER_DEACTIVATE_SUCCESS,
    USER_DEACTIVATE_FAILURE,
    USER_REJECT_SUCCESS,
    USER_REJECT_FAILURE,
    USER_SUSPEND_SUCCESS,
    USER_SUSPEND_FAILURE,
    USER_ASSIGN_SUCCESS,
    USER_ASSIGN_FAILURE,
    USER_BILL_SUCCESS,
    USER_BILL_FAILURE,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILURE,
    USER_MAGIC_LINK_SUCCESS,
    USER_MAGIC_LINK_FAILURE,
    USER_ADD_PHOTO_SUCCESS,
    USER_ADD_PHOTO_FAILURE,
    USER_IMPERSONATE_FAILURE,
    USER_IMPERSONATE_SUCCESS,
} from './userActions';

export default function* userSaga() {
    yield all([
        takeEvery(USER_ACTIVATE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.activate_success'
                )
            );
        }),
        takeEvery(USER_ACTIVATE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.activate_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_DEACTIVATE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.deactivate_success'
                )
            );
        }),
        takeEvery(USER_DEACTIVATE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.deactivate_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_REJECT_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.rejected_success'
                )
            );
        }),
        takeEvery(USER_REJECT_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.rejected_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_ASSIGN_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.rejected_success'
                )
            );
        }),
        takeEvery(USER_ASSIGN_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.rejected_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_SUSPEND_SUCCESS, function* () {
            yield put(
                showNotification('resources.users.notification.suspend_success')
            );
        }),
        takeEvery(USER_SUSPEND_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.suspend_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_BILL_SUCCESS, function* (action: ActionWithSideEffect) {
            const invoiceId =
                action.payload && action.payload.data && action.payload.data.id;

            if (invoiceId) {
                yield put(
                    showNotification(
                        `Invoice ${invoiceId} has been created successfully`
                    )
                );
            } else {
                yield put(
                    showNotification(
                        'resources.users.notification.bill_nothing',
                        'warning'
                    )
                );
            }
        }),
        takeEvery(USER_BILL_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.bill_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_RESET_PASSWORD_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.reset_password_success'
                )
            );
        }),
        takeEvery(USER_RESET_PASSWORD_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error ||
                        'resources.users.notification.reset_password_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_MAGIC_LINK_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.magic_link_success'
                )
            );
        }),
        takeEvery(USER_MAGIC_LINK_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.magic_link_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(USER_ADD_PHOTO_SUCCESS, function* () {
            yield put(showNotification('Upload Success'));
        }),
        takeEvery(USER_ADD_PHOTO_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(error || 'Error in uploading photo', 'warning')
            );
            console.error(error);
        }),
        takeEvery(USER_IMPERSONATE_SUCCESS, function* (
            action: ActionWithSideEffect
        ) {
            const token =
                action.payload &&
                action.payload.data &&
                action.payload.data.token;
            const userId =
                action.payload &&
                action.payload.data &&
                action.payload.data.userId;
            if (token && userId) {
                if (process.env.REACT_APP_ENV === 'staging') {
                    window.open(
                        `https://consumer-app-staging.boxitstorage.com/?token=${token}&userid=${userId}`
                    );
                } else {
                    window.open(
                        `https://app.boxitstorage.com?token=${token}&userid=${userId}`
                    );
                }
            }
        }),
        takeEvery(USER_IMPERSONATE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.users.notification.activate_error',
                    'warning'
                )
            );
            console.error(error);
        }),
    ]);
}
