import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceManyField,
    Datagrid,
    DateField,
    ChipField,
    ReferenceField,
    Tab,
    TabbedShowLayout,
} from 'react-admin';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import CountryField from '../components/CountryField';

const LocationStorageList = props => (
    <ReferenceManyField
        reference="storage-records"
        target="locationId"
        filter={{ locationId: props.record.id }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <DateField locales="en-IN" source="from" showTime />
            <DateField locales="en-IN" source="to" showTime />
            <ChipField source="status" />
            <ReferenceField
                label="Storage"
                source="storageId"
                reference="storages"
                link="show"
            >
                <TextField source="code" />
            </ReferenceField>
        </Datagrid>
    </ReferenceManyField>
);

const LocationSummary = props => {
    const { record } = props;
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <label>Country</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <CountryField record={record} />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Warehouse</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <ReferenceField
                            record={record}
                            label="Warehouse"
                            source="warehouseId"
                            reference="warehouses"
                            basePath="/warehouses"
                            resource="warehouses"
                            link="show"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Path</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.path}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Area</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.area}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Aisle</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.aisle}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>X</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.x}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Y</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.y}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Z</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.z}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

const LocationShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <LocationSummary />
            </Tab>
            <Tab label="Storages">
                <LocationStorageList />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default LocationShow;
