import React from 'react';
import { ReferenceInput, SelectInput } from 'react-admin';

export default props => {
    return (
        <ReferenceInput
            variant="outlined"
            label="Partner"
            source="partnerId"
            reference="partners"
            resource="partners"
            fullWidth={true}
            style={{ width: '256px' }}
        >
            <SelectInput optionText="name" defaultValue={1} />
        </ReferenceInput>
    );
};
