import React from 'react';
import { required } from 'react-admin';
import { SelectInput } from 'react-admin';

import { Create, SimpleForm, TextInput } from 'react-admin';
import { handleTagsError, handleUserError } from '../users/error';
import { useNotify } from 'react-admin';

const TagCreate = props => {
    const notify = useNotify();
    return (
        <Create
            {...props}
            title="Tag"
            onFailure={error => handleTagsError(error, notify)}
        >
            <SimpleForm variant="outlined" redirect="show">
                <TextInput source="name" validate={[required()]} />
                <SelectInput
                    validate={[required()]}
                    source="module"
                    choices={[
                        { id: 'Generic', name: 'Generic' },
                        { id: 'User', name: 'User' },
                    ]}
                />
            </SimpleForm>
        </Create>
    );
};

export default TagCreate;
