import React from 'react';
import { Show } from 'react-admin';
import CardShowActions from './components/CardShowActions';
import { CardTabs } from './components/CardTabs';

const CardShow = props => (
    <Show actions={<CardShowActions />} {...props}>
        <CardTabs />
    </Show>
);

export default CardShow;
