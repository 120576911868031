import React, { FC } from 'react';
import Analytics from './Analytics';

const StorageAnalytics: FC = () => {
    return (
        <Analytics
            title={'Storage'}
            items={[
                {
                    title: 'Net Count',
                    data: [
                        {
                            title: 'Net Count',
                            key: 'storage.qty_net',
                        },
                        {
                            title: 'Net Count (B2C)',
                            key: 'storage.qty_net_b2c',
                        },
                        {
                            title: 'Net Count (B2B)',
                            key: 'storage.qty_net_b2b',
                        },
                    ],
                },
                {
                    title: 'Net Volume',
                    data: [
                        {
                            title: 'Net Volume',
                            key: 'storage.volume_net',
                            unit: 'CBM',
                        },
                        {
                            title: 'Net Volume (B2C)',
                            key: 'storage.volume_net_b2c',
                            unit: 'CBM',
                        },
                        {
                            title: 'Net Volume (B2B)',
                            key: 'storage.volume_net_b2b',
                            unit: 'CBM',
                        },
                    ],
                },
                {
                    title: 'Volume IN',
                    data: [
                        {
                            title: 'Volume IN',
                            key: 'storage.volume_in',
                            unit: 'CBM',
                        },
                        {
                            title: 'Volume IN (B2C)',
                            key: 'storage.volume_in_b2c',
                            unit: 'CBM',
                        },
                        {
                            title: 'Volume IN (B2B)',
                            key: 'storage.volume_in_b2b',
                            unit: 'CBM',
                        },
                    ],
                },
                {
                    title: 'Volume OUT',
                    data: [
                        {
                            title: 'Volume OUT',
                            key: 'storage.volume_out',
                            unit: 'CBM',
                            reverse: true,
                        },
                        {
                            title: 'Volume OUT (B2C)',
                            key: 'storage.volume_out_b2c',
                            unit: 'CBM',
                            reverse: true,
                        },
                        {
                            title: 'Volume OUT (B2B)',
                            key: 'storage.volume_out_b2b',
                            unit: 'CBM',
                            reverse: true,
                        },
                    ],
                },
            ]}
        />
    );
};

export default StorageAnalytics;
