import React from 'react';
import _ from 'lodash';
import { FieldProps } from '../types';

const DamageField: React.FC<FieldProps<any>> = ({
    source = 'damage',
    record = {},
    ...props
}) =>
    !!source && _.get(record, source) ? (
        <span className="inline-block px-2 py-1 bg-red-200 text-red-800 rounded-full">
            Damaged
        </span>
    ) : null;

export default DamageField;
