import React from 'react';
import {
    Datagrid,
    DateField,
    ImageField,
    List,
    ShowButton,
    TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CountryField from '../components/CountryField';
import UserReferenceField from '../components/UserReferenceField';

const useStyles = makeStyles({
    logoContainer: {
        '& img': {
            height: 50,
            width: 50,
            objectFit: 'contain',
        },
    },
});

const PartnerList = props => {
    const classes = useStyles();
    return (
        <List title="Partners" sort={{ field: 'id', order: 'DESC' }} {...props}>
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" />
                <ImageField
                    source="logoUrl"
                    label="Logo"
                    className={classes.logoContainer}
                />
                <TextField source="name" />
                <CountryField />
                <DateField source="createdAt" label="Created" />
                <UserReferenceField label="Admin" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

PartnerList.defaultProps = {
    resource: 'partners',
    location: { search: '' },
};

export default PartnerList;
