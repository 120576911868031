import React from 'react';
import PropTypes from 'prop-types';
import TimeSlot from './TimeSlot';
import { FieldProps } from '../types';

type Props = FieldProps & {
    sourceFrom?: string;
    sourceTo?: string;
};

const TimeSlotField: React.FC<Props> = ({
    record,
    sourceFrom = 'from',
    sourceTo = 'to',
}) => {
    return !!record && !!record[sourceFrom] ? (
        <TimeSlot from={record[sourceFrom]} to={record[sourceTo]} />
    ) : null;
};

TimeSlotField.propTypes = {
    sourceFrom: PropTypes.string.isRequired,
    sourceTo: PropTypes.string.isRequired,
};

export default TimeSlotField;
