import React from 'react';
import { ImageField, SingleFieldList, ReferenceManyField } from 'react-admin';

const Thumbnail = ({ resource = 'photos', filter = {}, ...props }) => {
    return (
        <ReferenceManyField
            reference="user-photos"
            resource="orders"
            target="orderId"
            filter={filter}
            {...props}
        >
            <SingleFieldList linkType="show">
                <ImageField source="thumbnail" style={{ width: '100px' }} />
            </SingleFieldList>
        </ReferenceManyField>
    );
};

export default Thumbnail;
