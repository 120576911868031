const STORAGE_RATES = [
    { max: 1, rate: 150 },
    { max: 2, rate: 130 },
    { max: 3, rate: 110 },
    { max: 4, rate: 100 },
    { max: 5, rate: 90 },
    { max: 6, rate: 80 },
    { max: 8, rate: 75 },
    { max: 12, rate: 75 },
    { max: 15, rate: 75 },
    { max: 20, rate: 75 },
    { max: 30, rate: 75 },
    { max: 50, rate: 75 },
    { max: 100, rate: 75 },
    { max: Infinity, rate: 75 },
];

const PACKING_RATES = [
    { max: 3, rate: 110 },
    { max: 6, rate: 90 },
    { max: 10, rate: 80 },
    { max: 15, rate: 75 },
    { max: 22, rate: 70 },
    { max: 30, rate: 65 },
    { max: 40, rate: 63 },
    { max: 60, rate: 62 },
    { max: 100, rate: 60 },
    { max: Infinity, rate: 0 },
];

const PICKUP_RATES = [
    { max: 3, rate: 38 },
    { max: 6, rate: 35 },
    { max: 10, rate: 33 },
    { max: 15, rate: 30 },
    { max: 22, rate: 26 },
    { max: 30, rate: 23 },
    { max: 40, rate: 20 },
    { max: 60, rate: 18 },
    { max: 100, rate: 16 },
    { max: Infinity, rate: 0 },
];

const DELIVERY_RATES = [
    { max: 3, rate: 56 },
    { max: 6, rate: 49 },
    { max: 10, rate: 44 },
    { max: 15, rate: 40 },
    { max: 22, rate: 37 },
    { max: 30, rate: 35 },
    { max: 40, rate: 33 },
    { max: 60, rate: 31 },
    { max: 100, rate: 28 },
    { max: Infinity, rate: 0 },
];

export function computeStorageRate(totalSize, countryId) {
    return countryId === 1 ? getSlabRate(STORAGE_RATES, totalSize) : 100000;
}

export function computePackingRate(totalSize) {
    return getSlabRate(PACKING_RATES, totalSize);
}

export function computePickupRate(totalSize) {
    return getSlabRate(PICKUP_RATES, totalSize);
}

export function computeDeliveryRate(totalSize) {
    return getSlabRate(DELIVERY_RATES, totalSize);
}

export function computeMonthlyRent(
    totalSize: number,
    rate: number,
    countryId: number
) {
    return Math.max(totalSize * rate, countryId === 1 ? 1500000 : 60000);
}

export function computePackingCharge(totalSize) {
    const rate = computePackingRate(totalSize);
    return totalSize * rate;
}

export function computePickupCharge(totalSize) {
    const rate = computePickupRate(totalSize);
    return totalSize * rate;
}

export function computeDeliveryCharge(totalSize) {
    const rate = computeDeliveryRate(totalSize);
    return totalSize * rate;
}

export function computeOrderSize(items, storagetypes) {
    let size = 0;

    (items || []).forEach(item => {
        if (item && item.storagetypeId && item.quantity) {
            const type =
                storagetypes &&
                storagetypes.find(st => st.id === item.storagetypeId);
            if (type) {
                size += (type.size || 0) * item.quantity;
            }
        }
    });

    return Math.max(size, 0);
}

function getSlabRate(rates, size) {
    const slab = rates.find(i => i.max >= size);
    return slab ? slab.rate * 10000 : 0;
}
