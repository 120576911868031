import React from 'react';
import moment from 'moment';
import {
    BooleanInput,
    DateInput,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { TitleProps, User } from '../types';

const CouponTitle: React.FC<TitleProps<User>> = ({ record }) => (
    <span>Coupon {record ? `"${record.name}"` : ''}</span>
);

const CouponEdit = props => (
    <Edit title={<CouponTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="id" />
            <BooleanInput source="reusable" />
            <DateInput
                source="expiry"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
            <TextInput source="userId" />
            <TextInput source="offerId" />
            <ReferenceInput
                label="Offer"
                source="offerId"
                reference="offers"
                resource="offers"
                allowEmpty
            >
                <SelectInput optionText="id" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default CouponEdit;
