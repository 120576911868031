import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { TeamSummary } from './components/';
import { TeamTitle } from './components/TeamTitle';

const TeamShow = props => (
    <Show title={<TeamTitle />} {...props}>
        <SimpleShowLayout>
            <TeamSummary />
        </SimpleShowLayout>
    </Show>
);

export default TeamShow;
