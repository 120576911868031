import React from 'react';
import { FunctionField } from 'react-admin';
import { TabbedShowLayout } from 'react-admin';
import { Tab } from 'react-admin';
import { ChipField, Show, TextField } from 'react-admin';
import PhotoGallery from '../components/PhotoGallery';
import UserReferenceField from '../components/UserReferenceField';
import { ShowProps, Task } from '../types';

const SurveyShow: React.FC<ShowProps<Task>> = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <FunctionField
                    label="Order"
                    render={record => {
                        return (
                            <div
                                style={{
                                    width: '8rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <button
                                    style={{
                                        cursor: 'pointer',
                                        color: '#1890ff',
                                        outline: 'none',
                                    }}
                                    onClick={() => {
                                        window.location.href = `/#/orders/${record.orderId}/show`;
                                    }}
                                >
                                    {record.orderId}
                                </button>
                            </div>
                        );
                    }}
                />
                <UserReferenceField source="userId" label="User" />
                {/*<DateField source="submittedAt" showTime />*/}
                <TextField source="type" />
                <ChipField source="status" />
                <br />
                <FunctionField
                    label="Survey data"
                    render={record => {
                        return (
                            <div>
                                {record.surveyDataResponse &&
                                    Object.keys(record.surveyDataResponse).map(
                                        key => {
                                            return (
                                                <h3
                                                    style={{
                                                        marginBottom: '.3rem',
                                                    }}
                                                >
                                                    {`${key}: ${record.surveyDataResponse[key]}`}
                                                </h3>
                                            );
                                        }
                                    )}
                            </div>
                        );
                    }}
                />
            </Tab>
            <Tab label="Photos">
                <PhotoGallery
                    {...props}
                    target="surveyId"
                    reference="survey-photos"
                />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default SurveyShow;
