export const typesOptions = [
    {
        id: 'GENERATE_MONTHLY_INVOICE_FOR_USER',
        name: 'GENERATE_MONTHLY_INVOICE_FOR_USER',
    },
    {
        id: 'GENERATE_MONTHLY_INVOICE',
        name: 'GENERATE_MONTHLY_INVOICE',
    },
    { id: 'MEASURE_USAGE', name: 'MEASURE_USAGE' },
    {
        id: 'MAIL_INVOICE_TO_CUSTOMER',
        name: 'MAIL_INVOICE_TO_CUSTOMER',
    },
    {
        id: 'MAIL_RECEIPT_TO_CUSTOMER',
        name: 'MAIL_RECEIPT_TO_CUSTOMER',
    },
    {
        id: 'SEND_SIGNUP_MAIL_TO_CUSTOMER',
        name: 'SEND_SIGNUP_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_WELCOME_MAIL_TO_CUSTOMER',
        name: 'SEND_WELCOME_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_WELCOME_SMS_TO_CUSTOMER',
        name: 'SEND_WELCOME_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_THANKYOU_MAIL_TO_CUSTOMER',
        name: 'SEND_ORDER_THANKYOU_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_FEEDBACK_THANKYOU_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_FEEDBACK_THANKYOU_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_THANKYOU_SMS_TO_CUSTOMER',
        name: 'SEND_ORDER_THANKYOU_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_CONFIRMED_MAIL_TO_CUSTOMER',
        name: 'SEND_ORDER_CONFIRMED_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_CONFIRMED_SMS_TO_CUSTOMER',
        name: 'SEND_ORDER_CONFIRMED_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_MAIL_TO_AFFILIATE',
        name: 'SEND_ORDER_MAIL_TO_AFFILIATE',
    },
    {
        id: 'SEND_NEWBOX_SMS_TO_CUSTOMER',
        name: 'SEND_NEWBOX_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_PICKUP_SMS_TO_CUSTOMER',
        name: 'SEND_PICKUP_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_ACK_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_ACK_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_NOTIFY_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_NOTIFY_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_DELIVERY_SMS_TO_CUSTOMER',
        name: 'SEND_DELIVERY_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_NEWBOX_MAIL_TO_TEAM',
        name: 'SEND_NEWBOX_MAIL_TO_TEAM',
    },
    {
        id: 'SEND_PICKUP_MAIL_TO_TEAM',
        name: 'SEND_PICKUP_MAIL_TO_TEAM',
    },
    {
        id: 'SEND_DELIVERY_MAIL_TO_TEAM',
        name: 'SEND_DELIVERY_MAIL_TO_TEAM',
    },
    {
        id: 'SEND_PICKUP_MAIL_TO_WAREHOUSE',
        name: 'SEND_PICKUP_MAIL_TO_WAREHOUSE',
    },
    {
        id: 'SEND_DELIVERY_MAIL_TO_WAREHOUSE',
        name: 'SEND_DELIVERY_MAIL_TO_WAREHOUSE',
    },
    {
        id: 'SEND_REQUEST_NOTE_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_NOTE_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_NOTE_SMS_TO_CUSTOMER',
        name: 'SEND_REQUEST_NOTE_SMS_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_ACCEPTED_ACK_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_ACCEPTED_ACK_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_DISPUTE_MAIL_TO_BOXIT',
        name: 'SEND_REQUEST_DISPUTE_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_REQUEST_DISPUTE_ACK_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_DISPUTE_ACK_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_FEEDBACK_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_FEEDBACK_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_REQUEST_FEEDBACK_RESPONSE_MAIL_TO_BOXIT',
        name: 'SEND_REQUEST_FEEDBACK_RESPONSE_MAIL_TO_BOXIT',
    },
    { id: 'SEND_REQUEST_PHOTOS', name: 'SEND_REQUEST_PHOTOS' },
    {
        id: 'SEND_NEWBOX_MAIL_TO_OPS',
        name: 'SEND_NEWBOX_MAIL_TO_OPS',
    },
    {
        id: 'SEND_PICKUP_MAIL_TO_OPS',
        name: 'SEND_PICKUP_MAIL_TO_OPS',
    },
    {
        id: 'SEND_DELIVERY_MAIL_TO_OPS',
        name: 'SEND_DELIVERY_MAIL_TO_OPS',
    },
    {
        id: 'SEND_SIGNUP_MAIL_TO_BOXIT',
        name: 'SEND_SIGNUP_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_ORDER_MAIL_TO_BOXIT',
        name: 'SEND_ORDER_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_NEW_REQUEST_MAIL_TO_BOXIT',
        name: 'SEND_NEW_REQUEST_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_FEEDBACK_MAIL_TO_BOXIT',
        name: 'SEND_FEEDBACK_MAIL_TO_BOXIT',
    },
    { id: 'SEND_RESET_PASSWORD', name: 'SEND_RESET_PASSWORD' },
    {
        id: 'SEND_RESET_PASSWORD_DONE',
        name: 'SEND_RESET_PASSWORD_DONE',
    },
    {
        id: 'SEND_PASSWORD_CHANGED',
        name: 'SEND_PASSWORD_CHANGED',
    },
    {
        id: 'SEND_MAGIC_LINK_MAIL',
        name: 'SEND_MAGIC_LINK_MAIL',
    },
    {
        id: 'SEND_QUOTE_TO_CUSTOMER',
        name: 'SEND_QUOTE_TO_CUSTOMER',
    },
    {
        id: 'SCHEDULE_RECOVER_LEAD_MAILS',
        name: 'SCHEDULE_RECOVER_LEAD_MAILS',
    },
    {
        id: 'SEND_RECOVER_LEAD_MAIL_TO_CUSTOMER',
        name: 'SEND_RECOVER_LEAD_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_RENEWED_MAIL_TO_BOXIT',
        name: 'SEND_ORDER_RENEWED_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_QUOTE_RESPONSE_TO_ASSIGNEE',
        name: 'SEND_QUOTE_RESPONSE_TO_ASSIGNEE',
    },
    {
        id: 'SEND_CONFIRMED_LEAD_TO_BOXIT',
        name: 'SEND_CONFIRMED_LEAD_TO_BOXIT',
    },
    {
        id: 'SEND_REQUEST_MORE_DETAILS_MAIL_TO_CUSTOMER',
        name: 'SEND_REQUEST_MORE_DETAILS_MAIL_TO_CUSTOMER',
    },
    {
        id: 'SEND_PENDING_INVOICES_TO_CUSTOMER',
        name: 'SEND_PENDING_INVOICES_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_CONFIRMED_MAIL_TO_BOXIT',
        name: 'SEND_ORDER_CONFIRMED_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_SUPPORT_MAIL_TO_BOXIT',
        name: 'SEND_SUPPORT_MAIL_TO_BOXIT',
    },
    {
        id: 'SCHEDULE_RENEWAL_NOTICE_MAILS',
        name: 'SCHEDULE_RENEWAL_NOTICE_MAILS',
    },
    {
        id: 'SEND_RENEWAL_NOTICE_TO_CUSTOMER',
        name: 'SEND_RENEWAL_NOTICE_TO_CUSTOMER',
    },
    {
        id: 'SCHEDULE_BILLING_NOTICE_MAILS',
        name: 'SCHEDULE_BILLING_NOTICE_MAILS',
    },
    {
        id: 'SEND_BILLING_NOTICE_TO_CUSTOMER',
        name: 'SEND_BILLING_NOTICE_TO_CUSTOMER',
    },
    {
        id: 'SEND_ORDER_RENEWAL_INTENT_MAIL',
        name: 'SEND_ORDER_RENEWAL_INTENT_MAIL',
    },
    {
        id: 'SEND_ORDER_CLOSE_INTENT_MAIL',
        name: 'SEND_ORDER_CLOSE_INTENT_MAIL',
    },
    {
        id: 'SEND_ORDER_SIZE_UPDATE_MAIL_TO_BOXIT',
        name: 'SEND_ORDER_SIZE_UPDATE_MAIL_TO_BOXIT',
    },
    {
        id: 'SEND_PAYMENT_LINK_TO_CUSTOMER',
        name: 'SEND_PAYMENT_LINK_TO_CUSTOMER',
    },
    {
        id: 'CHARGE_INVOICE_TO_USER_CARD',
        name: 'CHARGE_INVOICE_TO_USER_CARD',
    },
    {
        id: 'SCHEDULE_REMINDER_TO_OPERATIONS_MAILS',
        name: 'SCHEDULE_REMINDER_TO_OPERATIONS_MAILS',
    },
    {
        id: 'SCHEDULE_REMINDER_TO_SALES_MAILS',
        name: 'SCHEDULE_REMINDER_TO_SALES_MAILS',
    },
    {
        id: 'SEND_REMINDER_TO_OPERATIONS_MAILS',
        name: 'SEND_REMINDER_TO_OPERATIONS_MAILS',
    },
    {
        id: 'SEND_UNCOMPLETED_ORDERS_TO_SALES',
        name: 'SEND_UNCOMPLETED_ORDERS_TO_SALES',
    },
    {
        id: 'SEND_TASK_FAILURE_MAILS',
        name: 'SEND_TASK_FAILURE_MAILS',
    },
    {
        id: 'MAIL_PAYMENT_FAILED_TO_CUSTOMER',
        name: 'MAIL_PAYMENT_FAILED_TO_CUSTOMER',
    },
    {
        id: 'MAIL_PAYMENT_SEUCCEEDED_TO_CUSTOMER',
        name: 'MAIL_PAYMENT_SEUCCEEDED_TO_CUSTOMER',
    },
    {
        id: 'SEND_SUBMITED_REQUEST_TO_SALES',
        name: 'SEND_SUBMITED_REQUEST_TO_SALES',
    },
];
