import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../util/money';

const Money = ({ amount, currency, showCurrency, skipEmptyFraction }) => {
    const value = formatMoney(amount, currency, skipEmptyFraction);

    return (
        <span>
            <small>{showCurrency && value ? currency : ''}</small> {value}
        </span>
    );
};

Money.propTypes = {
    amount: PropTypes.number,
    currency: PropTypes.string.isRequired,
    showCurrency: PropTypes.bool,
    skipEmptyFraction: PropTypes.bool,
};

Money.defaultProps = {
    showCurrency: false,
    skipEmptyFraction: false,
};

export default Money;
