import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core';
import UserReferenceField from '../../components/UserReferenceField';

export const StorageTypeSummary = props => {
    const { record } = props;

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <label>ID</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.id}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Customer</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <UserReferenceField {...props} label="Customer" />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Code</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.code}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Name</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.name}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Description</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.description}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Consumed size</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.consumedSize}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Size</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.size}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Length</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.length}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Height</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.height}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Width</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.width}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Inventory</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record?.inventory ? 'Yes' : 'No'}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

StorageTypeSummary.propTypes = {
    record: PropTypes.object,
};
