import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from 'react-html-parser';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

export const EmailTemplateWhatsapp = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Is WATI enabled</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.isWatiEnabled ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>WATI template name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.watiTempName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>WATI Template Variables Mapping</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {record.watiTemplateVariablesMapping
                                    ? record.watiTemplateVariablesMapping.map(
                                          temp => (
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                          'space-between',
                                                  }}
                                              >
                                                  <div
                                                      style={{
                                                          flex: 1,
                                                          display: 'flex',
                                                          justifyContent:
                                                              'flex-start',
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      {temp.watiVariableName}
                                                  </div>
                                                  <div
                                                      style={{
                                                          flex: 1,
                                                          display: 'flex',
                                                          justifyContent:
                                                              'flex-start',
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      {temp.variableName}
                                                  </div>
                                              </div>
                                          )
                                      )
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>WATI Template Variables</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.watiTemplateVariables
                                    ? record.watiTemplateVariables.map(
                                          variable => <span>{variable}</span>
                                      )
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

EmailTemplateWhatsapp.propTypes = {
    record: PropTypes.object,
};
