import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Card } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        flex: '1',
    },
    card: {
        overflow: 'inherit',
        textAlign: 'left',
        padding: 16,
        minHeight: 52,
    },
    layout: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        //textTransform: 'uppercase',
        color: '#8492A6',
        //marginLeft: '11px',
    },
    value: {
        fontWeight: 500,
        fontSize: '28px',
        lineHeight: '42px',
        //marginTop: '18px',
    },
    subText: {
        fontWeight: 400,
        fontSize: '11px',
        lineHeight: '18px',
        letterSpacing: '0.5px',
        color: '#8492A6',
    },
    avatar: {
        backgroundColor: 'white',
        color: '#8492A6',
        //backgroundColor: red[100],
        //color: red[500],
    },
    titleContainer: {
        marginLeft: '20px',
    },
});

export default ({
    value,
    title,
    subtext,
    icon,
}: {
    value: string | number;
    title: string;
    subtext?: any;
    icon: any;
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div className={classes.layout}>
                    <Avatar className={classes.avatar}>{icon}</Avatar>
                    <div className={classes.titleContainer}>
                        <span className={classes.title}>{title}</span>
                        <div className={classes.value}>{value}</div>
                        <span className={classes.subText}>
                            {subtext ? subtext : <span>&nbsp;</span>}
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    );
};
