import React, { Fragment } from 'react';
import moment from 'moment';
import {
    BulkDeleteButton,
    List,
    TextInput,
    Filter,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    DateInput,
    downloadCSV,
} from 'react-admin';

import jsonExport from 'jsonexport/dist';

import { ChargeType } from '../constants';
import ChargeGrid from './components/ChargeGrid';
import CustomSearchInput from '../components/CustomSearchInput';
import BulkEdit from './components/BulkEdit';

const exporter = charges => {
    const chargesForExport = charges.map((charge, index) => {
        const chargeForExport: any = {};
        chargeForExport.serial = index + 1; // serial
        chargeForExport.id = charge.id;
        chargeForExport.type = charge.type;
        chargeForExport.amount =
            charge.type === 'credit' ? `-${charge.amount}` : charge.amount;
        chargeForExport.currency = charge.currency;
        chargeForExport.year = charge.year;
        chargeForExport.month = charge.month;
        chargeForExport.currency = charge.currency;
        chargeForExport.startDate = charge.startDate;
        chargeForExport.endDate = charge.endDate;
        chargeForExport.description = charge.description;
        chargeForExport.invoiceId = charge.invoiceId;
        chargeForExport.orderId = charge.orderId;
        chargeForExport.customerName = charge.user.name;
        chargeForExport.customerEmail = charge.user.email;

        return chargeForExport;
    });
    jsonExport(
        chargesForExport,
        {
            //headers: ['serial', 'id', 'type', 'amount', 'currency', 'year', 'month', ], // order fields in the export
        },
        (err, csv) => {
            downloadCSV(csv, 'charges'); // download as 'posts.csv` file
        }
    );
};

const BulkActionButtons = props => (
    <Fragment>
        <BulkEdit {...props} />
        <BulkDeleteButton undoable={false} {...props} />
    </Fragment>
);

const ChargeFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />

        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user =>
                    user && (user.name ? user.name : user.email)
                }
            />
        </ReferenceInput>

        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>

        <SelectInput
            source="type"
            choices={Object.keys(ChargeType).map(c => ({ id: c, name: c }))}
        />

        <SelectInput
            source="invoiceId"
            label="Status"
            choices={[{ id: 'null', name: 'Unassigned' }]}
        />

        <DateInput
            source="createdAt[$gte]"
            label="Date From"
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="createdAt[$lte]"
            label="Date To"
            parse={v => v && moment(v).endOf('day').format()}
        />
    </Filter>
);

const ChargeList = props => (
    <List
        title="Charges"
        bulkActionButtons={<BulkActionButtons />}
        filters={<ChargeFilter />}
        exporter={exporter}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <ChargeGrid {...props} />
    </List>
);

ChargeList.defaultProps = {
    resource: 'charges',
    location: { search: '' },
};

export default ChargeList;
