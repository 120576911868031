import React, { FC } from 'react';
import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    BooleanInput,
} from 'react-admin';
import { FieldProps, Warehouse } from '../types';
import { FormDataConsumer } from 'react-admin';
import { AutocompleteInput } from 'react-admin';

const WarehouseTitle: FC<FieldProps<Warehouse>> = ({ record }) => (
    <span>Edit {record ? `"${record.name}"` : ''}</span>
);

const WarehouseEdit = props => {
    return (
        <Edit title={<WarehouseTitle />} undoable={false} {...props}>
            <SimpleForm variant="outlined" redirect="show">
                <TextInput disabled source="partnerId" />
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <ReferenceInput
                    label="City"
                    source="cityId"
                    resource="city"
                    reference="city"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    label="Country"
                    source="countryId"
                    reference="country"
                    resource="country"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceArrayInput
                    label="Members"
                    reference="users"
                    source="users"
                >
                    <AutocompleteArrayInput />
                </ReferenceArrayInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput
                            label="In location"
                            reference="locations"
                            source="inLocationId"
                            filter={{ warehouseId: formData.id }}
                            perPage={400}
                        >
                            <AutocompleteInput optionText="path" />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <ReferenceInput
                            label="Out location"
                            reference="locations"
                            source="outLocationId"
                            filter={{ warehouseId: formData.id }}
                            perPage={400}
                        >
                            <AutocompleteInput optionText="path" />
                        </ReferenceInput>
                    )}
                </FormDataConsumer>
                <BooleanInput source="active" />
            </SimpleForm>
        </Edit>
    );
};

export default WarehouseEdit;
