import React from 'react';
import { List, Datagrid, TextField, ShowButton, Filter } from 'react-admin';
import BoxitPagination from '../components/BoxitPagination';
import { SelectArrayInput } from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';

const TagFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
            <SelectArrayInput
                style={{ minWidth: '10rem' }}
                source="module"
                choices={[
                    { id: 'Generic', name: 'Generic' },
                    { id: 'User', name: 'User' },
                ]}
            />
        </Filter>
    );
};

const TagList = props => (
    <List
        title="Tags"
        bulkActionButtons={false}
        filters={<TagFilter />}
        pagination={<BoxitPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={10}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" />
            <TextField source="id" />
            <TextField source="name" />
            <TextField
                source="module"
                style={{ textTransform: 'capitalize' }}
            />
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

TagList.defaultProps = {
    resource: 'tags',
    location: { search: '' },
};

export default TagList;
