import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import MoneyInput from '../components/MoneyInput';
import { TitleProps, User } from '../types';

const CustomerTitle: React.FC<TitleProps<User>> = ({ record }) => (
    <span>Customer {record ? `#${record.userId}` : ''}</span>
);

const CustomerEdit = props => (
    <Edit title={<CustomerTitle />} undoable={false} {...props}>
        <SimpleForm redirect="edit">
            <MoneyInput source="credits" />
        </SimpleForm>
    </Edit>
);

export default CustomerEdit;
