import React from 'react';

import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ReferenceField,
    TextField,
} from 'react-admin';
import PlaceAutoComplete from '../components/PlaceAutoComplete';

const AddressEdit = props => (
    <Edit undoable={false} {...props}>
        <SimpleForm variant="outlined" redirect="show">
            <TextInput disabled source="id" />
            <ReferenceField
                label="Customer"
                source="userId"
                reference="users"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextInput source="name" />
            <TextInput multiline source="line1" label="Line" />
            <PlaceAutoComplete
                source="area"
            />
            <ReferenceInput
                label="City"
                source="cityId"
                reference="city"
                resource="city"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default AddressEdit;
