import React, { useState } from 'react';
import { FormDataConsumer } from 'react-admin';
import { useGetOne } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import {
    required,
    useNotify,
    Create,
    DateInput,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TabbedForm,
    TextInput,
} from 'react-admin';
import BoxitPhoneInput from '../components/BoxitPhoneInput';
import useLocalStorage from '../hooks/useLocalStorage';
import { CreateProps, User } from '../types';
import { handleUserError } from './error';
import AndOrInput from '../components/AndOrInput';
import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from 'react-admin';
import feathersClient from '../rest/feathersClient';
import { Button, Checkbox, Input } from '@material-ui/core';
import { CheckBox } from '@material-ui/icons';
import { FunctionField } from 'react-admin';
import { regex } from 'react-admin';

const optionText = user => `${user.name} (${user.id})`;

const isRequired = required();

const idTypeChoices = [
    { id: 'Passport', name: 'Passport' },
    { id: 'EmiratesID', name: 'Emirates ID' },
    { id: 'CivilID', name: 'Civil ID' },
];
// Define the email validation pattern
const emailValidation = regex(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    'Please enter a valid email address'
);
const UserCreate: React.FC<CreateProps<User>> = ({
    permissions = [],
    ...props
}) => {
    const notify = useNotify();
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);
    const [isCreateNewModule, setIsCreateNewModule] = useState(false);
    const [isGeneric, setIsGeneric] = useState(false);
    const [tagName, setTagName] = useState('');
    const [isNewTag, setIsNewTag] = useState(false);

    const addTag = (id, tags) => {
        console.log(notify);
        const tagService = feathersClient.service('tags');
        const userService = feathersClient.service('users');
        tagService
            .create({
                name: tagName,
                module: isGeneric ? 'Generic' : 'User',
            })
            .then(res => {
                window.location.reload();
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };

    return (
        <Create
            successMessage="resources.users.notification.create_success"
            onFailure={error => handleUserError(error, notify)}
            {...props}
        >
            <TabbedForm variant="outlined" redirect="show">
                <FormTab label="User">
                    {permissions.indexOf('staff') !== -1 && (
                        <ReferenceInput
                            source="partnerId"
                            reference="partners"
                            resource="partners"
                            label="Partner"
                            validate={isRequired}
                            defaultValue={1}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    )}
                    <TextInput source="name" validate={isRequired} />
                    <TextInput
                        source="email"
                        type="email"
                        validate={[isRequired, emailValidation]}
                    />
                    <FormDataConsumer>
                        {({ formData }) => (
                            <BoxitPhoneInput
                                source="phone"
                                validate={isRequired}
                                country={formData.countryId}
                                defaultCountry={currentUser?.countryId}
                            />
                        )}
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => (
                            <BoxitPhoneInput
                                source="phone2"
                                country={formData.countryId}
                                defaultCountry={currentUser?.countryId}
                            />
                        )}
                    </FormDataConsumer>

                    <ReferenceInput
                        source="countryId"
                        reference="country"
                        resource="country"
                        label="Country"
                        validate={isRequired}
                        defaultValue={currentUser?.countryId}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <ReferenceInput
                        label="Assignee"
                        source="assigneeId"
                        reference="users"
                        resource="users"
                    >
                        <AutocompleteInput
                            shouldRenderSuggestions={val =>
                                val.trim().length > 1
                            }
                            optionText={optionText}
                        />
                    </ReferenceInput>
                    <div>
                        <Checkbox
                            checked={isNewTag}
                            onChange={() => {
                                setIsNewTag(prev => !prev);
                            }}
                        />
                        <span>New Tag?</span>
                    </div>

                    {!isNewTag ? (
                        <ReferenceArrayInput
                            source="tags"
                            reference="tags"
                            label="tags"
                        >
                            <AutocompleteArrayInput />
                        </ReferenceArrayInput>
                    ) : (
                        <FunctionField
                            label="Add new Tag"
                            render={record => (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '.5rem',
                                        }}
                                    >
                                        <Input
                                            onChange={e =>
                                                setTagName(e.target.value)
                                            }
                                            placeholder="Tag name"
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: '#09C2F3',
                                                width: '8rem',
                                                height: '3rem',
                                                color: 'white',
                                            }}
                                            disabled={tagName === ''}
                                            onClick={() => {
                                                addTag(record.id, record.tags);
                                            }}
                                        >
                                            Add new tag
                                        </Button>
                                    </div>
                                    <div>
                                        <Checkbox
                                            placeholder="Is Generic"
                                            checked={isGeneric}
                                            onChange={() => {
                                                setIsGeneric(prev => !prev);
                                            }}
                                        />
                                        <span>Is generic?</span>
                                    </div>
                                </div>
                            )}
                        />
                    )}
                </FormTab>

                <FormTab label="Documents">
                    <SelectInput
                        source="id1Type"
                        label="ID Type"
                        {...props}
                        choices={idTypeChoices}
                    />
                    <TextInput source="id1Number" label="ID Number" />
                    <DateInput source="id1Expiry" label="ID Expiry Date" />
                    <SelectInput
                        source="id2Type"
                        label="ID Type"
                        {...props}
                        choices={idTypeChoices}
                    />
                    <TextInput source="id2Number" label="ID Number" />
                    <DateInput source="id2Expiry" label="ID Expiry Date" />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default UserCreate;
