import * as React from 'react';

function SvgMail(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.107 13.554L20 19.779l8.893-6.225A1.006 1.006 0 0028 13H12c-.388 0-.728.227-.893.554zM29 15.92l-8.427 5.898a1 1 0 01-1.146 0L11 15.921V26c0 .548.452 1 1 1h16c.548 0 1-.452 1-1V15.92zM9 14c0-1.652 1.348-3 3-3h16c1.652 0 3 1.348 3 3v12c0 1.652-1.348 3-3 3H12c-1.652 0-3-1.348-3-3V14z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgMail;
