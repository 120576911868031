import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { PartnerSummary } from './PartnerSummary';
import { PartnerInvoiceList } from './PartnerInvoiceList';
import { PartnerCommissionList } from './PartnerCommissionList';

export const PartnerTabs = props => (
    <TabbedShowLayout {...props}>
        <Tab label="summary">
            <PartnerSummary />
        </Tab>
        <Tab label="invoices">
            <PartnerInvoiceList />
        </Tab>
        <Tab label="commission">
            <PartnerCommissionList />
        </Tab>
    </TabbedShowLayout>
);

PartnerTabs.propTypes = {
    record: PropTypes.object,
};

PartnerTabs.defaultProps = {
    record: {},
};
