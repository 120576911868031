import React from 'react';

import {
    Create,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    NumberInput,
} from 'react-admin';

const ServiceTypeCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined" redirect="list">
            <TextInput
                disabled
                source="rent"
                defaultValue="0"
                style={{ display: 'none' }}
            />
            <TextInput
                disabled
                source="currency"
                defaultValue="AED"
                style={{ display: 'none' }}
            />
            {/*<TextInput source="id" />*/}
            <TextInput source="name" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export default ServiceTypeCreate;
