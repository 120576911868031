import React, { useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import capitalize from 'capitalize';
import queryString from 'query-string';
import { Button, InputAdornment } from '@material-ui/core';
import { Form, Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Source } from '../constants';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Edit,
    FormDataConsumer,
    FormTab,
    ListButton,
    ReferenceInput,
    SaveButton,
    SelectArrayInput,
    SelectInput,
    ShowButton,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    Toolbar,
    TopToolbar,
    useNotify,
    useRefresh,
    RadioButtonGroupInput,
    NumberInput,
} from 'react-admin';
import {
    PaymentMethod,
    PickupTimeFrom,
    PickupTimeTo,
    StorageType,
    OrderType,
    Caption,
} from '../constants';
import MoneyInput from '../components/MoneyInput';
import BillingCard from '../orders/components/BillingCard';
import UserQuickEditButton from './components/UserQuickEditButton';
import ItemsInput from '../orders/components/ItemsInput';
import { ActionProps, EditProps, Lead, ValidationErrors } from '../types';
import BoxitSalesCalendarInput from '../components/BoxitSalesCalendarInput';
import PartnerInput from '../components/PartnerInput';
import CountryInput from '../components/CountryInput';
import CityInput from '../components/CityInput';
import CurrencyInput from '../components/CurrencyInput';
import BoxitPhoneInput from '../components/BoxitPhoneInput';
import PlaceAutoComplete from '../components/PlaceAutoComplete';
import AddOrderChecklistPhoto from '../orders/components/AddOrderChecklistPhoto';
import WhenFieldChanges from '../components/WhenFieldChanges';
import { maxLength } from 'react-admin';

const hasBoxitBoxes = (formData: any): boolean => {
    return (
        formData.items &&
        formData.items.some(
            i =>
                i &&
                i.storagetypeId === StorageType.BB &&
                Number(i.quantity) > 0
        )
    );
};

const validate = values => {
    const errors: ValidationErrors = {};

    if (!values.countryId) {
        errors.countryId = ['errors.required'];
    }

    if (!values.cityId) {
        errors.cityId = ['errors.required'];
    }

    if (values.months > 36) {
        errors.months = ['Must be less than or equal to 36'];
    }

    if (values.type === OrderType.storage) {
        if (!values.need) {
            errors.need = ['errors.required'];
        }
    }

    if (!values.channel) {
        errors.channel = ['errors.required'];
    }

    if (!values.source) {
        errors.source = ['errors.required'];
    }

    return errors;
};

function getQuoteVersion(props) {
    const parsed =
        props &&
        props.location &&
        props.location.search &&
        queryString.parse(props.location.search);
    return parsed.quoteVersion;
}

const LeadEditActions: React.FC<ActionProps<Lead>> = ({
    basePath,
    data,
    resource,
}) => (
    <TopToolbar>
        <ShowButton basePath={basePath} record={data} />
        <ListButton color="secondary" basePath={basePath} record={data} />
    </TopToolbar>
);

const LeadEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const transform = data => ({
    ...data,
    ...(data.need && { need: data.need.trim().toLowerCase() }),
});

const LeadEdit: React.FC<EditProps<Lead>> = ({
    permissions = [],
    ...props
}) => {
    const [isEditCustomerDialogOpen, setIsEditCustomerDialogOpen] = useState(
        false
    );

    return (
        <Edit
            actions={<LeadEditActions />}
            undoable={false}
            successMessage="resources.leads.notification.update_success"
            {...props}
            transform={transform}
        >
            <TabbedForm
                variant="outlined"
                validate={validate}
                toolbar={<LeadEditToolbar />}
                redirect="show"
                {...sanitizeRestProps(props)}
            >
                <FormTab label="Storage">
                    <TextInput
                        style={{ display: 'none' }}
                        source="nextQuoteVersion"
                        defaultValue={getQuoteVersion(props)}
                    />

                    <SelectInput
                        source="type"
                        label="Order Type"
                        defaultValue={OrderType.storage}
                        choices={Object.keys(OrderType).map(i => ({
                            id: i,
                            name: capitalize(i.replace('_', ' ')),
                        }))}
                    />

                    {permissions.indexOf('staff') !== -1 && <PartnerInput />}
                    <CountryInput />
                    <CityInput />
                    <CurrencyInput />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <TextInput
                                    variant="outlined"
                                    source="months"
                                    label="Duration"
                                    defaultValue={1}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                Months
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    {/* <MoneyInput source="customerBudget" {...props} /> */}

                    <SelectInput
                        source="paymentMode"
                        label="Payment Mode"
                        choices={Object.keys(PaymentMethod).map(i => ({
                            id: i,
                            name: capitalize(i),
                        }))}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <SelectInput
                                    variant="outlined"
                                    label="Warehouse Type"
                                    source="warehouseType"
                                    choices={[
                                        { id: 'ac', name: 'AC' },
                                        { id: 'non-ac', name: 'Non AC' },
                                    ]}
                                    parse={v => v && [v]}
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <TextInput
                        multiline
                        source="terms"
                        label="Additional Terms"
                    />

                    <BooleanInput
                        label="Need Insurance in Quote?"
                        source="insuranceRequired"
                    />

                    <TextInput
                        label="Comment"
                        source="reason"
                        validate={maxLength(200)}
                    />
                </FormTab>

                <FormTab label="Customer">
                    <ReferenceInput
                        label="User"
                        source="userId"
                        reference="users"
                        resource="users"
                        allowEmpty
                    >
                        <AutocompleteInput
                            shouldRenderSuggestions={val =>
                                val.trim().length > 1
                            }
                            optionText={user => `${user.name}   (${user.id})`}
                        />
                    </ReferenceInput>
                    <>
                        <div style={{ marginBottom: '35px' }}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() =>
                                    setIsEditCustomerDialogOpen(true)
                                }
                            >
                                Edit Customer
                            </Button>
                        </div>
                    </>
                    <UserQuickEditButton
                        record={props.record}
                        isOpen={isEditCustomerDialogOpen}
                        setIsOpen={setIsEditCustomerDialogOpen}
                    />
                    <TextInput label="Reference" source="ref" />
                    <TextInput
                        variant="outlined"
                        multiline
                        source="need"
                        label="Need for Storage"
                    />
                    {_.intersection(permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && (
                        <SelectArrayInput
                            label="Tags"
                            source="tags"
                            choices={[
                                { key: 'admin', id: 'admin', name: 'admin' },
                            ]}
                            defaultValue={['admin']}
                            style={{ display: 'none' }}
                        />
                    )}
                    {_.intersection(permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && (
                        <SelectInput
                            key="channel"
                            source="channel"
                            choices={[
                                { id: 'google', name: 'Google' },
                                { id: 'youtube', name: 'YouTube' },
                                { id: 'instagram', name: 'Instagram' },
                                { id: 'existing', name: 'Existing Customer' },
                                { id: 'friend', name: 'Referred by friend' },
                                { id: 'facebook', name: 'Facebook' },
                                { id: 'tv', name: 'TV' },
                                { id: 'blog', name: 'Blog' },
                                { id: 'event', name: 'Event' },
                                { id: 'flyer', name: 'Flyer' },
                                { id: 'press', name: 'Press / News' },
                                { id: 'signage', name: 'Signage' },
                                { id: 'truck', name: 'Truck' },
                                { id: 'partner', name: 'Partner' },
                                { id: 'other', name: 'Other' },
                            ]}
                        />
                    )}

                    {_.intersection(permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && (
                        <SelectInput
                            key="source"
                            source="source"
                            choices={Object.keys(Source).map(c => ({
                                id: c,
                                name: Source[c],
                            }))}
                        />
                    )}

                    {_.intersection(permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && (
                        <TextInput label="Coupon" source="couponId" />
                    )}

                    <TextInput multiline source="notes" />
                </FormTab>

                <FormTab label="Address">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.moving && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Source Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Address"
                                    source="addressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                    allowEmpty
                                >
                                    <SelectInput
                                        source="addressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <TextInput
                                    source="line1"
                                    label="Address Line"
                                />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <PlaceAutoComplete source="area" />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.moving && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Destination Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Address"
                                    source="destAddressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                >
                                    <SelectInput
                                        source="destAddressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId && (
                                <TextInput
                                    source="destLine1"
                                    label="Address Line"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId && (
                                <TextInput
                                    source="destArea"
                                    label="Address Area"
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Items">
                    <FormDataConsumer>
                        {formDataProps => <ItemsInput {...formDataProps} />}
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Services">
                    <ArrayInput source="services" label="">
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="service"
                                source="servicetypeId"
                                reference="service-types"
                                resource="service-types"
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SelectInput
                                    source="id"
                                    label="Service"
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        paddingRight: '50px',
                                    }}
                                />
                            </ReferenceInput>
                            <MoneyInput source="amount" label="charge" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Date">
                    <RadioButtonGroupInput
                        label="Type of Operation"
                        source="pickupRequired"
                        defaultValue={true}
                        row={true}
                        choices={[
                            { id: true, name: 'Pickup' },
                            { id: false, name: 'Drop Off' },
                        ]}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <DateInput
                                label={
                                    formData.pickupRequired
                                        ? 'Pickup Date'
                                        : 'Drop Date'
                                }
                                source="date"
                                parse={v => v && moment(v).format('YYYY-MM-DD')}
                                style={{
                                    display: 'inline',
                                    float: 'left',
                                }}
                            />
                        )}
                    </FormDataConsumer>

                    <SelectInput
                        variant="outlined"
                        label="Time Slot"
                        source="pickupTimeFrom"
                        choices={[
                            {
                                id: 9,
                                name: '9 am - 1 pm',
                            },
                            {
                                id: 13,
                                name: '1 pm - 6 pm',
                            },
                        ]}
                        translateChoice={false}
                        style={{
                            marginLeft: '20px',
                        }}
                    />

                    <NumberInput
                        style={{ display: 'none' }}
                        source="pickupTimeTo"
                    />

                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={9}
                        set="pickupTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={13}
                        set="pickupTimeTo"
                        to={18}
                    />

                    {/*
                    <SelectInput
                        label="From"
                        source="pickupTimeFrom"
                        choices={PickupTimeFrom.map(c => ({
                            id: c,
                            name: c,
                        }))}
                        translateChoice={false}
                        fullWidth={true}
                        style={{
                            display: 'inline',
                            float: 'left',
                            marginLeft: '20px',
                            minWidth: '10px',
                            width: '100px',
                        }}
                    />

                    <SelectInput
                        label="To"
                        source="pickupTimeTo"
                        choices={PickupTimeTo.map(c => ({
                            id: c,
                            name: c,
                        }))}
                        translateChoice={false}
                        fullWidth={true}
                        style={{
                            display: 'inline',
                            float: 'left',
                            marginLeft: '20px',
                            minWidth: '10px',
                            width: '100px',
                        }}
                    />
                    */}

                    <>
                        <div style={{ clear: 'both', width: '100%' }} />
                        <br />
                        <br />
                    </>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.countryId && (
                                <BoxitSalesCalendarInput
                                    source="date"
                                    countryId={formData.countryId}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <>
                        <br />
                        <br />
                    </>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) && (
                                <BooleanInput
                                    source="newboxRequired"
                                    label="Deliver Boxit Boxes Early"
                                    defaultValue={false}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <DateInput
                                    label="Box Delivery Date"
                                    source="deliveryDate"
                                    parse={v =>
                                        v && moment(v).format('YYYY-MM-DD')
                                    }
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <SelectInput
                                    variant="outlined"
                                    label="Time Slot"
                                    source="deliveryTimeFrom"
                                    choices={[
                                        {
                                            id: 9,
                                            name: '9 am - 1 pm',
                                        },
                                        {
                                            id: 13,
                                            name: '1 pm - 6 pm',
                                        },
                                    ]}
                                    translateChoice={false}
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <NumberInput
                                    style={{ display: 'none' }}
                                    source="deliveryTimeTo"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={9}
                        set="deliveryTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={13}
                        set="deliveryTimeTo"
                        to={18}
                    />

                    {/*
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <SelectInput
                                    label="From"
                                    source="deliveryTimeFrom"
                                    choices={PickupTimeFrom.map(c => ({
                                        id: c,
                                        name: c,
                                    }))}
                                    translateChoice={false}
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        marginLeft: '20px',
                                        minWidth: '10px',
                                        width: '100px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <SelectInput
                                    label="To"
                                    source="deliveryTimeTo"
                                    choices={PickupTimeTo.map(c => ({
                                        id: c,
                                        name: c,
                                    }))}
                                    translateChoice={false}
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        marginLeft: '20px',
                                        minWidth: '10px',
                                        width: '100px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>
                    */}

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <hr style={{ clear: 'both', width: '100%' }} />
                            )
                        }
                    </FormDataConsumer>

                    <BooleanInput
                        label="Need Survey?"
                        source="surveyRequired"
                        fullWidth={true}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <DateInput
                                    source="surveyDate"
                                    label="Survey Date"
                                    parse={v =>
                                        v && moment(v).format('YYYY-MM-DD')
                                    }
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <SelectInput
                                    source="surveyTime"
                                    label="Time"
                                    choices={[
                                        { id: 9, name: '9 AM' },
                                        { id: 10, name: '10 AM' },
                                        { id: 11, name: '11 AM' },
                                        { id: 12, name: '12 PM' },
                                        { id: 13, name: '1 PM' },
                                        { id: 14, name: '2 PM' },
                                        { id: 15, name: '3 PM' },
                                        { id: 16, name: '4 PM' },
                                        { id: 17, name: '5 PM' },
                                        { id: 18, name: '6 PM' },
                                    ]}
                                    fullWidth={true}
                                    style={{
                                        display: 'inline',
                                        float: 'left',
                                        marginLeft: '20px',
                                        minWidth: '10px',
                                        width: '100px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.surveyRequired && (
                                <hr style={{ clear: 'both', width: '100%' }} />
                            )
                        }
                    </FormDataConsumer>

                    <DateInput
                        label="Follow Up"
                        source="followUpAt"
                        parse={v => v && moment(v).format('YYYY-MM-DD')}
                    />
                </FormTab>

                <FormTab label="Checklist">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <SelectInput
                                    source="checklist.buildingType"
                                    label="Type of Building"
                                    style={{ width: 300 }}
                                    choices={[
                                        { id: 'Flat', name: 'Flat' },
                                        { id: 'Villa', name: 'Villa' },
                                        { id: 'Hotel', name: 'Hotel' },
                                        { id: 'Office', name: 'Office' },
                                        { id: 'Other', name: 'Other' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is entry permit required"
                                    source="checklist.permitRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.checklist &&
                            formData.checklist.permitRequired && (
                                <AddOrderChecklistPhoto
                                    record={formData}
                                    caption={Caption.entry_permit}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is moving insurance required"
                                    source="checklist.movingInsuranceRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is parking slot available"
                                    source="checklist.parkingSlotAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.parkingSlotAvailable && (
                                <RadioButtonGroupInput
                                    label="Is parking slot inside or outside the building"
                                    source="checklist.isSlotInsideOrOutside"
                                    row={true}
                                    fullWidth={true}
                                    choices={[
                                        {
                                            id: 'Inside',
                                            name: 'Inside',
                                        },
                                        {
                                            id: 'Outside',
                                            name: 'Outside',
                                        },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            formData.checklist.parkingSlotAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLoadingTime"
                                    label="Maximum allowed Loading time"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <TextInput
                                    source="checklist.buildingFloor"
                                    label="Building Floor"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is lift available"
                                    source="checklist.liftAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLiftTime"
                                    label="Maximum allowed time for Lift"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <RadioButtonGroupInput
                                    label="Are there items that would not fit inside the lift"
                                    source="checklist.anyItemsNotFitinsideLift"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.anyItemsNotFitinsideLift && (
                                <TextInput
                                    multiline
                                    style={{ width: 300 }}
                                    label="If yes details"
                                    source="checklist.liftNotFitDetails"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="If customer wants to authorize someone"
                                    source="checklist.authorizeOtherPerson"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person Name"
                                    source="checklist.authorizedName"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <BoxitPhoneInput
                                    source="checklist.authorizedPhone"
                                    label="Authorized Person Phone"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person ID Number"
                                    source="checklist.authorizedID"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Billing">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <BillingCard record={formData} />
                        )}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default LeadEdit;
