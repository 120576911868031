import * as React from 'react';

function SvgHome(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.386 9.268a1 1 0 011.228 0l9 7a1 1 0 01.386.79v11a3 3 0 01-3 3H13a3 3 0 01-3-3v-11a1 1 0 01.386-.79l9-7zM12 17.546v10.511a1 1 0 001 1h14a1 1 0 001-1v-10.51l-8-6.223-8 6.222z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 20.057a1 1 0 011-1h6a1 1 0 011 1v10a1 1 0 11-2 0v-9h-4v9a1 1 0 11-2 0v-10z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgHome;
