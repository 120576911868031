import React, { cloneElement } from 'react';
import Swal from 'sweetalert2';
import { ImportButton } from 'react-admin-import-csv';
import {
    AutocompleteInput,
    BooleanField,
    List,
    Datagrid,
    TextField,
    ShowButton,
    CreateButton,
    ExportButton,
    Filter,
    SelectInput,
    ReferenceInput,
    useListContext,
    TopToolbar,
    sanitizeListRestProps,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import BoxitPagination from '../components/BoxitPagination';
import UserReferenceField from '../components/UserReferenceField';

const StorageTypeFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>
        <SelectInput
            source="inventory"
            label="Type"
            choices={[
                {
                    id: true,
                    name: 'Inventory',
                },
                {
                    id: false,
                    name: 'Not Inventory',
                },
            ]}
        />
    </Filter>
);
const ListActions = props => {
    const { className, exporter, filters, maxResults, ...rest } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <ImportButton
                {...props}
                logging={false}
                dynamicTyping={true}
                transformRows={(csvRows: any[]) => {
                    return csvRows.map(i => ({
                        ...i,
                        inventory: true,
                        partnerId: 1,
                    }));
                }}
                validateRow={(csvRowItem: any) => {
                    let missing: string[] = [];
                    if (!csvRowItem.code) missing.push('code');
                    if (!csvRowItem.name) missing.push('name');
                    if (!csvRowItem.size) missing.push('size');
                    if (!csvRowItem.userId) missing.push('userId');
                    if (!csvRowItem.partnerId) missing.push('partnerId');
                    if (!csvRowItem.inventory) missing.push('inventory');

                    if (missing.length) {
                        const error = `Missing fields: ${missing.join(', ')}`;
                        Swal.fire('Validation Error', error, 'error');
                        throw new Error(error);
                    }
                }}
                postCommitCallback={(error: any) => {
                    if (error.filter(row => !row.success).length) {
                        const msg = error
                            .map(
                                (i, index) =>
                                    `
<tr>
<td>${index + 1}. </td>
<td>${i.err.errors.map(e => e.value + ': ' + e.message).join(', ')}
</td>
</tr>`
                            )
                            .join('');

                        Swal.fire('Error', `<table>${msg}</table>`, 'error');
                    }
                }}
            />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <CreateButton basePath={basePath} />
        </TopToolbar>
    );
};

const StorageTypeList = props => (
    <List
        title="StorageTypes"
        bulkActionButtons={false}
        filters={<StorageTypeFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<BoxitPagination />}
        actions={<ListActions />}
        perPage={50}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <UserReferenceField label="Customer" />
            <TextField source="code" />
            <TextField source="name" />
            <TextField source="size" label="CBM" />
            <TextField source="length" label="Length" />
            <TextField source="height" label="Height" />
            <TextField source="width" label="Width" />
            <BooleanField source="inventory" />
            <ShowButton />
        </Datagrid>
    </List>
);

StorageTypeList.defaultProps = {
    resource: 'storage-types',
    location: { search: '' },
};

export default StorageTypeList;
