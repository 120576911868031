import * as React from 'react';

function SvgCalendar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 11a1 1 0 10-2 0v1h-6v-1a1 1 0 10-2 0v1h-2a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3V15a3 3 0 00-3-3h-2v-1zm3 7v-3a1 1 0 00-1-1h-2v1a1 1 0 11-2 0v-1h-6v1a1 1 0 11-2 0v-1h-2a1 1 0 00-1 1v3h16zm-16 2h16v9a1 1 0 01-1 1H13a1 1 0 01-1-1v-9z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgCalendar;
