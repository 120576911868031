import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    ChipField,
    EditButton,
    ShowButton,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
} from 'react-admin';

import CountryField from '../components/CountryField';
import CustomSearchInput from '../components/CustomSearchInput';
const TeamFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput optionText={choice => `${choice.name}`} />
        </ReferenceInput>
    </Filter>
);

const TeamList = props => (
    <List
        title="Teams"
        bulkActionButtons={false}
        filters={<TeamFilter />}
        sort={{ field: 'id', order: 'ASC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="name" />
            <BooleanField source="active" />
            <CountryField />
            <EditButton color="secondary" style={{ padding: 0 }} />
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

TeamList.defaultProps = {
    resource: 'teams',
    location: { search: '' },
};

export default TeamList;
