import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    Filter,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { commentCreate as commentCreateAction } from './actions/commentActions';

import UserReferenceField from '../components/UserReferenceField';
import CommentForm from './CommentCreate';

const CommentFilter = props => (
    <Filter variant="standard" {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>
    </Filter>
);

const CommentList = props => (
    <List title="Comments" filters={<CommentFilter />} {...props}>
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <DateField
                locales="en-IN"
                label="Date"
                source="createdAt"
                showTime
            />
            <UserReferenceField
                {...props}
                source="authorId"
                label="Author"
                link={false}
            />
            <UserReferenceField
                {...props}
                source="userId"
                label="Customer"
                link={false}
            />
            <TextField source="model" />
            <TextField source="comment" />
        </Datagrid>
    </List>
);

CommentList.defaultProps = {
    resource: 'comments',
    location: { search: '' },
};

export default CommentList;
