export function formatMoney(
    amount: number,
    currency: string,
    skipEmptyFraction = false
): string {
    if (amount === null || amount === undefined) {
        return '';
    }

    let money;

    if (currency === 'KWD') {
        money = (amount / 10000).toFixed(3);
        if (skipEmptyFraction) {
            return money.replace(/\.000$/, '');
        }
        return money;
    } else if (currency === 'AED') {
        money = (amount / 10000).toFixed(2);
        if (skipEmptyFraction) {
            return money.replace(/\.00$/, '');
        }
        return money;
    } else if (currency === 'SAR') {
        money = (amount / 10000).toFixed(2);
        if (skipEmptyFraction) {
            return money.replace(/\.00$/, '');
        }
        return money;
    }

    return '';
}
