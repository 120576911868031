import React from 'react';
import { List, Datagrid, TextField, ShowButton, Filter } from 'react-admin';
import BoxitPagination from '../components/BoxitPagination';
import { SelectArrayInput } from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import { EditButton } from 'react-admin';

const ReasonFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
        </Filter>
    );
};

const CancelReasonList = props => (
    <List
        title="cancel reasons"
        bulkActionButtons={false}
        filters={<ReasonFilter />}
        pagination={<BoxitPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={10}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" />
            <TextField source="id" />
            <TextField source="reason" />
            <EditButton color="secondary" style={{ padding: 0 }} />
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

CancelReasonList.defaultProps = {
    resource: 'cancel-reasons',
    location: { search: '' },
};

export default CancelReasonList;
