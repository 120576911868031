import React from 'react';
import {
    BooleanField,
    DateField,
    Datagrid,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import CountryField from '../components/CountryField';

const CouponList = props => (
    <List title="Coupons" {...props}>
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <DateField source="createdAt" label="Created" />
            <TextField source="id" />
            <BooleanField source="reusable" />
            <DateField locales="en-IN" source="expiry" />
            <CountryField />
            <EditButton />
        </Datagrid>
    </List>
);

CouponList.defaultProps = {
    resource: 'coupons',
    location: { search: '' },
};

export default CouponList;
