import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListButton, EditButton, RefreshButton, TopToolbar } from 'react-admin';
import ViewIcon from '@material-ui/icons/ViewComfy';
import { Button } from '@material-ui/core';
import { ActionProps, Warehouse } from '../../types';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

const WarehouseShowButtons: FC<ActionProps<Warehouse>> = ({
    basePath,
    data,
}) => {
    const classes = useStyles();

    return (
        <TopToolbar>
            <Button
                color="secondary"
                href={data ? `/#/warehouses/${data.id}/view` : ''}
            >
                <ViewIcon className={classes.leftIcon} />
                View
            </Button>

            <EditButton color="secondary" basePath={basePath} record={data} />
            <ListButton color="secondary" basePath={basePath} />
            <RefreshButton color="secondary" />
        </TopToolbar>
    );
};

export default WarehouseShowButtons;
