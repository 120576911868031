import React, { useState } from 'react';
import { useGetList, useNotify, useTranslate, useUpdate } from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AssignIcon from '@material-ui/icons/AssignmentInd';
import { Order } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
    })
);

type Props = {
    record: Order;
};

const AssignButton: React.FC<Props> = ({ record }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [assigneeId, setAssigneeId] = useState(null);

    const { ids, data, total, loaded } = useGetList(
        'users',
        {},
        { field: 'id', order: 'DESC' },
        { 'roles[$contains]': ['sales-staff'] }
    );

    const [assignOrder, { loading }] = useUpdate(
        'orders',
        record.id,
        { assigneeId },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('resources.leads.notification.assign_success', 'info');
                window.location.reload();
            },
            onFailure: () => {
                notify('resources.leads.notification.assign_error', 'warning');
            },
        }
    );

    const handleAssigneeIdChange = event => {
        setAssigneeId(event.target.value);
    };

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                disabled={loading}
                startIcon={<AssignIcon />}
                onClick={() => setIsOpen(true)}
            >
                {translate('action.assign')}
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>
                    {translate('resources.leads.action.assign')}
                </DialogTitle>

                <DialogContent>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel id="select-assigneeId-label">
                            Person
                        </InputLabel>
                        <Select
                            labelId="select-assigneeId-label"
                            id="select-assigneeId"
                            autoFocus
                            required
                            onChange={handleAssigneeIdChange}
                            label="Person"
                        >
                            {ids.map(id => (
                                <MenuItem key={id} value={id}>
                                    {data[id].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>
                        {translate('action.close')}
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading || !assigneeId}
                        onClick={() => {
                            assignOrder();
                            setIsOpen(false);
                        }}
                    >
                        {translate('resources.leads.action.assign')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AssignButton;
