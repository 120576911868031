import React from 'react';
import moment from 'moment';
import SetDefaultButton from '../components/SetDefaultButton';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    ShowButton,
} from 'react-admin';

import CountryField from '../components/CountryField';
import CustomSearchInput from '../components/CustomSearchInput';
import { SelectArrayInput } from 'react-admin';

const CCExpiry = ({ record = {}, source = 'expiryDate', label = '' }) => {
    const expiryDate = record[source];
    return (
        expiryDate && (
            <span>{`${expiryDate.substring(2, 4)} / ${expiryDate.substring(
                0,
                2
            )}`}</span>
        )
    );
};

const CardFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>

        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={[
                { id: 'active', name: 'active' },
                { id: 'inactive', name: 'inactive' },
            ]}
        />

        <DateInput
            source="createdAt[$gte]"
            label="Created From"
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="createdAt[$lte]"
            label="Created To"
            parse={v => v && moment(v).endOf('day').format()}
        />
    </Filter>
);

const CardList = props => (
    <List
        bulkActionButtons={false}
        filters={<CardFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <DateField
                locales="en-IN"
                source="createdAt"
                label="Created"
                showTime
            />
            <CountryField />
            <ReferenceField
                label="Customer"
                source="userId"
                reference="users"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField source="cardHolderName" label="Name" />
            <TextField source="cardNumber" label="Card" />
            <CCExpiry source="expiryDate" label="Expiry" />
            <TextField source="status" />
            <BooleanField source="default" />
            <ShowButton />

            <SetDefaultButton undoable={false} />
        </Datagrid>
    </List>
);

CardList.defaultProps = {
    resource: 'cards',
    location: { search: '' },
};

export default CardList;
