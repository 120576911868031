import React from 'react';
import {
    AutocompleteInput,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { EditProps, TitleProps, Partner } from '../types';

const PartnerTitle: React.FC<TitleProps<Partner>> = ({ record }) => (
    <span>{record!.name ? `${record!.name}` : `Partner #${record!.id}`}</span>
);

const PartnerEdit: React.FC<EditProps<Partner>> = props => (
    <Edit title={<PartnerTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="logoUrl" label="Logo" />
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput
                label="User"
                source="userId"
                reference="users"
                resource="users"
                allowEmpty
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 1}
                    optionText={user => `${user.name}   (${user.id})`}
                />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default PartnerEdit;
