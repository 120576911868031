import React from 'react';
import { TextInput, Create, SimpleForm } from 'react-admin';

const templateCategoryCreate = props => {
    const validate = values => {
        const errors: any = {};

        if (!values.name) {
            errors.name = ['Name is required'];
        }

        return errors;
    };
    return (
        <Create {...props}>
            <SimpleForm validate={validate} redirect="list">
                <TextInput label="Name" source="name" />
                <TextInput label="Description" source="description" />
            </SimpleForm>
        </Create>
    );
};

export default templateCategoryCreate;
