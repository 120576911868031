import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ShowButton,
    EditButton,
} from 'react-admin';

import MoneyField from '../../components/MoneyField';
import ChargeDateField from '../../components/ChargeDateField';
import DeleteButton from '../../components/DeleteButton';

const ChargeList = props => {
    if (!props.ids || !props.ids.length) return null;

    return (
        <Datagrid {...props}>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <ReferenceField
                {...props}
                source="invoiceId"
                reference="invoices"
                link="show"
                label="Invoice"
                allowEmpty
            >
                <TextField source="id" />
            </ReferenceField>
            <TextField source="type" />
            <ChargeDateField />
            <MoneyField source="amount" showCurrency />
            <TextField source="description" />
            <ShowButton style={{ padding: 0 }} />
            <EditButton color="secondary" style={{ padding: 0 }} />
            <DeleteButton undoable={false} />
        </Datagrid>
    );
};

const RequestChargeList = props => (
    <ReferenceManyField
        reference="charges"
        resource="charges"
        basePath="/charges"
        target="requestId"
        {...props}
    >
        <ChargeList />
    </ReferenceManyField>
);

RequestChargeList.propTypes = {
    record: PropTypes.object.isRequired,
};

export default RequestChargeList;
