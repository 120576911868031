import * as React from 'react';

function SvgBarChart(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25 11.497a1 1 0 011-1h4a1 1 0 011 1v18a1 1 0 01-1 1h-4a1 1 0 01-1-1v-18zm2 1v16h2v-16h-2zm-10 4a1 1 0 011-1h4a1 1 0 011 1v13a1 1 0 01-1 1h-4a1 1 0 01-1-1v-13zm2 1v11h2v-11h-2zm-10 4a1 1 0 011-1h4a1 1 0 011 1v8a1 1 0 01-1 1h-4a1 1 0 01-1-1v-8zm2 1v6h2v-6h-2z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgBarChart;
