import { GET_LIST } from 'react-admin';

export const getCards = userId => ({
    type: GET_LIST,
    payload: { filter: { userId } },
    meta: {
        resource: 'cards',
        fetch: GET_LIST,
        cancelPrevious: false,
        query: { userId },
    },
});
