import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    DateField,
    ChipField,
    EmailField,
    ReferenceField,
    TextField,
} from 'react-admin';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CountryField from '../../components/CountryField';
import MoneyField from '../../components/MoneyField';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { ShowProps, User } from '../../types';

const UserDocs: React.FC<ShowProps<User>> = ({
    record,
    basePath,
    permissions = [],
    ...props
}) => (
    <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>No</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>Expiry</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>1</TableCell>
                    <TableCell>{record.id1Type}</TableCell>
                    <TableCell>{record.id1Number}</TableCell>
                    <TableCell>{record.id1Expiry}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>2</TableCell>
                    <TableCell>{record.id2Type}</TableCell>
                    <TableCell>{record.id2Number}</TableCell>
                    <TableCell>{record.id2Expiry}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
);

export default UserDocs;
