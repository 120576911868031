import React from 'react';
import { ListButton, EditButton, TopToolbar } from 'react-admin';
import { ActionProps, Card } from '../../types';
import DeleteButton from '../../../src/components/DeleteButton';

const CardShowButtons: React.FC<ActionProps<Card>> = ({
    basePath,
    data,
    resource,
}) => {
    return (
        <TopToolbar>
            {/* <EditButton color="secondary" basePath={basePath} record={data} /> */}
            <DeleteButton
                basePath={basePath}
                record={data}
                resource={resource}
                undoable={false}
            />
            <ListButton color="secondary" basePath={basePath} />
        </TopToolbar>
    );
};

export default CardShowButtons;
