import * as React from 'react';

function SvgBox(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.556 12.345h-.001l-7.998 4h-.002a1 1 0 00-.555.896v9.526a1 1 0 00.55.9l7.997 3.999a1.001 1.001 0 00.895 0l8-4 .003-.001A1 1 0 0029 26.77v-9.53a.998.998 0 00-.555-.894l-.002-.001-7.998-4a1 1 0 00-.89 0zm1.334-.895l.445-.895a3 3 0 00-2.67 0h-.002l-7.998 4h-.001A3 3 0 009 17.24v9.517a3 3 0 001.65 2.696l.003.002 8 4a3 3 0 002.684 0l7.998-4h.001A3 3 0 0031 26.771v-9.53a3 3 0 00-1.665-2.686l-7.998-4-.447.895z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.426 15.713a1 1 0 011.341-.447L20 19.882l9.233-4.616a1 1 0 01.894 1.789l-9.68 4.84a1 1 0 01-.894 0l-9.68-4.84a1 1 0 01-.447-1.342z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 20a1 1 0 011 1v11.76a1 1 0 01-2 0V21a1 1 0 011-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgBox;
