import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomerIcon from '@material-ui/icons/PersonAdd';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardIcon from './CardIcon';
import {
    Card,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Typography,
    Divider,
} from '@material-ui/core';
import { User } from '../../types';

const useStyles = makeStyles(theme => ({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        padding: '16px 0',
        overflow: 'inherit',
        textAlign: 'right',
    },
    title: {
        padding: '0 16px',
    },
    value: {
        padding: '0 16px',
        minHeight: 48,
    },
    listItemText: {
        paddingRight: 0,
    },
}));

export default ({ users = [], nb }: { users: User[]; nb: number }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.main}>
            <CardIcon Icon={CustomerIcon} bgColor="#4caf50" />
            <Card className={classes.card}>
                <Typography
                    variant="body2"
                    className={classes.title}
                    color="textSecondary"
                >
                    {translate('pos.dashboard.new_customers')}
                </Typography>
                <Typography variant="h4" className={classes.value}>
                    {nb}
                </Typography>
                <Divider />
                <List>
                    {users.map(record => (
                        <ListItem
                            button
                            to={`/customers/${record.id}`}
                            component={Link}
                            key={record.id}
                        >
                            <Avatar>{record.name[0]}</Avatar>
                            <ListItemText
                                primary={`${record.name}`}
                                className={classes.listItemText}
                            />
                        </ListItem>
                    ))}
                </List>
            </Card>
        </div>
    );
};
