import React from 'react';
import {
    DateField,
    ChipField,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField,
} from 'react-admin';
import Money from '../../components/Money';
import UserReferenceField from '../../components/UserReferenceField';
import InvoiceNo from './InvoiceNo';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    makeStyles,
} from '@material-ui/core';
import { ShowPropsOptional, Invoice } from '../../types';
import { FunctionField } from 'react-admin';
import { formatMoney } from '../../util/formatMoney';

const useStyles = makeStyles({
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

export const InvoiceSummary: React.FC<ShowPropsOptional<Invoice>> = props => {
    const { record } = props;
    const classes = useStyles();

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="type" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Invoice Id</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Invoice No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <InvoiceNo record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="date"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="status" />
                        </TableCell>
                    </TableRow>
                    {record && record.orderId && (
                        <TableRow>
                            <TableCell>Order</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    {...props}
                                    label="Order"
                                    source="orderId"
                                    reference="orders"
                                    link="show"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <small style={{ display: 'block' }}>
                                {record?.currency}
                            </small>
                            {record?.type === 'creditNote'
                                ? '-' +
                                  formatMoney(record?.amount, record?.currency)
                                : formatMoney(record?.amount, record?.currency)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Amount Paid</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <small style={{ display: 'block' }}>
                                {record?.currency}
                            </small>
                            {record?.type === 'creditNote'
                                ? formatMoney(
                                      record?.amountPaid,
                                      record?.currency
                                  )
                                : formatMoney(
                                      record?.amountPaid,
                                      record?.currency
                                  )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Amount Remaining</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <small style={{ display: 'block' }}>
                                {record?.currency}
                            </small>
                            {record?.type === 'creditNote'
                                ? formatMoney(
                                      record?.amount - record?.amountPaid,
                                      record?.currency
                                  )
                                : formatMoney(
                                      record?.amount - record?.amountPaid,
                                      record?.currency
                                  )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Paid At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="paidAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Payments</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceArrayField
                                record={record}
                                label="Payments"
                                reference="payments"
                                source="paymentIds"
                                basePath="/payments"
                                resource="payments"
                            >
                                <SingleFieldList linkType="show">
                                    <ChipField source="id" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Created At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};
