import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Money from './Money';
import { FieldProps } from '../types';

type Props = FieldProps & {
    record?: any;
    source: string;
    showCurrency?: boolean;
};

const MoneyField: React.FC<Props> = ({ record, source, showCurrency }) => {
    if (!record) return null;
    const value = get(record, source);
    const currency = get(record, 'currency');
    if (value == null || currency === null) return null;

    return (
        <Money amount={value} currency={currency} showCurrency={showCurrency} />
    );
};

MoneyField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    showCurrency: PropTypes.bool,
};

MoneyField.defaultProps = {
    record: null,
    showCurrency: false,
};

export default MoneyField;
