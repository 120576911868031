import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { Comments } from '../../comments';
import { History } from '../../revisions';
import { OrderChargesList } from './OrderChargesList';
import { OrderInvoicesList } from './OrderInvoicesList';
import { OrderRequestsList } from './OrderRequestsList';
import { OrderStoragesList } from './OrderStoragesList';
import { OrderSummary } from './OrderSummary';
import StorageRecords from '../../components/StorageRecords';
import { ShowProps, Order } from '../../types';
import { OrderCommunicationsList } from './OrderCommunicationsList';

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

export const OrderTabs: React.FC<ShowProps<Order>> = ({
    permissions = [],
    ...props
}) => (
    <TabbedShowLayout {...sanitizeRestProps(props)}>
        <Tab label="summary">
            <OrderSummary />
        </Tab>
        <Tab label="Storages">
            <OrderStoragesList />
        </Tab>
        <Tab label="Requests">
            <OrderRequestsList />
        </Tab>
        <Tab label="Invoices">
            <OrderInvoicesList />
        </Tab>
        <Tab label="Charges">
            <OrderChargesList />
        </Tab>
        <Tab label="Comments">
            <Comments
                model="order"
                documentId={props.record.id}
                userId={props.record.userId}
            />
        </Tab>
        <Tab label="History">
            <History {...props} model="order" documentId={props.record.id} />
        </Tab>
        <Tab label="Communication">
            <OrderCommunicationsList />
        </Tab>
    </TabbedShowLayout>
);
