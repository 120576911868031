import englishMessages from 'ra-language-english';
import * as importCSVMessages from 'react-admin-import-csv/lib/i18n';

import customEnglishMessages from './en';

export default {
    en: {
        ...englishMessages,
        ...importCSVMessages.en,
        ...customEnglishMessages,
    },
};
