import React from 'react';
import moment from 'moment';
import capitalize from 'capitalize';
import {
    ArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    DateInput,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    required,
    number,
    minValue,
    NumberInput,
} from 'react-admin';
import {
    RequestType,
    PickupTimeFrom,
    PickupTimeTo,
    PaymentMethod,
} from '../constants';
import { isTransportRequest } from '../core/utils/request';
import SelectStorageInput from './components/SelectStorageInput';
import WhenFieldChanges from '../components/WhenFieldChanges';
import { useSelector } from 'react-redux';
import useLocalStorage from '../hooks/useLocalStorage';
import { useGetOne } from 'react-admin';
import { isTransportRequestLocal } from './utils';

const RequestCreate = props => {
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);
    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.requests?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });
    return (
        <Create {...props}>
            <TabbedForm {...props} redirect="show" variant="outlined">
                <FormTab label="Request">
                    <ReferenceInput
                        label="User"
                        source="userId"
                        reference="users"
                        resource="users"
                        allowEmpty
                        defaultValue={selectedCustomer?.id}
                    >
                        <AutocompleteInput
                            shouldRenderSuggestions={val =>
                                val.trim().length > 2
                            }
                            optionText={user => `${user.name}   (${user.id})`}
                            defaultValue={selectedCustomer?.id}
                        />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Order"
                                    source="orderId"
                                    reference="orders"
                                    resource="orders"
                                    filter={{ userId: formData.userId }}
                                    perPage={100}
                                    allowEmpty
                                >
                                    <SelectInput
                                        source="orderId"
                                        optionValue="id"
                                        optionText={record =>
                                            `Order #${record.id}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <SelectInput
                        source="type"
                        choices={Object.keys(RequestType).map(c => ({
                            id: c,
                            name: capitalize(c.replace('_', ' ')),
                        }))}
                    />

                    <DateInput
                        source="date"
                        parse={v => v && moment(v).format('YYYY-MM-DD')}
                    />

                    <SelectInput
                        variant="outlined"
                        label="Time Slot"
                        source="from"
                        choices={[
                            {
                                id: 9,
                                name: '9 am - 1 pm',
                            },
                            {
                                id: 13,
                                name: '1 pm - 6 pm',
                            },
                        ]}
                        translateChoice={false}
                    />

                    <NumberInput style={{ display: 'none' }} source="to" />

                    <WhenFieldChanges
                        field="from"
                        becomes={9}
                        set="to"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="from"
                        becomes={13}
                        set="to"
                        to={18}
                    />

                    {/*
                <SelectInput
                    source="from"
                    translateChoice={false}
                    choices={PickupTimeFrom.map(c => ({ id: c, name: c }))}
                />
                <SelectInput
                    source="to"
                    translateChoice={false}
                    choices={PickupTimeTo.map(c => ({ id: c, name: c }))}
                />
                */}

                    <ReferenceInput
                        label="City"
                        source="cityId"
                        reference="city"
                        resource="city"
                        allowEmpty
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            isTransportRequestLocal(formData) &&
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Address"
                                    source="addressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                    allowEmpty
                                >
                                    <SelectInput
                                        source="addressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <SelectInput
                        source="paymentMode"
                        label="Payment Mode"
                        choices={Object.keys(PaymentMethod).map(i => ({
                            id: i,
                            name: capitalize(i),
                        }))}
                    />
                </FormTab>

                <FormTab label="Lines">
                    <BooleanInput
                        source="isSME"
                        label="Enable SME Mode"
                        defaultValue={false}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.isSME &&
                            !!formData.userId &&
                            formData.type !== RequestType.survey && (
                                <SelectStorageInput
                                    formData={formData}
                                    {...rest}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.isSME &&
                            !!formData.userId && (
                                <ArrayInput source="request_lines" label="">
                                    <SimpleFormIterator>
                                        <ReferenceInput
                                            label="Item"
                                            source="storageId"
                                            reference="storages"
                                            resource="storages"
                                            filter={{ userId: formData.userId }}
                                            perPage={20}
                                        >
                                            <AutocompleteInput
                                                source="id"
                                                optionText={s =>
                                                    `[${s.code}] ${s.storagetype.code}`
                                                }
                                                shouldRenderSuggestions={val =>
                                                    true ||
                                                    val.trim().length > 0
                                                }
                                            />
                                        </ReferenceInput>
                                        <TextInput
                                            source="qty"
                                            label="Qty"
                                            validate={[
                                                required(),
                                                number(),
                                                minValue(1),
                                            ]}
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default RequestCreate;
