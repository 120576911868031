import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { ChipField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import './BoxitChipField.css';
const useStyles = makeStyles({
    root: {
        padding: '1px 8px',
        background: '#F5F5F5',
        border: '1px solid #D9D9D9',
    },
    new: {
        fontSize: '20px',
    },
});

const BoxitChipField = ({ record, source, list, ...props }) => {
    const classes = useStyles();
    if (list == 'request') {
        var status = `request_${record.status}`;
    } else if (list == 'storage') {
        var status = `storage_${record.status}`;
    } else if (list == 'order') {
        var status = `order_${record.status}`;
    } else if (list == 'box') {
        var status = `box_${record.status}`;
    } else if (list == 'storage_record') {
        var status = `storage_record_${record.status}`;
    } else if (list == 'invoice') {
        var status = `invoice_${record.status}`;
    } else if (list == 'payment') {
        var status = `payment_${record.status}`;
    } else if (list == 'task') {
        var status = `task_${record.status}`;
    } else if (list == 'user') {
        var status = `user_${record.status}`;
    } else {
        var status = 'pending';
    }
    return record && get(record, source) ? (
        <ChipField
            className={`${classes.root} ${status}`}
            {...{ record, source, ...props }}
        />
    ) : null;
};

BoxitChipField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

BoxitChipField.defaultProps = {
    record: {},
    source: 'status',
};
export default BoxitChipField;
