import moment from 'moment';
import { RequestStatus, RequestStatusColor } from '../constants';

export function getRequestColor(request) {
    const today = moment().format('YYYY-MM-DD');

    if (
        [
            RequestStatus.complete,
            RequestStatus.rejected,
            RequestStatus.cancelled,
        ].indexOf(request.status) !== -1
    ) {
        return RequestStatusColor[request.status];
    } else if (request.date < today) {
        return RequestStatusColor.pending;
    }

    return RequestStatusColor[request.status];
}

export function isTransportRequestLocal(request) {
    if (request) {
        return (
            [
                'newbox',
                'pickup',
                'delivery',
                'survey',
                'moving',
                'shipping',
                'other_services',
            ].indexOf(request.type) !== -1
        );
    }
    return false;
}
