import React from 'react';
import { SelectInput, useGetList } from 'react-admin';
import { Country } from '../types';

const CurrencyInput = props => {
    const { ids, data, total, loaded } = useGetList(
        'country',
        { page: 1, perPage: 10 },
        { field: 'id', order: 'DESC' },
        {}
    );

    const countries: Country[] = loaded ? Object.values(data) : [];

    return (
        <SelectInput
            variant="outlined"
            source="currency"
            label="Currency"
            fullWidth={true}
            style={{ width: '256px' }}
            choices={countries.map(c => ({
                id: c.currency,
                name: c.currency,
            }))}
            {...(props.defaultValue
                ? {
                      defaultValue: props.defaultValue,
                  }
                : {})}
        />
    );
};

export default CurrencyInput;
