import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, ConnectedProps } from 'react-redux';
import compose from 'recompose/compose';
import {
    DateField,
    ChipField,
    EmailField,
    ReferenceField,
    TextField,
} from 'react-admin';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocalPhone from '@material-ui/icons/LocalPhone';
import PhoneIcon from '@material-ui/icons/Phone';
import CountryField from '../../components/CountryField';
import BoxitChipField from '../../components/BoxitChipField';
import { StorageIcon } from '../../storages';
import { RequestIcon } from '../../requests';
import { OrderIcon } from '../../orders';
import { CardIcon } from '../../cards';
import { ChargeIcon } from '../../charges';
import { InvoiceIcon } from '../../invoices';
import { PaymentIcon } from '../../payments';
import { getUserStats as getUserStatsAction } from '../actions/userActions';
import LinkToRelatedItems from './LinkToRelatedItems';
import BillingCard from '../../components/BillingCard';
import ItemStats from '../../components/ItemStats';
import MoneyField from '../../components/MoneyField';
import Address from '../../components/Address';
import {
    Grid,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Checkbox,
    Button,
} from '@material-ui/core';
import { ShowProps } from '../../types';
import UserReferenceField from '../../components/UserReferenceField';
import Tags from '../../components/tags';
import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from 'react-admin';
import { SimpleForm } from 'react-admin';
import feathersClient from '../../rest/feathersClient';
import { useNotify } from 'react-admin';
import classes from './userSummary.module.css';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ShowProps & {
        userStats: object;
    };

const UserSummary = ({
    record,
    basePath,
    permissions = [],
    userStats,
    getUserStats,
    ...props
}: any) => {
    const notify = useNotify();
    const [isGeneric, setIsGeneric] = useState(false);
    const [tagName, setTagName] = useState('');
    const [isNewTag, setIsNewTag] = useState(false);
    const [currentTags, setCurrentTags] = useState([]);

    useEffect(() => {
        if (record) {
            getUserStats(record.id);
            setCurrentTags(record?.tags?.map(item => item.id));
        }
    }, []);

    const addTag = (id, tags) => {
        const tagService = feathersClient.service('tags');
        const userService = feathersClient.service('users');
        console.log(currentTags);
        tagService
            .create({
                name: tagName,
                module: isGeneric ? 'Generic' : 'User',
            })
            .then(res => {
                userService
                    .patch(id, {
                        tags: [...currentTags, res.id],
                    })
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(err => {
                        notify(err.message, 'error');
                    });
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };

    const setTags = tags => {
        const userService = feathersClient.service('users');
        userService
            .patch(record.id, {
                tags,
            })
            .then(res => {
                //window.location.reload();
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };
    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.type && record.type.toUpperCase()}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <EmailField record={record} source="email" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Phone</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.phone && (
                                <>
                                    {record.phone}&nbsp;&nbsp;
                                    <a
                                        href={`https://wa.me/${
                                            record.phone &&
                                            record.phone.replace(
                                                /[^\d]|^0+/g,
                                                ''
                                            )
                                        }`}
                                        target="_blank"
                                    >
                                        <WhatsAppIcon />
                                    </a>
                                    <a
                                        href={`tel:${
                                            record.phone &&
                                            record.phone.replace(
                                                /[^\d]|^0+/g,
                                                ''
                                            )
                                        }`}
                                    >
                                        <LocalPhone />
                                    </a>
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Alternate Phone</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.phone2 && (
                                <>
                                    {record.phone2}&nbsp;&nbsp;
                                    <a
                                        href={`https://wa.me/${
                                            record.phone2 &&
                                            record.phone2.replace(
                                                /[^\d]|^0+/g,
                                                ''
                                            )
                                        }`}
                                        target="_blank"
                                    >
                                        <WhatsAppIcon />
                                    </a>
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tags</TableCell>
                        {/*<TableCell style={{ textAlign: 'right' }}>
                                {record.tags && <Tags tags={record.tags} />}
                            </TableCell>
                                        */}
                        <TableCell>
                            <div className={classes.tags}>
                                <div className={classes.form}>
                                    <SimpleForm
                                        onSubmit={data => {
                                            console.log(data);
                                        }}
                                    >
                                        {record.tags && (
                                            <ReferenceArrayInput
                                                source="tags"
                                                reference="tags"
                                                label=""
                                                defaultValue={record.tags.map(
                                                    item => item.id
                                                )}
                                                parse={val => {
                                                    setCurrentTags(val);
                                                    setTags(val);
                                                    return val;
                                                }}
                                            >
                                                <AutocompleteArrayInput />
                                            </ReferenceArrayInput>
                                        )}
                                    </SimpleForm>
                                </div>
                                <div className={classes.tags_new}>
                                    <div className={classes.tag_new_check}>
                                        <Checkbox
                                            checked={isNewTag}
                                            onChange={() => {
                                                setIsNewTag(prev => !prev);
                                            }}
                                        />
                                        <span>New Tag?</span>
                                    </div>
                                    {isNewTag && (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: '.5rem',
                                                }}
                                            >
                                                <Input
                                                    onChange={e =>
                                                        setTagName(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Tag name"
                                                />
                                                <Button
                                                    style={{
                                                        backgroundColor:
                                                            '#09C2F3',
                                                        width: '8rem',
                                                        height: '3rem',
                                                        color: 'white',
                                                    }}
                                                    disabled={tagName === ''}
                                                    onClick={() => {
                                                        addTag(
                                                            record.id,
                                                            record.tags
                                                        );
                                                    }}
                                                >
                                                    Add new tag
                                                </Button>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    placeholder="Is Generic"
                                                    checked={isGeneric}
                                                    onChange={() => {
                                                        setIsGeneric(
                                                            prev => !prev
                                                        );
                                                    }}
                                                />
                                                <span>Is generic?</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Enable Automatic Card Charging</label>
                        </TableCell>
                        {record && record.enableAutoCardCharging && (
                            <TableCell style={{ textAlign: 'right' }}>
                                Yes
                            </TableCell>
                        )}
                        {record && !record.enableAutoCardCharging && (
                            <TableCell style={{ textAlign: 'right' }}>
                                No
                            </TableCell>
                        )}
                    </TableRow>

                    <TableRow>
                        <TableCell>Address</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.addressId && (
                                <Address id={record.addressId} />
                            )}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>Country</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField basePath={basePath} record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>TRN</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.trn}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BoxitChipField
                                record={record}
                                source="status"
                                list="user"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Deposits</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <MoneyField
                                source="deposits"
                                record={record}
                                showCurrency
                            />
                        </TableCell>
                    </TableRow>
                    {permissions.indexOf('partner') === -1 && (
                        <TableRow>
                            <TableCell>Credits</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <MoneyField
                                    source="credits"
                                    record={record}
                                    showCurrency
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Created At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Assignee</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                record={record}
                                source="assigneeId"
                                label="Assignee"
                            />
                        </TableCell>
                    </TableRow>
                    {record.storageRate && (
                        <TableRow>
                            <TableCell>Storage rate</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <MoneyField
                                    source="storageRate"
                                    record={record}
                                    showCurrency
                                />{' '}
                                <small>/ CBM / month</small>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <br />
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Grid container spacing={3}>
                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <BillingCard
                                billing={userStats && userStats.billing}
                            />
                        </Grid>
                    )}

                    <Grid item xs={6} sm={3}>
                        <LinkToRelatedItems
                            userId={record.id}
                            resource="storages"
                            icon={<StorageIcon />}
                        >
                            {userStats && userStats.storages && (
                                <ItemStats
                                    data={[
                                        ...userStats.storages,
                                        ...((!!userStats.size && [
                                            {
                                                status: 'Volume stored',
                                                count: `${userStats.size} CBM`,
                                            },
                                        ]) ||
                                            []),
                                    ]}
                                />
                            )}
                        </LinkToRelatedItems>
                    </Grid>

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                userId={record.id}
                                resource="requests"
                                icon={<RequestIcon />}
                            >
                                {userStats && userStats.requests && (
                                    <ItemStats data={userStats.requests} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    <Grid item xs={6} sm={3}>
                        <LinkToRelatedItems
                            userId={record.id}
                            resource="orders"
                            icon={<OrderIcon />}
                        >
                            {userStats && userStats.orders && (
                                <ItemStats data={userStats.orders} />
                            )}
                        </LinkToRelatedItems>
                    </Grid>

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                userId={record.id}
                                resource="charges"
                                icon={<ChargeIcon />}
                            >
                                {userStats && userStats.charges && (
                                    <ItemStats
                                        source="category"
                                        unit="currency"
                                        data={userStats.charges}
                                    />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                userId={record.id}
                                resource="invoices"
                                icon={<InvoiceIcon />}
                            >
                                {userStats && userStats.invoices && (
                                    <ItemStats data={userStats.invoices} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                userId={record.id}
                                resource="payments"
                                icon={<PaymentIcon />}
                            >
                                {userStats && userStats.payments && (
                                    <ItemStats data={userStats.payments} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}

                    {permissions.indexOf('partner') === -1 && (
                        <Grid item xs={6} sm={3}>
                            <LinkToRelatedItems
                                userId={record.id}
                                resource="cards"
                                icon={<CardIcon />}
                            >
                                {userStats && userStats.cards && (
                                    <ItemStats data={userStats.cards} />
                                )}
                            </LinkToRelatedItems>
                        </Grid>
                    )}
                </Grid>
            </div>
        </div>
    );
};

const mapState = (state, props) => ({
    userStats:
        props.record &&
        state.admin.resources['user-stats'] &&
        state.admin.resources['user-stats'].data[props.record.id],
});

const mapDispatch = {
    getUserStats: getUserStatsAction,
};

const connector = connect(mapState, mapDispatch);

//const enhance = compose(connector);
//export default enhance(UserSummary);

export default connector(UserSummary);
