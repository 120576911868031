import * as React from 'react';

function SvgUser(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 15.895a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6zm-9 15a5 5 0 015-5h8a5 5 0 015 5v2a1 1 0 01-2 0v-2a3 3 0 00-3-3h-8a3 3 0 00-3 3v2a1 1 0 01-2 0v-2z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgUser;
