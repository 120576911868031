import React from 'react';
import {
    ChipField,
    Datagrid,
    DateField,
    EditButton,
    List,
    TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CountryField from '../components/CountryField';
const useStyles = makeStyles({
    root: {
        padding: '1px 8px',
        color: '#595959',
        background: '#F5F5F5',
        borderRadius: '4px',
    },
});
const OfferList = props => {
    const classes = useStyles();
    return (
        <List title="Offers" {...props}>
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <DateField source="createdAt" label="Created" />
                <TextField source="id" />
                <ChipField className={classes.root} source="type" />
                <TextField source="discount" />
                <TextField source="percent" />
                <TextField source="limit" />
                <TextField source="reward" />
                <CountryField />
                <EditButton />
            </Datagrid>
        </List>
    );
};

OfferList.defaultProps = {
    resource: 'offers',
    location: { search: '' },
};

export default OfferList;
