import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    STORAGE_ADD_PHOTO_SUCCESS,
    STORAGE_ADD_PHOTO_FAILURE,
} from './storageActions';

export default function* storageSaga() {
    yield all([
        takeEvery(STORAGE_ADD_PHOTO_SUCCESS, function* () {
            yield put(showNotification('Upload Success'));
        }),
        takeEvery(STORAGE_ADD_PHOTO_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(error || 'Error in uploading photo', 'warning')
            );
            console.error(error);
        }),
    ]);
}
