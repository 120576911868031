import * as React from 'react';

function SvgUserPlus(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 15a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6zM28 15a1 1 0 011 1v2h2a1 1 0 110 2h-2v2a1 1 0 11-2 0v-2h-2a1 1 0 110-2h2v-2a1 1 0 011-1zM8 27a5 5 0 015-5h7a5 5 0 015 5v2a1 1 0 11-2 0v-2a3 3 0 00-3-3h-7a3 3 0 00-3 3v2a1 1 0 11-2 0v-2z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgUserPlus;
