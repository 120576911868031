import React, { FC } from 'react';
import Analytics from './Analytics';

const FinanceAnalytics: FC = () => {
    return (
        <Analytics
            title={'Finance'}
            needCountry={true}
            items={[
                {
                    title: 'Total Revenue',
                    country: true,
                    data: [
                        {
                            title: 'Total Revenue',
                            key: 'invoice.total_revenue',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'Total Revenue (B2C)',
                            key: 'invoice.total_revenue_b2c',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'Total Revenue (B2B)',
                            key: 'invoice.total_revenue_b2b',
                            currency: 'invoice.currency',
                        },
                    ],
                },
                {
                    title: 'New Revenue',
                    country: true,
                    data: [
                        {
                            title: 'New Revenue',
                            key: 'invoice.new_revenue',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'New Revenue (B2C)',
                            key: 'invoice.new_revenue_b2c',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'New Revenue (B2B)',
                            key: 'invoice.new_revenue_b2b',
                            currency: 'invoice.currency',
                        },
                    ],
                },
                {
                    title: 'Storage',
                    country: true,
                    indent: 1,
                    data: [
                        {
                            title: 'New Storage Revenue',
                            key: 'charge.new_storage_revenue',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'New Storage Revenue (B2C)',
                            key: 'charge.new_storage_revenue_b2c',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'New Storage Revenue (B2B)',
                            key: 'charge.new_storage_revenue_b2b',
                            currency: 'charge.currency',
                        },
                    ],
                },
                {
                    title: 'Service',
                    country: true,
                    indent: 1,
                    data: [
                        {
                            title: 'Total Service Revenue',
                            key: 'charge.total_service_revenue',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Total Service Revenue (B2C)',
                            key: 'charge.total_service_revenue_b2c',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Total Service Revenue (B2B)',
                            key: 'charge.total_service_revenue_b2b',
                            currency: 'charge.currency',
                        },
                    ],
                },
                {
                    title: 'Other',
                    country: true,
                    indent: 1,
                    data: [
                        {
                            title: 'Other Revenue',
                            key: 'charge.custom_revenue',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Other Revenue (B2C)',
                            key: 'charge.custom_revenue_b2c',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Other Revenue (B2B)',
                            key: 'charge.custom_revenue_b2b',
                            currency: 'charge.currency',
                        },
                    ],
                },
                {
                    title: 'Recurring Revenue',
                    country: true,
                    data: [
                        {
                            title: 'Recurring Revenue',
                            key: 'invoice.recurring_revenue',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'Recurring Revenue (B2C)',
                            key: 'invoice.recurring_revenue_b2c',
                            currency: 'invoice.currency',
                        },
                        {
                            title: 'Recurring Revenue (B2B)',
                            key: 'invoice.recurring_revenue_b2b',
                            currency: 'invoice.currency',
                        },
                    ],
                },
                {
                    title: 'Storage',
                    country: true,
                    indent: 1,
                    data: [
                        {
                            title: 'Recurring Storage Revenue',
                            key: 'charge.recurring_storage_revenue',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Recurring Storage Revenue (B2C)',
                            key: 'charge.recurring_storage_revenue_b2c',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Recurring Storage Revenue (B2B)',
                            key: 'charge.recurring_storage_revenue_b2b',
                            currency: 'charge.currency',
                        },
                    ],
                },
                /*
                {
                    title: 'Total Storage Revenue',
                    country: true,
                    data: [
                        {
                            title: 'Total Storage Revenue',
                            key: 'charge.total_storage_revenue',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Total Storage Revenue (B2C)',
                            key: 'charge.total_storage_revenue_b2c',
                            currency: 'charge.currency',
                        },
                        {
                            title: 'Total Storage Revenue (B2B)',
                            key: 'charge.total_storage_revenue_b2b',
                            currency: 'charge.currency',
                        },
                    ],
                },
                */
                {
                    title: 'Collection',
                    country: true,
                    data: [
                        {
                            title: 'Collection',
                            key: 'payment.total_payments',
                            currency: 'payment.currency',
                        },
                        {
                            title: 'Collection (B2C)',
                            key: 'payment.total_payments_b2c',
                            currency: 'payment.currency',
                        },
                        {
                            title: 'Collection (B2B)',
                            key: 'payment.total_payments_b2b',
                            currency: 'payment.currency',
                        },
                    ],
                },
                {
                    title: 'Deposits',
                    country: true,
                    data: [
                        {
                            title: 'Deposits',
                            key: 'payment.total_deposits',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                        {
                            title: 'Deposits (B2C)',
                            key: 'payment.total_deposits_b2c',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                        {
                            title: 'Deposits (B2B)',
                            key: 'payment.total_deposits_b2b',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                    ],
                },
                {
                    title: 'Refunds',
                    country: true,
                    data: [
                        {
                            title: 'Refunds',
                            key: 'payment.total_refunds',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                        {
                            title: 'Refunds (B2C)',
                            key: 'payment.total_refunds_b2c',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                        {
                            title: 'Refunds (B2B)',
                            key: 'payment.total_refunds_b2b',
                            currency: 'payment.currency',
                            reverse: true,
                        },
                    ],
                },
                {
                    title: 'Receivable',
                    country: true,
                    data: [
                        {
                            title: 'Receivable',
                            key: 'invoice.total_receivable',
                            currency: 'invoice.currency',
                            reverse: true,
                        },
                        {
                            title: 'Receivable (B2C)',
                            key: 'invoice.total_receivable_b2c',
                            currency: 'invoice.currency',
                            reverse: true,
                        },
                        {
                            title: 'Receivable (B2B)',
                            key: 'invoice.total_receivable_b2b',
                            currency: 'invoice.currency',
                            reverse: true,
                        },
                    ],
                },
            ]}
        />
    );
};

export default FinanceAnalytics;
