import React from 'react';

function getBgColor(percent: number): string {
    return 'text-gray-500';
    if (percent < -100) return 'bg bg-red-700 text-gray-50';
    if (percent < -80) return 'bg bg-red-600 text-gray-50';
    if (percent < -60) return 'bg bg-red-500 text-gray-50';
    if (percent < -50) return 'bg bg-red-400 text-gray-50';
    if (percent < -40) return 'bg bg-red-300 text-gray-50';
    if (percent < -30) return 'bg bg-red-200 text-gray-50';
    if (percent < -20) return 'bg bg-red-100 text-gray-50';
    if (percent < -10) return 'bg bg-red-50 text-gray-50';
    if (percent < -10) return 'bg bg-red-50 text-gray-50';
    if (percent > 100) return 'bg bg-green-700 text-gray-50';
    if (percent > 80) return 'bg bg-green-600 text-gray-50';
    if (percent > 60) return 'bg bg-green-500 text-gray-50';
    if (percent > 50) return 'bg bg-green-400 text-gray-50';
    if (percent > 40) return 'bg bg-green-300 text-gray-50';
    if (percent > 30) return 'bg bg-green-200 text-gray-50';
    if (percent > 20) return 'bg bg-green-100 text-gray-50';
    if (percent > 10) return 'bg bg-green-50 text-gray-50';
    return '';
}

export default ({
    title,
    current,
    previous,
    percent = 0,
    selected = false,
    reverse = false,
    onClick = () => {},
    ...props
}) => {
    const isPositive = isFinite(percent) && percent > 0;
    const isGreen = reverse ? !isPositive : isPositive;
    const arrowColor = isGreen ? 'text-green-500' : 'text-red-500';
    const pillColor = isGreen
        ? 'bg-green-100 text-green-800'
        : 'bg-red-100 text-red-800';

    return (
        <td
            className={`border border-gray-200 px-6 py-4 whitespace-nowrap ${getBgColor(
                percent
            )} ${
                selected ? 'bg bg-indigo-500' : ''
            } text-sm hover:bg-indigo-300 cursor-pointer`}
            onClick={onClick}
        >
            <div className="flex justify-between">
                {isFinite(percent) ? (
                    <div
                        className={`inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 ${pillColor}`}
                    >
                        {percent > 0 ? (
                            <svg
                                className={`-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ${arrowColor}`}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                className={`-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 ${arrowColor}`}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                        {Math.abs(percent)}%
                    </div>
                ) : (
                    <span></span>
                )}
                <span className={`${selected ? 'text-white' : ''}`}>
                    {current}
                </span>
            </div>
        </td>
    );
};
