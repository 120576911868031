import React, { useEffect } from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';
import { ReferenceField } from 'react-admin';

export const PaymentCommunicationsList = props => {
    useEffect(() => {
        localStorage.setItem(
            'currentComMod',
            `/payments/${props.record.id}/show/2`
        );
    }, []);
    return (
        <ReferenceManyField
            reference="Communication-records"
            target="resourceId"
            filter={{ resourceId: props.record.id, resourceType: 'payment' }}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" label="Communication Id" />
                <TextField source="taskStatus" label="Task Status" />
                <TextField source="taskType" label="Task Type" />
                <TextField source="templateName" label="Template Name" />
                <TextField source="senderEmail" label="Sender Email" />
                <TextField source="recipientEmail" label="Recipient Email" />
                <TextField source="channel" label="Channel" />
                <TextField source="deliveryStatus" label="Delivery Status" />
                <ShowButton />
            </Datagrid>
        </ReferenceManyField>
    );
};
