import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Form } from 'react-final-form';
import {
    crudCreate,
    DateInput,
    ReferenceInput,
    SelectInput,
    useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionAdd from '@material-ui/icons/PlaylistAdd';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import { RequestType, PickupTimeFrom, PickupTimeTo } from '../../constants';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@material-ui/core';

const sanitizeRestProps: (any) => any = ({
    basePath,
    classes,
    crudCreate,
    filterValues,
    label,
    resource,
    selectedIds,
    cityId,
    countryId,
    userId,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    input: {
        display: 'block',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    menu: {
        width: 200,
    },
    actionButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}));

type Styles = {
    input: string;
    iconPaddingStyle: string;
    confirmPrimary: string;
    confirmWarning: string;
    actionButton: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    classes: Styles;
    cancel: string;
    label: string;
    resource: string;
    selectedIds: any[];
    icon: object;
    confirm: string;
    confirmColor: string;
    translate: (a: string, b: object) => string;
    type: string;
    date: string;
    from: number;
    to: number;
    cityId: string;
    countryId: number;
    userId: number;
};

type State = {
    isOpen: boolean;
    loading: boolean;
};

class CreateRequest extends Component<Props, State> {
    state = {
        isOpen: false,
        loading: false,
    };

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    createRequest = values => {
        const { crudCreate, selectedIds, countryId, userId } = this.props;
        const { type, date, from, to, cityId, partnerId, orderId } = values;

        if (
            partnerId &&
            orderId &&
            type &&
            date &&
            from &&
            to &&
            cityId &&
            countryId &&
            userId &&
            selectedIds &&
            selectedIds.length
        ) {
            const data = {
                partnerId,
                orderId,
                type,
                date,
                from,
                to,
                cityId,
                countryId,
                userId,
                request_lines: selectedIds.map(id => ({ storageId: id })),
            };

            this.setState({ loading: true });
            crudCreate('requests', data, '/requests', 'show');
        }
    };

    render() {
        const {
            classes,
            translate,
            label = 'Create Request',
            cancel = 'ra.action.cancel',
            confirm = 'ra.action.confirm',
            confirmColor = 'primary',
            icon = <ActionAdd />,
            resource,
            selectedIds,
            countryId,
            userId,
            ...rest
        } = this.props;
        const { isOpen, loading } = this.state;

        return userId && countryId ? (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    className={classes.actionButton}
                    {...sanitizeRestProps(rest)}
                >
                    {icon}
                    {translate(label, { _: label })}
                </Button>
                <Dialog open={isOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Create Request</DialogTitle>
                    <DialogContent>
                        <Form
                            onSubmit={this.createRequest}
                            render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                values,
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <ReferenceInput
                                        label="Partner"
                                        source="partnerId"
                                        reference="partners"
                                        resource="partners"
                                        className={classes.input}
                                        fullWidth
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>
                                    <ReferenceInput
                                        label="Order"
                                        source="orderId"
                                        reference="orders"
                                        resource="orders"
                                        filter={{ userId }}
                                        className={classes.input}
                                        fullWidth
                                    >
                                        <SelectInput
                                            source="orderId"
                                            optionValue="id"
                                            optionText={record =>
                                                `Order #${record.id}`
                                            }
                                        />
                                    </ReferenceInput>
                                    <SelectInput
                                        source="type"
                                        className={classes.input}
                                        fullWidth
                                        choices={Object.keys(RequestType).map(
                                            c => ({
                                                id: c,
                                                name: c,
                                            })
                                        )}
                                    />
                                    <DateInput
                                        source="date"
                                        className={classes.input}
                                        parse={v =>
                                            v && moment(v).format('YYYY-MM-DD')
                                        }
                                        fullWidth
                                    />
                                    <SelectInput
                                        source="from"
                                        className={classes.input}
                                        translateChoice={false}
                                        choices={PickupTimeFrom.map(c => ({
                                            id: c,
                                            name: c,
                                        }))}
                                        fullWidth
                                    />
                                    <SelectInput
                                        source="to"
                                        className={classes.input}
                                        translateChoice={false}
                                        choices={PickupTimeTo.map(c => ({
                                            id: c,
                                            name: c,
                                        }))}
                                        fullWidth
                                    />
                                    <ReferenceInput
                                        label="City"
                                        source="cityId"
                                        reference="city"
                                        resource="city"
                                        className={classes.input}
                                        fullWidth
                                    >
                                        <SelectInput optionText="name" />
                                    </ReferenceInput>

                                    <DialogActions>
                                        <Button
                                            disabled={loading}
                                            onClick={this.handleDialogClose}
                                        >
                                            <AlertError
                                                className={
                                                    classes.iconPaddingStyle
                                                }
                                            />
                                            {translate(cancel, { _: cancel })}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={submitting}
                                            className={classnames(
                                                'ra-confirm',
                                                {
                                                    [classes.confirmWarning]:
                                                        confirmColor ===
                                                        'warning',
                                                    [classes.confirmPrimary]:
                                                        confirmColor ===
                                                        'primary',
                                                }
                                            )}
                                            autoFocus
                                        >
                                            <ActionCheck
                                                className={
                                                    classes.iconPaddingStyle
                                                }
                                            />
                                            {translate(confirm, { _: confirm })}
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        />
                    </DialogContent>
                </Dialog>
            </Fragment>
        ) : null;
    }
}

const mapState = (state, props) => {
    const storages = Object.values(
        _.pick(state.admin.resources.storages.data, props.selectedIds)
    );
    const userId =
        storages &&
        storages.length &&
        _.every(storages, ['userId', storages[0].userId])
            ? storages[0].userId
            : null;
    const countryId =
        storages &&
        storages.length &&
        _.every(storages, ['countryId', storages[0].countryId])
            ? storages[0].countryId
            : null;

    return {
        countryId,
        userId,
    };
};

const mapDispatch = {
    crudCreate,
};

const connector = connect(mapState, mapDispatch);

const ConnectedCreateRequest = connector(CreateRequest);

export default props => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <ConnectedCreateRequest
            classes={classes}
            translate={translate}
            {...props}
        />
    );
};
