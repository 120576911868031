import React, { useEffect, useState } from 'react';
import feathersClient from '../rest/feathersClient';
import PhotoBox from './photobox';
import classes from './NewPhotoGallery.module.css';
import { Button } from '@material-ui/core';
import { useRefresh } from 'react-admin';
import ReactPlayer from 'react-player';
import { RequestStatus } from '../constants';

const NewPhotoGallery = ({ record }: any) => {
    const refresh = useRefresh();
    const [photos, setPhotos] = useState<any[]>([]);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number | null>(
        null
    );
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
    const [deleteItemMimetype, setDeleteItemMimetype] = useState<string | null>(
        null
    );

    const getPhotos = () => {
        const photosService = feathersClient.service('photos');
        photosService
            .find({
                query: { requestId: record.id, video: true },
            })
            .then(res => {
                setPhotos(res.data);
            })
            .catch(err => {});
    };

    const deletePhoto = (id: string, type: string) => {
        const photosService = feathersClient.service('photos');
        const commentService = feathersClient.service('comments');
        photosService
            .remove(id, {})
            .then(res => {
                commentService
                    .create({
                        comment: `Deleted a ${
                            type === 'video' ? 'video' : 'photo'
                        }`,
                        documentId: record.id,
                        model: 'request',
                        userId: record.userId,
                    })
                    .then(() => {
                        setIsDeleteOpen(false);
                        setDeleteItemId(null);
                        setDeleteItemMimetype(null);
                        refresh();
                    });
            })
            .catch(err => {});
    };

    useEffect(() => {
        getPhotos();
    }, [record]);

    return (
        <div className={classes.gallery}>
            {photos.map((item, index) => {
                return (
                    <div className={classes.photo}>
                        {item.mimetype === 'video' ? (
                            <div onClick={() => setCurrentPhotoIndex(index)}>
                                <div
                                    style={{
                                        zIndex: 1000,
                                        width: '100%',
                                        height: '192px',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                    }}
                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '0',
                                            right: '0',
                                            backgroundColor: 'green',
                                            color: 'white',
                                            padding: '.2rem .5rem',
                                        }}
                                    >
                                        Video
                                    </div>
                                </div>
                                <ReactPlayer
                                    url={item.photo}
                                    controls={false}
                                    width={'192px'}
                                    height={'192px'}
                                />
                            </div>
                        ) : (
                            <img
                                src={item.photo}
                                onClick={() => setCurrentPhotoIndex(index)}
                            />
                        )}
                        {record.status !== RequestStatus.complete && (
                            <button
                                style={{ zIndex: 2000 }}
                                onClick={e => {
                                    e.stopPropagation();
                                    //deletePhoto(item.id, item.mimetype);
                                    setDeleteItemId(item.id);
                                    setDeleteItemMimetype(item.mimetype);
                                    setIsDeleteOpen(true);
                                }}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                );
            })}
            {currentPhotoIndex !== null && (
                <PhotoBox
                    photos={photos}
                    initialPhotoIndex={currentPhotoIndex}
                    cancel={() => setCurrentPhotoIndex(null)}
                />
            )}
            {isDeleteOpen ? (
                <div
                    style={{
                        backgroundColor: 'rgba(0,0,0,.5)',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100vw',
                        height: '100vh',
                        zIndex: 100000,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40rem',
                            padding: '1rem',
                            gap: '1rem',
                        }}
                    >
                        <p style={{ textAlign: 'center' }}>
                            Are you sure you want to delete the selected
                            photo(s)/video(s) from this request? This action
                            cannot be undone.
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem',
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: '#ff4f6e',
                                    padding: '.25rem 1rem',
                                    color: 'white',
                                    borderRadius: '3px',
                                }}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    if (deleteItemId && deleteItemMimetype) {
                                        deletePhoto(
                                            deleteItemId as string,
                                            deleteItemMimetype
                                        );
                                    }
                                }}
                            >
                                Delete
                            </button>
                            <button
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    setIsDeleteOpen(false);
                                    setDeleteItemId(null);
                                    setDeleteItemMimetype(null);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default NewPhotoGallery;
