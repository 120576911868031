import React from 'react';
import { Filter } from 'react-admin';
import { SelectInput } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { ShowButton } from 'react-admin';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';

const EmailPlaceholderFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>
        <BooleanInput label="Is generic?" source="isGeneric" />
    </Filter>
);

const emailPlaceholderList = props => {
    return (
        <List
            title="Email placeholders"
            bulkActionButtons={false}
            filters={<EmailPlaceholderFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="name" label="Name" />
                <FunctionField
                    label="Emails"
                    render={record => {
                        if (record.emails && record.emails.length) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.emails.map(email => (
                                        <span>{email}</span>
                                    ))}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <FunctionField
                    label="Country"
                    render={record => {
                        return record.countryId === 1
                            ? 'UAE'
                            : record.countryId === 2
                            ? 'Kuwait'
                            : 'KSA';
                    }}
                />
                <TextField source="isGeneric" label="Is generic" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

emailPlaceholderList.defaultProps = {
    resource: 'email-placeholder',
    location: { search: '' },
};

export default emailPlaceholderList;
