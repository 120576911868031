import React from 'react';
import { useNotify, useUpdate } from 'react-admin';
import SweetAlert from 'sweetalert-react';
import { OrderStatus } from '../../constants';
import { Order } from '../../types';

type Props = {
    record: Order;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
};

const RestoreButton: React.FC<Props> = ({ record, isOpen, setIsOpen }) => {
    const notify = useNotify();

    const [restoreOrder, { loading }] = useUpdate(
        'orders',
        record.id,
        { status: OrderStatus.new },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('resources.orders.notification.restore_success', 'info');
                window.location.reload();
            },
            onFailure: () => {
                notify(
                    'resources.orders.notification.restore_error',
                    'warning'
                );
            },
        }
    );

    return (
        <SweetAlert
            show={isOpen}
            title="Are you sure?"
            text="Are you sure you want to restore this lead to new?"
            showCancelButton
            enableButtons={!loading}
            onCancel={() => setIsOpen(false)}
            onEscapeKey={() => setIsOpen(false)}
            onOutsideClick={() => setIsOpen(false)}
            onConfirm={() => {
                setIsOpen(false);
                restoreOrder();
            }}
        />
    );
};

export default RestoreButton;
