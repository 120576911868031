import * as React from 'react';

function SvgXOctagon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.153 9.293A1 1 0 0115.86 9h8.28a1 1 0 01.707.293l5.86 5.86a1 1 0 01.293.707v8.28a1 1 0 01-.293.707l-5.86 5.86a1 1 0 01-.707.293h-8.28a1 1 0 01-.707-.293l-5.86-5.86A1 1 0 019 24.14v-8.28a1 1 0 01.293-.707l5.86-5.86zM16.274 11L11 16.274v7.452L16.274 29h7.452L29 23.726v-7.452L23.726 11h-7.452z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.707 16.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.293 16.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgXOctagon;
