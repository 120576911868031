import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { History } from '../../revisions';
import ChargeSummary from './ChargeSummary';

export const ChargeTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <ChargeSummary />
            </Tab>
            <Tab label="History">
                <History {...props} model="charge" documentId={record.id} />
            </Tab>
        </TabbedShowLayout>
    );
};

ChargeTabs.propTypes = {
    record: PropTypes.object,
};

ChargeTabs.defaultProps = {
    record: {},
};
