import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import { DateField, ChipField } from 'react-admin';

import Money from '../../components/Money';
import BoxitChipField from '../../components/BoxitChipField';
import UserReferenceField from '../../components/UserReferenceField';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Paper,
} from '@material-ui/core';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

export const CardSummary = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Card Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Created At</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Customer</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Mode</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.mode}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Card Holder Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.cardHolderName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Card Number</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.cardNumber}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Expiry</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.expiryDate}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {/* <ChipField record={record} source="status" /> */}
                            <BoxitChipField
                                record={record}
                                source="status"
                                list="user"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow />
                </TableBody>
            </Table>
            {record.data && record.data && (
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <strong>
                                    {capitalize(record.mode)} Response
                                </strong>
                            </TableCell>
                        </TableRow>
                        {Object.keys(record.data.response || record.data).map(
                            k => (
                                <TableRow key={k}>
                                    <TableCell>
                                        <label>{k}</label>
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        {typeof (record.data.response ||
                                            record.data)[k] !== 'object'
                                            ? (record.data.response ||
                                                  record.data)[k]
                                            : "Can't View Object"}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

CardSummary.propTypes = {
    record: PropTypes.object,
};
