import React from 'react';
import BoxitSalesCalendar from '../components/BoxitSalesCalendar';
import { getOrderColor } from '../orders/utils';
import { Order } from '../types';

function getOrderStyle(
    order: Order,
    start: Date,
    end: Date,
    isSelected: boolean
) {
    const backgroundColor = getOrderColor(order);

    const style = {
        backgroundColor,
    };

    return { style };
}

const SalesCalendar = props => {
    return <BoxitSalesCalendar eventPropGetter={getOrderStyle} />;
};

export default SalesCalendar;
