import * as React from 'react';

function SvgTrello(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 12a1 1 0 00-1 1v14a1 1 0 001 1h14a1 1 0 001-1V13a1 1 0 00-1-1H13zm-3 1a3 3 0 013-3h14a3 3 0 013 3v14a3 3 0 01-3 3H13a3 3 0 01-3-3V13z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 15a1 1 0 011-1h3a1 1 0 011 1v9a1 1 0 01-1 1h-3a1 1 0 01-1-1v-9zm2 1v7h1v-7h-1zM21 15a1 1 0 011-1h3a1 1 0 011 1v5a1 1 0 01-1 1h-3a1 1 0 01-1-1v-5zm2 1v3h1v-3h-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgTrello;
