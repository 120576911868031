import React, { useState } from 'react';
import {
    required,
    useNotify,
    BooleanInput,
    DateInput,
    Edit,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    FormDataConsumer,
} from 'react-admin';
import { UserType, CustomerPersona } from '../constants';
import MoneyInput from '../components/MoneyInput';
import PartnerInput from '../components/PartnerInput';
import CountryInput from '../components/CountryInput';
import CurrencyInput from '../components/CurrencyInput';
import { EditProps, TitleProps, User } from '../types';
import { handleUserError } from './error';
import { AutocompleteInput } from 'react-admin';
import { ReferenceArrayInput } from 'react-admin';
import { AutocompleteArrayInput } from 'react-admin';
import feathersClient from '../rest/feathersClient';
import { Button, Checkbox, Input } from '@material-ui/core';
import { FunctionField } from 'react-admin';
const isRequired = required();

const optionText = user => `${user.name} (${user.id})`;

const UserTitle: React.FC<TitleProps<User>> = ({ record }) => (
    <span>Edit {record ? `"${record.name}"` : ''}</span>
);

const idTypeChoices = [
    { id: 'Passport', name: 'Passport' },
    { id: 'EmiratesID', name: 'Emirates ID' },
    { id: 'CivilID', name: 'Civil ID' },
];

const UserEdit: React.FC<EditProps<User>> = ({
    permissions = [],
    ...props
}) => {
    const notify = useNotify();

    const transform = data => {
        if (data.tags) {
            delete data.tags;
        }
        return data;
    };

    return (
        <Edit
            title={<UserTitle />}
            undoable={false}
            onFailure={error => handleUserError(error, notify)}
            successMessage="resources.users.notification.update_success"
            transform={transform}
            {...props}
        >
            <TabbedForm variant="outlined" redirect="show">
                <FormTab label="User">
                    {permissions.indexOf('staff') !== -1 && (
                        <ReferenceInput
                            label="Partner"
                            source="partnerId"
                            reference="partners"
                            resource="partners"
                        >
                            <SelectInput
                                optionText="name"
                                translateChoice={false}
                            />
                        </ReferenceInput>
                    )}
                    <TextInput disabled source="id" />
                    {permissions.indexOf('staff') !== -1 && <PartnerInput />}
                    <CountryInput />
                    <CurrencyInput />
                    <SelectInput
                        source="type"
                        label="Customer Type"
                        choices={Object.keys(UserType).map(c => ({
                            id: c,
                            name: c.toUpperCase(),
                        }))}
                    />

                    <SelectInput
                        source="persona"
                        label="Customer Persona"
                        choices={Object.keys(CustomerPersona).map(c => ({
                            id: c,
                            name: CustomerPersona[c],
                        }))}
                    />
                    <TextInput source="name" />
                    <TextInput source="email" type="email" />
                    <TextInput source="phone" />
                    <TextInput source="phone2" />
                    <BooleanInput
                        source="enableAutoCardCharging"
                        label="Enable Automatic Card Charging"
                    />
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.id && (
                                <ReferenceInput
                                    label="Primary Address"
                                    source="addressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.id }}
                                    allowEmpty
                                >
                                    <SelectInput
                                        source="addressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>
                    {permissions.indexOf('partner') === -1 && (
                        <MoneyInput source="credits" />
                    )}
                    {permissions.indexOf('partner') === -1 && (
                        <MoneyInput source="deposits" />
                    )}
                    <TextInput source="trn" label="TRN" />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type == 'sme' && (
                                <MoneyInput
                                    source="storageRate"
                                    label="storage rate"
                                />
                            )
                        }
                    </FormDataConsumer>
                    <ReferenceInput
                        label="Assignee"
                        source="assigneeId"
                        reference="users"
                        resource="users"
                    >
                        <AutocompleteInput
                            shouldRenderSuggestions={val =>
                                val.trim().length > 1
                            }
                            optionText={optionText}
                        />
                    </ReferenceInput>
                    {/*<div>
                        <Checkbox
                            checked={isNewTag}
                            onChange={() => {
                                setIsNewTag(prev => !prev);
                            }}
                        />
                        <span>New Tag?</span>
                    </div>

                    {!isNewTag ? (
                        <ReferenceArrayInput
                            source="tags"
                            reference="tags"
                            label="tags"
                        >
                            <AutocompleteArrayInput />
                        </ReferenceArrayInput>
                    ) : (
                        <FunctionField
                            label="Add new Tag"
                            render={record => (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '.5rem',
                                        }}
                                    >
                                        <Input
                                            onChange={e =>
                                                setTagName(e.target.value)
                                            }
                                            placeholder="Tag name"
                                        />
                                        <Button
                                            style={{
                                                backgroundColor: '#09C2F3',
                                                width: '8rem',
                                                height: '3rem',
                                                color: 'white',
                                            }}
                                            disabled={tagName === ''}
                                            onClick={() => {
                                                addTag(record.id, record.tags);
                                            }}
                                        >
                                            Add new tag
                                        </Button>
                                    </div>
                                    <div>
                                        <Checkbox
                                            placeholder="Is Generic"
                                            checked={isGeneric}
                                            onChange={() => {
                                                setIsGeneric(prev => !prev);
                                            }}
                                        />
                                        <span>Is generic?</span>
                                    </div>
                                </div>
                            )}
                        />
                                        )}*/}
                </FormTab>

                <FormTab label="Documents">
                    <SelectInput
                        source="id1Type"
                        label="ID Type"
                        {...props}
                        choices={idTypeChoices}
                    />
                    <TextInput source="id1Number" label="ID Number" />
                    <DateInput source="id1Expiry" label="ID Expiry Date" />
                    <SelectInput
                        source="id2Type"
                        label="ID Type"
                        {...props}
                        choices={idTypeChoices}
                    />
                    <TextInput source="id2Number" label="ID Number" />
                    <DateInput source="id2Expiry" label="ID Expiry Date" />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default UserEdit;
