const config = {
    development: {
        apiUrl: 'http://localhost:3030',
    },

    staging: {
        apiUrl: 'https://api.staging.boxitstorage.com',
    },

    production: {
        apiUrl: 'https://api.boxitstorage.com',
    },
};

console.log('settings: ' + process.env.REACT_APP_ENV);
export default config[process.env.REACT_APP_ENV || 'development'];
