import React from 'react';
import {
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

const LocationEdit = props => (
    <Edit title="Edit Location" undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="id" />
            <ReferenceInput
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                resource="warehouses"
                allowEmpty
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput disabled source="path" />
            <TextInput source="area" />
            <TextInput source="aisle" />
            <TextInput source="x" />
            <TextInput source="y" />
            <TextInput source="z" />
        </SimpleForm>
    </Edit>
);

export default LocationEdit;
