import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';

import { Card, CardHeader, CardContent } from '@material-ui/core';

const styles = {
    card: { borderLeft: 'solid 4px #4caf50', flex: 1 },
    icon: {
        float: 'right',
        width: 64,
        height: 64,
        padding: 16,
        color: '#4caf50',
    },
};

const UsageGraph = props => {
    const data = props.data;

    return (
        <Card style={styles.card}>
            <CardHeader title="Usage" />
            <CardContent>
                <LineChart
                    width={1200}
                    height={400}
                    data={data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={tick => {
                            return moment(tick).format('D');
                        }}
                    />
                    <YAxis type="number" scale="auto" />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="volume"
                        stroke="#82ca9d"
                        name="CBM"
                    />
                </LineChart>
            </CardContent>
        </Card>
    );
};

UsageGraph.propTypes = {
    data: PropTypes.array,
};

UsageGraph.defaultProps = {
    data: [],
};

export default UsageGraph;
