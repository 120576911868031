import React from 'react';
import {
    AutocompleteInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { ChargeType } from '../constants';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';
import { EditProps, Charge, ValidationErrors } from '../types';

const validate = values => {
    const errors: ValidationErrors = {};

    if (!values.countryId) {
        errors.countryId = ['Country is required'];
    }

    if (!values.userId) {
        errors.userId = ['User is required'];
    }

    if (!values.type) {
        errors.type = ['Type is required'];
    }

    if (
        values.type !== 'discount' &&
        values.type !== 'custom' &&
        !values.orderId
    ) {
        errors.orderId = ['Order is required'];
    }

    if (!values.currency) {
        errors.currency = ['Currency is required'];
    }

    if (isNaN(values.amount)) {
        errors.amount = ['Amount is required'];
    } else if (values.amount <= 0) {
        errors.amount = ['Amount must be a positive number'];
    }

    if (!values.year) {
        errors.year = ['Year is required'];
    }

    if (!values.month) {
        errors.month = ['Month is required'];
    }

    if (!values.startDate) {
        errors.startDate = ['Start Date is required'];
    }

    if (!values.endDate) {
        errors.endDate = ['End Date is required'];
    }

    if (!values.requestId) {
        errors.requestId = ['Request is required'];
    }

    if (['custom', 'discount', 'credit'].indexOf(values.type) !== -1) {
        if (!values.description) {
            errors.description = [`Description is required`];
        }
    }

    return errors;
};

const ChargeEdit: React.FC<EditProps<Charge>> = ({
    permissions = [],
    ...props
}) => (
    <Edit undoable={false} {...props}>
        <SimpleForm variant="outlined" validate={validate} redirect="show">
            {permissions.indexOf('staff') !== -1 && (
                <ReferenceInput
                    label="Partner"
                    source="partnerId"
                    reference="partners"
                    resource="partners"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            )}
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="User"
                source="userId"
                reference="users"
                resource="users"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => `${user.name}   (${user.id})`}
                />
            </ReferenceInput>

            <SelectInput
                source="type"
                choices={Object.keys(ChargeType).map(c => ({ id: c, name: c }))}
            />

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    !!formData.userId && (
                        <ReferenceInput
                            label="Order"
                            source="orderId"
                            reference="orders"
                            resource="orders"
                            filter={{ userId: formData.userId }}
                            allowEmpty
                        >
                            <SelectInput
                                source="orderId"
                                optionValue="id"
                                optionText={record => `Order #${record.id}`}
                            />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    !!formData.userId && (
                        <ReferenceInput
                            label="Invoice"
                            source="invoiceId"
                            reference="invoices"
                            resource="invoices"
                            filter={{ userId: formData.userId }}
                            allowEmpty
                        >
                            <SelectInput
                                source="invoiceId"
                                optionValue="id"
                                optionText={record => `Invoice #${record.id}`}
                            />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    !!formData.userId && (
                        <ReferenceInput
                            label="Request"
                            source="requestId"
                            reference="requests"
                            resource="requests"
                            filter={{ userId: formData.userId }}
                            allowEmpty
                        >
                            <SelectInput
                                source="requestId"
                                optionText={r =>
                                    `#${r.id} - ${r.type} on ${r.date}`
                                }
                            />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>

            <CurrencyInput />

            <MoneyInput source="amount" />

            <SelectInput
                source="year"
                choices={[
                    2015,
                    2016,
                    2017,
                    2018,
                    2019,
                    2020,
                    2021,
                    2022,
                    2023,
                    2024,
                    2025,
                ].map(a => ({
                    id: a,
                    name: a,
                }))}
                translateChoice={false}
            />

            <SelectInput
                source="month"
                choices={Array.from(Array(12).keys()).map(a => ({
                    id: a + 1,
                    name: a + 1,
                }))}
                translateChoice={false}
            />

            <SelectInput
                source="startDate"
                label="Start Date"
                choices={Array.from(Array(31).keys()).map(a => ({
                    id: a + 1,
                    name: a + 1,
                }))}
                translateChoice={false}
            />

            <SelectInput
                source="endDate"
                label="End Date"
                choices={Array.from(Array(31).keys()).map(a => ({
                    id: a + 1,
                    name: a + 1,
                }))}
                translateChoice={false}
            />

            <FormDataConsumer>
                {({ formData, ...rest }) =>
                    ['custom', 'discount', 'credit'].indexOf(formData.type) !==
                        -1 && (
                        <TextInput source="description" label="Description" />
                    )
                }
            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export default ChargeEdit;
