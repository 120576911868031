import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import { getWarehouse as getWarehouseAction } from './actions/warehouseActions';
import { getLocations as getLocationsAction } from '../locations/actions/locationActions';
import WarehouseScene from './WarehouseScene';
import { AppState, Warehouse, Location } from '../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    warehouse: Warehouse;
    locations: Location[];
    match: RouteComponentProps;
    getWarehouse: (arg0: string) => void;
    getLocations: (arg0: string) => void;
};

class WarehouseView extends React.Component<Props> {
    componentDidMount() {
        const { match, getWarehouse, getLocations } = this.props;

        const warehouseId = match && match.params && match.params.warehouseId;

        if (warehouseId) {
            getWarehouse(warehouseId);
            getLocations(warehouseId);
        }
    }

    render() {
        const { warehouse, locations } = this.props;

        return warehouse &&
            locations &&
            locations.length === Number(warehouse.locationCount) ? (
            <WarehouseScene warehouse={warehouse} locations={locations} />
        ) : null;
    }
}

const mapStateToProps = (state: AppState, props) => {
    const warehouseId =
        props.match && props.match.params && props.match.params.warehouseId;

    return {
        warehouse:
            state.admin.resources.warehouses &&
            state.admin.resources.warehouses.data &&
            state.admin.resources.warehouses.data[warehouseId],
        locations:
            state.admin.resources.locations &&
            Object.values(
                _.pickBy(
                    state.admin.resources.locations.data,
                    i => i.warehouseId === warehouseId
                )
            ),
    };
};

const mapDispatchToProps = {
    getWarehouse: getWarehouseAction,
    getLocations: getLocationsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(WarehouseView);
