import { all, put, takeEvery } from 'redux-saga/effects';
import { closeDialog } from 'redux-dialog';
import { showNotification } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    REQUEST_CONFIRM_SUCCESS,
    REQUEST_CONFIRM_FAILURE,
    REQUEST_ONHOLD_SUCCESS,
    REQUEST_ONHOLD_FAILURE,
    REQUEST_APPROVE_SUCCESS,
    REQUEST_APPROVE_FAILURE,
    REQUEST_CANCEL_SUCCESS,
    REQUEST_CANCEL_FAILURE,
    REQUEST_PICKUP_SUCCESS,
    REQUEST_PICKUP_FAILURE,
    REQUEST_DELIVER_SUCCESS,
    REQUEST_DELIVER_FAILURE,
    REQUEST_RECEIVE_SUCCESS,
    REQUEST_RECEIVE_FAILURE,
    REQUEST_DISPATCH_SUCCESS,
    REQUEST_DISPATCH_FAILURE,
    REQUEST_COMPLETE_SUCCESS,
    REQUEST_COMPLETE_FAILURE,
    REQUEST_NOTIFY_CUSTOMER_SUCCESS,
    REQUEST_NOTIFY_CUSTOMER_FAILURE,
    REQUEST_NOTIFY_TEAM_SUCCESS,
    REQUEST_NOTIFY_TEAM_FAILURE,
    REQUEST_NOTIFY_WAREHOUSE_SUCCESS,
    REQUEST_NOTIFY_WAREHOUSE_FAILURE,
    REQUEST_ADD_PHOTO_FAILURE,
    REQUEST_ADD_PHOTO_SUCCESS,
    USER_REQUEST_ASSIGN_SUCCESS,
} from './requestActions';

export default function* requestSaga() {
    yield all([
        takeEvery(REQUEST_CONFIRM_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.confirmed_success'
                )
            );
        }),
        takeEvery(REQUEST_CONFIRM_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.confirmed_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_ONHOLD_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.onhold_success'
                )
            );
        }),
        takeEvery(REQUEST_ONHOLD_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.onhold_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_APPROVE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.approved_success'
                )
            );
        }),
        takeEvery(REQUEST_APPROVE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.approved_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_CANCEL_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.cancelled_success'
                )
            );
        }),
        takeEvery(REQUEST_CANCEL_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.cancelled_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_PICKUP_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.pickedup_success'
                )
            );
        }),
        takeEvery(REQUEST_PICKUP_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.pickedup_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_DELIVER_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.delivered_success'
                )
            );
        }),
        takeEvery(REQUEST_DELIVER_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.delivered_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_RECEIVE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.received_success'
                )
            );
        }),
        takeEvery(REQUEST_RECEIVE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.received_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_DISPATCH_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.dispatched_success'
                )
            );
        }),
        takeEvery(REQUEST_DISPATCH_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.dispatched_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_COMPLETE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.completed_success'
                )
            );
        }),
        takeEvery(REQUEST_COMPLETE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.requests.notification.completed_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_NOTIFY_CUSTOMER_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.notify_customer_success'
                )
            );
        }),
        takeEvery(REQUEST_NOTIFY_CUSTOMER_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error ||
                        'resources.requests.notification.notify_customer_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_NOTIFY_TEAM_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.notify_team_success'
                )
            );
        }),
        takeEvery(REQUEST_NOTIFY_TEAM_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error ||
                        'resources.requests.notification.notify_team_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_NOTIFY_WAREHOUSE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.requests.notification.notify_warehouse_success'
                )
            );
        }),
        takeEvery(REQUEST_NOTIFY_WAREHOUSE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error ||
                        'resources.requests.notification.notify_warehouse_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(REQUEST_ADD_PHOTO_SUCCESS, function* () {
            yield put(showNotification('Upload Success'));
            window.location.reload();
        }),
        takeEvery(REQUEST_ADD_PHOTO_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(error || 'Error in uploading photo', 'warning')
            );
            console.error(error);
        }),
        takeEvery(USER_REQUEST_ASSIGN_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.users.notification.rejected_success'
                )
            );
        }),
    ]);
}
