import React from 'react';

import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
} from 'react-admin';
import { typesOptions } from './consts';
import DropdownSearchInput from './components/dropdownSearchInput';
import { required } from 'react-admin';

const TaskCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined" redirect="list">
            <DateInput source="date" />
            {/*<TextInput source="type" />*/}
            {/*<SelectInput source="type" choices={typesOptions} />*/}
            <SelectInput
                source="status"
                choices={[
                    { id: 'new', name: 'new' },
                    { id: 'running', name: 'running' },
                    { id: 'complete', name: 'complete' },
                    { id: 'failed', name: 'failed' },
                    { id: 'paused', name: 'paused' },
                ]}
            />
            <DropdownSearchInput
                source="type"
                label="Type"
                validate={required()}
                choices={typesOptions}
            />
            <TextInput
                source="data"
                parse={v => JSON.parse(v)}
                format={v => JSON.stringify(v)}
                fullWidth={false}
                multiline={true}
            />
            <div style={{ height: '19rem' }} />
        </SimpleForm>
    </Create>
);

export default TaskCreate;
