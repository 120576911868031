import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Color from 'color';
import capitalize from 'capitalize';
import BigCalendar from 'react-big-calendar';
import { connect, ConnectedProps } from 'react-redux';
import { crudGetList as crudGetListAction } from 'react-admin';
import { OrderStatus } from '../constants';
import { getOrderColor } from '../orders/utils';
import { AppState, Order } from '../types';
import 'react-big-calendar/lib/css/react-big-calendar.css';

BigCalendar.momentLocalizer(moment);

const DEFAULT_VIEW = 'month';
const API_GET_DATE_FORMAT = 'YYYY-MM-DD';

function getOrderStyle(
    order: Order,
    start: Date,
    end: Date,
    isSelected: boolean
) {
    const backgroundColor = getOrderColor(order);

    const style = {
        backgroundColor,
    };

    return { style };
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Event = {
    title: string;
    date: string;
    status: string;
};

type Props = PropsFromRedux & {
    events: Event;
    value?: string;
    views?: string[];
    countryId?: string;
    dayPropGetter?: (date: Date) => any;
    eventPropGetter?: (
        event: any,
        start: Date,
        end: Date,
        isSelected: boolean
    ) => any;
    onChange?: (event: any) => void;
};

type State = {
    currentDate: moment.Moment;
    currentView: string;
};

class SalesCalendar extends Component<Props, State> {
    static defaultProps = {
        views: ['month', 'day', 'week'],
        eventPropGetter: getOrderStyle,
    };

    constructor(props) {
        super(props);

        this.state = {
            currentDate: moment().endOf(DEFAULT_VIEW),
            currentView: DEFAULT_VIEW,
        };

        this.bindScopes([
            'onView',
            'onNavigate',
            'fetchData',
            'updateRequests',
        ]);
    }

    componentDidMount() {
        this.updateRequests();
    }

    onView(view) {
        this.setState({
            currentView: view,
        });

        this.updateRequests(this.state.currentDate, view);
    }

    onNavigate(date, view) {
        const newDate = moment(date);
        this.setState({
            currentDate: newDate,
        });

        this.updateRequests(newDate, view);
    }

    bindScopes(keys) {
        for (const key of keys) {
            this[key] = this[key].bind(this);
        }
    }

    fetchData(start, end) {
        const { crudGetList, countryId } = this.props;

        /*
        crudGetList('orders', null, null, {
            $select: ['id', 'date', 'status', 'userId', 'countryId'],
            'date[$gte]': start,
            'date[$lte]': end,
            ...(countryId && { countryId }),
            status: {
                $in: [
                    OrderStatus.quote_sent,
                    OrderStatus.confirmed,
                    OrderStatus.complete,
                    OrderStatus.onhold,
                ],
            },
        });
        */
    }

    updateRequests(
        date = this.state.currentDate,
        view = this.state.currentView
    ) {
        let start = moment(date).startOf('day');
        let end = moment(date).endOf('day');

        if (view === 'week') {
            start = moment(date).startOf('isoWeek');
            end = moment(date).endOf('isoWeek');
        } else if (view === 'month') {
            start = moment(date).startOf('month').subtract(7, 'days');
            end = moment(date).endOf('month').add(7, 'days');
        }

        this.fetchData(
            start.format(API_GET_DATE_FORMAT),
            end.format(API_GET_DATE_FORMAT)
        );
    }

    handleSelect = ({ start, end }) => {
        if (moment(start).format('YYYY-MM-DD') !== this.props.value) {
            if (this.props.onChange) {
                this.props.onChange(start);
            }
        }
    };

    render() {
        return (
            <div>
                <BigCalendar
                    selectable
                    style={{ height: '100vh' }}
                    onView={this.onView}
                    onNavigate={this.onNavigate}
                    // @ts-ignore
                    events={this.props.events}
                    defaultDate={new Date()}
                    step={60}
                    onSelectSlot={this.handleSelect}
                    {...this.props}
                />
            </div>
        );
    }
}

const mapState = (state: AppState, props: any) => {
    const orders: Order[] =
        state.admin.resources.orders &&
        state.admin.resources.orders.data &&
        Object.values(state.admin.resources.orders.data);

    const salesData: any = {};

    (orders || []).forEach((order: Order) => {
        if (props.countryId && order.countryId !== props.countryId) {
            return;
        }

        if (salesData[order.date] == undefined) {
            salesData[order.date] = {};
        }

        if (salesData[order.date][order.status] == undefined) {
            salesData[order.date][order.status] = 1;
        } else {
            salesData[order.date][order.status] += 1;
        }
    });

    const events: any = [];
    for (const date in salesData) {
        if (salesData.hasOwnProperty(date)) {
            const daySales = salesData[date];
            for (const status in daySales) {
                if (status != undefined) {
                    const count = daySales[status];
                    events.push({
                        title: `${count} ${capitalize.words(
                            status.replace('quote_sent', 'tentative')
                        )}`,
                        date: date,
                        start: date,
                        end: date,
                        allDay: true,
                        status: status,
                    });
                }
            }
        }
    }

    return {
        events,
    };
};

const mapDispatch = {
    crudGetList: crudGetListAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(SalesCalendar);
