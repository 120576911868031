import React from 'react';

import { Show, SimpleShowLayout } from 'react-admin';

import TagSummary from './components/TagSummary';
import TagShowActions from './components/TagShowActions';

const TagTitle = ({ record }) => (
    <span>{record ? `${record.name}` : `Tag #${record.id}`}</span>
);

const TagShow = props => (
    <Show {...props} actions={<TagShowActions />}>
        <SimpleShowLayout>
            <TagSummary />
        </SimpleShowLayout>
    </Show>
);

export default TagShow;
