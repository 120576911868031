import React from 'react';

import { Show, SimpleShowLayout } from 'react-admin';

import PhotoSummary from './components/PhotoSummary';

const PhotoShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <PhotoSummary />
        </SimpleShowLayout>
    </Show>
);

export default PhotoShow;
