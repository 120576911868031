import moment from 'moment';
import {
    OrderStatus,
    OrderStatusColor,
    OrderStatusBorderColor,
} from '../constants';

export function getOrderColor(order) {
    const today = moment().format('YYYY-MM-DD');

    if (
        [
            OrderStatus.complete,
            OrderStatus.rejected,
            OrderStatus.cancelled,
            OrderStatus.lost,
        ].indexOf(order.status) !== -1
    ) {
        return OrderStatusColor[order.status];
    } else if (order.date < today) {
        return OrderStatusColor.pending;
    }

    return OrderStatusColor[order.status];
}

export function getButtonColor(order) {
    if (
        [
            OrderStatus.complete,
            OrderStatus.rejected,
            OrderStatus.cancelled,
            OrderStatus.lost,
        ].indexOf(order.status) !== -1
    ) {
        return OrderStatusBorderColor[order.status];
    }
    return OrderStatusBorderColor[order.status];
}
