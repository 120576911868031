import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { History } from '../../revisions';
import { PaymentSummary } from './PaymentSummary';
import { PaymentCommunicationsList } from './PaymentCommunicationsList';

export const PaymentTabs = props => (
    <TabbedShowLayout {...props}>
        <Tab label="summary">
            <PaymentSummary />
        </Tab>
        <Tab label="History">
            <History {...props} model="payment" documentId={props.record.id} />
        </Tab>
        <Tab label="Communication">
            <PaymentCommunicationsList />
        </Tab>
    </TabbedShowLayout>
);

PaymentTabs.propTypes = {
    record: PropTypes.object,
};

PaymentTabs.defaultProps = {
    record: {},
};
