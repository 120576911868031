import React from 'react';
import { useNotify, useQuery, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import useLocalStorage from '../hooks/useLocalStorage';

type Props = {};

const MyUserMenu: React.FC<Props> = props => {
    const notify = useNotify();
    const [currentUserId] = useLocalStorage('userId', null);
    const { data, loading, error } = useQuery({
        type: 'getOne',
        resource: 'users',
        payload: { id: currentUserId },
    });
    return (
        <UserMenu label={data ? data.name : 'Profile'} {...props}>
            <MenuItemLink
                to="/my-profile"
                primaryText="Account Settings"
                leftIcon={<SettingsIcon />}
            />
            <CopyToClipboard
                text={`https://app.boxitstorage.com/storage-calculator?assigneeId=${currentUserId}`}
                onCopy={() => notify('Sales link copied to Clipboard')}
            >
                <MenuItemLink
                    to="#"
                    primaryText="Sales Link"
                    leftIcon={<AssignmentIndIcon />}
                />
            </CopyToClipboard>
        </UserMenu>
    );
};

export default MyUserMenu;
