import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    ChipField,
    ReferenceManyField,
} from 'react-admin';

import MoneyField from '../components/MoneyField';

const Charges = props => (
    <ReferenceManyField
        {...props}
        addLabel={false}
        reference="charges"
        target="storageId"
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <DateField locales="en-IN" source="createdAt" label="Created" />
            <TextField source="type" />
            <TextField source="year" />
            <TextField source="month" />
            <TextField source="startDate" label="start" />
            <TextField source="endDate" label="end" />
            <MoneyField source="amount" showCurrency />
            <ChipField source="invoiceId" label="Invoice" />
            <TextField source="description" />
        </Datagrid>
    </ReferenceManyField>
);

Charges.defaultProps = {
    resource: 'charges',
    location: { search: '' },
};

export default Charges;
