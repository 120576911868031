export function timeRange(from, to) {
    if (from && to) {
        return `${formatTime(from)} - ${formatTime(to)}`;
    }
    return '';
}

export function formatTime(time) {
    if (time != 12) {
        return `${time % 12} ${time < 12 ? 'am' : 'pm'}`;
    } else {
        return `${time} pm`;
    }
    return '';
}

export const arrayFlatten = htmlToAST => {
    return htmlToAST.reduce(
        (acc, cur) =>
            acc.concat(
                Array.isArray(cur?.children)
                    ? arrayFlatten(cur?.children)
                    : cur?.content
            ),
        []
    );
};
