import React from 'react';

import { Show, SimpleShowLayout } from 'react-admin';

import AddressSummary from './components/AddressSummary';

const AddressTitle = ({ record }) => (
    <span>{record ? `${record.name}` : `Address #${record.id}`}</span>
);

const AddressShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <AddressSummary />
        </SimpleShowLayout>
    </Show>
);

export default AddressShow;
