import React from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { createStyles } from '@material-ui/core/styles';
import TrackballControls from './TrackballControls';
import { Warehouse, Location } from '../types';

const infoStyle: React.CSSProperties = {
    position: 'absolute',
    top: '100px',
    width: '100%',
    textAlign: 'left',
    zIndex: 100,
    display: 'block',
};

const boxStyle = {
    listStyleType: 'none',
    width: '10%',
    border: '1px solid black',
    padding: '10px',
    marginLeft: '20px',
};

function createTexture(text, fillStyle) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
        context.fillStyle = fillStyle;
        context.fillRect(0, 0, 300, 300);
        context.font = 'bold 16px arial';
        context.fillStyle = 'black';
        context.fillText(text, 30, 30);
    }

    // canvas contents will be used for a texture
    const texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;

    return texture;
}

function createFloor(scene) {
    const floorMaterial = new THREE.MeshBasicMaterial({
        color: 0xedecec,
        side: THREE.DoubleSide,
    });
    const floorGeometry = new THREE.PlaneGeometry(2200, 2200);
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.position.y = 40;
    floor.position.z = 1200;
    floor.rotation.x = Math.PI / 2;
    scene.add(floor);

    const grid = new THREE.GridHelper(2200, 5);
    grid.position.y = 42;
    grid.position.z = 1200;
    scene.add(grid);
}

type Props = {
    warehouse: Warehouse;
    locations: Location[];
};

class WarehouseScene extends React.Component<Props> {
    mount: any;
    scene: any;
    camera: any;
    renderer: any;
    controls: any;
    frameId: any;

    constructor(props) {
        super(props);

        this.start = this.start.bind(this);
        this.stop = this.stop.bind(this);
        this.animate = this.animate.bind(this);
    }

    componentDidMount() {
        const { locations } = this.props;

        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;

        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);

        const SCREEN_WIDTH = width;
        const SCREEN_HEIGHT = height;
        const VIEW_ANGLE = 45;
        const ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT;
        const NEAR = 0.1;
        const FAR = 20000;
        const camera = new THREE.PerspectiveCamera(
            VIEW_ANGLE,
            ASPECT,
            NEAR,
            FAR
        );
        camera.position.set(0, 500, 4000);
        camera.lookAt(scene.position);

        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setClearColor('#000000');
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);

        createFloor(scene);

        const geometry = new THREE.BoxGeometry(100, 100, 300);
        const wireframeMaterial = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 2,
        });

        const aisles = { C: 0, B: 1, A: 2 };

        for (let i = 0; i < locations.length; ++i) {
            const loc = locations[i];

            const fillStyle = loc.storagerecords.length
                ? 'rgba(255, 255, 255)'
                : 'rgba(0, 255, 0)';

            const material = new THREE.MeshBasicMaterial({
                map: createTexture(loc.path, fillStyle),
                polygonOffset: true,
                polygonOffsetFactor: 1, // positive value pushes polygon further away
                polygonOffsetUnits: 1,
            });

            const mesh = new THREE.Mesh(geometry, material);

            mesh.position.x = (aisles[loc.aisle] % 3) * 500 - 500;
            mesh.position.y = loc.y * 100;
            mesh.position.z = loc.x * 300;
            scene.add(mesh);

            const wireframeGeometry = new THREE.EdgesGeometry(mesh.geometry);
            const wireframe = new THREE.LineSegments(
                wireframeGeometry,
                wireframeMaterial
            );
            wireframe.updateMatrix();
            wireframe.matrixAutoUpdate = false;
            mesh.add(wireframe);
        }

        this.scene = scene;
        this.camera = camera;
        this.renderer = renderer;

        this.mount.appendChild(this.renderer.domElement);

        const controls = new TrackballControls(
            camera,
            this.renderer.domElement
        );

        controls.addEventListener('change', this.renderScene);
        this.controls = controls;

        this.start();
    }

    componentWillUnmount() {
        this.stop();
        this.mount.removeChild(this.renderer.domElement);
    }

    start() {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    }

    stop() {
        cancelAnimationFrame(this.frameId);
    }

    animate() {
        this.renderScene();
        this.frameId = window.requestAnimationFrame(this.animate);
        this.controls.update();
    }

    renderScene() {
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
        }
    }

    render() {
        const { warehouse, locations } = this.props;
        const total = locations.length;
        const occupied = locations.filter(i => i.storagerecords.length).length;
        const vaccant = locations.filter(i => !i.storagerecords.length).length;

        return (
            <div>
                <div style={infoStyle}>
                    <ul style={boxStyle}>
                        <li>
                            <strong>{warehouse.name}</strong>
                        </li>
                        <li>Total: {total}</li>
                        <li style={{ color: '#0000ff' }}>
                            Occupied: {occupied}
                        </li>
                        <li style={{ color: '#00ff00' }}>Vaccant: {vaccant}</li>
                    </ul>
                </div>
                <div
                    style={{
                        width: window.innerWidth - 300,
                        height: window.innerHeight - 100,
                    }}
                    ref={mount => {
                        this.mount = mount;
                    }}
                />
            </div>
        );
    }
}

export default WarehouseScene;
