import React from 'react';
import moment from 'moment';
import {
    Create,
    SimpleForm,
    AutocompleteInput,
    CheckboxGroupInput,
    ReferenceInput,
    ReferenceArrayInput,
    DateInput,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';
import { InvoiceType } from '../constants';
import { getCurrencyString } from '../charges/utils';
import { useSelector } from 'react-redux';

const CheckboxGroup = props => (
    <CheckboxGroupInput
        {...props}
        source="chargeIds"
        optionText={rec =>
            `[${rec.type}]: ${getCurrencyString(rec)} - ${rec.description}`
        }
        choices={(props.choices || []).filter(c => c.invoiceId === null)}
    />
);

const InvoiceCreate = props => {
    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.invoices?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });
    return (
        <Create {...props}>
            <SimpleForm variant="outlined" redirect="show">
                <ReferenceInput
                    label="User"
                    source="userId"
                    reference="users"
                    resource="users"
                    allowEmpty
                    defaultValue={selectedCustomer?.id}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                        defaultValue={selectedCustomer?.id}
                    />
                </ReferenceInput>

                <SelectInput
                    source="type"
                    choices={Object.keys(InvoiceType).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />

                <DateInput
                    source="date"
                    label="Invoice Date"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceArrayInput
                                label="Charges"
                                source="chargeIds"
                                reference="charges"
                                resource="charges"
                                filter={{
                                    userId: formData.userId,
                                    ...(formData.type === 'creditNote'
                                        ? {
                                              type: 'credit',
                                          }
                                        : {
                                              type: { $ne: 'credit' },
                                          }),
                                }}
                                allowEmpty
                            >
                                <CheckboxGroup />
                            </ReferenceArrayInput>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default InvoiceCreate;
