import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    emails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    email: {},
};

export const EmailTemplateDetails = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Template name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.templateName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Description</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.description}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.status}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

EmailTemplateDetails.propTypes = {
    record: PropTypes.object,
};
