import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { Comments } from '../../comments';
import { History } from '../../revisions';
import { OrderInvoicesList } from '../../orders/components/OrderInvoicesList';
import { OrderRequestsList } from '../../orders/components/OrderRequestsList';
import { OrderStoragesList } from '../../orders/components/OrderStoragesList';
import { LeadSummary } from './LeadSummary';
import { LeadCommunicationsList } from './LeadCommunicationsList';

export const LeadTabs = props => (
    <TabbedShowLayout {...props}>
        <Tab label="summary">
            <LeadSummary />
        </Tab>
        <Tab label="Storages">
            <OrderStoragesList />
        </Tab>
        <Tab label="Requests">
            <OrderRequestsList />
        </Tab>
        <Tab label="Invoices">
            <OrderInvoicesList />
        </Tab>
        <Tab label="Comments">
            <Comments
                model="order"
                documentId={props.record.id}
                userId={props.record.userId}
            />
        </Tab>
        <Tab label="History">
            <History {...props} model="order" documentId={props.record.id} />
        </Tab>
        <Tab label="Communication">
            <LeadCommunicationsList />
        </Tab>
    </TabbedShowLayout>
);

LeadTabs.propTypes = {
    record: PropTypes.object,
};

LeadTabs.defaultProps = {
    record: {},
};
