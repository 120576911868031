import * as React from 'react';

function SvgFileText(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.879 9.879A3 3 0 0114 9h8a1 1 0 01.707.293l6 6A1 1 0 0129 16v12a3 3 0 01-3 3H14a3 3 0 01-3-3V12a3 3 0 01.879-2.121zM14 11a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V16.414L21.586 11H14z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 9a1 1 0 011 1v5h5a1 1 0 110 2h-6a1 1 0 01-1-1v-6a1 1 0 011-1zM15 21a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1zM15 25a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1zM15 17a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgFileText;
