import React from 'react';
import cx from 'classnames';
// @ts-ignore
import s from './AddVideo.module.css';
import { Button } from '@material-ui/core';
import feathersClient from '../rest/feathersClient';
import axios from 'axios';

type Props = {
    record: any;
};

type State = {
    parsedFiles: File[];
    isLoading: boolean;
    isUploading: boolean;
    isOverSize: boolean;
    errors: string[];
    currentFileSize: number;
    currentProgress: number;
    uploadedFiles: number;
    currentFileIndex: number;
    uploadedVideos: number;
    uploadedPhotos: number;
};

class AddVideo extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            parsedFiles: [],
            isUploading: false,
            isLoading: false,
            isOverSize: false,
            errors: [],
            currentFileSize: 0,
            currentProgress: 0,
            uploadedFiles: 0,
            currentFileIndex: 1,
            uploadedVideos: 0,
            uploadedPhotos: 0,
        };
    }

    getAndUploadVideoUrl = async () => {
        this.setState({
            isUploading: true,
        });
        const uploadUrl = feathersClient.service('PresignedUrl');
        const confirmService = feathersClient.service(
            'uploadConfirmationService'
        );
        const commentService = feathersClient.service('comments');

        let urlsData;

        try {
            urlsData = await uploadUrl.create({
                filenames: this.state.parsedFiles.map(item => item.name),
                requestId: this.props.record.id,
                mimetypes: this.state.parsedFiles.map(item => {
                    return item.type.includes('image')
                        ? 'Image/jpeg'
                        : item.type.includes('video')
                        ? 'video'
                        : null;
                }),
            });
        } catch (e) {
            this.setState(prev => ({
                errors: [...prev.errors, `Error: ${(e as any).message}!`],
                isUploading: false,
            }));
            return;
        }

        for (let index = 0; index < this.state.parsedFiles.length; index++) {
            this.setState({
                currentFileSize: this.state.parsedFiles[index].size,
                currentFileIndex: index + 1,
                currentProgress: 0,
            });
            try {
                await axios.put(
                    urlsData[index].URL,
                    this.state.parsedFiles[index],
                    {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        onUploadProgress: progressEvent =>
                            this.setState({
                                currentProgress: progressEvent.loaded,
                            }),
                    }
                );
            } catch (e) {
                this.setState(prev => ({
                    errors: [
                        ...prev.errors,
                        `Error: file (${this.state.parsedFiles[index].name}) upload failed!`,
                    ],
                }));
                continue;
            }

            try {
                await confirmService.create({
                    userId: this.props.record.userId,
                    requestId: this.props.record.id,
                    key: urlsData[index].KEY,
                    size: this.state.parsedFiles[index].size,
                    mimetype: this.state.parsedFiles[index].type.includes(
                        'image'
                    )
                        ? 'Image/jpeg'
                        : this.state.parsedFiles[index].type.includes('video')
                        ? 'video'
                        : null,
                });
                if (this.state.parsedFiles[index].type.includes('image')) {
                    this.setState(prev => {
                        return {
                            uploadedPhotos: prev.uploadedPhotos + 1,
                        };
                    });
                }

                if (this.state.parsedFiles[index].type.includes('video')) {
                    this.setState(prev => {
                        return {
                            uploadedVideos: prev.uploadedVideos + 1,
                        };
                    });
                }
            } catch (e) {
                this.setState(prev => ({
                    errors: [
                        ...prev.errors,
                        `Error: file (${
                            this.state.parsedFiles[index].name
                        }) upload failed! ${(e as any).message}`,
                    ],
                    isUploading: false,
                }));
            }
        }

        await commentService.create({
            comment: `Uploaded ${
                this.state.uploadedPhotos > 0 ? this.state.uploadedPhotos : ''
            }${
                this.state.uploadedPhotos > 0
                    ? this.state.uploadedPhotos === 1
                        ? ' photo'
                        : ' photos'
                    : ''
            }${
                this.state.uploadedPhotos > 0 && this.state.uploadedVideos > 0
                    ? ' and '
                    : ''
            }${this.state.uploadedVideos > 0 ? this.state.uploadedVideos : ''}${
                this.state.uploadedVideos > 0
                    ? this.state.uploadedVideos === 1
                        ? ' video'
                        : ' videos'
                    : ''
            }`,
            documentId: this.props.record.id,
            model: 'request',
            userId: this.props.record.userId,
        });
        this.setState({
            isUploading: false,
            uploadedPhotos: 0,
            uploadedVideos: 0,
        });
        window.location.reload();
    };

    handleSubmit = e => {
        e.preventDefault();

        this.getAndUploadVideoUrl();
    };

    handleImageChange = async e => {
        this.setState({
            isLoading: true,
            isOverSize: false,
            parsedFiles: [],
        });
        e.preventDefault();
        let files: any[] = [];
        files = e.target.files;
        let filesSize = 0;
        Object.keys(files).forEach(i => {
            const file = files[i];
            const reader = new FileReader();
            filesSize = filesSize + file.size;
            reader.onloadend = e => {
                this.setState((prev: any) => {
                    return {
                        parsedFiles: [...prev.parsedFiles, file],
                    };
                });
            };
            reader.readAsBinaryString(file);
            this.setState({
                isLoading: false,
            });
        });
        if (filesSize > 524288000) {
            this.setState({
                isOverSize: true,
                isLoading: false,
            });
            return;
        }
    };

    render() {
        return (
            <div>
                {/*<span>Upload Video</span>*/}
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="file"
                        style={{
                            border: '4px solid lightgray',
                            padding: '5px',
                            margin: '10px',
                            cursor: 'pointer',
                            marginLeft: '0px',
                        }}
                        onChange={this.handleImageChange}
                        multiple={true}
                        accept=".gif,.jpg,.jpeg,.png,.mpg,.mp2,.mpeg,.mpe,.mpv,.mp4,.mov"
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.handleSubmit}
                        disabled={
                            this.state.parsedFiles.length === 0 ||
                            this.state.isLoading ||
                            this.state.isOverSize ||
                            this.state.isUploading
                        }
                    >
                        Upload
                    </Button>

                    {this.state.isLoading && (
                        <div className="alert alert-info" role="alert">
                            Loading Files...
                        </div>
                    )}
                    {this.state.isUploading && (
                        <div className={s.uoloadData}>
                            <span className={s.uploadDataInfo}>
                                Uploading files {this.state.currentFileIndex} of{' '}
                                {this.state.parsedFiles.length}
                            </span>
                            {this.state.currentFileSize !== 0 && (
                                <div className={s.uploadProgressData}>
                                    <div className={s.uploadProgress}>
                                        <div
                                            style={{
                                                width: `${(
                                                    (this.state
                                                        .currentProgress /
                                                        this.state
                                                            .currentFileSize) *
                                                    100
                                                ).toFixed()}%`,
                                            }}
                                        />
                                    </div>
                                    <span>
                                        {(
                                            (this.state.currentProgress /
                                                this.state.currentFileSize) *
                                            100
                                        ).toFixed()}
                                        %
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    {this.state.isOverSize && (
                        <div
                            className="alert alert-info text-red-600"
                            role="alert"
                        >
                            Total files size is more than 500MB!
                        </div>
                    )}
                    {this.state.errors.length > 0 &&
                        this.state.errors.map(er => (
                            <div
                                className="alert alert-info text-red-600"
                                role="alert"
                            >
                                {er}
                            </div>
                        ))}
                </form>
            </div>
        );
    }
}

export default AddVideo;
