import React from 'react';
import moment from 'moment';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { dialogReducer } from 'redux-dialog';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'sweetalert/dist/sweetalert.css';
import { authProvider, dataProvider } from './rest';
import themeReducer from './themeReducer';
import messages from './i18n';
import customRoutes from './customRoutes';
import { Dashboard } from './dashboard';
import { Login, Layout } from './layout';
import { UserList, UserShow, UserEdit, UserCreate } from './users';
import { CustomerEdit } from './customers';
import { StaffList, StaffCreate, StaffEdit } from './staff';
import {
    StorageList,
    StorageShow,
    StorageEdit,
    StorageCreate,
} from './storages';
import { storageSaga } from './storages/actions';
import { BoxList, BoxCreate, BoxEdit } from './boxes';
import { RequestLineEdit, RequestLineShow } from './request-lines';
import {
    RequestList,
    RequestShow,
    RequestEdit,
    RequestCreate,
} from './requests';
import {
    WarehouseList,
    WarehouseCreate,
    WarehouseEdit,
    WarehouseShow,
} from './warehouses';
import { OrderList, OrderCreate, OrderEdit, OrderShow } from './orders';
import { CouponList, CouponCreate, CouponEdit } from './coupons';
import { OfferList, OfferCreate, OfferEdit } from './offers';
import { AffiliateList, AffiliateCreate, AffiliateEdit } from './affiliates';
import { TeamList, TeamCreate, TeamEdit, TeamShow } from './teams';
import {
    InvoiceList,
    InvoiceCreate,
    InvoiceEdit,
    InvoiceShow,
} from './invoices';
import {
    PaymentList,
    PaymentCreate,
    PaymentEdit,
    PaymentShow,
} from './payments';
import {
    AddressList,
    AddressShow,
    AddressCreate,
    AddressEdit,
} from './addresses';
import { TaskList, TaskCreate, TaskEdit, TaskShow } from './tasks';
import {
    LocationList,
    LocationCreate,
    LocationEdit,
    LocationShow,
} from './locations';
import {
    StorageRecordList,
    StorageRecordShow,
    StorageRecordEdit,
} from './storage-records';
import { CardEdit, CardList, CardShow } from './cards';
import { AffiliateCommissionList } from './affiliate-commissions';
import { Analytics } from './analytics';
import { ChargeCreate, ChargeList, ChargeEdit, ChargeShow } from './charges';
import { BlockedSlotList, BlockedSlotCreate } from './blocked-slots';
import { LeadList, LeadCreate, LeadShow, LeadEdit } from './leads';
import {
    PartnerList,
    PartnerCreate,
    PartnerShow,
    PartnerEdit,
} from './partners';
import {
    EmailTemplateList,
    EmailTemplateCreate,
    EmailTemplateEdit,
} from './email-templates';
import {
    StorageTypeList,
    StorageTypeCreate,
    StorageTypeEdit,
    StorageTypeShow,
} from './storage-types';
import {
    ServiceTypeList,
    ServiceTypeCreate,
    ServiceTypeEdit,
} from './service-types';
import { TermsConditionsList } from './terms-conditions';

import { CommentList } from './comments';
import { PhotoList, PhotoShow } from './photos';

import { userActions, userSaga } from './users/actions';
import { leadActions, leadSaga } from './leads/actions';
import { orderActions, orderSaga } from './orders/actions';
import { requestActions, requestSaga } from './requests/actions';
import { invoiceActions, invoiceSaga } from './invoices/actions';
import { commentActions, commentSaga } from './comments/actions';
import { taskActions, taskSaga } from './tasks/actions';
import { userHistoryList } from './user-history';
import EmailTemplateShow from './email-templates/EmailTemplateShow';
import {
    TemplateCategoriesList,
    TemplateCategoryCreate,
    TemplateCategoryEdit,
} from './template-categories';
import {
    EmailPlaceholderCreate,
    EmailPlaceholderEdit,
    EmailPlaceholderList,
    EmailPlaceholderShow,
} from './email-placeholder';
import SurveyList from './surveys/SurveyList';
import SurveyShow from './surveys/SurveyShow';
import CommunicationList from './communication-records/CommunicationList';
import CommunicationShow from './communication-records/CommunicationShow';
import TagList from './tags/TagList';
import TagShow from './tags/TagShow';
import TagCreate from './tags/TagCreate';
import TagEdit from './tags/TagEdit';
import ReasonList from './lost-reasons/ReasonsList';
import HoldReasonList from './hold-reasons/ReasonsList';
import ReasonsEdit from './lost-reasons/ReasonsEdit';
import ReasonsCreate from './lost-reasons/ReasonsCreate';
import ReasonShow from './lost-reasons/ReasonsShow';
import RejectReasonList from './reject-reasons/ReasonsList';
import CancelReasonList from './cancel-reasons/ReasonsList';
import HoldReasonsCreate from './hold-reasons/ReasonsCreate';
import HoldReasonsEdit from './hold-reasons/ReasonsEdit';
import RejectReasonsEdit from './reject-reasons/ReasonsEdit';
import RejectReasonsCreate from './reject-reasons/ReasonsCreate';
import HoldReasonShow from './hold-reasons/ReasonsShow';
import RejectReasonShow from './reject-reasons/ReasonsShow';
import cancelReasonShow from './cancel-reasons/ReasonsShow';
import CancelReasonShow from './cancel-reasons/ReasonsShow';
import CancelReasonsCreate from './cancel-reasons/ReasonsCreate';
import CancelReasonsEdit from './cancel-reasons/ReasonsEdit';

moment.updateLocale('en', {
    longDateFormat: {
        LT: 'h:mm A',
        LTS: 'h:mm:ss A',
        L: 'DD/MM/YYYY',
        LL: 'Do MMMM YYYY',
        LLL: 'Do MMMM YYYY LT',
        LLLL: 'dddd, Do MMMM YYYY LT',
    },
});

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'en');

const App = () => (
    <Admin
        title="Boxit OMS"
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        customActions={[
            userActions,
            leadActions,
            orderActions,
            requestActions,
            invoiceActions,
            commentActions,
            taskActions,
        ]}
        customSagas={[
            userSaga,
            leadSaga,
            orderSaga,
            requestSaga,
            invoiceSaga,
            commentSaga,
            taskSaga,
            storageSaga,
        ]}
        customReducers={{ dialogReducer, theme: themeReducer }}
    >
        {permissions =>
            permissions.indexOf('partner') !== -1
                ? [
                      <Resource name="country" />,
                      <Resource name="city" />,
                      <Resource name="services" />,
                      <Resource name="revisions" />,
                      <Resource name="rooms" />,
                      <Resource
                          name="users"
                          list={UserList}
                          create={UserCreate}
                          edit={UserEdit}
                          show={UserShow}
                      />,
                      <Resource
                          name="leads"
                          list={LeadList}
                          create={LeadCreate}
                          show={LeadShow}
                          edit={LeadEdit}
                      />,
                      <Resource
                          name="orders"
                          list={OrderList}
                          create={OrderCreate}
                          edit={OrderEdit}
                          show={OrderShow}
                      />,
                      <Resource
                          name="storages"
                          list={StorageList}
                          show={StorageShow}
                      />,
                      <Resource
                          name="addresses"
                          list={AddressList}
                          create={AddressCreate}
                          show={AddressShow}
                          edit={AddressEdit}
                      />,
                      <Resource name="partners" />,
                      <Resource name="requests" />,
                      <Resource
                          name="invoices"
                          list={InvoiceList}
                          show={InvoiceShow}
                      />,
                      <Resource name="user-stats" />,
                      <Resource name="storage-types" />,
                      <Resource name="service-types" />,
                      <Resource name="comments" />,
                      <Resource
                          name="photos"
                          list={PhotoList}
                          show={PhotoShow}
                      />,
                      <Resource name="user-photos" show={PhotoShow} />,
                  ]
                : [
                      <Resource name="country" />,
                      <Resource name="city" />,
                      <Resource name="services" />,
                      <Resource name="revisions" />,
                      <Resource name="rooms" />,
                      <Resource
                          name="users"
                          list={UserList}
                          create={UserCreate}
                          edit={UserEdit}
                          show={UserShow}
                      />,
                      <Resource name="customers" edit={CustomerEdit} />,
                      <Resource
                          name="orders"
                          list={OrderList}
                          create={OrderCreate}
                          edit={OrderEdit}
                          show={OrderShow}
                      />,

                      <Resource
                          name="requests"
                          list={RequestList}
                          create={RequestCreate}
                          edit={RequestEdit}
                          show={RequestShow}
                      />,
                      <Resource
                          name="storages"
                          list={StorageList}
                          create={StorageCreate}
                          edit={StorageEdit}
                          show={StorageShow}
                      />,
                      <Resource
                          name="addresses"
                          list={AddressList}
                          create={AddressCreate}
                          show={AddressShow}
                          edit={AddressEdit}
                      />,
                      <Resource
                          name="boxes"
                          list={BoxList}
                          create={BoxCreate}
                          edit={BoxEdit}
                      />,
                      <Resource
                          name="request-lines"
                          show={RequestLineShow}
                          edit={RequestLineEdit}
                      />,
                      <Resource
                          name="warehouses"
                          list={WarehouseList}
                          create={WarehouseCreate}
                          edit={WarehouseEdit}
                          show={WarehouseShow}
                      />,
                      <Resource
                          name="locations"
                          list={LocationList}
                          create={LocationCreate}
                          edit={LocationEdit}
                          show={LocationShow}
                      />,
                      <Resource
                          name="storage-records"
                          list={StorageRecordList}
                          edit={StorageRecordEdit}
                          show={StorageRecordShow}
                      />,
                      <Resource
                          name="teams"
                          list={TeamList}
                          create={TeamCreate}
                          edit={TeamEdit}
                          show={TeamShow}
                      />,

                      <Resource
                          name="invoices"
                          list={InvoiceList}
                          create={InvoiceCreate}
                          edit={InvoiceEdit}
                          show={InvoiceShow}
                      />,
                      <Resource
                          name="payments"
                          list={PaymentList}
                          create={PaymentCreate}
                          edit={PaymentEdit}
                          show={PaymentShow}
                      />,
                      <Resource
                          name="cards"
                          list={CardList}
                          show={CardShow}
                          edit={CardEdit}
                      />,
                      <Resource
                          name="charges"
                          list={ChargeList}
                          create={ChargeCreate}
                          edit={ChargeEdit}
                          show={ChargeShow}
                      />,

                      <Resource
                          name="coupons"
                          list={CouponList}
                          create={CouponCreate}
                          edit={CouponEdit}
                      />,
                      <Resource
                          name="offers"
                          list={OfferList}
                          create={OfferCreate}
                          edit={OfferEdit}
                      />,
                      <Resource name="analytics" list={Analytics} />,
                      <Resource
                          name="affiliates"
                          list={AffiliateList}
                          create={AffiliateCreate}
                          edit={AffiliateEdit}
                      />,

                      <Resource
                          name="staff"
                          list={StaffList}
                          create={StaffCreate}
                          edit={StaffEdit}
                      />,
                      <Resource
                          name="tasks"
                          list={TaskList}
                          create={TaskCreate}
                          edit={TaskEdit}
                          show={TaskShow}
                      />,
                      <Resource
                          name="blocked-slots"
                          list={BlockedSlotList}
                          create={BlockedSlotCreate}
                      />,
                      <Resource
                          name="storage-types"
                          list={StorageTypeList}
                          show={StorageTypeShow}
                          create={StorageTypeCreate}
                          edit={StorageTypeEdit}
                      />,
                      <Resource
                          name="service-types"
                          list={ServiceTypeList}
                          create={ServiceTypeCreate}
                          edit={ServiceTypeEdit}
                      />,
                      <Resource
                          name="leads"
                          list={LeadList}
                          create={LeadCreate}
                          show={LeadShow}
                          edit={LeadEdit}
                      />,
                      <Resource
                          name="partners"
                          list={PartnerList}
                          create={PartnerCreate}
                          show={PartnerShow}
                          edit={PartnerEdit}
                      />,

                      <Resource name="comments" list={CommentList} />,
                      <Resource
                          name="photos"
                          list={PhotoList}
                          show={PhotoShow}
                      />,
                      <Resource
                          name="terms-conditions"
                          list={TermsConditionsList}
                      />,
                      <Resource name="user-logs" list={userHistoryList} />,
                      <Resource name="user-photos" show={PhotoShow} />,
                      <Resource name="user-stats" />,
                      <Resource name="notify" />,
                      <Resource name="billing" />,
                      <Resource name="authManagement" />,
                      <Resource name="partner-commission-slabs" />,
                      <Resource name="tasklogs" />,
                      <Resource name="request-lines" />,
                      <Resource name="user-usages" />,
                      <Resource name="usages" />,
                      <Resource
                          name="communication-templates"
                          list={EmailTemplateList}
                          //create={EmailTemplateCreate}
                          edit={EmailTemplateEdit}
                          show={EmailTemplateShow}
                      />,
                      <Resource
                          name="template-categories"
                          list={TemplateCategoriesList}
                          create={TemplateCategoryCreate}
                          edit={TemplateCategoryEdit}
                      />,
                      <Resource
                          name="email-placeholder"
                          list={EmailPlaceholderList}
                          create={EmailPlaceholderCreate}
                          edit={EmailPlaceholderEdit}
                          show={EmailPlaceholderShow}
                      />,
                      <Resource
                          name="surveys"
                          list={SurveyList}
                          show={SurveyShow}
                      />,
                      <Resource name="survey-photos" />,
                      <Resource
                          name="Communication-records"
                          list={CommunicationList}
                          show={CommunicationShow}
                      />,
                      <Resource
                          name="tags"
                          list={TagList}
                          show={TagShow}
                          edit={TagEdit}
                          create={TagCreate}
                      />,
                      <Resource
                          name="lost-reasons"
                          list={ReasonList}
                          edit={ReasonsEdit}
                          create={ReasonsCreate}
                          show={ReasonShow}
                      />,
                      <Resource
                          name="hold-reasons"
                          list={HoldReasonList}
                          edit={HoldReasonsEdit}
                          create={HoldReasonsCreate}
                          show={HoldReasonShow}
                      />,
                      <Resource
                          name="reject-reasons"
                          list={RejectReasonList}
                          edit={RejectReasonsEdit}
                          create={RejectReasonsCreate}
                          show={RejectReasonShow}
                      />,
                      <Resource
                          name="cancel-reasons"
                          list={CancelReasonList}
                          edit={CancelReasonsEdit}
                          create={CancelReasonsCreate}
                          show={CancelReasonShow}
                      />,
                  ]
        }
    </Admin>
);

export default App;
