import * as React from 'react';

function SvgTruck(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 13a1 1 0 011-1h15a1 1 0 011 1v4h3a1 1 0 01.707.293l3 3A1 1 0 0132 21v5a1 1 0 01-1 1h-1.337a3.5 3.5 0 11-6.326 0h-6.674a3.5 3.5 0 11-6.326 0H9a1 1 0 01-1-1V13zm15 12V14H10v11h13zm2 0v-6h2.586L30 21.414V25h-5zm-11.5 2a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm13 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgTruck;
