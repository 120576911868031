import * as React from 'react';

function SvgAlertTriangle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                d="M20 16a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM20 26a1 1 0 100-2 1 1 0 000 2z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.435 11.341a3 3 0 015.13 0l.003.005 8.47 14.14.008.014a3 3 0 01-2.565 4.5H11.519a3 3 0 01-2.565-4.5l.008-.014 8.47-14.14.003-.005zm1.711 1.036l-8.463 14.129A1 1 0 0011.536 28h16.928a1 1 0 00.853-1.494l-8.462-14.127-.001-.002a1 1 0 00-1.708 0z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgAlertTriangle;
