import * as React from 'react';

function SvgBarChart2(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 17a1 1 0 011 1v10a1 1 0 11-2 0V18a1 1 0 011-1zM20 11a1 1 0 011 1v16a1 1 0 11-2 0V12a1 1 0 011-1zM14 21a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgBarChart2;
