import React, { FC, useEffect } from 'react';
import { Show, useRefresh } from 'react-admin';
import { UserTabs } from './components/UserTabs';
import UserShowActions from './components/UserShowActions';
import { ShowProps, FieldProps, User } from '../types';
import { modelView, modelViewType } from '../util/modelView';

const sanitizeRestProps: (any) => any = ({ hasShow, ...rest }) => rest;

const UserTitle: FC<FieldProps<User>> = ({ record }) => (
    <span>{record!.name ? `${record!.name}` : `User #${record!.id}`}</span>
);

const UserShow: FC<ShowProps<User>> = ({ permissions = [], ...props }) => {
    const refresh = useRefresh();
    useEffect(() => {
        modelView(modelViewType.USERS, parseInt(props.id));
    }, [props.id]);

    useEffect(() => {
        setTimeout(() => {
            refresh();
        }, 1000);
    }, []);
    return (
        <Show
            title={<UserTitle />}
            actions={<UserShowActions permissions={permissions} />}
            {...sanitizeRestProps(props)}
        >
            <UserTabs />
        </Show>
    );
};

export default UserShow;
