import React from 'react';
import moment from 'moment';
import capitalize from 'capitalize';
import _ from 'lodash';
import { InputAdornment } from '@material-ui/core';
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    SelectInput,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    DateInput,
    BooleanInput,
    AutocompleteInput,
    SelectArrayInput,
    SimpleFormIterator,
    RadioButtonGroupInput,
    NumberInput,
} from 'react-admin';
import { PaymentMethod, StorageType, OrderType } from '../constants';
import MoneyInput from '../components/MoneyInput';
import BillingCard from '../orders/components/BillingCard';
import ItemsInput from './components/ItemsInput';
import BoxitPhoneInput from '../components/BoxitPhoneInput';
import WhenFieldChanges from '../components/WhenFieldChanges';
import { AppState, CreateProps, Order, ValidationErrors } from '../types';
import PlaceAutoComplete from '../components/PlaceAutoComplete';
import { Source } from '../constants';
import { connect } from 'react-redux';
import useLocalStorage from '../hooks/useLocalStorage';
import { useGetOne } from 'react-admin';
import { useSelector } from 'react-redux';

const optionText = user => `${user.name} (${user.id})`;

const hasBoxitBoxes = (formData: any): boolean => {
    return (
        formData.items &&
        formData.items.some(
            i =>
                i &&
                i.storagetypeId === StorageType.BB &&
                Number(i.quantity) > 0
        )
    );
};

const validate = values => {
    const errors: ValidationErrors = {};

    if (!values.cityId) {
        errors.cityId = ['City is required'];
    }

    if (values.months > 36) {
        errors.months = ['Must be less than or equal to 36'];
    }

    if (values.existingCustomer && !values.userId) {
        errors.userId = ['User is required'];
    }

    if (values.partnerId === 1 && !values.channel) {
        errors.channel = ['Channel is required'];
    }

    if (!values.source) {
        errors.source = ['errors.required'];
    }

    return errors;
};

const transform = data => {
    const countryId = localStorage.getItem('countryId');
    return {
        ...data,
        warehouseType:
            countryId && countryId === '2' && !data.warehouseType
                ? ['ac']
                : data.warehouseType,
    };
};

const OrderCreate: React.FC<CreateProps<Order>> = ({
    permissions = [],
    ...props
}) => {
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);

    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.orders?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });

    const mostRecentAddress = useSelector((state: any) => {
        const addresses =
            state.admin?.references?.possibleValues?.['addresses@addressId']
                ?.map(
                    addressId =>
                        state.admin?.resources?.addresses?.data[addressId]
                )
                .sort(
                    (a, b) =>
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                ) || [];
        return addresses[0] || null;
    });
    return (
        <Create
            successMessage="resources.orders.notification.create_success"
            {...props}
            transform={transform}
        >
            <TabbedForm
                variant="outlined"
                validate={validate}
                redirect="show"
                {...props}
            >
                <FormTab label="Storage">
                    <TextInput
                        disabled
                        {...props}
                        source="status"
                        defaultValue="confirmed"
                        style={{ display: 'none' }}
                    />
                    <DateInput
                        label="Confirmed At"
                        source="confirmedAt"
                        defaultValue={new Date()}
                        style={{ display: 'none' }}
                    />
                    <BooleanInput
                        style={{ display: 'none' }}
                        source="skipSignupMail"
                        defaultValue={true}
                    />
                    <BooleanInput
                        style={{ display: 'none' }}
                        source="skipOrderMail"
                        defaultValue={true}
                    />

                    <SelectInput
                        source="type"
                        label="Order Type"
                        defaultValue={OrderType.storage}
                        choices={Object.keys(OrderType).map(i => ({
                            id: i,
                            name: capitalize(i.replace('_', ' ')),
                        }))}
                    />

                    {permissions.indexOf('staff') !== -1 && (
                        <ReferenceInput
                            label="Partner"
                            source="partnerId"
                            reference="partners"
                            resource="partners"
                            defaultValue={1}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    )}

                    <ReferenceInput
                        label="Country"
                        source="countryId"
                        reference="country"
                        resource="country"
                        defaultValue={currentUser?.countryId}
                    >
                        <SelectInput optionText="name" optionValue="id" />
                    </ReferenceInput>

                    <ReferenceInput
                        label="City"
                        source="cityId"
                        reference="city"
                        resource="city"
                        defaultValue={currentUser?.countryId === 2 ? 6 : ''}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <TextInput
                        source="months"
                        label="Duration"
                        defaultValue={1}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    Months
                                </InputAdornment>
                            ),
                        }}
                    />

                    <SelectInput
                        source="paymentMode"
                        label="Payment Mode"
                        choices={Object.keys(PaymentMethod).map(i => ({
                            id: i,
                            name: capitalize(i),
                        }))}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.type === OrderType.storage && (
                                <SelectInput
                                    variant="outlined"
                                    label={
                                        currentUser?.countryId === 2
                                            ? 'AC'
                                            : 'Warehouse Type'
                                    }
                                    source="warehouseType"
                                    choices={[
                                        { id: 'ac', name: 'AC' },
                                        { id: 'non-ac', name: 'Non AC' },
                                    ]}
                                    parse={v => v && [v]}
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Customer">
                    <BooleanInput
                        source="existingCustomer"
                        label="This is for an existing Customer"
                        defaultValue={!!selectedCustomer?.id || false}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.existingCustomer && (
                                <ReferenceInput
                                    label="User"
                                    source="userId"
                                    reference="users"
                                    resource="users"
                                    defaultValue={selectedCustomer?.id}
                                >
                                    <AutocompleteInput
                                        shouldRenderSuggestions={val =>
                                            val.trim().length > 1
                                        }
                                        optionText={optionText}
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <TextInput
                        label="Reference"
                        source="ref"
                        // defaultValue={selectedCustomer?.id}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <TextInput
                                    variant="outlined"
                                    source="name"
                                    label="Customer Name"
                                    defaultValue={selectedCustomer?.name}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <TextInput
                                    variant="outlined"
                                    source="email"
                                    type="email"
                                    label="Customer Email"
                                    defaultValue={selectedCustomer?.email}
                                />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.existingCustomer && (
                                <BoxitPhoneInput
                                    source="phone"
                                    label="Customer Phone"
                                    defaultValue={selectedCustomer?.phone}
                                    country={formData.countryId}
                                    defaultCountry={currentUser?.countryId}
                                />
                            )
                        }
                    </FormDataConsumer>

                    {_.intersection(permissions, [
                        'admin',
                        'staff',
                        'global-staff',
                    ]).length > 0 && [
                        <SelectArrayInput
                            key="tags"
                            source="tags"
                            choices={[{ id: 'admin', name: 'admin' }]}
                            defaultValue={['admin']}
                            style={{ display: 'none' }}
                        />,
                        <SelectInput
                            key="channel"
                            source="channel"
                            defaultValue={selectedCustomer ? 'existing' : ''}
                            choices={[
                                { id: 'google', name: 'Google' },
                                { id: 'youtube', name: 'YouTube' },
                                { id: 'instagram', name: 'Instagram' },
                                { id: 'existing', name: 'Existing Customer' },
                                { id: 'friend', name: 'Referred by friend' },
                                { id: 'facebook', name: 'Facebook' },
                                { id: 'tv', name: 'TV' },
                                { id: 'blog', name: 'Blog' },
                                { id: 'event', name: 'Event' },
                                { id: 'flyer', name: 'Flyer' },
                                { id: 'press', name: 'Press / News' },
                                { id: 'signage', name: 'Signage' },
                                { id: 'truck', name: 'Truck' },
                                { id: 'partner', name: 'Partner' },
                                { id: 'other', name: 'Other' },
                            ]}
                        />,
                        <SelectInput
                            key="source"
                            source="source"
                            defaultValue={selectedCustomer ? 'phone' : ''}
                            choices={Object.keys(Source).map(c => ({
                                id: c,
                                name: Source[c],
                            }))}
                        />,
                        // <TextInput
                        //     key="couponId"
                        //     label="Coupon"
                        //     source="couponId"
                        // />,
                    ]}
                    <TextInput multiline source="notes" />
                </FormTab>

                <FormTab label="Address">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            (formData.type === OrderType.moving ||
                                formData.type === OrderType.shipping ||
                                formData.type === OrderType.other_services) && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Source Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId && (
                                <ReferenceInput
                                    label="Address"
                                    source="addressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                    defaultValue={mostRecentAddress?.id}
                                >
                                    <SelectInput
                                        variant="outlined"
                                        source="addressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <TextInput
                                    variant="outlined"
                                    source="line1"
                                    label="Address Line"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.addressId && (
                                <PlaceAutoComplete source="area" />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            (formData.type === OrderType.moving ||
                                formData.type === OrderType.shipping ||
                                formData.type === OrderType.other_services) && (
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-800">
                                    Destination Address
                                </h3>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !!formData.userId &&
                            (formData.type === OrderType.moving ||
                                formData.type === OrderType.shipping ||
                                formData.type === OrderType.other_services) && (
                                <ReferenceInput
                                    label="Address"
                                    source="destAddressId"
                                    reference="addresses"
                                    resource="addresses"
                                    filter={{ userId: formData.userId }}
                                >
                                    <SelectInput
                                        variant="outlined"
                                        source="destAddressId"
                                        optionText={addr =>
                                            `${addr.line1}, ${addr.area}, ${addr.cityId}`
                                        }
                                    />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId &&
                            (formData.type === OrderType.moving ||
                                formData.type === OrderType.shipping ||
                                formData.type === OrderType.other_services) && (
                                <TextInput
                                    variant="outlined"
                                    source="destLine1"
                                    label="Address Line"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            !formData.destAddressId &&
                            (formData.type === OrderType.moving ||
                                formData.type === OrderType.shipping ||
                                formData.type === OrderType.other_services) && (
                                <TextInput
                                    variant="outlined"
                                    source="destArea"
                                    label="Area"
                                    fullWidth={true}
                                    style={{ width: '256px' }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Items">
                    <FormDataConsumer>
                        {formDataProps => <ItemsInput {...formDataProps} />}
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Services">
                    <ArrayInput source="services" label="">
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="service"
                                source="servicetypeId"
                                reference="service-types"
                                resource="service-types"
                                sort={{ field: 'name', order: 'ASC' }}
                            >
                                <SelectInput source="id" label="Service" />
                            </ReferenceInput>
                            <MoneyInput source="amount" label="charge" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>

                <FormTab label="Date">
                    <RadioButtonGroupInput
                        label="Type of Operation"
                        source="pickupRequired"
                        defaultValue={true}
                        row={true}
                        choices={[
                            { id: true, name: 'Pickup' },
                            { id: false, name: 'Drop Off' },
                        ]}
                    />

                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <DateInput
                                variant="outlined"
                                label={
                                    formData.pickupRequired
                                        ? 'Pickup Date'
                                        : 'Drop Date'
                                }
                                source="date"
                                parse={v => v && moment(v).format('YYYY-MM-DD')}
                                style={{
                                    display: 'inline',
                                    float: 'left',
                                }}
                            />
                        )}
                    </FormDataConsumer>

                    <SelectInput
                        variant="outlined"
                        label="Time Slot"
                        source="pickupTimeFrom"
                        choices={[
                            {
                                id: 9,
                                name: '9 am - 1 pm',
                            },
                            {
                                id: 13,
                                name: '1 pm - 6 pm',
                            },
                        ]}
                        translateChoice={false}
                        style={{
                            marginLeft: '20px',
                        }}
                    />

                    <NumberInput
                        style={{ display: 'none' }}
                        source="pickupTimeTo"
                    />

                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={9}
                        set="pickupTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="pickupTimeFrom"
                        becomes={13}
                        set="pickupTimeTo"
                        to={18}
                    />

                    {/*
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <SelectInput
                            label="From"
                            variant="outlined"
                            source="pickupTimeFrom"
                            choices={PickupTimeFrom.map(c => ({
                                id: c,
                                name: c,
                            }))}
                            translateChoice={false}
                            fullWidth={true}
                            style={{
                                display: 'inline',
                                float: 'left',
                                marginLeft: '20px',
                                minWidth: '10px',
                                width: '100px',
                            }}
                        />
                    )}
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <SelectInput
                            label="To"
                            variant="outlined"
                            source="pickupTimeTo"
                            choices={PickupTimeTo.map(c => ({
                                id: c,
                                name: c,
                            }))}
                            translateChoice={false}
                            fullWidth={true}
                            style={{
                                display: 'inline',
                                float: 'left',
                                marginLeft: '20px',
                                minWidth: '10px',
                                width: '100px',
                            }}
                        />
                    )}
                </FormDataConsumer>
                */}

                    <div style={{ clear: 'both', width: '100%' }} />
                    {/*
                <br />
                <br />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.countryId && (
                            <BoxitSalesCalendarInput
                                source="date"
                                countryId={formData.countryId}
                            />
                        )
                    }
                </FormDataConsumer>
                <br />
                <br />
                */}

                    <FormDataConsumer>
                        {({ record, ...rest }) =>
                            hasBoxitBoxes(record) && <hr />
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ record, ...rest }) =>
                            hasBoxitBoxes(record) && (
                                <BooleanInput
                                    source="newboxRequired"
                                    label="Deliver Boxit Boxes Early"
                                    defaultValue={false}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ record, ...rest }) =>
                            hasBoxitBoxes(record) &&
                            !!record.newboxRequired && (
                                <DateInput
                                    label="Box Delivery Date"
                                    source="deliveryDate"
                                    parse={v =>
                                        v && moment(v).format('YYYY-MM-DD')
                                    }
                                />
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <SelectInput
                                    variant="outlined"
                                    label="Time Slot"
                                    source="deliveryTimeFrom"
                                    choices={[
                                        {
                                            id: 9,
                                            name: '9 am - 1 pm',
                                        },
                                        {
                                            id: 13,
                                            name: '1 pm - 6 pm',
                                        },
                                    ]}
                                    translateChoice={false}
                                    style={{
                                        marginLeft: '20px',
                                    }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            hasBoxitBoxes(formData) &&
                            !!formData.newboxRequired && (
                                <NumberInput
                                    style={{ display: 'none' }}
                                    source="deliveryTimeTo"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={9}
                        set="deliveryTimeTo"
                        to={13}
                    />
                    <WhenFieldChanges
                        field="deliveryTimeFrom"
                        becomes={13}
                        set="deliveryTimeTo"
                        to={18}
                    />
                </FormTab>

                <FormTab label="Checklist">
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <SelectInput
                                    source="checklist.buildingType"
                                    label="Type of Building"
                                    style={{ width: 300 }}
                                    choices={[
                                        { id: 'Flat', name: 'Flat' },
                                        { id: 'Villa', name: 'Villa' },
                                        { id: 'Hotel', name: 'Hotel' },
                                        { id: 'Office', name: 'Office' },
                                        { id: 'Other', name: 'Other' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is entry permit required"
                                    source="checklist.permitRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is moving insurance required"
                                    source="checklist.movingInsuranceRequired"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is parking slot available"
                                    source="checklist.parkingSlotAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.parkingSlotAvailable && (
                                <RadioButtonGroupInput
                                    label="Is parking slot inside or outside the building"
                                    source="checklist.isSlotInsideOrOutside"
                                    row={true}
                                    fullWidth={true}
                                    choices={[
                                        {
                                            id: 'Inside',
                                            name: 'Inside',
                                        },
                                        {
                                            id: 'Outside',
                                            name: 'Outside',
                                        },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            formData.checklist.parkingSlotAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLoadingTime"
                                    label="Maximum allowed Loading time"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <TextInput
                                    source="checklist.buildingFloor"
                                    label="Building Floor"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="Is lift available"
                                    source="checklist.liftAvailable"
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <SelectInput
                                    source="checklist.maximumAllowedLiftTime"
                                    label="Maximum allowed time for Lift"
                                    style={{ width: 300 }}
                                    choices={[
                                        {
                                            id: 'Unlimitted',
                                            name: 'Unlimitted',
                                        },
                                        { id: '1 Hr', name: '1 Hr' },
                                        { id: '2 Hr', name: '2 Hr' },
                                        { id: '3 Hr', name: '3 Hr' },
                                        { id: '4 Hr', name: '4 Hr' },
                                        { id: '5 Hr', name: '5 Hr' },
                                        { id: '6 Hr', name: '6 Hr' },
                                        { id: '7 Hr', name: '7 Hr' },
                                        { id: '8 Hr', name: '8 Hr' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.liftAvailable && (
                                <RadioButtonGroupInput
                                    label="Are there items that would not fit inside the lift"
                                    source="checklist.anyItemsNotFitinsideLift"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.anyItemsNotFitinsideLift && (
                                <TextInput
                                    multiline
                                    style={{ width: 300 }}
                                    label="If yes details"
                                    source="checklist.liftNotFitDetails"
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired && (
                                <RadioButtonGroupInput
                                    label="If customer wants to authorize someone"
                                    source="checklist.authorizeOtherPerson"
                                    fullWidth={true}
                                    row={true}
                                    choices={[
                                        { id: true, name: 'Yes' },
                                        { id: false, name: 'No' },
                                    ]}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person Name"
                                    source="checklist.authorizedName"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <BoxitPhoneInput
                                    source="checklist.authorizedPhone"
                                    label="Authorized Person Phone"
                                    style={{ width: 300 }}
                                    country={formData.countryId}
                                    defaultCountry={currentUser?.countryId}
                                />
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            formData.pickupRequired &&
                            !!formData.checklist &&
                            !!formData.checklist.authorizeOtherPerson && (
                                <TextInput
                                    label="Authorized Person ID Number"
                                    source="checklist.authorizedID"
                                    style={{ width: 300 }}
                                />
                            )
                        }
                    </FormDataConsumer>
                </FormTab>

                <FormTab label="Billing">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (
                            <BillingCard record={formData} />
                        )}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

const mapStateToProps = (state: AppState, props) => {
    // const invoiceId = props.data && props.data.invoiceId;
    // console.log(state);
    return state;
};
const connector = connect(mapStateToProps);

// export default OrderCreate;

export default connector(OrderCreate);
