import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import {
    ChipField,
    DateField,
    ReferenceArrayField,
    SingleFieldList,
} from 'react-admin';
import Money from '../../components/Money';
import UserReferenceField from '../../components/UserReferenceField';
import {
    makeStyles,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Chip,
    Paper,
} from '@material-ui/core';

const useStyles = makeStyles({
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

export const EmailPlaceholdertSummary = props => {
    const { record } = props;
    const classes = useStyles();

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Placeholder name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Placeholder description</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.description}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Emails</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.emails
                                    ? record.emails.map(email => (
                                          <span>{email}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Is generic</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.isGeneric ? 'True' : 'False'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.countryId === 1
                                ? 'UAE'
                                : record.countryId === 2
                                ? 'Kuwait'
                                : 'KSA'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

EmailPlaceholdertSummary.propTypes = {
    record: PropTypes.object,
};
