import * as React from 'react';

function SvgSettings(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 20.89a4 4 0 118 0 4 4 0 01-8 0zm4-2a2 2 0 100 4 2 2 0 000-4z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10.89a1 1 0 00-1 1v.175a2.65 2.65 0 01-1.606 2.425c-.085.036-.173.06-.264.072a2.65 2.65 0 01-2.73-.606l-.007-.008-.06-.06a1.002 1.002 0 00-1.415 0h-.001a1 1 0 000 1.415l.068.068c.75.767.963 1.91.543 2.894a2.65 2.65 0 01-2.415 1.705H11a1 1 0 100 2h.174a2.65 2.65 0 012.423 1.602 2.65 2.65 0 01-.532 2.918l-.008.008-.06.06a1.001 1.001 0 000 1.415 1 1 0 001.415 0l.069-.067a2.65 2.65 0 012.894-.543 2.65 2.65 0 011.705 2.414V29.891a1 1 0 102 0V29.717a2.65 2.65 0 011.601-2.423 2.65 2.65 0 012.918.532l.008.008.06.06a1.001 1.001 0 001.415 0h.001a1 1 0 000-1.416l-.068-.068a2.65 2.65 0 01-.532-2.918 2.65 2.65 0 012.423-1.601H29a1 1 0 100-2H28.826a2.65 2.65 0 01-2.425-1.606.994.994 0 01-.073-.265 2.65 2.65 0 01.607-2.729l.008-.008.06-.06a1.001 1.001 0 000-1.415 1 1 0 00-1.416 0l-.068.068a2.65 2.65 0 01-2.918.532A2.65 2.65 0 0121 11.985v-.094a1 1 0 00-1-1zm-3 1a3 3 0 016 0v.088a.65.65 0 00.394.594l.01.004a.65.65 0 00.714-.127l.055-.055a3 3 0 114.244 4.244l-.055.055a.65.65 0 00-.127.714c.028.064.05.13.064.199a.65.65 0 00.534.285H29a3 3 0 010 6h-.087a.65.65 0 00-.594.394l-.004.01a.65.65 0 00.127.713l.055.055a3.001 3.001 0 11-4.244 4.245l-.055-.055a.65.65 0 00-.714-.128l-.01.005a.65.65 0 00-.394.593v.168a3 3 0 11-6 0v-.076a.65.65 0 00-.425-.586 1.025 1.025 0 01-.059-.023.65.65 0 00-.714.127l-.054.054a3.001 3.001 0 11-4.245-4.244l.055-.055a.65.65 0 00.127-.714l-.004-.01a.65.65 0 00-.593-.393H11a3 3 0 110-6h.076a.65.65 0 00.585-.426.953.953 0 01.024-.058.65.65 0 00-.127-.714l-.055-.055a3 3 0 114.245-4.244l.054.055a.65.65 0 00.714.127c.064-.029.131-.05.2-.064a.65.65 0 00.284-.534v-.167z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgSettings;
