import * as React from 'react';

function SvgMapPin(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10a8 8 0 00-8 8c0 3.098 2.016 6.104 4.226 8.437A29.412 29.412 0 0020 29.773a29.412 29.412 0 003.774-3.335C25.984 24.103 28 21.097 28 18a8 8 0 00-8-8zm0 21l-.555.832-.003-.002-.007-.005-.023-.015a28.532 28.532 0 01-1.441-1.074 31.433 31.433 0 01-3.197-2.923C12.484 25.396 10 21.902 10 18c0-5.523 4.477-10 10-10s10 4.477 10 10c0 3.902-2.484 7.396-4.774 9.813a31.433 31.433 0 01-4.254 3.726 18.887 18.887 0 01-.384.27l-.023.016-.007.005-.002.001s-.001.001-.556-.831zm0 0l.555.832a1 1 0 01-1.11 0L20 31zm-4-13a4 4 0 118 0 4 4 0 01-8 0zm4-2a2 2 0 100 4 2 2 0 000-4z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgMapPin;
