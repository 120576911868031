import { CREATE, UPDATE, GET_ONE } from 'react-admin';
import { OrderStatus, Caption } from '../../constants';

export const ORDER_CONFIRM = 'ORDER_CONFIRM';
export const ORDER_CONFIRM_LOADING = 'ORDER_CONFIRM_LOADING';
export const ORDER_CONFIRM_FAILURE = 'ORDER_CONFIRM_FAILURE';
export const ORDER_CONFIRM_SUCCESS = 'ORDER_CONFIRM_SUCCESS';

export const orderConfirm = (id, data) => ({
    type: ORDER_CONFIRM,
    payload: { id, data: { ...data, status: OrderStatus.confirmed } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_REJECT = 'ORDER_REJECT';
export const ORDER_REJECT_LOADING = 'ORDER_REJECT_LOADING';
export const ORDER_REJECT_FAILURE = 'ORDER_REJECT_FAILURE';
export const ORDER_REJECT_SUCCESS = 'ORDER_REJECT_SUCCESS';

export const orderReject = (id, data) => ({
    type: ORDER_REJECT,
    payload: { id, data: { ...data, status: OrderStatus.rejected } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_ONHOLD = 'ORDER_ONHOLD';
export const ORDER_ONHOLD_LOADING = 'ORDER_ONHOLD_LOADING';
export const ORDER_ONHOLD_FAILURE = 'ORDER_ONHOLD_FAILURE';
export const ORDER_ONHOLD_SUCCESS = 'ORDER_ONHOLD_SUCCESS';

export const orderOnHold = (id, data) => ({
    type: ORDER_ONHOLD,
    payload: { id, data: { ...data, status: OrderStatus.onhold } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_CANCEL = 'ORDER_CANCEL';
export const ORDER_CANCEL_LOADING = 'ORDER_CANCEL_LOADING';
export const ORDER_CANCEL_FAILURE = 'ORDER_CANCEL_FAILURE';
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS';

export const orderCancel = (id, data) => ({
    type: ORDER_CANCEL,
    payload: { id, data: { ...data, status: OrderStatus.cancelled } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_SYNC = 'ORDER_SYNC';
export const ORDER_SYNC_LOADING = 'ORDER_SYNC_LOADING';
export const ORDER_SYNC_FAILURE = 'ORDER_SYNC_FAILURE';
export const ORDER_SYNC_SUCCESS = 'ORDER_SYNC_SUCCESS';

export const orderSync = (id, data) => ({
    type: ORDER_SYNC,
    payload: { id, data: { ...data, action: 'SYNC_ORDER' } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_COMPLETE = 'ORDER_COMPLETE';
export const ORDER_COMPLETE_LOADING = 'ORDER_COMPLETE_LOADING';
export const ORDER_COMPLETE_FAILURE = 'ORDER_COMPLETE_FAILURE';
export const ORDER_COMPLETE_SUCCESS = 'ORDER_COMPLETE_SUCCESS';

export const orderComplete = (id, data) => ({
    type: ORDER_COMPLETE,
    payload: { id, data: { ...data, status: OrderStatus.complete } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_UPDATE_ITEMS = 'ORDER_UPDATE_ITEMS';
export const ORDER_UPDATE_ITEMS_LOADING = 'ORDER_UPDATE_ITEMS_LOADING';
export const ORDER_UPDATE_ITEMS_FAILURE = 'ORDER_UPDATE_ITEMS_FAILURE';
export const ORDER_UPDATE_ITEMS_SUCCESS = 'ORDER_UPDATE_ITEMS_SUCCESS';

export const orderUpdateItems = (id, data, items) => ({
    type: ORDER_UPDATE_ITEMS,
    payload: { id, data: { ...data, items }, previousData: data },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_CREATE_INVOICE = 'ORDER_CREATE_INVOICE';
export const ORDER_CREATE_INVOICE_LOADING = 'ORDER_CREATE_INVOICE_LOADING';
export const ORDER_CREATE_INVOICE_FAILURE = 'ORDER_CREATE_INVOICE_FAILURE';
export const ORDER_CREATE_INVOICE_SUCCESS = 'ORDER_CREATE_INVOICE_SUCCESS';

export const orderCreateInvoice = (id, data) => ({
    type: ORDER_CREATE_INVOICE,
    payload: { id, data: { ...data, action: 'CREATE_INVOICE' } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});

export const ORDER_ADD_PHOTO = 'ORDER_ADD_PHOTO';
export const ORDER_ADD_PHOTO_LOADING = 'ORDER_ADD_PHOTO_LOADING';
export const ORDER_ADD_PHOTO_FAILURE = 'ORDER_ADD_PHOTO_FAILURE';
export const ORDER_ADD_PHOTO_SUCCESS = 'ORDER_ADD_PHOTO_SUCCESS';

export const orderAddPhoto = (userId, orderId, file, caption) => ({
    type: ORDER_ADD_PHOTO,
    payload: { data: { userId, orderId, file, caption } },
    meta: { resource: 'user-photos', fetch: CREATE, cancelPrevious: false },
});

export const ORDER_SEND = 'ORDER_SEND';
export const ORDER_SEND_LOADING = 'ORDER_SEND_LOADING';
export const ORDER_SEND_FAILURE = 'ORDER_SEND_FAILURE';
export const ORDER_SEND_SUCCESS = 'ORDER_SEND_SUCCESS';

export const orderSend = (id, data) => ({
    type: 'ORDER_SEND',
    payload: {
        id,
        data: {
            ...data,
            status: OrderStatus.quote_sent,
            quoteSentAt: new Date(),
            quoteVersionSent: data.quoteVersion,
        },
    },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});
