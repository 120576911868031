import React from 'react';
import { Show } from 'react-admin';
import { PartnerTabs } from './components/PartnerTabs';
import { ShowProps, TitleProps, Partner } from '../types';

const PartnerTitle: React.FC<TitleProps<Partner>> = ({ record }) => (
    <span>{record!.name ? `${record!.name}` : `Partner #${record!.id}`}</span>
);

const PartnerShow: React.FC<ShowProps<Partner>> = props => (
    <Show title={<PartnerTitle />} {...props}>
        <PartnerTabs />
    </Show>
);

export default PartnerShow;
