import * as React from 'react';

function SvgStar(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 9a1 1 0 01.897.557l2.857 5.79 6.39.934a1 1 0 01.554 1.705l-4.623 4.503 1.09 6.362a1 1 0 01-1.45 1.054L20 26.9l-5.714 3.005a1 1 0 01-1.452-1.054l1.091-6.362-4.623-4.503a1 1 0 01.553-1.706l6.39-.934 2.858-5.789A1 1 0 0120 9zm0 3.26l-2.193 4.443a1 1 0 01-.752.547l-4.907.717 3.55 3.457a1 1 0 01.288.885l-.838 4.883 4.387-2.307a1 1 0 01.93 0l4.387 2.307-.838-4.883a1 1 0 01.288-.885l3.55-3.457-4.907-.717a1 1 0 01-.752-.547L20 12.259z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgStar;
