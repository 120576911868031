import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Filter,
    TextInput,
    DateField,
    EditButton,
    ReferenceField,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';

const StaffFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
    </Filter>
);

const Roles = ({ record }: any) => {
    const roles = record.roles;
    return (
        roles && (
            <ul>
                {roles.sort().map(r => (
                    <li key={r}>{r}</li>
                ))}
            </ul>
        )
    );
};

const StaffList = props => (
    <List
        title="Staffs"
        bulkActionButtons={false}
        filters={<StaffFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" label="UserId" />
            <DateField
                locales="en-IN"
                source="createdAt"
                label="Created"
                showTime
            />
            <ReferenceField
                label="User"
                source="id"
                reference="users"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <Roles />
            <EditButton color="secondary" style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

StaffList.defaultProps = {
    resource: 'orders',
    location: { search: '' },
};

export default StaffList;
