import React from 'react';
import moment from 'moment';
import { useGetOne } from 'react-admin';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { userBill as userBillAction } from '../actions/userActions';
import { Button, TextField, Paper, CircularProgress } from '@material-ui/core';
import { User, Usage } from '../../types';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';
import { Card } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        margin: 30,
    },
    card: { flex: 1 },
}));

type Styles = {
    container: string;
    card: string;
    actions: string;
    iconPaddingStyle: string;
};

type Props = {
    classes: Styles;
    saving: boolean;
    record: User;
    invalid: boolean;
    showNotification: (arg0: string, arg1: string) => void;
};

export default props => {
    const { record } = props;
    const classes = useStyles();
    const { data: userUsage, loaded } = useGetOne('user-usages', record.id, {});
    const usages: Usage[] = loaded && userUsage ? userUsage.data : [];
    const data = usages.reduce((acc: any[], item: Usage) => {
        if (item) {
            let index = acc.findIndex(a => a && a.date === item.date);

            if (index === -1) {
                acc.push({
                    date: item.date,
                });
            }

            index = acc.findIndex(a => a && a.date === item.date);

            acc[index] = {
                ...acc[index],
                //...(item.type === 'qty' && { qty: item.value }),
                ...(item.type === 'volume' && { volume: item.value }),
            };
        }

        return acc;
    }, []);

    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <LineChart
                    width={1200}
                    height={400}
                    data={data}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        tickFormatter={tick => {
                            return moment(tick).format('D');
                        }}
                    />
                    <YAxis type="number" scale="auto" />
                    <Tooltip />
                    <Legend />
                    <Line
                        type="monotone"
                        dataKey="volume"
                        stroke="#82ca9d"
                        name="CBM"
                    />
                </LineChart>
            </Card>
        </div>
    );
};
