import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    LEAD_CONFIRM_SUCCESS,
    LEAD_CONFIRM_FAILURE,
    LEAD_REJECT_SUCCESS,
    LEAD_REJECT_FAILURE,
    LEAD_ONHOLD_SUCCESS,
    LEAD_ONHOLD_FAILURE,
    LEAD_FOLLOW_UP_SUCCESS,
    LEAD_REJECT_QUOTE_SUCCESS,
} from './LeadActions';

export default function* leadSaga() {
    yield all([
        takeEvery(LEAD_CONFIRM_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.leads.notification.confirmed_success'
                )
            );
            window.location.reload();
        }),
        takeEvery(LEAD_CONFIRM_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.leads.notification.confirmed_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(LEAD_REJECT_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.leads.notification.rejected_success'
                )
            );
        }),
        takeEvery(LEAD_REJECT_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.leads.notification.rejected_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(LEAD_ONHOLD_SUCCESS, function* () {
            yield put(
                showNotification('resources.leads.notification.onhold_success')
            );
        }),
        takeEvery(LEAD_FOLLOW_UP_SUCCESS, function* () {
            yield put(showNotification('Follow up success'));
        }),
        takeEvery(LEAD_ONHOLD_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.leads.notification.onhold_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(LEAD_REJECT_QUOTE_SUCCESS, function* () {
            yield put(showNotification('Quote reject success'));
        }),
    ]);
}
