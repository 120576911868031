import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core';

const TeamSummary = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Team Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.countryId}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Members</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceArrayField
                                record={record}
                                label="Members"
                                reference="users"
                                source="users"
                                basePath="/users"
                                resource="users"
                            >
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Active</label>
                        </TableCell>
                        {record && record.active && (
                            <TableCell style={{ textAlign: 'right' }}>
                                Yes
                            </TableCell>
                        )}
                        {record && !record.active && (
                            <TableCell style={{ textAlign: 'right' }}>
                                No
                            </TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

TeamSummary.propTypes = {
    record: PropTypes.object,
};

export default TeamSummary;
