import React from 'react';
import moment from 'moment';

import {
    Create,
    SelectInput,
    SimpleForm,
    ReferenceInput,
    DateInput,
    TextInput,
    FormDataConsumer,
} from 'react-admin';
import { PickupTimeFrom, PickupTimeTo } from '../constants';
import { FunctionField } from 'react-admin';

const BlockedSlotCreate = props => (
    <Create {...props} title="Block Slot">
        <SimpleForm variant="outlined">
            <TextInput
                style={{ display: 'none' }}
                source="partnerId"
                defaultValue="1"
            />

            {/*<DateInput
                source="date"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />*/}

            {/*<SelectInput
                source="from"
                choices={PickupTimeFrom.map(c => ({ id: c, name: c }))}
            />

            <SelectInput
                source="to"
                choices={PickupTimeTo.map(c => ({ id: c, name: c }))}
            />*/}

            <DateInput
                variant="outlined"
                source="from"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
            <DateInput
                variant="outlined"
                source="to"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
            <ReferenceInput
                source="countryId"
                reference="country"
                resource="country"
                label="Country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/*<FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.countryId === 1) {
                        return (
                            <ReferenceInput
                                label="City"
                                source="cityId"
                                reference="city"
                                resource="city"
                                filter={{ countryId: 1 }}
                                allowEmpty
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        );
                    } else if (formData.countryId === 2) {
                        return (
                            <ReferenceInput
                                label="City"
                                source="cityId"
                                reference="city"
                                resource="city"
                                filter={{ countryId: 2 }}
                                allowEmpty
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        );
                    }
                }}
            </FormDataConsumer>*/}

            {/*<FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.cityId === 'kuwait') {
                        return (
                            <TextInput
                                style={{ display: 'none' }}
                                source="countryId"
                                defaultValue="kuwait"
                            />
                        );
                    } else if (formData.cityId === 'uae') {
                        return (
                            <TextInput
                                style={{ display: 'none' }}
                                source="countryId"
                                defaultValue="uae"
                            />
                        );
                    }
                    return null;
                }}
            </FormDataConsumer>*/}
        </SimpleForm>
    </Create>
);

export default BlockedSlotCreate;
