import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import Sidebar from './Sidebar';
import { darkTheme, lightTheme } from './theme';
import { AppState } from '../types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    content: {
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        //paddingTop: '100px',
        //paddingLeft: '30px',
    },
}));

export default props => {
    const classes = useStyles();
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );

    return (
        <Layout
            {...props}
            classes={classes}
            appBar={AppBar}
            sidebar={Sidebar}
            menu={Menu}
            theme={theme}
        />
    );
};
