// in LatLongInput.js
import { useInput } from 'react-admin';
import React, { useEffect, useRef } from 'react';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/ext-language_tools';
import Beautify from 'ace-builds/src-noconflict/ext-beautify';

const AceCodeInput = props => {
    const editorRef = useRef<any>();
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);

    useEffect(() => {
        Beautify.beautify(editorRef.current.editor.session);
    }, []);

    return (
        <AceEditor
            mode="html"
            theme="chrome"
            ref={editorRef}
            onChange={val => {
                onChange(val);
            }}
            highlightActiveLine={true}
            editorProps={{ $blockScrolling: true }}
            showPrintMargin={true}
            showGutter={true}
            defaultValue={props.record.body}
            commands={Beautify.commands}
            style={{ width: '100%', minHeight: '20rem' }}
            enableLiveAutocompletion={true}
            fontSize={'16px'}
        />
    );
};

export default AceCodeInput;
