import React from 'react';
import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
} from 'react-admin';
import { TeamTitle } from './components/TeamTitle';

const TeamEdit = props => (
    <Edit title={<TeamTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined" redirect="show">
            <TextInput disabled source="id" />
            <TextInput disabled source="partnerId" />
            <TextInput source="name" />
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceArrayInput
                label="Members"
                reference="users"
                source="users"
            >
                <AutocompleteArrayInput />
            </ReferenceArrayInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Edit>
);

export default TeamEdit;
