import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
    BooleanInput,
    FormDataConsumer,
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    SaveButton,
    DeleteButton,
    Toolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { StorageStatus } from '../constants';
import { EditProps, TitleProps, Storage, AppState } from '../types';
import feathersClient from '../rest/feathersClient';
import { Switch } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useRedirect } from 'react-admin';

const useStyles = makeStyles({
    spaceBetween: { justifyContent: 'space-between' },
});

const getTitle = record => {
    if (record && record.code) {
        return record.code;
    } else if (record) {
        return record.id;
    }
    return '';
};

const StorageTitle: React.FC<TitleProps<Storage>> = ({ record }) => (
    <span>Edit storage {`"${getTitle(record)}"`}</span>
);

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const StorageEditToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const classes = useStyles();
    const { permissions, record } = props;

    const onSuccess = data => {
        if ('0' in data.data) {
            notify(`Storage "${data.data['0'].id}" saved!`);
            redirect(`/storages/${data.data['0'].id}/show`);
        } else {
            notify(`Storage "${data.data.id}" saved!`);
            redirect(`/storages/${data.data.id}/show`);
        }
    };

    return (
        <Toolbar {...props} className={classes.spaceBetween}>
            <SaveButton disabled={props.pristine} onSuccess={onSuccess} />
            {((permissions && permissions.indexOf('admin') !== -1) ||
                record.status == 'new') && <DeleteButton />}
        </Toolbar>
    );
};

const cbmValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const eightDigitsValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 8
    ) {
        return 'Only 8 digits after the decimal points are allowed';
    }
    return undefined;
};

const threeDigitsValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const cbmActualValidation = (value, allValues) => {
    if (allValues.height && allValues.width && allValues.length) {
        if (value !== allValues.height * allValues.width * allValues.length) {
            return 'Size is not equal to width x height x width';
        }
    }
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const StorageEdit: React.FC<EditProps<Storage>> = ({
    permissions = [],
    ...props
}) => {
    const [currentStorageTypeId, setCurrentStorageTypeId] = useState<any>(null);
    const [currentStorageTypeRecord, setCurrentStorageTypeRecord] = useState<
        any
    >(null);
    const currentStorageTypeRecordRef = useRef<any>(null);
    const isSyncConsumedWithActual = useRef<boolean>(false);
    const [isDisableConsumed, setIsDisableConsumed] = useState<boolean>(false);

    const getStorageTypes = () => {
        const storageTypesService = feathersClient.service('storage-types');
        storageTypesService
            .find({
                query: { id: currentStorageTypeId },
            })
            .then(res => {
                console.log(res.data[0]);
                setCurrentStorageTypeRecord(res.data[0]);
                currentStorageTypeRecordRef.current = res.data[0];
            })
            .catch(err => {});
    };

    useEffect(() => {
        if (currentStorageTypeId) {
            getStorageTypes();
        }
    }, [currentStorageTypeId]);

    /*useEffect(() => {
        if (currentStorageTypeRecord) {
            localStorage.setItem(
                'isTracking',
                currentStorageTypeRecord.tracking ? 'yes' : 'no'
            );
            localStorage.setItem('typeSize', currentStorageTypeRecord.size);
            localStorage.setItem('typeLength', currentStorageTypeRecord.length);
            localStorage.setItem('typeHeight', currentStorageTypeRecord.height);
            localStorage.setItem('typeWidth', currentStorageTypeRecord.width);
        } else {
            localStorage.removeItem('isTracking');
            localStorage.removeItem('typeSize');
            localStorage.removeItem('typeLength');
            localStorage.removeItem('typeHeight');
            localStorage.removeItem('typeWidth');
        }
    }, [currentStorageTypeRecord]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('isTracking');
            localStorage.removeItem('typeSize');
            localStorage.removeItem('typeLength');
            localStorage.removeItem('typeHeight');
            localStorage.removeItem('typeWidth');
        };
    }, []);

    const transform = data => {
        console.log(currentStorageTypeRecord);
        return {
            ...data,
            sizeEntered:
                localStorage.getItem('tracking') === 'no'
                    ? localStorage.getItem('typeSize')
                    : data.sizeEntered,
            consumedSize:
                localStorage.getItem('tracking') === 'no'
                    ? localStorage.getItem('typeSize')
                    : data.consumedSize,
            length:
                localStorage.getItem('tracking') === 'no'
                    ? localStorage.getItem('typeLength')
                    : data.length,
            height:
                localStorage.getItem('tracking') === 'no'
                    ? localStorage.getItem('typeHeight')
                    : data.height,
            width:
                localStorage.getItem('tracking') === 'no'
                    ? localStorage.getItem('typeWidth')
                    : data.width,
        };
    };*/

    const transform = data => {
        const transformData = {
            ...data,
        };

        transformData.consumedSize = isSyncConsumedWithActual.current
            ? data.sizeEntered
            : data.consumedSize;

        if (currentStorageTypeRecord && currentStorageTypeRecord.inventory) {
            transformData.sizeEntered =
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.size
                    : data.sizeEntered;

            transformData.consumedSize =
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.size
                    : isSyncConsumedWithActual.current
                    ? data.sizeEntered
                    : data.consumedSize;

            transformData.length =
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.length
                    : data.length;

            transformData.height =
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.height
                    : data.height;

            transformData.width =
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.width
                    : data.width;
        } /* else {
            delete transformData.sizeEntered;
            delete transformData.consumedSize;
            delete transformData.length;
            delete transformData.height;
            delete transformData.width;
        }*/
        /*return {
            ...data,
            sizeEntered:
                currentStorageTypeRecordRef.current &&
                !currentStorageTypeRecordRef.current.tracking
                    ? currentStorageTypeRecordRef.current.size
                    : data.sizeEntered,
            consumedSize:
                currentStorageTypeRecordRef.current &&
                !currentStorageTypeRecordRef.current.tracking
                    ? currentStorageTypeRecordRef.current.size
                    : data.consumedSize,
            length:
                currentStorageTypeRecordRef.current &&
                !currentStorageTypeRecordRef.current.tracking
                    ? currentStorageTypeRecordRef.current.length
                    : data.length,
            height:
                currentStorageTypeRecordRef.current &&
                !currentStorageTypeRecordRef.current.tracking
                    ? currentStorageTypeRecordRef.current.height
                    : data.height,
            width:
                currentStorageTypeRecordRef.current &&
                !currentStorageTypeRecordRef.current.tracking
                    ? currentStorageTypeRecordRef.current.width
                    : data.width,
        };*/

        return transformData;
    };

    return (
        <Edit
            title={<StorageTitle />}
            undoable={false}
            successMessage="resources.storages.notification.update_success"
            transform={transform}
            {...props}
        >
            <SimpleForm
                {...sanitizeRestProps(props)}
                variant="outlined"
                redirect="show"
                toolbar={
                    <StorageEditToolbar permissions={permissions} {...props} />
                }
            >
                <TextInput disabled source="id" />

                {permissions.indexOf('staff') !== -1 && (
                    <ReferenceInput
                        label="Partner"
                        source="partnerId"
                        reference="partners"
                        resource="partners"
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}

                <ReferenceInput
                    label="User"
                    source="userId"
                    resource="users"
                    reference="users"
                    allowEmpty
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                    />
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Order"
                                source="orderId"
                                reference="orders"
                                resource="orders"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                            >
                                <SelectInput
                                    variant="outlined"
                                    source="orderId"
                                    optionValue="id"
                                    optionText={record => `Order #${record.id}`}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default length value for storage type:{' '}
                        {currentStorageTypeRecord.length}
                    </span>
                )}
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default height value for storage type:{' '}
                        {currentStorageTypeRecord.height}
                    </span>
                )}
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default width value for storage type:{' '}
                        {currentStorageTypeRecord.width}
                    </span>
                )}
                <ReferenceInput
                    label="StorageType"
                    source="storagetypeId"
                    reference="storage-types"
                    resource="storage-types"
                    sort={{ field: 'name', order: 'ASC' }}
                    allowEmpty
                    validation={{ required: true }}
                    format={val => {
                        setCurrentStorageTypeId(val);
                        return val;
                    }}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText="name"
                    />
                </ReferenceInput>
                <BooleanInput
                    label="Packed by Customer"
                    source="customerPacked"
                />
                <BooleanInput label="Damaged" source="damage" />
                <BooleanInput label="This is a Part" source="isPart" />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isPart && (
                            <ReferenceInput
                                label="Parent"
                                source="parentId"
                                reference="storages"
                                resource="storages"
                                filter={{
                                    userId: formData.userId,
                                    isPart: false,
                                }}
                                allowEmpty
                            >
                                <SelectInput
                                    source="parentId"
                                    optionValue="id"
                                    optionText={record => `${record.code}`}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <TextInput source="code" parse={v => (v === '' ? null : v)} />

                <NumberInput
                    source="sizeEntered"
                    label="Actual CBM Size (test)"
                    validate={eightDigitsValidation}
                    disabled={
                        currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false
                    }
                />

                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Switch
                            onChange={value => {
                                isSyncConsumedWithActual.current = !isSyncConsumedWithActual.current;
                                setIsDisableConsumed(prev => !prev);
                            }}
                            value={isSyncConsumedWithActual.current}
                        />
                        <h3>
                            synchronize the consumed size with the actual size
                        </h3>
                    </div>
                ) : null}

                <NumberInput
                    source="consumedSize"
                    label="Consumed Size (test)"
                    validate={eightDigitsValidation}
                    disabled={
                        isDisableConsumed ||
                        (currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false)
                    }
                />

                <NumberInput
                    source="length"
                    label="Length"
                    validate={threeDigitsValidation}
                    disabled={
                        currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false
                    }
                />

                <NumberInput
                    source="height"
                    label="Height"
                    validate={threeDigitsValidation}
                    disabled={
                        currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false
                    }
                />

                <NumberInput
                    source="width"
                    label="Width"
                    validate={threeDigitsValidation}
                    disabled={
                        currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false
                    }
                />

                <NumberInput
                    source="size"
                    label="Size in CBM"
                    validate={eightDigitsValidation}
                    disabled={
                        currentStorageTypeRecord
                            ? currentStorageTypeRecord.inventory
                            : false
                    }
                />

                <TextInput source="name" />
                <TextInput source="description" multiline />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Room"
                                source="roomId"
                                reference="rooms"
                                resource="rooms"
                                sort={{ field: 'name', order: 'ASC' }}
                                allowEmpty
                            >
                                <SelectInput
                                    variant="outlined"
                                    source="roomId"
                                    optionValue="id"
                                    optionText="name"
                                    sort={{ field: 'name', order: 'ASC' }}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <DateInput
                    source="from"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />
                <DateInput
                    source="to"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />
                <SelectInput
                    source="status"
                    choices={Object.keys(StorageStatus).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />
            </SimpleForm>
        </Edit>
    );
};

export default StorageEdit;
