import React, { FC } from 'react';
import Analytics from './Analytics';

const WarehouseAnalytics: FC = () => {
    return (
        <Analytics
            title={'Warehouse'}
            items={[
                {
                    title: 'Total Count',
                    data: [
                        {
                            title: 'Total Count',
                            key: 'warehouse.qty_total',
                        },
                    ],
                },
                {
                    title: 'Active Count',
                    data: [
                        {
                            title: 'Active Count',
                            key: 'warehouse.qty_active',
                        },
                    ],
                },
                {
                    title: 'Total Area',
                    data: [
                        {
                            title: 'Total Area',
                            key: 'warehouse.total_area',
                            unit: 'SQM',
                        },
                    ],
                },
            ]}
        />
    );
};

export default WarehouseAnalytics;
