import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { crudGetMany as crudGetManyAction } from 'react-admin';
import { Address } from '../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    id: number;
    address: Address;
};

class AddressType extends Component<Props> {
    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const { crudGetMany, id } = this.props;
        crudGetMany('addresses', [id]);
    }

    render() {
        const { address, city } = this.props;

        return (
            <div>
                <div className="clearfix" />
                {address && (
                    <p>
                        {address.line1}
                        <br />
                        {address.area}
                        <br />
                        {city && city.name}
                    </p>
                )}
            </div>
        );
    }
}

const mapState = (state, ownProps) => {
    const { id } = ownProps;
    const address =
        state.admin.resources.addresses &&
        state.admin.resources.addresses.data[id];
    const city =
        address &&
        state.admin.resources.city &&
        state.admin.resources.city.data[address.cityId];

    return {
        address,
        city,
    };
};

const mapDispatch = {
    crudGetMany: crudGetManyAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(AddressType);
