import React from 'react';
import { useFormState } from 'react-final-form';
import { ReferenceInput, SelectInput } from 'react-admin';

export default props => {
    const { values } = useFormState();
    return (
        <ReferenceInput
            variant="outlined"
            label="Country"
            source="countryId"
            reference="country"
            resource="country"
            fullWidth={true}
            style={{ width: '256px' }}
            {...(props.defaultValue
                ? {
                      defaultValue: props.defaultValue,
                  }
                : {})}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    );
};
