import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SimpleForm, TextInput } from 'react-admin';
import { requestCancel as requestCancelAction } from '../actions/requestActions';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { Request, ValidationErrors } from '../../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    record: Request;
    onClose: () => void;
};

type State = {
    comment: string;
};

const validate = values => {
    const errors: ValidationErrors = {};
    if (values.comment == '' || values.comment == null) {
        errors.comment = ['Reason is required'];
    }
    return errors;
};

class RequestCancel extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
        };
    }

    render() {
        const { record, requestCancel, open, onClose } = this.props;

        return (
            <Dialog open={!!open} onClose={onClose}>
                <DialogTitle>Cancel Request</DialogTitle>
                <SimpleForm toolbar={null} validate={validate}>
                    <TextInput
                        multiline
                        source="comment"
                        label="Reason"
                        onChange={e =>
                            this.setState({ comment: e.target.value })
                        }
                    />
                    <DialogActions>
                        <Button onClick={onClose}>Close</Button>
                        <Button
                            disabled={!this.state.comment}
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                requestCancel(record.id, {
                                    ...record,
                                    comment: this.state.comment,
                                });
                                validate({ comment: this.state.comment });
                                onClose();
                            }}
                        >
                            Cancel Request
                        </Button>
                    </DialogActions>
                </SimpleForm>
            </Dialog>
        );
    }
}

const mapDispatch = {
    requestCancel: requestCancelAction,
};

const connector = connect(null, mapDispatch);

export default connector(RequestCancel);
