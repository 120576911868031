import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
    Datagrid,
    TextField,
    DateField,
    ReferenceManyField,
} from 'react-admin';

import { commentCreate as commentCreateAction } from './actions/commentActions';
import UserReferenceField from '../components/UserReferenceField';
import CommentForm from './CommentCreate';

const styles = theme => ({
    container: {
        margin: theme.spacing(2),
    },
});

const Comments = props => {
    const {
        classes,
        commentCreate,
        model,
        documentId,
        userId,
        ...rest
    } = props;

    return (
        <div className={classes.container}>
            <ReferenceManyField
                {...props}
                filter={{ model, documentId }}
                sort={{ field: 'createdAt', order: 'ASC' }}
                addLabel={false}
                reference="comments"
                target="documentId"
                perPage={500}
            >
                <Datagrid>
                    <TextField source="serial" sortable={false} />
                    <DateField
                        locales="en-IN"
                        label="Date"
                        source="createdAt"
                        showTime
                    />
                    <UserReferenceField
                        {...rest}
                        source="authorId"
                        label="Author"
                        link={false}
                    />
                    <TextField source="comment" />
                </Datagrid>
            </ReferenceManyField>
            <CommentForm
                onSubmit={values =>
                    commentCreate({ ...values, model, documentId, userId })
                }
            />
        </div>
    );
};

Comments.propTypes = {
    classes: PropTypes.object.isRequired,
    model: PropTypes.string.isRequired,
    documentId: PropTypes.number.isRequired,
    userId: PropTypes.number.isRequired,
    commentCreate: PropTypes.func.isRequired,
};

Comments.defaultProps = {
    resource: 'comments',
    location: { search: '' },
};

export default withStyles(styles)(
    connect(null, {
        commentCreate: commentCreateAction,
    })(Comments)
);
