import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    ChipField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';
import CountryField from '../../components/CountryField';
import InvoiceNo from '../../invoices/components/InvoiceNo';
import MoneyField from '../../components/MoneyField';
import UserReferenceField from '../../components/UserReferenceField';

export const OrderInvoicesList = props => (
    <ReferenceManyField
        reference="invoices"
        target="orderId"
        filter={{ orderId: props.record.id }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <InvoiceNo label="No" />
            <DateField
                locales="en-IN"
                source="createdAt"
                showTime
                label="Created"
            />
            <DateField locales="en-IN" source="date" />
            <UserReferenceField label="Customer" />
            <MoneyField source="amount" showCurrency />
            <MoneyField source="amountPaid" showCurrency label="Paid" />
            <ChipField source="status" />
            <DateField locales="en-IN" source="paidAt" label="Paid On" />
            <CountryField />
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
);
