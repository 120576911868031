import React from 'react';
import {
    DateField,
    Datagrid,
    ReferenceField,
    ShowButton,
    TextField,
} from 'react-admin';
import MoneyField from '../../components/MoneyField';
import UserReferenceField from '../../components/UserReferenceField';
import { FunctionField } from 'react-admin';
import { formatMoney } from '../../core/utils/money';

const sanitizeRestProps: (any) => any = ({
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const ChargeGrid = props => (
    <Datagrid {...sanitizeRestProps(props)}>
        <TextField source="serial" sortable={false} />
        <TextField source="id" />
        <ReferenceField
            source="partnerId"
            reference="partners"
            link="show"
            label="Partner"
            allowEmpty
        >
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField
            source="invoiceId"
            reference="invoices"
            link="show"
            label="Invoice"
            allowEmpty
        >
            <TextField source="id" />
        </ReferenceField>
        <TextField source="type" />
        <DateField
            locales="en-IN"
            source="createdAt"
            showTime
            label="Created"
        />
        <TextField source="year" />
        <TextField source="month" />
        <TextField source="startDate" label="Start" />
        <TextField source="endDate" label="End" />
        <ReferenceField
            source="orderId"
            reference="orders"
            link="show"
            label="Order"
            allowEmpty
        >
            <TextField source="id" />
        </ReferenceField>
        <UserReferenceField label="Customer" />
        {/*<MoneyField source="amount" showCurrency />*/}
        <FunctionField
            label="Amount"
            render={record => {
                return record.type === 'credit' ? (
                    <>
                        <small style={{ display: 'block' }}>
                            {record.currency}
                        </small>
                        -{formatMoney(record.amount, record.currency)}
                    </>
                ) : (
                    <>
                        <small style={{ display: 'block' }}>
                            {record.currency}
                        </small>
                        {formatMoney(record.amount, record.currency)}
                    </>
                );
            }}
        />
        <TextField source="description" />
        {props.hasShow && <ShowButton label="" style={{ padding: 0 }} />}
    </Datagrid>
);

export default ChargeGrid;
