//import feathers from '@feathersjs/client';
import feathers from '@feathersjs/feathers';
import rest from '@feathersjs/rest-client';
import auth from '@feathersjs/authentication-client';
import settings from '../settings';

const client = feathers();
client.configure(rest(settings.apiUrl).fetch(window.fetch.bind(window)));
//client.configure(auth({ jwtStrategy: 'jwt', storage: window.localStorage }));
client.configure(auth({ jwtStrategy: 'jwt' }));
export default client;
