import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { Affiliate, TitleProps } from '../types';

const AffiliateTitle: React.FC<TitleProps<Affiliate>> = ({ record }) => (
    <span>{record?.name ? `${record?.name}` : `Affiliate #${record?.id}`}</span>
);

const AffiliateEdit = props => (
    <Edit title={<AffiliateTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="id" />
            <TextInput disabled source="partnerId" />
            <TextInput source="name" />
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default AffiliateEdit;
