import React, { Fragment } from 'react';
import {
    List,
    Filter,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    Pagination,
    SelectInput,
    TextField,
    Datagrid,
} from 'react-admin';
import moment from 'moment';
import CustomSearchInput from '../components/CustomSearchInput';
import { FunctionField } from 'react-admin';
import { add, format, parse, parseISO } from 'date-fns';

const getFixedDate = (date: string, isLogin: boolean) => {
    if (date) {
        /*const parsedDate = parse(
            date,
            isLogin ? 'dd/MM/yyyy,hh:mm:ss' : 'dd/MM/yyyy, hh:mm:ss',
            new Date()
        );*/

        const parsedDate = parseISO(date);
        const parsedLocalDate = add(parsedDate, {
            minutes: 0,
        });
        const formatedDate = format(parsedLocalDate, 'dd/MM/yyyy,hh:mm a');
        return formatedDate;
    } else {
        return '';
    }
};

const UserHistoryFilter = props => (
    <Filter variant="outlined" {...props}>
        {/* <SelectInput
          // source="type"
          choices={Object.keys(UserType).map(c => ({
          id: c,
          name: c,
          }))}
        /> */}

        <CustomSearchInput alwaysOn />
        <DateInput
            source="createdAt[$gte]"
            label="Logs From"
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="createdAt[$lte]"
            label="Logs To"
            parse={v => v && moment(v).endOf('day').format()}
        />
        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>
        <ReferenceInput
            source="staffId"
            reference="staff"
            resource="users"
            label="Staff"
            perPage={0}
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>
    </Filter>
);

const ListPagination = props => (
    <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

function UserHistoryList(props) {
    return (
        <Fragment>
            <List
                title="User Logs"
                bulkActionButtons={false}
                filters={<UserHistoryFilter />}
                pagination={<ListPagination />}
                sort={{ field: 'id', order: 'DESC' }}
                {...props}
            >
                <Datagrid>
                    <TextField source="serial" sortable={false} />
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="type" />
                    {/*<TextField source="loginAt" />*/}
                    <FunctionField
                        label="Login at"
                        render={record =>
                            `${getFixedDate(record.loginAt, true)}`
                        }
                    />
                    <FunctionField
                        label="Logout at"
                        render={record =>
                            `${getFixedDate(record.logoutAt, false)}`
                        }
                    />
                    {/*<TextField source="logoutAt" />*/}
                </Datagrid>
            </List>
        </Fragment>
    );
}

UserHistoryList.defaultProps = {
    resource: 'user-logs',
    location: { search: '' },
};

export default UserHistoryList;
