import React from 'react';
import moment from 'moment';
import {
    AutocompleteInput,
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    Pagination,
    ReferenceInput,
    ShowButton,
    TextField,
    TextInput,
} from 'react-admin';
import UserReferenceField from '../components/UserReferenceField';
import CustomSearchInput from '../components/CustomSearchInput';
import BoxitChipField from '../components/BoxitChipField';
import { SelectInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
const TaskFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <DateInput
            source="createdAt[$gte]"
            label="Created From"
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="createdAt[$lte]"
            label="Created To"
            parse={v => v && moment(v).endOf('day').format()}
        />
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={[
                {
                    id: 'new',
                    name: 'new',
                },
                {
                    id: 'running',
                    name: 'running',
                },
                {
                    id: 'complete',
                    name: 'complete',
                },
                {
                    id: 'failed',
                    name: 'failed',
                },
                {
                    id: 'paused',
                    name: 'paused',
                },
                {
                    id: 'cancelled',
                    name: 'cancelled',
                },
            ]}
        />
        <ReferenceInput
            source="creatorId"
            reference="users"
            resource="users"
            label="Creator"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>
    </Filter>
);

const ListPagination = props => (
    <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const TaskList = props => (
    <List
        title="Tasks"
        filters={<TaskFilter />}
        pagination={<ListPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <DateField locales="en-IN" source="date" showTime />
            <UserReferenceField source="creatorId" label="Creator" />
            <TextField source="type" />
            <BoxitChipField record source="status" list="task" />
            {/*<TextField source="data" />*/}
            <FunctionField
                label="data"
                render={record => {
                    let dataTask: string | undefined = undefined;
                    if (record.data.task && JSON.stringify(record.data.task)) {
                        dataTask =
                            JSON.stringify(record.data.task).length <= 50
                                ? JSON.stringify(record.data.task)
                                : JSON.stringify(record.data.task).slice(
                                      0,
                                      50
                                  ) + '...';
                    }
                    let dataTaskError: string | undefined = undefined;
                    if (record.data.taskError) {
                        dataTaskError =
                            record.data.taskError.length <= 50
                                ? record.data.taskError
                                : record.data.taskError.slice(0, 50) + '...';
                    }
                    return JSON.stringify({
                        ...record.data,
                        task: dataTask ? dataTask : undefined,
                        taskError: dataTaskError ? dataTaskError : undefined,
                    }).replace(/\\/g, '');
                }}
            />
            <TextField source="priority" />
            <EditButton color="secondary" />
            <ShowButton color="secondary" />
        </Datagrid>
    </List>
);

TaskList.defaultProps = {
    resource: 'tasks',
    location: { search: '' },
};

export default TaskList;
