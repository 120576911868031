import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    AutocompleteInput,
    TextInput,
    Edit,
    ReferenceInput,
    SimpleForm,
} from 'react-admin';

export default function CardEdit(props) {
    return (
        <Edit undoable={false} {...props}>
            <SimpleForm variant="outlined" {...props} redirect="show">
                <TextInput disabled source="id" style={{ display: 'none' }} />

                <ReferenceInput
                    label="User"
                    source="userId"
                    reference="users"
                    resource="users"
                    allowEmpty
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
}

CardEdit.propTypes = {
    record: PropTypes.object,
};

CardEdit.defaultProps = {
    record: {},
};
