import React from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { ListButton, EditButton, TopToolbar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/MailOutline';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import CardIcon from '@material-ui/icons/CreditCard';
import SyncIcon from '@material-ui/icons/Sync';
import SweetAlert from 'sweetalert-react';
import { InvoiceStatus, PaymentMode } from '../../constants';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import {
    captureCreate as captureCreateAction,
    invoiceSync as invoiceSyncAction,
} from '../actions/invoiceActions';
import settings from '../../settings';
import { getCards as getCardsAction } from '../../cards/actions/cardActions';
import { moneyString } from '../../core/utils/money';
import DeleteButton from '../../../src/components/DeleteButton';
import { Button } from '@material-ui/core';
import { ActionProps, Card } from '../../types';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

type Styles = {
    button: string;
    leftIcon: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ActionProps & {
        cards: Card[];
        classes: Styles;
    };

type State = {
    show: boolean;
    showSyncConfirmDialog: boolean;
};

class InvoiceShowActions extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showSyncConfirmDialog: false,
        };
    }

    componentDidMount() {
        const { getCards, data } = this.props;
        if (data) {
            getCards(data.userId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            const { getCards, data } = nextProps;
            if (data) {
                getCards(data.userId);
            }
        }
    }

    render() {
        const {
            basePath,
            captureCreate,
            cards,
            classes,
            data,
            invoiceSync,
            permissions = [],
            resource,
            taskCreate,
        } = this.props;

        return (
            <TopToolbar>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    href={
                        data
                            ? `${settings.apiUrl}/pdf/invoice?id=${data.id}`
                            : ''
                    }
                    target="_blank"
                >
                    <FileIcon className={classes.leftIcon} />
                    View
                </Button>

                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    onClick={() =>
                        taskCreate({
                            type: 'MAIL_INVOICE_TO_CUSTOMER',
                            data: { invoiceId: data!.id },
                        })
                    }
                >
                    <EmailIcon className={classes.leftIcon} />
                    Send
                </Button>
                {data &&
                    (data.status === InvoiceStatus.unpaid ||
                        data.status === InvoiceStatus.partial) &&
                    data.amount - data.amountPaid > 0 &&
                    data.countryId === 1 &&
                    cards.length > 0 && (
                        <span>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                className={classes.button}
                                onClick={() => this.setState({ show: true })}
                            >
                                <CardIcon className={classes.leftIcon} />
                                Pay
                            </Button>

                            <SweetAlert
                                show={this.state.show}
                                title="Are you sure?"
                                text={`This will charge ${moneyString(
                                    data.amount - data.amountPaid,
                                    data.currency
                                )} to the customer's credit card`}
                                showCancelButton
                                onCancel={() => this.setState({ show: false })}
                                onEscapeKey={() =>
                                    this.setState({ show: false })
                                }
                                onOutsideClick={() =>
                                    this.setState({ show: false })
                                }
                                onConfirm={() => {
                                    this.setState({ show: false });
                                    captureCreate(PaymentMode.payfort, {
                                        userId: data.userId,
                                        amount: data.amount - data.amountPaid,
                                        invoiceId: data.id,
                                    });
                                }}
                            />
                        </span>
                    )}

                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    startIcon={<SyncIcon />}
                    onClick={() =>
                        this.setState({ showSyncConfirmDialog: true })
                    }
                >
                    Sync
                </Button>

                <SweetAlert
                    show={this.state.showSyncConfirmDialog}
                    title="Are you sure?"
                    text={'Do you want to sync this invoice?'}
                    showCancelButton
                    onCancel={() =>
                        this.setState({ showSyncConfirmDialog: false })
                    }
                    onEscapeKey={() =>
                        this.setState({ showSyncConfirmDialog: false })
                    }
                    onOutsideClick={() =>
                        this.setState({ showSyncConfirmDialog: false })
                    }
                    onConfirm={() => {
                        this.setState({ showSyncConfirmDialog: false });
                        invoiceSync(data!.id, data);
                    }}
                />

                {data &&
                    data.date &&
                    _.intersection(permissions, ['admin', 'finance']) &&
                    ((new Date(data.date).getFullYear() >=
                        new Date().getFullYear() &&
                        new Date(data.date).getMonth() >=
                            new Date().getMonth()) ||
                        (new Date(data.date).getFullYear() >=
                            new Date().getFullYear() &&
                            new Date(data.date).getMonth() >=
                                new Date().getMonth() - 1 &&
                            _.intersection(permissions, [
                                'finance-edit-old-invoice',
                            ]).length > 0) ||
                        _.intersection(permissions, ['admin']).length > 0) && (
                        <EditButton
                            color="secondary"
                            basePath={basePath}
                            record={data}
                        />
                    )}

                {data &&
                    _.intersection(permissions, ['admin', 'finance']) &&
                    ((new Date(data.date).getFullYear() >=
                        new Date().getFullYear() &&
                        new Date(data.date).getMonth() >=
                            new Date().getMonth()) ||
                        _.intersection(permissions, [
                            'admin',
                            'finance-delete-old-invoice',
                        ]).length > 0) && (
                        <DeleteButton
                            basePath={basePath}
                            record={data}
                            resource={resource}
                            undoable={false}
                        />
                    )}

                <ListButton color="secondary" basePath={basePath} />
            </TopToolbar>
        );
    }
}

const mapState = (state, props) => ({
    cards:
        (props.data &&
            state.admin.resources.cards &&
            Object.values(
                _.pickBy(
                    state.admin.resources.cards.data,
                    v => v.userId === props.data.userId && v.status === 'active'
                )
            )) ||
        [],
});

const mapDispatch = {
    captureCreate: captureCreateAction,
    getCards: getCardsAction,
    invoiceSync: invoiceSyncAction,
    taskCreate: taskCreateAction,
};

const connector = connect(mapState, mapDispatch);

const ConnectedInvoiceShowActions = connector(InvoiceShowActions);

export default props => {
    const classes = useStyles();
    return <ConnectedInvoiceShowActions classes={classes} {...props} />;
};
