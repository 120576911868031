import React from 'react';
import cx from 'classnames';
import jic from './JIC';
// @ts-ignore
import s from './AddPhoto.css';
import { Button } from '@material-ui/core';

const QUALITY = 80;
const OUTPUT_FORMAT = 'jpg';

type Props = {
    record: any;
    onSubmit?: (base64: string) => void;
};

type State = {
    file: string;
    imagePreviewUrl: string;
};

class AddPhoto extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
        };
    }

    handleSubmit = e => {
        e.preventDefault();

        const { onSubmit } = this.props;

        if (onSubmit && this.refs.imagePreview) {
            const base64 = jic.compress(
                this.refs.imagePreview,
                QUALITY,
                OUTPUT_FORMAT
            ).src;

            onSubmit(base64);
        }
    };

    handleImageChange = e => {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file,
                imagePreviewUrl: reader.result as string,
            });
        };

        reader.readAsDataURL(file);
    };

    render() {
        const { imagePreviewUrl } = this.state;
        const { record } = this.props;

        const uploading = record.addPhotosStatus === 'ACTION_START';
        const uploadSuccess = record.addPhotosStatus === 'ACTION_SUCCESS';
        const uploadFailed = record.addPhotosStatus === 'ACTION_FAILED';

        return (
            <div>
                {imagePreviewUrl && (
                    <img
                        style={{
                            width: '500px',
                            textAlign: 'center',
                            margin: '5px 15px',
                            padding: '10px',
                            border: '4px solid lightblue',
                        }}
                        src={imagePreviewUrl}
                        ref="imagePreview"
                    />
                )}
                <form onSubmit={this.handleSubmit}>
                    <input
                        type="file"
                        style={{
                            border: '4px solid lightgray',
                            padding: '5px',
                            margin: '10px',
                            cursor: 'pointer',
                            marginLeft: '0px',
                        }}
                        onChange={this.handleImageChange}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.handleSubmit}
                        disabled={this.state.file === ''}
                    >
                        Upload
                    </Button>

                    {uploading && (
                        <div className="alert alert-info" role="alert">
                            Uploading Photos...
                            <span
                                className={cx(
                                    s.storagePhotoStatus,
                                    'pretty circle smooth plain',
                                    uploading && 'info',
                                    uploadSuccess && 'success',
                                    uploadFailed && 'danger'
                                )}
                            >
                                <input
                                    id={`select_${record.id}`}
                                    type="checkbox"
                                    readOnly
                                    checked
                                />
                                <label htmlFor={`select_${record.id}`}>
                                    {uploading && (
                                        <i className="fa fa-spinner p-5" />
                                    )}
                                    {uploadSuccess && (
                                        <i className="fa fa-check p-5" />
                                    )}
                                    {uploadFailed && (
                                        <i className="fa fa-times p-5" />
                                    )}
                                </label>
                            </span>
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

export default AddPhoto;
