import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import capitalize from 'capitalize';
import {
    BooleanInput,
    Edit,
    FormDataConsumer,
    FormTab,
    TextField,
    TextInput,
    SelectInput,
    TabbedForm,
    DateInput,
    ReferenceField,
    ReferenceInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
    SimpleFormIterator,
    ArrayInput,
    AutocompleteInput,
    required,
    number,
    minValue,
    NumberInput,
} from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
    PickupTimeFrom,
    PickupTimeTo,
    RequestStatus,
    RequestType,
    PaymentMethod,
} from '../constants';
import { moneyString } from '../core/utils/money';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';
import { EditProps, Request } from '../types';
import SelectStorageInput from './components/SelectStorageInput';
import WhenFieldChanges from '../components/WhenFieldChanges';
import BoxitPagination from '../components/BoxitPagination';

const sanitizeRestProps: (any) => any = ({
    hasEdit,
    hasList,
    hasShow,
    hasCreate,
    ...rest
}) => rest;

const RequestEdit: React.FC<EditProps<Request>> = ({
    permissions = [],
    ...props
}) => (
    <Edit
        undoable={false}
        successMessage="resources.requests.notification.update_success"
        {...props}
    >
        <TabbedForm
            variant="outlined"
            redirect="show"
            {...sanitizeRestProps(props)}
        >
            <FormTab label="Request">
                <TextInput disabled style={{ display: 'none' }} source="id" />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isPrimary && (
                            <p className="my-8 p-4 rounded border border-blue-200 bg-blue-100">
                                In order to change other fields, directly edit
                                the parent order&nbsp;
                                <ReferenceField
                                    label="Order"
                                    record={formData}
                                    source="orderId"
                                    reference="orders"
                                    basePath="/orders"
                                    link="edit"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                                <EditIcon className="ml-2 p-1 rounded-full bg-blue-200" />
                            </p>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <SelectInput
                                source="type"
                                choices={Object.keys(RequestType).map(c => ({
                                    id: c,
                                    name: capitalize(c.replace('_', ' ')),
                                }))}
                            />
                        )
                    }
                </FormDataConsumer>

                {_.intersection(permissions, [
                    'admin',
                    'operations-lead',
                    'operations-manager',
                    'request-full-update',
                ]).length > 0 && (
                    <SelectInput
                        source="status"
                        choices={Object.keys(RequestStatus).map(c => ({
                            id: c,
                            name: c,
                        }))}
                    />
                )}

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <DateInput
                                source="date"
                                parse={v => v && moment(v).format('YYYY-MM-DD')}
                            />
                        )
                    }
                </FormDataConsumer>

                <SelectInput
                    variant="outlined"
                    label="Time Slot"
                    source="from"
                    choices={[
                        {
                            id: 9,
                            name: '9 am - 1 pm',
                        },
                        {
                            id: 13,
                            name: '1 pm - 6 pm',
                        },
                    ]}
                    translateChoice={false}
                />

                <NumberInput style={{ display: 'none' }} source="to" />

                <WhenFieldChanges field="from" becomes={9} set="to" to={13} />
                <WhenFieldChanges field="from" becomes={13} set="to" to={18} />

                {/*
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <SelectInput
                                source="from"
                                translateChoice={false}
                                choices={PickupTimeFrom.map(c => ({
                                    id: c,
                                    name: c,
                                }))}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <SelectInput
                                source="to"
                                translateChoice={false}
                                choices={PickupTimeTo.map(c => ({
                                    id: c,
                                    name: c,
                                }))}
                            />
                        )
                    }
                </FormDataConsumer>
                */}

                <TextInput
                    disabled
                    style={{ display: 'none' }}
                    source="countryId"
                    label="Country"
                />
                <TextInput
                    disabled
                    style={{ display: 'none' }}
                    source="userId"
                />
                <TextInput
                    disabled
                    style={{ display: 'none' }}
                    source="partnerId"
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <ReferenceInput
                                label="City"
                                source="cityId"
                                reference="city"
                                resource="city"
                                allowEmpty
                            >
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId &&
                        !formData.isPrimary && (
                            <ReferenceInput
                                label="Address"
                                source="addressId"
                                reference="addresses"
                                resource="addresses"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                            >
                                <SelectInput
                                    source="addressId"
                                    optionValue="id"
                                    optionText={record =>
                                        `${record.line1}, ${record.area}, ${record.cityId}`
                                    }
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId &&
                        !formData.isPrimary && (
                            <ReferenceInput
                                label="Order"
                                source="orderId"
                                reference="orders"
                                resource="orders"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                            >
                                <SelectInput
                                    source="orderId"
                                    optionValue="id"
                                    optionText={record => `Order #${record.id}`}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <SelectInput
                                source="paymentMode"
                                label="Payment Mode"
                                choices={Object.keys(PaymentMethod).map(i => ({
                                    id: i,
                                    name: capitalize(i),
                                }))}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && <CurrencyInput />
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isPrimary && (
                            <MoneyInput source="amount" label="Payment" />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.countryId && (
                            <ReferenceInput
                                label="Team"
                                source="teamId"
                                reference="teams"
                                resource="teams"
                                filter={{ countryId: formData.countryId }}
                                allowEmpty
                            >
                                <SelectInput source="teamid" optionValue="id" />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>

                <ReferenceInput
                    label="Warehouse"
                    source="warehouseId"
                    reference="warehouses"
                    resource="warehouses"
                    allowEmpty
                >
                    <SelectInput source="warehouseId" optionValue="id" />
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        [RequestType.pickup, RequestType.drop].indexOf(
                            formData.type
                        ) !== -1 &&
                        [
                            RequestStatus.approved,
                            RequestStatus.received,
                            RequestStatus.complete,
                        ].indexOf(formData.status) !== -1 && (
                            <NumberInput
                                variant="outlined"
                                name="actualSize"
                                label="Actual Size"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            CBM
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )
                    }
                </FormDataConsumer>
            </FormTab>

            <FormTab label="Lines">
                <BooleanInput
                    source="isSME"
                    label="Enable SME Mode"
                    defaultValue={false}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !formData.isSME &&
                        !!formData.userId &&
                        formData.type !== RequestType.survey && (
                            <SelectStorageInput formData={formData} {...rest} />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.isSME &&
                        !!formData.userId && (
                            <ArrayInput source="request_lines" label="">
                                <SimpleFormIterator>
                                    <ReferenceInput
                                        label="Item"
                                        source="storageId"
                                        reference="storages"
                                        resource="storages"
                                        filter={{ userId: formData.userId }}
                                        perPage={20}
                                    >
                                        <AutocompleteInput
                                            source="id"
                                            optionText={s =>
                                                `[${s.code}] ${s.storagetype.code}`
                                            }
                                            shouldRenderSuggestions={val =>
                                                true || val.trim().length > 0
                                            }
                                        />
                                    </ReferenceInput>
                                    <TextInput
                                        source="qty"
                                        label="Qty"
                                        validate={[
                                            required(),
                                            number(),
                                            minValue(1),
                                        ]}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        )
                    }
                </FormDataConsumer>
            </FormTab>

            <FormTab label="Services">
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.orderId && (
                            <ReferenceArrayInput
                                label="Services"
                                source="serviceIds"
                                reference="services"
                                resource="services"
                                sort={{ field: 'id', order: 'ASC' }}
                                filter={{
                                    orderId: formData.orderId,
                                    $or: [
                                        { requestId: formData.id },
                                        { requestId: 'null' },
                                    ],
                                }}
                                allowEmpty
                            >
                                <CheckboxGroupInput
                                    label="Services"
                                    source="serviceIds"
                                    optionText={rec =>
                                        `${
                                            rec.servicetype.name
                                        } - ${moneyString(
                                            rec.amount,
                                            rec.currency
                                        )}`
                                    }
                                />
                            </ReferenceArrayInput>
                        )
                    }
                </FormDataConsumer>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default RequestEdit;
