import _ from 'lodash';

export function diffItems(list1, list2) {
    if (!list1) list1 = [];
    if (!list2) list2 = [];
    const diff = {};

    list2.forEach(item2 => {
        const item1 = list1.find(i => i.storagetypeId === item2.storagetypeId);

        if (item1) {
            if (item1.quantity && item2.quantity === 0) {
                diff[item2.storagetypeId] = 'red';
            } else if (item2.quantity !== item1.quantity) {
                diff[item2.storagetypeId] = 'blue';
            }
        } else {
            diff[item2.storagetypeId] = 'green';
        }
    });

    list1.forEach(item1 => {
        const item2 = list2.find(i => i.storagetypeId === item1.storagetypeId);
        if (!item2) {
            diff[item1.storagetypeId] = 'red';
        }
    });

    return diff;
}

export function getItemListFromStorages(storages) {
    const items = _.map(_.groupBy(storages, 'storagetypeId'), (v, k) => ({
        storagetypeId: parseInt(k, 10),
        quantity: v.reduce((count, i) => count + (i.qty || 1), 0),
    }));

    return items;
}

export function getItemCodeListFromStorages(storages) {
    const items = _.map(_.groupBy(storages, 'storagetype.code'), (v, k) => ({
        storagetypeId: k,
        quantity: v.length,
    }));

    return items;
}

export function convertItemListToItemCodeList(items, storagetypes) {
    return items
        .map(i => ({
            ...i,
            item: _.find(storagetypes, ['id', i.storagetypeId]),
        }))
        .filter(i => i && i.item)
        .map(i => ({
            storagetypeId: i.item.code,
            quantity: i.quantity,
        }));
}
