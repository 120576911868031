import React, { useEffect } from 'react';
import { Filter } from 'react-admin';
import { SelectInput } from 'react-admin';
//import { BooleanInput } from 'react-admin';
//import { ReferenceInput } from 'react-admin';
import { ShowButton } from 'react-admin';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
//import CustomSearchInput from '../components/CustomSearchInput';
//import { ReferenceField } from 'react-admin';
import { TextInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
//import { FunctionField } from 'react-admin';
//import { AutocompleteInput } from 'react-admin';
import { DateInput } from 'react-admin';
import moment from 'moment';
import { SelectArrayInput } from 'react-admin';

const CommunicationFilter = props => (
    <Filter variant="outlined" {...props}>
        {/*<CustomSearchInput alwaysOn />*/}
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="taskStatus"
            label="Task Status"
            choices={[
                {
                    id: 'new',
                    name: 'new',
                },
                {
                    id: 'running',
                    name: 'running',
                },
                {
                    id: 'complete',
                    name: 'complete',
                },
                {
                    id: 'failed',
                    name: 'failed',
                },
                {
                    id: 'paused',
                    name: 'paused',
                },
                {
                    id: 'cancelled',
                    name: 'cancelled',
                },
            ]}
        />
        <TextInput source="taskType" label="Task Type" />
        <TextInput label="Template Name" source="templateName" />
        <TextInput source="senderEmail" label="Sender Email" />
        <TextInput source="recipientEmail" label="Recipient Email" />
        <SelectInput
            source="channel"
            label="Channel"
            choices={[
                {
                    id: 'email',
                    name: 'Email',
                },
                {
                    id: 'whatsApp',
                    name: 'WhatsApp',
                },
            ]}
        />
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="deliveryStatus"
            label="Delivery Status"
            choices={[
                {
                    id: 'delivered',
                    name: 'delivered',
                },
                {
                    id: 'failed',
                    name: 'failed',
                },
                {
                    id: 'read',
                    name: 'read',
                },
                {
                    id: 'clicked',
                    name: 'clicked',
                },
                {
                    id: 'sent',
                    name: 'sent',
                },
                {
                    id: 'receivedReply',
                    name: 'receivedReply',
                },
            ]}
        />
        {/*<SelectInput
            source="status"
            label="Comm Status"
            choices={[
                {
                    id: 'sent',
                    name: 'sent',
                },
                {
                    id: 'rejected',
                    name: 'rejected',
                },
                {
                    id: 'pending',
                    name: 'pending',
                },
                {
                    id: 'initiated',
                    name: 'initiated',
                },
            ]}
        />*/}
        {/*<ReferenceInput
            source="senderId"
            reference="users"
            resource="users"
            label="sender"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>*/}
        {/*<ReferenceInput
            source="recipientId"
            reference="users"
            resource="users"
            label="Recipient"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>*/}
        {/*<TextInput source="senderPhone" label="Sender Phone" />
        <TextInput source="recipientPhone" label="Recipient Phone" />*/}
        {/*<TextInput source="taskId" label="Task Id" />*/}
        <DateInput
            source="sendDate[$gte]"
            label="Comm Sent Date From"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="sendDate[$lte]"
            label="Comm Sent Date To"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).endOf('day').format()}
        />
        <DateInput
            source="deliveryDate[$gte]"
            label="Delivery Date From"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="deliveryDate[$lte]"
            label="Delivery Date To"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).endOf('day').format()}
        />
        <DateInput
            source="openedDate[$gte]"
            label="Read (Opened) Date from"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="openedDate[$lte]"
            label="Read (Opened) Date To"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).endOf('day').format()}
        />
        <DateInput
            source="clickedDate[$gte]"
            label="Clicked Date From"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="clickedDate[$lte]"
            label="Clicked Date To"
            style={{ width: '15rem' }}
            parse={v => v && moment(v).endOf('day').format()}
        />
    </Filter>
);

const CommunicationList = props => {
    useEffect(() => {
        localStorage.removeItem('currentComMod');
    }, []);
    return (
        <List
            title="Communication"
            bulkActionButtons={false}
            filters={<CommunicationFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" label="Communication Id" />
                <TextField
                    source="taskStatus"
                    label="Task Status"
                    sortable={false}
                />
                <TextField
                    source="taskType"
                    label="Task Type"
                    sortable={false}
                />
                <TextField source="templateName" label="Template Name" />
                <TextField source="senderEmail" label="Sender Email" />
                <TextField source="recipientEmail" label="Recipient Email" />
                <TextField source="channel" label="Channel" />
                <TextField source="deliveryStatus" label="Delivery Status" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

CommunicationList.defaultProps = {
    resource: 'Communication-records',
    location: { search: '' },
};

export default CommunicationList;
