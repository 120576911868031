import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import PaymentShowButtons from './components/PaymentShowButtons';
import { PaymentTabs } from './components/PaymentTabs';
import { modelView, modelViewType } from '../util/modelView';

const PaymentShow = props => {
    useEffect(() => {
        modelView(modelViewType.PAYMENTS, parseInt(props.id));
    }, [props.id]);
    return (
        <Show actions={<PaymentShowButtons />} {...props}>
            <PaymentTabs />
        </Show>
    );
};

export default PaymentShow;
