import React, { useEffect } from 'react';
import { EditButton, Show, TopToolbar } from 'react-admin';
import { StorageTabs } from './components/StorageTabs';
import { ActionProps, ShowProps, Storage } from '../types';
import { modelView, modelViewType } from '../util/modelView';

const StorageShowActions: React.FC<ActionProps<Storage>> = ({
    basePath,
    data,
}) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
    </TopToolbar>
);

const StorageShow: React.FC<ShowProps<Storage>> = ({
    permissions = [],
    ...props
}) => {
    useEffect(() => {
        modelView(modelViewType.STORAGES, parseInt(props.id));
    }, [props.id]);
    return (
        <Show
            actions={
                permissions.indexOf('staff') !== -1 ? (
                    <StorageShowActions permissions={permissions} />
                ) : null
            }
            {...props}
        >
            <StorageTabs permissions={permissions} {...props} />
        </Show>
    );
};

export default StorageShow;
