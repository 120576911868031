import React from 'react';
import moment from 'moment';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import {
    List,
    Datagrid,
    downloadCSV,
    TextField,
    DateField,
    ShowButton,
    Filter,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ChipField from '../components/ChipField';
import BoxitChipField from '../components/BoxitChipField';
import MoneyField from '../components/MoneyField';
import BoxitPagination from '../components/BoxitPagination';
import UserReferenceField from '../components/UserReferenceField';
import { PaymentStatus, PaymentType, PaymentMode } from '../constants';
import CountryField from '../components/CountryField';
import { formatMoney } from '../util/formatMoney';
import { SelectArrayInput } from 'react-admin';

const useStyles = makeStyles({
    root: {
        padding: '1px 8px',
        color: '#595959',
        background: '#F5F5F5',
        borderRadius: '4px',
    },
});
const PaymentTypeField = ({ record = {}, source }) => {
    const classes = useStyles();
    return record && record[source] && record[source] !== 'payment' ? (
        <ChipField className={classes.root} record={record} source={source} />
    ) : null;
};

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'countryId', 'country').then(countries => {
        const data = records.map(record => ({
            ...record,
            amount: formatMoney(record.amount, record.currency),
            country:
                countries[record.countryId] && countries[record.countryId].name,
            name: record.user.name,
        }));
        const csv = convertToCSV({
            data,
            fields: [
                'id',
                'type',
                'mode',
                'currency',
                'amount',
                'status',
                'paidAt',
                'name',
                'country',
            ],
        });
        downloadCSV(csv, `Payments_${Date.now()}`);
    });
};

const PaymentFilter = props => (
    <Filter variant="outlined" {...props}>
        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <SelectInput
            source="type"
            choices={Object.keys(PaymentType).map(c => ({ id: c, name: c }))}
        />

        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={Object.keys(PaymentStatus).map(c => ({ id: c, name: c }))}
        />

        <SelectInput
            source="mode"
            choices={Object.keys(PaymentMode).map(c => ({ id: c, name: c }))}
        />

        <SelectInput
            label="Method"
            source="data.response.eci"
            choices={[
                { id: 'ECOMMERCE', name: 'ECOMMERCE' },
                { id: 'RECURRING', name: 'RECURRING' },
            ]}
        />

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user =>
                    user && (user && user.name ? user.name : user.email)
                }
            />
        </ReferenceInput>

        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>

        <DateInput
            source="paidAt[$gte]"
            label="Date From"
            parse={v => v && moment(v).startOf('day').format()}
        />
        <DateInput
            source="paidAt[$lte]"
            label="Date To"
            parse={v => v && moment(v).endOf('day').format()}
            format={v =>
                v && moment(v).subtract(1, 'days').format('YYYY-MM-DD')
            }
        />
    </Filter>
);

const PaymentList = props => {
    const classes = useStyles();
    return (
        <List
            title="Payments"
            bulkActionButtons={false}
            exporter={exporter}
            filters={<PaymentFilter />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            pagination={<BoxitPagination />}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" />
                <DateField
                    locales="en-IN"
                    source="createdAt"
                    label="Created"
                    showTime
                />
                <UserReferenceField label="Customer" />
                <CountryField />
                <ChipField className={classes.root} source="mode" />
                <ChipField source="data.response.eci" label="Method" />
                <MoneyField source="amount" showCurrency />
                <PaymentTypeField source="type" />
                <BoxitChipField record source="status" list="payment" />
                <DateField locales="en-IN" source="paidAt" label="Date" />
                <UserReferenceField source="creatorId" label="Creator" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

PaymentList.defaultProps = {
    resource: 'teams',
    location: { search: '' },
};

export default PaymentList;
