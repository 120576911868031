import React from 'react';
import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import PlaceAutoComplete from '../components/PlaceAutoComplete';

const AddressCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined" redirect="show">
            <ReferenceInput
                label="User"
                source="userId"
                reference="users"
                resource="users"
                validation={{ required: true }}
                allowEmpty
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>
            <TextInput source="name" label="Address Name" />
            <TextInput multiline source="line1" label="Line" />
            <PlaceAutoComplete
                source="area"
            />
            <ReferenceInput
                label="City"
                source="cityId"
                reference="city"
                resource="city"
                allowEmpty
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default AddressCreate;
