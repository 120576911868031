import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'capitalize';
import { DateField, ChipField, TextField, ReferenceField } from 'react-admin';

import Money from '../../components/Money';
import UserReferenceField from '../../components/UserReferenceField';
import CountryField from '../../components/CountryField';

import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Paper,
} from '@material-ui/core';
import { FunctionField } from 'react-admin';
import { formatMoney } from '../../util/formatMoney';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

const ChargeSummary = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Charge Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Invoice Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.invoiceId && (
                                <ReferenceField
                                    {...props}
                                    source="invoiceId"
                                    reference="invoices"
                                    link="show"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Created At</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Customer</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Type</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="type" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Amount</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.type === 'credit' ? (
                                <>
                                    <small style={{ display: 'block' }}>
                                        {record.currency}
                                    </small>
                                    -
                                    {formatMoney(
                                        record.amount,
                                        record.currency
                                    )}
                                </>
                            ) : (
                                <>
                                    <small style={{ display: 'block' }}>
                                        {record.currency}
                                    </small>
                                    {formatMoney(
                                        record.amount,
                                        record.currency
                                    )}
                                </>
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Description</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="description" />
                        </TableCell>
                    </TableRow>
                    {record.startDate &&
                    record.endDate &&
                    record.startDate === record.endDate ? (
                        <TableRow>
                            <TableCell>
                                <label>Date</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <span>
                                    {`${record.startDate}/${record.month}/${record.year}`}
                                </span>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <TableCell>
                                <label>Period</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <span>
                                    {`${record.startDate}/${record.month}/${record.year} to ${record.endDate}/${record.month}/${record.year}`}
                                </span>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField record={record} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

ChargeSummary.propTypes = {
    record: PropTypes.object,
};

export default ChargeSummary;
