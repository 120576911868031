import * as React from 'react';

function SvgActivity(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 10a1 1 0 01.949.684L23 25.838l2.051-6.154A1 1 0 0126 19h4a1 1 0 110 2h-3.28l-2.771 8.316a1 1 0 01-1.898 0L17 14.162l-2.051 6.154A1 1 0 0114 21h-4a1 1 0 110-2h3.28l2.771-8.316A1 1 0 0117 10z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgActivity;
