import * as React from 'react';

function SvgList(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                d="M13 13.503a1 1 0 110 2h-1a1 1 0 110-2h1zM16 13.503a1 1 0 100 2h13a1 1 0 100-2H16zM16 19.503a1 1 0 100 2h13a1 1 0 100-2H16zM15 26.503a1 1 0 011-1h13a1 1 0 110 2H16a1 1 0 01-1-1zM14 20.503a1 1 0 00-1-1h-1a1 1 0 100 2h1a1 1 0 001-1zM13 25.503a1 1 0 110 2h-1a1 1 0 110-2h1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgList;
