import React from 'react';
import UserIcon from '@material-ui/icons/Person';
import Money from '../../components/Money';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
    Avatar,
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Order, User } from '../../types';

type Props = {
    title: string;
    orders?: Order[];
    users?: User[];
    count?: number;
};

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        marginRight: '1em',
    },
    title: {
        color: theme.palette.text.primary,
    },
}));

export default (props: Props) => {
    const classes = useStyles();
    const {
        title = 'Pending Orders',
        orders = [],
        count = 0,
        users = [],
    } = props;

    return (
        <Card className={classes.root}>
            <CardHeader
                className={classes.title}
                title={`${title} (${count})`}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>City</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Pickup</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders.map(order => {
                        const user = users.find(u => u.id === order.userId);
                        return user ? (
                            <TableRow key={order.id}>
                                <TableCell>{order.id}</TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{order.city}</TableCell>
                                <TableCell>{order.size}</TableCell>
                                <TableCell>{order.date}</TableCell>
                                <TableCell>{order.status}</TableCell>
                            </TableRow>
                        ) : null;
                    })}
                </TableBody>
            </Table>
        </Card>
    );
};
