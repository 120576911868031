import { GET_LIST, GET_ONE } from 'react-admin';

export const getWarehouses = (countryId: number) => ({
    type: GET_LIST,
    payload: { filter: { countryId, active: true } },
    meta: { resource: 'warehouses', fetch: GET_LIST, cancelPrevious: false },
});

export const getWarehouse = id => ({
    type: GET_ONE,
    payload: { id },
    meta: { resource: 'warehouses', fetch: GET_ONE, cancelPrevious: false },
});
