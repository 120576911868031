import React from 'react';
import capitalize from 'capitalize';
import {
    DateField,
    TextField,
    BooleanField,
    ReferenceField,
    useQueryWithStore,
    ImageField,
    ReferenceManyField,
    SingleFieldList,
} from 'react-admin';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Chip,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CityField from '../../components/CityField';
import CountryField from '../../components/CountryField';
import BoxitChipField from '../../components/BoxitChipField';
import UserReferenceField from '../../components/UserReferenceField';
import Money from '../../components/Money';
import VolumeField from '../../components/VolumeField';
import TimeSlotField from '../../components/TimeSlotField';
import ChipField from '../../components/ChipField';
import Address from '../../components/Address';
import Thumbnail from '../../components/Thumbnail';
import ItemStorageList from './ItemStorageList';
import { Caption, OrderStatus, OrderType } from '../../constants';
import { Order } from '../../types';
import { FunctionField } from 'react-admin';
import humanize from '../../util/humanize';

type Props = {
    record?: Order;
};

export function OrderSummary(props: Props): JSX.Element | null {
    const { record } = props;

    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: 'charges',
        payload: { filter: { orderId: record && record.id } },
    });

    const charges = loaded ? data : [];
    const isPartnerOrder = record && record.partnerId && record.partnerId !== 1;
    const isComplete = record && record.status === OrderStatus.complete;
    const monthlyRent = record && record.customerRent;
    const monthlyRentBoxit = record && record.rent;
    const totalRent = record && record.customerRent * record.months;
    const totalRentBoxit = record && monthlyRentBoxit * record.months;

    const discountService =
        record &&
        record.services &&
        record.services.find(i => i.servicetype.name === 'Discount');
    const discountAmount = discountService ? discountService.amount : null;
    const deliveryService =
        record &&
        record.services &&
        record.services.find(
            i => i.servicetype.name === 'Delivery (Charged Later)'
        );
    const deliveryCharge = deliveryService ? deliveryService.amount : null;
    const services =
        record &&
        record.services &&
        record.services.filter(
            i =>
                ['Discount', 'Delivery (Charged Later)'].indexOf(
                    i.servicetype.name
                ) === -1
        );
    const subtotalServiceCharges =
        record &&
        services &&
        services
            .filter(
                i =>
                    ['2020OFFER', 'JUNE2021'].indexOf(record.couponId) === -1 ||
                    !(
                        ((i.servicetype.name === 'Pickup' ||
                            i.servicetype.name === 'Pickup and Packing') &&
                            record.months >= 3) ||
                        (i.servicetype.name === 'Delivery' &&
                            record.months >= 6)
                    )
            )
            .reduce((sum, i) => sum + Number(i.amount), 0);
    const totalServiceCharges = subtotalServiceCharges - discountAmount;
    const totalServiceChargesBoxit =
        record && record.partnerServiceCommissionPercent
            ? totalServiceCharges *
              ((100 - record.partnerServiceCommissionPercent) / 100)
            : totalServiceCharges;
    const totalAmount = (totalRent || 0) + totalServiceCharges;
    const totalAmountBoxit = (totalRentBoxit || 0) + totalServiceChargesBoxit;

    if (!record) return null;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Order Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <Chip label={humanize(record.type)} />
                            {/*<ChipField record={record} source="type" />*/}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Order No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Lead No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="Lead"
                                source="id"
                                reference="leads"
                                link="show"
                            >
                                <TextField source="id" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField {...props} label="Customer" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Reference</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="ref" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Assignee</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                source="assigneeId"
                                {...props}
                                label="Assignee"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>City</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CityField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Country</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <BoxitChipField
                                record={record}
                                source="status"
                                list="order"
                            />
                        </TableCell>
                    </TableRow>
                    {record &&
                    record.cancelReasonId &&
                    record.status === OrderStatus.cancelled ? (
                        <TableRow hover>
                            <TableCell>Reason</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    {...props}
                                    label="Reason"
                                    source="cancelReasonId"
                                    reference="cancel-reasons"
                                    link="show"
                                >
                                    <TextField source="reason" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {(record &&
                        record.reason &&
                        record.status === OrderStatus.onhold) ||
                    record.status === OrderStatus.lost ||
                    record.status === OrderStatus.cancelled ? (
                        <TableRow hover>
                            {record.status === OrderStatus.cancelled && (
                                <TableCell>{'Comment'}</TableCell>
                            )}

                            <TableCell style={{ textAlign: 'right' }}>
                                {record.reason}
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {/* {record && record.reason && (
                        <TableRow hover>
                            <TableCell>comment</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TextField record={record} source="reason" />
                            </TableCell>
                        </TableRow>
                    )} */}
                    <TableRow hover>
                        <TableCell>Created At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="createdAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Quote Sent At</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="quoteSentAt"
                                showTime
                            />
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Duration</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && record.months} months
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage From</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="date"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Renewal Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="renewalDate"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage Type</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ChipField
                                    record={record}
                                    source="storageType"
                                />
                            </TableCell>
                        </TableRow>
                    )}

                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>EXPECTED</TableCell>
                                            <TableCell>ACTUAL</TableCell>
                                            <TableCell>WAREHOUSE</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>
                                                {record && record.qty}
                                            </TableCell>
                                            <TableCell>
                                                {record && record.actualQty}
                                            </TableCell>
                                            <TableCell>
                                                {record && record.currentQty}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Size</TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="size"
                                                    showUnit
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="actualSize"
                                                    showUnit
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <VolumeField
                                                    record={record}
                                                    source="currentSize"
                                                    showUnit
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Rent</TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={record.rent}
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={
                                                                record.actualRent
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record && (
                                                    <>
                                                        <Money
                                                            amount={
                                                                record.currentRent
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / month
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>
                                                {record.size && (
                                                    <span>
                                                        <Money
                                                            amount={
                                                                record.customerRent /
                                                                parseFloat(
                                                                    record.size
                                                                )
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record.actualSize && (
                                                    <span>
                                                        <Money
                                                            amount={
                                                                record.customerRent /
                                                                parseFloat(
                                                                    record.actualSize
                                                                )
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {record.currentSize && (
                                                    <span>
                                                        <Money
                                                            amount={
                                                                record.customerRent /
                                                                parseFloat(
                                                                    record.currentSize
                                                                )
                                                            }
                                                            currency={
                                                                record.currency
                                                            }
                                                            showCurrency
                                                        />{' '}
                                                        / CBM / month
                                                    </span>
                                                )}
                                            </TableCell>
                                        </TableRow>

                                        <ItemStorageList record={record} />
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    )}

                    {record && isPartnerOrder && (
                        <>
                            <TableRow hover>
                                <TableCell colSpan={2}>
                                    <strong>Partner Commissions</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Storage Commission</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {record.partnerStorageCommissionPercent} %
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>Service Commission</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    {record.partnerServiceCommissionPercent} %
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell colSpan={2} />
                            </TableRow>
                        </>
                    )}

                    <TableRow hover>
                        <TableCell colSpan={2}>
                            <strong>Billing</strong>
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Coupon</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ChipField record={record} source="couponId" />
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Monthly Rent</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <span>
                                        <Money
                                            amount={monthlyRent}
                                            currency={record.currency}
                                            showCurrency
                                        />{' '}
                                        / month
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type !== OrderType.storage && (
                        <TableRow>
                            <TableCell>Amount</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <span>
                                        <Money
                                            amount={record.amount}
                                            currency={record.currency}
                                            showCurrency
                                        />
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {isPartnerOrder && record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Monthly Rent to Boxit</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <span>
                                        <Money
                                            amount={monthlyRentBoxit}
                                            currency={record.currency}
                                            showCurrency
                                        />{' '}
                                        <small>/ month</small>
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Storage Rate</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && record.size && (
                                    <span>
                                        <Money
                                            amount={record.rate}
                                            currency={record.currency}
                                            showCurrency
                                        />{' '}
                                        <small>/ CBM / month</small>
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type !== OrderType.storage && (
                        <TableRow>
                            <TableCell>Effective Rate</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.size && (
                                    <span>
                                        <Money
                                            amount={
                                                record.amount /
                                                parseFloat(record.size)
                                            }
                                            currency={record.currency}
                                            showCurrency
                                        />{' '}
                                        / CBM
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>
                                <strong>Total Rent</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <strong>
                                    {record && (
                                        <Money
                                            amount={totalRent}
                                            currency={record.currency}
                                            showCurrency
                                        />
                                    )}
                                </strong>
                            </TableCell>
                        </TableRow>
                    )}
                    {record &&
                        isPartnerOrder &&
                        record.type === OrderType.storage && (
                            <TableRow>
                                <TableCell>Total Rent to Boxit</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <Money
                                        amount={totalRentBoxit}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    <TableRow>
                        <TableCell colSpan={2}>
                            <strong>Addional Services</strong>
                        </TableCell>
                    </TableRow>
                    {record &&
                        services &&
                        services.map(i => {
                            if (i && i.id) {
                                if (
                                    (record.months >= 3 &&
                                        (i.servicetype.name === 'Pickup' ||
                                            i.servicetype.name ===
                                                'Pickup and Packing')) ||
                                    (record.months >= 6 &&
                                        i.servicetype.name === 'Delivery')
                                ) {
                                    return (
                                        <TableRow key={i.id}>
                                            <TableCell>
                                                {capitalize(i.servicetype.name)}
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: 'right' }}
                                            >
                                                Free
                                            </TableCell>
                                        </TableRow>
                                    );
                                } else {
                                    return (
                                        <TableRow key={i.id}>
                                            <TableCell>
                                                {capitalize(i.servicetype.name)}
                                            </TableCell>
                                            <TableCell
                                                style={{ textAlign: 'right' }}
                                            >
                                                <Money
                                                    amount={i.amount || 0}
                                                    currency={i.currency}
                                                    showCurrency
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            }
                        })}
                    {record && !!discountAmount && (
                        <TableRow hover>
                            <TableCell>Discount</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {charges.find(
                                    c => c.serviceId === discountService.id
                                ) && <DoneIcon color="primary" />}
                                (
                                <Money
                                    amount={discountAmount}
                                    currency={record.currency}
                                    showCurrency
                                />
                                )
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow hover>
                        <TableCell>
                            <strong>Total Service Charges</strong>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <strong>
                                {record && (
                                    <Money
                                        amount={totalServiceCharges}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                )}
                            </strong>
                        </TableCell>
                    </TableRow>
                    {isPartnerOrder && (
                        <TableRow hover>
                            <TableCell>
                                Total Service Charges to Boxit
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <Money
                                        amount={totalServiceChargesBoxit}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow hover>
                            <TableCell>
                                <strong>Total Amount</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <strong>
                                    {record && (
                                        <Money
                                            amount={totalAmount}
                                            currency={record.currency}
                                            showCurrency
                                        />
                                    )}
                                </strong>
                            </TableCell>
                        </TableRow>
                    )}
                    {isPartnerOrder && record.type === OrderType.storage && (
                        <TableRow hover>
                            <TableCell>Total Amount to Boxit</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record && (
                                    <Money
                                        amount={totalAmountBoxit}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type !== OrderType.storage && (
                        <TableRow hover>
                            <TableCell>
                                <strong>Total Amount</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <strong>
                                    {record && (
                                        <Money
                                            amount={
                                                record.amount +
                                                totalServiceCharges
                                            }
                                            currency={record.currency}
                                            showCurrency
                                        />
                                    )}
                                </strong>
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow hover>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Delivery (Charged Later)</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && (
                                <Money
                                    amount={deliveryCharge}
                                    currency={record.currency}
                                    showCurrency
                                />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Payment Mode</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record &&
                                record.paymentMode &&
                                capitalize(record.paymentMode)}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell colSpan={2} />
                    </TableRow>
                    <TableRow hover>
                        <TableCell colSpan={2}>
                            <strong>Logistics</strong>
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.storage && (
                        <TableRow>
                            <TableCell>Warehouse Type</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record &&
                                    record.warehouseType &&
                                    record.warehouseType.map(wt => (
                                        <span key="{wt}" className="tags-chip">
                                            {wt.toUpperCase()}
                                        </span>
                                    ))}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type === OrderType.storage && (
                        <TableRow hover>
                            <TableCell>Type of Operation</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record?.pickupRequired ? 'Pickup' : 'Drop Off'}
                            </TableCell>
                        </TableRow>
                    )}
                    {record && record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Pickup Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="date"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record && record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Pickup Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TimeSlotField
                                    record={record}
                                    sourceFrom="pickupTimeFrom"
                                    sourceTo="pickupTimeTo"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record && !record.pickupRequired && (
                        <TableRow hover>
                            <TableCell>Drop Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="date"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Payment To Collect</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && (
                                <Money
                                    amount={record.collectAmount}
                                    currency={record.currency}
                                    showCurrency
                                />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Need Empty Boxes in advance</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && record.newboxRequired ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                    {record && record.newboxRequired && (
                        <TableRow hover>
                            <TableCell>Newbox Delivery Date</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <DateField
                                    locales="en-IN"
                                    record={record}
                                    source="deliveryDate"
                                    options={{
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record && record.newboxRequired && (
                        <TableRow hover>
                            <TableCell>Newbox Delivery Time</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <TimeSlotField
                                    record={record}
                                    sourceFrom="deliveryTimeFrom"
                                    sourceTo="deliveryTimeTo"
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow hover>
                        <TableCell>
                            {record.type === OrderType.moving
                                ? 'Source Address'
                                : 'Address'}
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && record.addressId && (
                                <Address id={record.addressId} />
                            )}
                        </TableCell>
                    </TableRow>
                    {record.type === OrderType.moving && (
                        <TableRow>
                            <TableCell>Destination Address</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.destAddressId && (
                                    <Address id={record.destAddressId} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Notes</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record && record.notes}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Channel</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="channel" />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField record={record} source="source" />
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Tags</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record &&
                                record.tags &&
                                record.tags.map(t => (
                                    <span key="{t}" className="tags-chip">
                                        {t}
                                    </span>
                                ))}
                        </TableCell>
                    </TableRow>
                    {record?.pickupRequired && (
                        <>
                            <TableRow hover>
                                <TableCell colSpan={2}>
                                    <strong>Checklist</strong>
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>1. Type of Building</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.buildingType"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    2. Is entry permit required?
                                </TableCell>
                                <TableRow>
                                    <TableCell
                                        colSpan={2}
                                        style={{
                                            padding: '0px',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            borderBottom:
                                                                'none',
                                                        }}
                                                    >
                                                        <BooleanField
                                                            record={record}
                                                            source="checklist.permitRequired"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            borderBottom:
                                                                'none',
                                                        }}
                                                    >
                                                        {record &&
                                                            record.checklist &&
                                                            record.checklist
                                                                .permitRequired && (
                                                                <Thumbnail
                                                                    resource="user-photos"
                                                                    filter={{
                                                                        caption:
                                                                            Caption.entry_permit,
                                                                    }}
                                                                    {...props}
                                                                />
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    3. Is moving insurance required?
                                </TableCell>
                                <TableCell
                                    style={{
                                        textAlign: 'right',
                                        borderTopWidth: '1px',
                                    }}
                                >
                                    <BooleanField
                                        record={record}
                                        source="checklist.movingInsuranceRequired"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    4. Is parking slot available?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.parkingSlotAvailable"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    5. Is parking slot inside or outside the
                                    building?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.isSlotInsideOrOutside"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    6. Maximum allowed time for loading
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.maximumAllowedLoadingTime"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>7. Building Floor</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.buildingFloor"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>8. Is lift available?</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.liftAvailable"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    9. Maximum allowed time for using the lift
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.maximumAllowedLiftTime"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    10. Are there items that would not fit
                                    inside the lift?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.anyItemsNotFitinsideLift"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>11. If yes details</TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <TextField
                                        record={record}
                                        source="checklist.liftNotFitDetails"
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow hover>
                                <TableCell>
                                    12. If customer wants to authorize someone?
                                </TableCell>
                                <TableCell style={{ textAlign: 'right' }}>
                                    <BooleanField
                                        record={record}
                                        source="checklist.authorizeOtherPerson"
                                    />
                                </TableCell>
                            </TableRow>
                            {record?.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        13. Authorized Person Name
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedName"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {record?.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        14. Authorized Person Phone
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedPhone"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {record?.checklist?.authorizeOtherPerson && (
                                <TableRow hover>
                                    <TableCell>
                                        15. Authorized Person ID Number
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        <TextField
                                            record={record}
                                            source="checklist.authorizedID"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
