import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ReferenceField,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
} from 'react-admin';

import CountryField from '../components/CountryField';
import CustomSearchInput from '../components/CustomSearchInput';

const AffiliateFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput optionText={choice => `${choice.name}`} />
        </ReferenceInput>
    </Filter>
);

const AffiliateList = props => (
    <List {...props} filters={<AffiliateFilter />}>
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <CountryField />
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="commissionsPercent" label="Commission (%)" />
            <ReferenceField
                label="User"
                source="userId"
                reference="users"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <EditButton color="secondary" />
        </Datagrid>
    </List>
);

AffiliateList.defaultProps = {
    resource: 'affiliates',
    location: { search: '' },
};

export default AffiliateList;
