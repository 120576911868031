import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceField, TextField } from 'react-admin';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import settings from '../../settings';

const getFixedDate = (date: string) => {
    if (date) {
        const localTime = new Date(date);
        return localTime.toLocaleString();
    } else {
        return '';
    }
};

const CommunicationDetails = props => {
    const { record } = props;

    console.log(record.attachmentTypesAndIds);

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Communication Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Task Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="Task Id"
                                source="taskId"
                                reference="tasks"
                                link="show"
                            >
                                <TextField source="id" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Task Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.taskStatus}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Task Type</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.taskType}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Template Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.templateName}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Channel</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.channel}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <div
                                style={{
                                    width: '145%',
                                    height: '1.5rem',
                                    backgroundColor: '#EEF3F8',
                                    marginLeft: '-4rem',
                                    borderTop: '1px solid #E5E9F2',
                                    borderBottom: '1px solid #E5E9F2',
                                }}
                            ></div>
                        </TableCell>
                        <TableCell>
                            <div
                                style={{
                                    width: '120%',
                                    height: '1.5rem',
                                    backgroundColor: '#EEF3F8',
                                    borderTop: '1px solid #E5E9F2',
                                    borderBottom: '1px solid #E5E9F2',
                                }}
                            ></div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Sender</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="Sender"
                                source="senderId"
                                reference="users"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    {record.channel === 'email' && (
                        <TableRow>
                            <TableCell>
                                <label>Sender Email</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.senderEmail}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.channel === 'whatsApp' && (
                        <TableRow>
                            <TableCell>
                                <label>Sender Phone</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.senderPhone}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <label>Recipient</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="Recipient"
                                source="recipientId"
                                reference="users"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    {record.channel === 'email' && (
                        <TableRow>
                            <TableCell>
                                <label>Recipient Email</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.recipientEmail}
                            </TableCell>
                        </TableRow>
                    )}
                    {record.channel === 'whatsApp' && (
                        <TableRow>
                            <TableCell>
                                <label>Recipient Phone</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.recipientPhone}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>
                            <label>Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.status}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Delivery Status</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.deliveryStatus}
                        </TableCell>
                    </TableRow>
                    {/*<TableRow>
                        <TableCell>
                            <label>Inbox Placement</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.inboxPlacement}
                        </TableCell>
                    </TableRow>*/}
                    <TableRow>
                        <TableCell>
                            <label>Communication Sent Date</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.sendDate
                                ? getFixedDate(record.sendDate)
                                : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Delivery Date</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.deliveryDate
                                ? getFixedDate(record.deliveryDate)
                                : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Read (Opened) Date</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.openedDate
                                ? getFixedDate(record.openedDate)
                                : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            {record.channel === 'email' ? (
                                <label>Clicked Date</label>
                            ) : (
                                <label>Received Reply Date</label>
                            )}
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.clickedDate
                                ? getFixedDate(record.clickedDate)
                                : '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <div
                                style={{
                                    width: '145%',
                                    height: '1.5rem',
                                    backgroundColor: '#EEF3F8',
                                    marginLeft: '-4rem',
                                    borderTop: '1px solid #E5E9F2',
                                    borderBottom: '1px solid #E5E9F2',
                                }}
                            ></div>
                        </TableCell>
                        <TableCell>
                            <div
                                style={{
                                    width: '120%',
                                    height: '1.5rem',
                                    backgroundColor: '#EEF3F8',
                                    borderTop: '1px solid #E5E9F2',
                                    borderBottom: '1px solid #E5E9F2',
                                }}
                            ></div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Has attachments</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.hasAttachment ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Attachments</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.attachmentTypesAndIds
                                    ? record.attachmentTypesAndIds.map(
                                          attachment => (
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      gap: '1rem',
                                                      marginBottom: '1rem',
                                                  }}
                                              >
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'estimate' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/quote?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'quotation' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/quote?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'requestnote' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/note?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'estimate' &&
                                                  attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'quotation' &&
                                                  attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'requestnote' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${
                                                              settings.apiUrl
                                                          }/pdf/${attachment.attachmentType
                                                              .slice(0, -3)
                                                              .toLowerCase()}?id=${
                                                              attachment.attachmentId
                                                          }`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                              </div>
                                          )
                                      )
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Recource Type</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.resourceType}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Recource</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.resourceType && record.resourceId ? (
                                    <a
                                        style={{
                                            color: '#1890ff',
                                        }}
                                        href={
                                            process.env.REACT_APP_ENV ===
                                            'staging'
                                                ? `https://test19.boxitstorage.com/#/${record.resourceType}s/${record.resourceId}/show`
                                                : `https://admin.boxitstorage.com/#/${record.resourceType}s/${record.resourceId}/show`
                                        }
                                    >
                                        {record.resourceId}
                                    </a>
                                ) : null}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

CommunicationDetails.propTypes = {
    record: PropTypes.object,
};

export default CommunicationDetails;
