import React from 'react';
import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
} from 'react-admin';
import { OfferType } from '../constants';
import { TitleProps, Offer } from '../types';

const OfferTitle: React.FC<TitleProps<Offer>> = ({ record }) => (
    <span>Offer {record ? `"${record.name}"` : ''}</span>
);

const OfferEdit = props => (
    <Edit title={<OfferTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="id" />
            <SelectInput
                source="type"
                choices={Object.keys(OfferType).map(c => ({ id: c, name: c }))}
            />
            <TextInput source="description" />
            <NumberInput source="discount" />
            <NumberInput source="percent" />
            <NumberInput source="limit" />
            <NumberInput source="reward" />

            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default OfferEdit;
