import React, { useState } from 'react';
import { ListButton, EditButton, TopToolbar } from 'react-admin';
import { ActionProps, Card } from '../../types';
import DeleteButton from '../../components/DeleteButton';
import { useNotify } from 'react-admin';
import feathersClient from '../../rest/feathersClient';
import Popup from '../../components/popup';
import { Button, Input } from '@material-ui/core';
import styles from './testStyles.module.css';
import EmailIcon from '@material-ui/icons/MailOutline';

const CardShowButtons: React.FC<ActionProps<Card>> = ({ basePath, data }) => {
    const [isEmailTestPopupOpen, setIsEmailTestPopupOpen] = useState(false);
    const [testEmailEntry, setTestEmailEntry] = useState<string>('');
    const [isWhatsappTestPopupOpen, setIsWhatsappTestPopupOpen] = useState(
        false
    );
    const [testWhatsAppEntry, setTestWhatsAppEntry] = useState<string>('');
    const notify = useNotify();
    const testEmail = record => {
        const emailTemplate = feathersClient.service(
            'communication-templates-testing'
        );
        console.log(data);
        emailTemplate
            .create({
                email: testEmailEntry,
                templateName: data && data.templateName,
                countryId: data && data.countryId,
            })
            .then(res => {
                notify(`Test sent`, 'success');
                setIsEmailTestPopupOpen(false);
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };

    const testWhatsapp = () => {
        const emailTemplate = feathersClient.service(
            'communication-templates-testing'
        );
        emailTemplate
            .create({
                phone: testWhatsAppEntry,
                templateName: data && data.watiTempName,
                countryId: data && data.countryId,
            })
            .then(res => {
                notify(`Test sent`, 'success');
                setIsWhatsappTestPopupOpen(false);
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };
    return (
        <TopToolbar>
            <Button
                startIcon={<EmailIcon />}
                style={{ marginTop: '-2px', marginRight: '5px' }}
                onClick={() => {
                    setIsWhatsappTestPopupOpen(true);
                }}
            >
                test whatsapp
            </Button>
            <Button
                startIcon={<EmailIcon />}
                style={{ marginTop: '-2px' }}
                onClick={() => {
                    setIsEmailTestPopupOpen(true);
                }}
            >
                test email
            </Button>
            <EditButton color="secondary" basePath={basePath} record={data} />
            {/*<DeleteButton
                basePath={basePath}
                record={data}
                resource={resource}
                undoable={false}
    />*/}
            <ListButton color="secondary" basePath={basePath} />
            {isWhatsappTestPopupOpen && (
                <Popup
                    cancel={() => {
                        setIsWhatsappTestPopupOpen(false);
                    }}
                >
                    <div className={styles.testPopup}>
                        <h2 className={styles.title}>Test Whatsapp</h2>
                        <Input
                            className={styles.input}
                            onChange={e => {
                                setTestWhatsAppEntry(e.target.value);
                            }}
                            placeholder="Enter Number"
                            type="number"
                        />
                        <div className={styles.actions}>
                            <button
                                className={[styles.btn, styles.btn_cancel].join(
                                    ' '
                                )}
                                onClick={() => {
                                    setIsWhatsappTestPopupOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={[styles.btn, styles.btn_charge].join(
                                    ' '
                                )}
                                disabled={testWhatsAppEntry === ''}
                                onClick={testWhatsapp}
                            >
                                Test
                            </button>
                        </div>
                    </div>
                </Popup>
            )}
            {isEmailTestPopupOpen && (
                <Popup
                    cancel={() => {
                        setIsEmailTestPopupOpen(false);
                    }}
                >
                    <div className={styles.testPopup}>
                        <h2 className={styles.title}>Test Email</h2>
                        <Input
                            className={styles.input}
                            onChange={e => {
                                setTestEmailEntry(e.target.value);
                            }}
                            type="email"
                            placeholder="Enter Email"
                        />
                        <div className={styles.actions}>
                            <button
                                className={[styles.btn, styles.btn_cancel].join(
                                    ' '
                                )}
                                onClick={() => {
                                    setIsEmailTestPopupOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={[styles.btn, styles.btn_charge].join(
                                    ' '
                                )}
                                disabled={testEmailEntry === ''}
                                onClick={testEmail}
                            >
                                Test
                            </button>
                        </div>
                    </div>
                </Popup>
            )}
        </TopToolbar>
    );
};

export default CardShowButtons;
