import React from 'react';
import { ReferenceManyField } from 'react-admin';
import ChargeGrid from '../../charges/components/ChargeGrid';

export const OrderChargesList = props => (
    <ReferenceManyField
        reference="charges"
        target="orderId"
        filter={{ orderId: props.record.id }}
        {...props}
    >
        <ChargeGrid {...props} />
    </ReferenceManyField>
);
