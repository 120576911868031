import React from 'react';
import { Sidebar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useSidebarStyles = makeStyles(theme => ({
    drawerPaper: {
        marginTop: '0',
        height: '100%',
        minHeight: '100vh',
        ...(theme.palette.type === 'light' && {
            backgroundColor: '#fff',
            borderRight: '1px solid #e8e8e8',
        }),
    },
}));

const CustomSidebar = props => {
    const classes = useSidebarStyles();
    return <Sidebar classes={classes} {...props} />;
};

export default CustomSidebar;
