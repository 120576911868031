// in LatLongInput.js
import { useInput } from 'react-admin';
import React, { useEffect, useRef, useState } from 'react';

const DropdownSearchInput = props => {
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);
    const [foundItems, setFoundItems] = useState<any[]>(props.choices);
    const [showList, setShowList] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState<string>('');
    const dropDownInput = useRef(null);
    const dropDownArea = useRef(null);
    const inputContainer = useRef(null);

    const searchChoices = searchTerm => {
        console.log(error);
        setCurrentValue(searchTerm);
        setFoundItems(() => {
            const newFoundItem = props.choices.filter(choice => {
                return choice.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            return newFoundItem;
        });
    };

    useEffect(() => {
        const handleClickAway = (e: MouseEvent) => {
            if (
                dropDownInput.current &&
                !(dropDownInput.current as any).contains(e.target) &&
                dropDownArea.current &&
                !(dropDownArea.current as any).contains(e.target)
            ) {
                setShowList(false);
            }
        };
        document.body.addEventListener('click', handleClickAway);
        setCurrentValue(() => {
            const valueIndex = props.choices.findIndex(
                choice => rest.value === choice.id
            );
            if (valueIndex !== -1) {
                return props.choices[valueIndex].name;
            } else {
                return '';
            }
        });
        return () => {
            document.body.removeEventListener('click', handleClickAway);
        };
    }, []);

    useEffect(() => {}, [currentValue]);

    return (
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                zIndex: 10000,
                marginBottom: '1.5rem',
            }}
            ref={inputContainer}
        >
            <label style={{ color: '#8492A6', marginBottom: '4px' }}>
                {props.label}
            </label>
            <input
                onChange={e => {
                    searchChoices(e.target.value);
                }}
                onFocus={() => {
                    setShowList(true);
                }}
                value={currentValue}
                style={{
                    width: '256px',
                    height: '2.5rem',
                    border: '1px solid #d2d6dc',
                    borderRadius: '4px',
                    padding: '18.5px 14px',
                }}
                placeholder={props.label}
                ref={dropDownInput}
            />
            {error && touched ? <span>{error}</span> : null}
            {showList && (
                <div
                    style={{
                        position: 'absolute',
                        top: '4.5rem',
                        left: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        maxHeight: '20rem',
                        overflowY: 'scroll',
                        boxShadow:
                            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                        backgroundColor: 'white',
                        minWidth: '256px',
                        borderRadius: '4px',
                        zIndex: 10000,
                    }}
                    ref={dropDownArea}
                >
                    {foundItems.map(item => {
                        return (
                            <div
                                style={{
                                    textAlign: 'start',
                                    padding: '.5rem',
                                    width: '100%',
                                    cursor: 'pointer',
                                    backgroundColor:
                                        item.name === currentValue
                                            ? 'rgba(0, 0, 0, 0.08)'
                                            : undefined,
                                }}
                                onClick={() => {
                                    onChange(item.id);
                                    setCurrentValue(item.name);
                                    setShowList(false);
                                }}
                            >
                                {item.name}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default DropdownSearchInput;
