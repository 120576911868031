import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import CardIcon from './CardIcon';
import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    title: {
        padding: '0 16px',
    },
});

export default ({ value }) => {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.main}>
            <CardIcon Icon={ShoppingCartIcon} bgColor="#ff9800" />
            <Card className={classes.card}>
                <Typography
                    variant="body2"
                    className={classes.title}
                    color="textSecondary"
                >
                    {translate('pos.dashboard.new_orders')}
                </Typography>
                <Typography variant="h4">{value}</Typography>
            </Card>
        </div>
    );
};
