export const baseUnitMultiplier = 10000;

export const AddressType = {
    home: 'home',
    office: 'office',
};

export const Country = {
    UAE: 'UAE',
    Kuwait: 'Kuwait',
    SaudiArabia: 'ksa',
};

export const CountryCode = {
    '1': 'AE',
    '2': 'KW',
    '3': 'SA',
};

export const City = {
    Dubai: 'Dubai',
    AbuDhabi: 'Abu Dhabi',
    Sharjah: 'Sharjah',
    Ajman: 'Ajman',
    AlAin: 'Al Ain',
    Kuwait: 'Kuwait',
    riyadh: 'riyadh',
};

export const City2Country = {
    Dubai: 'UAE',
    AbuDhabi: 'UAE',
    'Abu Dhabi': 'UAE',
    Sharjah: 'UAE',
    Ajman: 'UAE',
    AlAin: 'UAE',
    'Al Ain': 'UAE',
    Kuwait: 'Kuwait',
    riyadh: 'ksa',
};

export const UserType = {
    staff: 'staff',
    b2c: 'b2c',
    sme: 'sme',
    partner: 'partner',
    // business: 'business',
    // enterprise: 'enterprise',
};

export const TZ = {
    UAE: 'Asia/Dubai',
    Dubai: 'Asia/Dubai',
    AbuDhabi: 'Asia/Dubai',
    Sharjah: 'Asia/Dubai',
    Ajman: 'Asia/Dubai',
    AlAin: 'Asia/Dubai',
    Kuwait: 'Asia/Kuwait',
    riyadh: 'Asia/Riyadh',
};

export const Currency = {
    uae: 'AED',
    kuwait: 'KWD',
    saudi: 'SAR',
};

export const CurrencyByCountryId = {
    1: 'AED',
    2: 'KWD',
    3: 'SAR',
};

export const StorageStatus = {
    new: 'new',
    forpickup: 'forpickup',
    pickedup: 'pickedup',
    in: 'in',

    stored: 'stored',

    fordelivery: 'fordelivery',
    out: 'out',
    delivered: 'delivered',

    closed: 'closed',
    cancelled: 'cancelled',
    transfer: 'transfer',

    for_discard: 'for_discard',
    discard: 'discard',
    unknown: 'unknown',
    damaged: 'damaged',
    lost: 'lost',
    missing: 'missing',
    not_returned: 'not_returned',
    active: 'active',
};

export const RequestStatus = {
    pending: 'pending',
    new: 'new',
    confirmed: 'confirmed',
    onhold: 'onhold',
    approved: 'approved',
    transit: 'transit',
    received: 'received',
    delivered: 'delivered',
    complete: 'complete',
    rejected: 'rejected',
    cancelled: 'cancelled',
};

export const RequestType = {
    newbox: 'newbox',
    pickup: 'pickup',
    delivery: 'delivery',
    drop: 'drop',
    collect: 'collect',
    close: 'close',
    survey: 'survey',
    scrap: 'scrap',
    transfer: 'transfer',
    moving: 'moving',
    shipping: 'shipping',
    other_services: 'other_services',
};

export const PaymentMode = {
    credits: 'credits',
    cod: 'cod',
    tap: 'tap',
    payfort: 'payfort',
    cheque: 'cheque',
    bank_deposit: 'bank_deposit',
    bank_transfer: 'bank_transfer',
    deposit: 'deposit',
};

export const PaymentStatus = {
    pending: 'pending',
    complete: 'complete',
    failed: 'failed',
    refunded: 'refunded',
};

export const PaymentType = {
    payment: 'payment',
    refund: 'refund',
    deposit: 'deposit',
};

export const PaymentMethod = {
    cash: 'cash',
    card: 'card',
    cheque: 'cheque',
};

export const OfferType = {
    discount: 'discount',
    percent: 'percent',
};

export const InvoiceItemType = {
    rent: 'rent',
    delivery: 'delivery',
    coupon: 'coupon',
    credits: 'credits',
    discount: 'discount',
};

export const InvoiceStatus = {
    unpaid: 'unpaid',
    paid: 'paid',
    partial: 'partial',
    cancelled: 'cancelled',
};

export const UserStatus = {
    new: 'new',
    active: 'active',
    inactive: 'inactive',
    rejected: 'rejected',
    suspended: 'suspended',
};

export const BoxStatus = {
    new: 'new',
    empty: 'empty',
    assigned: 'assigned',
    damaged: 'damaged',
    unknown: 'unknown',
    other: 'other',
    lost: 'lost',
    missing: 'missing',
    not_returned: 'not_returned',
};

export const OrderStatus = {
    locked: 'locked',
    //pending: 'pending',
    new: 'new',
    renew: 'renew',
    rejected: 'rejected',
    quote_sent: 'quote_sent',
    quote_accepted: 'quote_accepted',
    quote_rejected: 'quote_rejected',
    lost: 'lost',
    confirmed: 'confirmed',
    onhold: 'onhold',
    cancelled: 'cancelled',
    complete: 'complete',
};

export const StorageRecordStatus = {
    in: 'in',
    out: 'out',
};

export const StorageRecordType = {
    check_in: 'check_in',
    check_out: 'check_out',
    storage: 'storage',
};

export const ChargeType = {
    storage: 'storage',
    delivery: 'delivery',
    pickup: 'pickup',
    packing: 'packing',
    loading: 'loading',
    pickup_packing: 'pickup_packing',
    pickup_delivery: 'pickup_delivery',
    pickup_packing_delivery: 'pickup_packing_delivery',
    discount: 'discount',
    credit: 'credit',
    custom: 'custom',
    service: 'service',
    order: 'order',
    deposit: 'deposit',
};

export const StorageType = {
    BB: 1,
    ITEM: 2,
};

export const PickupTimeFrom = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
export const PickupTimeTo = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

export const RequestStatusColor = {
    [RequestStatus.pending]: 'red',
    [RequestStatus.new]: 'purple',
    [RequestStatus.confirmed]: 'orange',
    [RequestStatus.approved]: 'green',
    [RequestStatus.transit]: 'cyan',
    [RequestStatus.received]: 'cyan',
    [RequestStatus.delivered]: 'cyan',
    [RequestStatus.onhold]: 'brown',
    [RequestStatus.cancelled]: 'grey',
    [RequestStatus.rejected]: 'grey',
    [RequestStatus.complete]: '#3174ad',
};

export const OrderStatusColor = {
    //[OrderStatus.pending]: 'red',
    [OrderStatus.new]: 'purple',
    [OrderStatus.quote_sent]: 'orange',
    [OrderStatus.quote_accepted]: 'purple',
    [OrderStatus.quote_rejected]: 'purple',
    [OrderStatus.confirmed]: 'green',
    [OrderStatus.onhold]: 'brown',
    [OrderStatus.cancelled]: 'grey',
    [OrderStatus.rejected]: 'grey',
    [OrderStatus.complete]: '#3174ad',
};
export const OrderStatusBorderColor = {
    //[OrderStatus.pending]: '#D9D9D9',
    [OrderStatus.new]: '#1890FF',
    [OrderStatus.quote_sent]: '#FAAD14',
    [OrderStatus.confirmed]: '#52C41A',
    [OrderStatus.onhold]: 'brown',
    [OrderStatus.cancelled]: '#D9D9D9',
    [OrderStatus.rejected]: '#D9D9D9',
    [OrderStatus.complete]: '#FA541C',
};

export const CustomerPersona = {
    commercial: 'Commercial',
    personal: 'Personal',
    female_student: 'Female Student',
    female_single: 'Single Lady',
    female_married: 'Married Lady',
    female_young_kids: 'Mother with young kids',
    female_old_kids: 'Mother with older kids',
    female_old: 'Old Lady',
    male_student: 'Male Student',
    male_single: 'Single Man',
    male_married: 'Married Man',
    male_young_kids: 'Father with young kids',
    male_old_kids: 'Father with older kids',
    male_old: 'Old Man',
};

export const InvoiceType = {
    order: 'order',
    recurring: 'recurring',
    final: 'final',
    service: 'service',
    other: 'other',
    creditNote: 'creditNote',
    deposit: 'deposit',
};

export const OrderType = {
    storage: 'storage',
    moving: 'moving',
    sale: 'sale',
    packing: 'packing',
    shipping: 'shipping',
    other_services: 'other_services',
};

export const Caption = {
    entry_permit: 'Entry Permit',
};

export const Source = {
    phone: 'Phone',
    whatsapp: 'WhatsApp',
    email: 'Email',
    partner: 'Partner',
    website: 'Website',
};
