import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { EmailTemplateDetails } from './EmailTemplateDetails';
import { EmailTemplateBody } from './EmailTemplateBody';
import { EmailTemplateFields } from './EmailTemplateFields';
import { EmailTemplateWhatsapp } from './EmailTemplateWhatsapp';

export const EmailTemplateTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="fields">
                <EmailTemplateFields />
            </Tab>
            <Tab label="Details">
                <EmailTemplateDetails />
            </Tab>
            <Tab label="Body">
                <EmailTemplateBody />
            </Tab>
            <Tab label="Whatsapp">
                <EmailTemplateWhatsapp />
            </Tab>
        </TabbedShowLayout>
    );
};

EmailTemplateTabs.propTypes = {
    record: PropTypes.object,
};

EmailTemplateTabs.defaultProps = {
    record: {},
};
