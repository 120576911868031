import React from 'react';
import moment from 'moment';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { userBill as userBillAction } from '../actions/userActions';
import { Button, TextField, Paper, CircularProgress } from '@material-ui/core';
import { User } from '../../types';

const useStyles = makeStyles(theme => ({
    container: {
        margin: 30,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    actions: {
        marginTop: 30,
    },
    button: {
        margin: '10px 24px',
        position: 'relative',
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}));

type Styles = {
    container: string;
    actions: string;
    iconPaddingStyle: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    classes: Styles;
    saving: boolean;
    record: User;
    invalid: boolean;
    showNotification: (arg0: string, arg1: string) => void;
};

type State = {
    date: string;
};

class UserBillTillDateForm extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('YYYY-MM-DD'),
        };
    }

    handleClick = e => {
        const {
            record,
            invalid,
            saving,
            showNotification,
            userBill,
        } = this.props;

        const { date } = this.state;

        if (saving) {
            // prevent double submission
            e.preventDefault();
        } else {
            if (invalid) {
                showNotification('ra.message.invalid_form', 'warning');
            }
            // always submit form explicitly regardless of button type
            if (e) {
                e.preventDefault();
            }
            userBill({ userId: record.id, date });
        }
    };

    render() {
        const { classes, saving } = this.props;

        return (
            <div className={classes.container}>
                <h2>Bill Customer</h2>
                <form noValidate>
                    <TextField
                        label="Till Date"
                        type="date"
                        defaultValue={this.state.date}
                        onChange={e => this.setState({ date: e.target.value })}
                    />
                    <div className={classes.actions}>
                        <Button
                            variant="contained"
                            color={saving ? 'default' : 'secondary'}
                            disabled={saving}
                            onClick={this.handleClick}
                        >
                            {saving && (
                                <CircularProgress
                                    size={25}
                                    thickness={2}
                                    className={classes.iconPaddingStyle}
                                />
                            )}
                            Create Invoice
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapState = state => ({
    saving: state.admin.saving,
});

const mapDispatch = {
    userBill: userBillAction,
    showNotification: showNotificationAction,
};

const connector = connect(mapState, mapDispatch);

const ConnectedUserBillTillDateForm = connector(UserBillTillDateForm);

export default props => {
    const classes = useStyles();
    return <ConnectedUserBillTillDateForm classes={classes} {...props} />;
};
