import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
    BooleanInput,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    NumberInput,
} from 'react-admin';
import { StorageStatus } from '../constants';
import MoneyInput from '../components/MoneyInput';
import { CreateProps, Storage } from '../types';
import { useSelector } from 'react-redux';
import feathersClient from '../rest/feathersClient';
import { Switch, makeStyles } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { useRedirect } from 'react-admin';
import { Toolbar } from 'react-admin';
import { SaveButton } from 'react-admin';

const useStyles = makeStyles({
    spaceBetween: { justifyContent: 'space-between' },
});

const StorageCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const classes = useStyles();
    const { permissions, record } = props;

    const onSuccess = data => {
        if ('0' in data.data) {
            notify(`Storage "${data.data['0'].id}" saved!`);
            redirect(`/storages/${data.data['0'].id}/show`);
        } else {
            notify(`Storage "${data.data.id}" saved!`);
            redirect(`/storages/${data.data.id}/show`);
        }
    };

    return (
        <Toolbar {...props} className={classes.spaceBetween}>
            <SaveButton disabled={props.pristine} onSuccess={onSuccess} />
        </Toolbar>
    );
};

const cbmValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const cbmActualValidation = (value, allValues) => {
    if (allValues.height && allValues.width && allValues.length) {
        if (value !== allValues.height * allValues.width * allValues.length) {
            return 'Size is not equal to width x height x width';
        }
    }
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const eightDigitsValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 8
    ) {
        return 'Only 8 digits after the decimal points are allowed';
    }
    return undefined;
};

const threeDigitsValidation = (value, allValues) => {
    if (value !== null && value <= 0) {
        return 'Value must be more than 0 up to 10';
    }
    if (value !== null && value > 10) {
        return 'Value must be more than 0 up to 10';
    }
    if (
        value !== undefined &&
        value !== null &&
        value.toString().length > 0 &&
        value.toString().includes('.') &&
        value.toString().split('.')[1] &&
        value.toString().split('.')[1].length > 3
    ) {
        return 'Only 3 digits after the decimal points are allowed';
    }
    return undefined;
};

const StorageCreate: React.FC<CreateProps<Storage>> = ({
    permissions = [],
    ...props
}) => {
    const [currentStorageTypeId, setCurrentStorageTypeId] = useState<any>(null);
    const [currentStorageTypeRecord, setCurrentStorageTypeRecord] = useState<
        any
    >(null);
    const currentStorageTypeRecordRef = useRef<any>(null);
    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.storages?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });
    const isSyncConsumedWithActual = useRef<boolean>(false);
    const [isDisableConsumed, setIsDisableConsumed] = useState<boolean>(false);

    const getStorageTypes = () => {
        const storageTypesService = feathersClient.service('storage-types');
        storageTypesService
            .find({
                query: { id: currentStorageTypeId },
            })
            .then(res => {
                setCurrentStorageTypeRecord(res.data[0]);
                currentStorageTypeRecordRef.current = res.data[0];
            })
            .catch(err => {});
    };

    useEffect(() => {
        if (currentStorageTypeId) {
            getStorageTypes();
        }
    }, [currentStorageTypeId]);

    const transform = data => {
        return {
            ...data,
            sizeEntered:
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.size
                    : data.sizeEntered,
            consumedSize:
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.size
                    : isSyncConsumedWithActual.current
                    ? data.sizeEntered
                    : data.consumedSize,
            length:
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.length
                    : data.length,
            height:
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.height
                    : data.height,
            width:
                currentStorageTypeRecordRef.current &&
                currentStorageTypeRecordRef.current.inventory
                    ? currentStorageTypeRecordRef.current.width
                    : data.width,
        };
    };

    return (
        <Create
            successMessage="resources.storages.notification.create_success"
            transform={transform}
            {...props}
        >
            <SimpleForm
                variant="outlined"
                redirect="show"
                toolbar={
                    <StorageCreateToolbar
                        permissions={permissions}
                        {...props}
                    />
                }
            >
                {permissions.indexOf('staff') !== -1 && (
                    <ReferenceInput
                        label="Partner"
                        source="partnerId"
                        reference="partners"
                        resource="partners"
                        defaultValue={selectedCustomer?.partnerId}
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}

                <ReferenceInput
                    label="User"
                    source="userId"
                    resource="users"
                    reference="users"
                    allowEmpty
                    defaultValue={selectedCustomer?.id}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                        defaultValue={selectedCustomer?.id}
                    />
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceInput
                                label="Order"
                                source="orderId"
                                reference="orders"
                                resource="orders"
                                filter={{ userId: formData.userId }}
                                allowEmpty
                            >
                                <SelectInput
                                    source="orderId"
                                    optionValue="id"
                                    optionText={record => `Order #${record.id}`}
                                />
                            </ReferenceInput>
                        )
                    }
                </FormDataConsumer>
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default length value for storage type:{' '}
                        {currentStorageTypeRecord.length}
                    </span>
                )}
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default height value for storage type:{' '}
                        {currentStorageTypeRecord.height}
                    </span>
                )}
                {currentStorageTypeRecord && (
                    <span style={{ fontSize: '.8rem' }}>
                        Default width value for storage type:{' '}
                        {currentStorageTypeRecord.width}
                    </span>
                )}
                <ReferenceInput
                    label="StorageType"
                    source="storagetypeId"
                    reference="storage-types"
                    resource="storage-types"
                    allowEmpty
                    validation={{ required: true }}
                    format={val => {
                        setCurrentStorageTypeId(val);
                        return val;
                    }}
                    filter={{ inventory: false }}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText="name"
                    />
                </ReferenceInput>

                <BooleanInput label="Damaged" source="damage" />
                <TextInput source="code" parse={v => (v === '' ? null : v)} />
                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <NumberInput
                        source="sizeEntered"
                        label="Actual CBM Size (test)"
                        validate={eightDigitsValidation}
                        disabled={
                            currentStorageTypeRecord
                                ? currentStorageTypeRecord.inventory
                                : false
                        }
                    />
                ) : null}

                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Switch
                            onChange={value => {
                                isSyncConsumedWithActual.current = !isSyncConsumedWithActual.current;
                                setIsDisableConsumed(prev => !prev);
                            }}
                            value={isSyncConsumedWithActual}
                        />
                        <h3>
                            synchronize the consumed size with the actual size
                        </h3>
                    </div>
                ) : null}

                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <NumberInput
                        source="consumedSize"
                        label="Consumed Size (test)"
                        validate={eightDigitsValidation}
                        disabled={
                            isDisableConsumed ||
                            (currentStorageTypeRecord
                                ? currentStorageTypeRecord.inventory
                                : false)
                        }
                    />
                ) : null}
                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <NumberInput
                        source="length"
                        label="Length"
                        validate={threeDigitsValidation}
                        disabled={
                            currentStorageTypeRecord
                                ? currentStorageTypeRecord.inventory
                                : false
                        }
                    />
                ) : null}
                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <NumberInput
                        source="height"
                        label="Height"
                        validate={threeDigitsValidation}
                        disabled={
                            currentStorageTypeRecord
                                ? currentStorageTypeRecord.inventory
                                : false
                        }
                    />
                ) : null}
                {currentStorageTypeRecord &&
                !currentStorageTypeRecord.inventory ? (
                    <NumberInput
                        source="width"
                        label="Width"
                        validate={threeDigitsValidation}
                        disabled={
                            currentStorageTypeRecord
                                ? currentStorageTypeRecord.inventory
                                : false
                        }
                    />
                ) : null}

                <NumberInput
                    source="size"
                    label="Size in CBM"
                    validate={eightDigitsValidation}
                />
                <TextInput source="name" />
                <TextInput source="description" multiline />
                <DateInput
                    source="form"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />
                <DateInput
                    source="to"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />
                <ReferenceInput
                    label="Country"
                    source="countryId"
                    reference="country"
                    resource="country"
                    defaultValue={selectedCustomer?.countryId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <SelectInput
                    source="status"
                    choices={Object.keys(StorageStatus).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />
            </SimpleForm>
        </Create>
    );
};

export default StorageCreate;
