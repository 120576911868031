import React from 'react';
import PropTypes from 'prop-types';
import { timeRange } from '../utils';

const TimeSlot = ({ from, to }) => <span>{timeRange(from, to)}</span>;

TimeSlot.propTypes = {
    from: PropTypes.number.isRequired,
    to: PropTypes.number.isRequired,
};

export default TimeSlot;
