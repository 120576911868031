import React, { Component } from 'react';
import _ from 'lodash';
import { ListButton, TopToolbar } from 'react-admin';
import { DeleteButton } from 'react-admin';
import { EditButton } from 'react-admin';

class ReasonShowActions extends Component<any, any> {
    render() {
        const { basePath, data, resource } = this.props;

        return (
            <TopToolbar>
                <EditButton
                    color="secondary"
                    basePath={basePath}
                    record={data}
                />
                <ListButton color="secondary" basePath={basePath} />
                <DeleteButton
                    basePath={basePath}
                    record={data}
                    resource={resource}
                    undoable={false}
                />
            </TopToolbar>
        );
    }
}

export default ReasonShowActions;
