import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    icon: {
        fontSize: '20px',
    },
    container: {
        margin: '15px 0px -15px 0px !important',
    },
});
const CustomSearchInput = ({ alwaysOn, ...props }) => {
    const classes = useStyles();
    return (
        <TextInput
            variant="outlined"
            label="Search"
            source="q"
            alwaysOn
            className={classes.container}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" className={classes.icon}>
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    );
};
CustomSearchInput.propTypes = {
    alwaysOn: PropTypes.bool,
};
CustomSearchInput.defaultProps = {
    alwaysOn: true,
};

export default CustomSearchInput;
