import React from 'react';
import { Edit } from 'react-admin';
import { required } from 'react-admin';
import { SelectInput } from 'react-admin';

import { SimpleForm, TextInput } from 'react-admin';
import { handleTagsError, handleUserError } from '../users/error';
import { useNotify } from 'react-admin';

const TagTitle = ({ record }: { record?: any }) => (
    <span>Edit {record ? `"${record.name}"` : ''}</span>
);

const TagEdit = props => {
    const notify = useNotify();
    return (
        <Edit
            title={<TagTitle />}
            undoable={false}
            {...props}
            onFailure={error => handleTagsError(error, notify)}
        >
            <SimpleForm variant="outlined" redirect="show">
                <TextInput source="name" validate={[required()]} />
                <SelectInput
                    validate={[required()]}
                    source="module"
                    choices={[
                        { id: 'Generic', name: 'Generic' },
                        { id: 'User', name: 'User' },
                    ]}
                />
            </SimpleForm>
        </Edit>
    );
};

export default TagEdit;
