import React, { useEffect, useRef, useState } from 'react';
import classes from './dropdownlist.module.css';

const DropDownList = props => {
    const [value, setValue] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const listButtonRef = useRef(null);
    const listRef = useRef(null);

    useEffect(() => {
        const handleClickAway = (e: MouseEvent) => {
            if (
                listButtonRef.current &&
                !(listButtonRef.current as any).contains(e.target) &&
                listRef.current &&
                !(listRef.current as any).contains(e.target)
            ) {
                setIsMenuOpen(false);
            }
        };
        document.body.addEventListener('click', handleClickAway);
        return () => {
            document.body.removeEventListener('click', handleClickAway);
        };
    }, []);

    const selectValue = selectedValue => {
        setValue(selectedValue);
        props.onChange(selectedValue, props.additional);
        setIsMenuOpen(false);
    };

    return (
        <div className={classes.dropdownlist}>
            <div
                onClick={() => setIsMenuOpen(true)}
                className={classes.button}
                ref={listButtonRef}
            >
                <h3>
                    {value
                        ? value
                        : props.defaultValue
                        ? props.defaultValue
                        : props.label}
                </h3>
            </div>
            {isMenuOpen ? (
                <div className={classes.list} ref={listRef}>
                    {props.options.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => selectValue(option.value)}
                            className={classes.list_item}
                        >
                            {option.text}
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default DropDownList;
