import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect, ConnectedProps } from 'react-redux';
import { Field } from 'react-final-form';
import {
    crudGetManyReference as crudGetManyReferenceAction,
    NumberInput,
    GET_ONE,
    DateInput,
} from 'react-admin';
import {
    computeOrderSize,
    computeStorageRate,
    computeMonthlyRent,
} from '../../core/utils/pricing';
import Money from '../../components/Money';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    InputAdornment,
} from '@material-ui/core';
import {
    AppState,
    Order,
    StorageType,
    ServiceType,
    PartnerCommissionSlab,
} from '../../types';
import { dataProvider } from '../../rest';
import { CurrencyByCountryId, OrderType } from '../../constants';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    record: Order;
    storageTypes: StorageType[];
    serviceTypes: ServiceType[];
};

class BillingCard extends Component<Props> {
    state = {
        storageRate: 0,
    };
    componentDidMount() {
        this.fetchPartnerCommissionSlabs();
        this.fetchUserStorageRate(this.props.record.userId);
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEqual(nextProps.record, this.props.record)) {
            this.fetchPartnerCommissionSlabs();
        }

        /*if (this.props.record && this.props.record.userId) {
            this.fetchUserStorageRate(this.props.record.userId);
        }*/
    }

    fetchUserStorageRate(userId) {
        if (userId) {
            dataProvider(GET_ONE, 'users', {
                id: userId,
            }).then(response => {
                if (response.data && response.data.storageRate) {
                    this.setState({ storageRate: response.data.storageRate });
                }
            });
        }
    }

    fetchPartnerCommissionSlabs() {
        const { record, crudGetManyReference } = this.props;

        if (record && record.partnerId && record.partnerId != 1) {
            crudGetManyReference(
                'partner-commission-slabs',
                'partnerId',
                record.partnerId,
                null, // relatedTo,
                null, // pagination,
                null, // sort,
                { target: { $lt: record.size || 1 } } // filter
            );
        }
    }

    render() {
        const {
            record,
            country,
            storageTypes,
            serviceTypes,
            partnerCommissionSlabs,
        } = this.props;
        const { storageRate } = this.state;
        const storageItems = record.items && record.items.filter(item => item);
        const currency = CurrencyByCountryId[record.countryId];
        const amount = record.amount || 0;
        const baseUnitMultiplier = 10000;
        const precision = country ? country.decimalPlaces : 2;

        const computedSize = computeOrderSize(storageItems, storageTypes);
        const computedRate = computeStorageRate(computedSize, record.countryId);
        const computedRent = computeMonthlyRent(
            computedSize,
            storageRate || computedRate,
            record.countryId
        );

        const services = record.services && record.services.filter(s => s);
        const quotedMonthlyRent = amount;

        const isStorageOrder = record.type === OrderType.storage;
        const isPartnerOrder = record.partnerId && record.partnerId !== 1;

        function findCommissionSlab(serviceCategory) {
            const slab = (partnerCommissionSlabs || []).reduce(
                (res: PartnerCommissionSlab | null, s) => {
                    if (
                        s.service_category &&
                        s.service_category.name === serviceCategory
                    ) {
                        if ((record.size || 1) > s.target) {
                            if (!res) return s;
                            if (s.target > res!.target) return s;
                        }
                    }
                    return res;
                },
                null
            );
            return slab;
        }

        const storageCommisionSlab =
            isPartnerOrder && findCommissionSlab('Storage');
        const serviceCommisionSlab =
            isPartnerOrder && findCommissionSlab('Service');

        function getServiceName(service) {
            if (service.servicetype) return service.servicetype.name;
            const serviceType = serviceTypes.find(
                (t: ServiceType) => t.id === service.servicetypeId
            );
            return serviceType && serviceType.name;
        }

        return (
            <Table>
                <TableBody>
                    {services
                        ? services.map(service => (
                              <TableRow
                                  key={service.id || service.servicetypeId}
                              >
                                  <TableCell>
                                      {getServiceName(service)}
                                  </TableCell>
                                  <TableCell style={{ textAlign: 'right' }}>
                                      <Money
                                          amount={service.amount || 0}
                                          currency={
                                              service.currency || currency
                                          }
                                          showCurrency
                                      />
                                  </TableCell>
                              </TableRow>
                          ))
                        : null}

                    {computedSize ? (
                        <TableRow>
                            <TableCell>Computed Size</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <div className="right">
                                    <span>{computedSize.toFixed(2)}</span>
                                    <span> CBM</span>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : null}

                    {computedSize && storageRate ? (
                        <TableRow>
                            <TableCell>Agreed Storage Rate</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <div className="right">
                                    <span>
                                        <Money
                                            amount={storageRate}
                                            currency={currency}
                                            showCurrency
                                        />{' '}
                                        /CBM
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : null}

                    {computedSize && !storageRate ? (
                        <TableRow>
                            <TableCell>Computed Rate</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <div className="right">
                                    <span>
                                        <Money
                                            amount={computedRate}
                                            currency={currency}
                                            showCurrency
                                        />{' '}
                                        /CBM
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : null}

                    {computedSize ? (
                        <TableRow>
                            <TableCell>Computed Rent</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <div className="right">
                                    <span>
                                        <Money
                                            amount={computedRent}
                                            currency={currency}
                                            showCurrency
                                        />
                                    </span>
                                    <span> /month</span>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : null}

                    <TableRow>
                        <TableCell>
                            <b>Quoted Storage Rate</b>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <div className="right">
                                {!!record.rate && (
                                    <b>
                                        <span>
                                            <Money
                                                amount={record.rate}
                                                currency={currency}
                                                showCurrency
                                            />
                                        </span>
                                        <span> /CBM</span>
                                    </b>
                                )}
                            </div>
                        </TableCell>
                    </TableRow>

                    {record.actualSize ? (
                        <TableRow>
                            <TableCell>Actual Size</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <div className="right">
                                    <span>{record.actualSize}</span>
                                    <span> CBM</span>
                                </div>
                            </TableCell>
                        </TableRow>
                    ) : null}

                    <TableRow>
                        <TableCell colSpan={2}>
                            <div>
                                <NumberInput
                                    variant="outlined"
                                    name="size"
                                    label="Expected Size"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                CBM
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <div>
                                <NumberInput
                                    variant="outlined"
                                    name="amount"
                                    label={isStorageOrder ? 'Rent' : 'Amount'}
                                    parse={v =>
                                        Math.round(v * baseUnitMultiplier)
                                    }
                                    format={v =>
                                        v >= 0
                                            ? +(v / baseUnitMultiplier).toFixed(
                                                  precision
                                              )
                                            : ''
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currency}
                                            </InputAdornment>
                                        ),
                                        endAdornment: isStorageOrder ? (
                                            <InputAdornment position="start">
                                                / month
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                />
                            </div>
                            <hr className="mb-4" />
                            <div>
                                <NumberInput
                                    variant="outlined"
                                    name="collectAmount"
                                    label="Team to Collect Cash"
                                    parse={v =>
                                        Math.round(v * baseUnitMultiplier)
                                    }
                                    format={v =>
                                        v >= 0
                                            ? +(v / baseUnitMultiplier).toFixed(
                                                  precision
                                              )
                                            : ''
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currency}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            {isStorageOrder && record.renewalDate && (
                                <div>
                                    <DateInput
                                        variant="outlined"
                                        label="Renewal Date"
                                        source="renewalDate"
                                        parse={v =>
                                            v && moment(v).format('YYYY-MM-DD')
                                        }
                                        style={{
                                            display: 'inline',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            )}
                        </TableCell>
                    </TableRow>
                    {isPartnerOrder && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <strong>Partner Commissions</strong>
                                <br />
                                <br />
                                <div>
                                    <Field
                                        name="partnerStorageCommissionPercent"
                                        label="Storage Commission"
                                        component={NumberInput}
                                        defaultValue={
                                            storageCommisionSlab
                                                ? storageCommisionSlab.commissionPercent
                                                : 0
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="partnerServiceCommissionPercent"
                                        label="Service Commission"
                                        component={NumberInput}
                                        defaultValue={
                                            serviceCommisionSlab
                                                ? serviceCommisionSlab.commissionPercent
                                                : 0
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

const mapState = (state: AppState, props) => {
    const order = props.record;

    const country =
        order &&
        state.admin.resources['country'] &&
        state.admin.resources['country'].data &&
        state.admin.resources['country'].data[order.countryId];

    const storageTypes =
        order &&
        state.admin.resources['storage-types'] &&
        state.admin.resources['storage-types'].data &&
        Object.values(state.admin.resources['storage-types'].data);

    const serviceTypes =
        order &&
        state.admin.resources['service-types'] &&
        state.admin.resources['service-types'].data &&
        Object.values(state.admin.resources['service-types'].data);

    const partnerCommissionSlabs =
        order &&
        state.admin.resources['partner-commission-slabs'] &&
        state.admin.resources['partner-commission-slabs'].data &&
        Object.values(
            _.pickBy(
                state.admin.resources['partner-commission-slabs'].data,
                c => c.partnerId === order.partnerId
            )
        );

    return {
        country,
        storageTypes,
        serviceTypes,
        partnerCommissionSlabs,
    };
};

const mapDispatch = {
    crudGetManyReference: crudGetManyReferenceAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(BillingCard);
