import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    ChipField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';
import DeleteButton from '../../components/DeleteButton';
import MoneyField from '../../components/MoneyField';
import CountryField from '../../components/CountryField';
import StorageTypeField from '../../components/StorageTypeField';
import { Storage } from '../../types';
import { FunctionField } from 'react-admin';

const WarehouseField = ({ record }: { label: string; record?: Storage }) => {
    const storageRecord =
        record?.storagerecords?.length && record?.storagerecords[0];
    return <span>{storageRecord?.warehouse?.name}</span>;
};

const LocationField = ({ record }: { label: string; record?: Storage }) => {
    const storageRecord =
        record?.storagerecords?.length && record?.storagerecords[0];
    return <span>{storageRecord?.location?.path}</span>;
};

export const OrderStoragesList = props => (
    <ReferenceManyField
        reference="storages"
        target="orderId"
        perPage={200}
        filter={{ orderId: props.record.id }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="code" />
            <StorageTypeField source="storagetypeId" label="Type" />
            <TextField source="qty" />
            <TextField source="size" label="CBM" />
            <FunctionField
                label="Total size"
                render={record =>
                    record.qty !== undefined && record.size !== undefined
                        ? record.qty * record.size
                        : ''
                }
            />
            <CountryField />
            <ChipField source="status" />
            <DateField locales="en-IN" source="from" />
            <DateField locales="en-IN" source="to" />
            <WarehouseField label="Warehouse" />
            <LocationField label="Location" />
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
);
