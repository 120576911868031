import 'react-quill/dist/quill.snow.css';
import React, { Fragment, useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import {
    Box,
    TextField,
    createStyles,
    Grid,
    makeStyles,
    MenuItem,
    Theme,
    Button,
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import HTMLParser from 'html-parse-stringify2';

import { PrepareBody } from '../global/interfaces/termsCondition.interface';

import { arrayFlatten } from '../utils';

type IstorageTypes = Array<string>;
type ICountries = Array<string>;
type IAllTandCList = Array<PrepareBody>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiTextField-root': {
                margin: theme.spacing(1),
                width: '22ch',
            },
            '& .MuiGrid-grid-xs-6': {
                'flex-basis': 'auto',
            },
        },
    })
);

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
];

const modules = {
    toolbar: toolbarOptions,
};

const TermsConditionsList = ({ ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const classes = useStyles();
    const [allTandCList, setAllTandCList] = useState<IAllTandCList>([]);
    const [selectedTandC, setSelectedTandC] = useState<any>();
    const [value, setValue] = useState<string | undefined>();
    const [countries, setCountries] = useState<ICountries>([]);
    const [country, setCountry] = useState(null);
    const [storageTypes, setStorageTypes] = useState<IstorageTypes>([]);
    const [storageType, setStorageType] = useState(null);

    useEffect(() => {
        const getAllTemsAndCondition = async () => {
            try {
                const _allCountries: Array<string> = [],
                    _storageTypes: Array<string> = [];
                const tAndC = await dataProvider.getList('terms-conditions', {
                    pagination: {},
                    sort: {},
                    filter: {},
                });
                // console.log('terms and conditions =>',tAndC );
                if (!tAndC) {
                    console.log('tAndC Error =>', tAndC);
                } else {
                    const allTandC = tAndC?.data;
                    if (allTandC.length > 0) {
                        allTandC.forEach((t_c: any) => {
                            //remove duplicate country and storage type and store in an array
                            const isStorageTypeExist = _storageTypes.some(
                                storageType => storageType === t_c?.type
                            );
                            const isCountryExist = _allCountries.some(
                                country => country === t_c?.countryName
                            );
                            if (!isStorageTypeExist)
                                _storageTypes.push(t_c?.type);
                            if (!isCountryExist)
                                _allCountries.push(t_c?.countryName);
                        });
                        // console.log("_storageTypes =>",_storageTypes);
                        // console.log("_allContries =>",_allCountries);
                        setStorageTypes(_storageTypes);
                        setCountries(_allCountries);
                        setAllTandCList(tAndC?.data);
                    } else {
                        console.log(
                            'terms and conditions length =>',
                            allTandC?.length
                        );
                        notify(
                            tAndC?.message || 'There is no T&C list available!',
                            'info'
                        );
                    }
                }
            } catch (error) {
                console.log('getList api:', (error as Error).message);
            }
        };
        getAllTemsAndCondition();
    }, []);

    useEffect(() => {
        const title: any = document.getElementById('react-admin-title');
        title.innerHTML = '<span>Terms and Conditions</span>';
        return () => {
            title.removeChild(title.firstChild);
        };
    }, []);

    const countrySelectHandler = event => {
        setCountry(event?.target?.value);
    };

    const storageTypeSelectHandler = event => {
        setStorageType(event?.target?.value);
    };

    const onChangeHanlder = html => {
        setValue(html);
    };

    const ListTandCHandler = () => {
        if (country && storageType) {
            const findTandC = allTandCList.find(
                t_c => t_c?.countryName === country && t_c?.type === storageType
            );
            setValue(findTandC?.htmlContent);
            setSelectedTandC(findTandC);
        } else {
            console.log('Please choose the options');
        }
    };

    const saveTandCHandler = async () => {
        console.log(selectedTandC);
        const prepareBody: PrepareBody = {
            id: selectedTandC?.id,
            tag: selectedTandC?.tag,
            title: selectedTandC?.title,
            countryId: selectedTandC?.countryId,
            countryName: selectedTandC?.countryName,
            content: [],
            htmlContent: selectedTandC?.htmlContent,
            type: selectedTandC?.type,
            userId: selectedTandC?.userId,
            createdAt: selectedTandC?.createdAt,
            updatedAt: selectedTandC?.updatedAt,
        };
        prepareBody.htmlContent = value;
        try {
            const htmlToAST = HTMLParser.parse(value);
            // console.log('HTML', '==>', value);
            // console.log('AST', '==>', JSON.stringify(htmlToAST));
            const formatedContent = arrayFlatten(htmlToAST);
            prepareBody.content = formatedContent;
            console.log('prepareBody =>', prepareBody);
            const res = await dataProvider.update('terms-conditions', {
                id: prepareBody?.id,
                data: prepareBody,
            });
            console.log('res =>', res?.data);
            if (res?.data?.status === 'Success') {
                notify(
                    res?.data?.message || 'Data Updated Successfully.',
                    'success'
                );
                const copyOfAllTandC = [...allTandCList];
                const findTandCIndex = allTandCList.findIndex(
                    tAndC => tAndC.id === prepareBody.id
                );
                copyOfAllTandC[findTandCIndex] = prepareBody;
                setAllTandCList(copyOfAllTandC);
            }
        } catch (error) {
            console.log('Error: while parsing html', (error as Error).message);
        }
    };

    return (
        // <List {...props} >
        <Fragment>
            <div className={classes.root}>
                <Grid
                    container
                    item
                    direction="row"
                    xs={12}
                    spacing={2}
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid
                        container
                        xs={6}
                        item
                        alignItems="center"
                        justify="space-between"
                        spacing={1}
                        direction="row"
                    >
                        <Grid item xs={5}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="outlined-select-country"
                                    select
                                    label="Select"
                                    value={country}
                                    onChange={countrySelectHandler}
                                    helperText="Please select your country"
                                    variant="outlined"
                                >
                                    {countries.length !== 0 &&
                                        countries.map(country => (
                                            <MenuItem
                                                key={country}
                                                value={country}
                                            >
                                                {country}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </form>
                        </Grid>
                        <Grid xs={5}>
                            <form noValidate autoComplete="off">
                                <TextField
                                    id="outlined-select-type"
                                    select
                                    label="Select"
                                    value={storageType}
                                    onChange={storageTypeSelectHandler}
                                    helperText="Please select your storage type"
                                    variant="outlined"
                                >
                                    {storageTypes.length !== 0 &&
                                        storageTypes.map(type => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </form>
                        </Grid>
                        <Grid xs={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={ListTandCHandler}
                                disabled={!country || !storageType}
                            >
                                List
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                    >
                        {/* <Grid xs={12}>
                                <Button variant="contained" color="secondary" onClick={saveTandCHandler} >Save</Button>
                            </Grid> */}
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Box
                        style={{ marginBottom: '10px', width: '100%' }}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={saveTandCHandler}
                            disabled={!value}
                        >
                            Save
                        </Button>
                    </Box>
                    <Grid item xs={12}>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            value={value}
                            onChange={html => onChangeHanlder(html)}
                        />
                    </Grid>
                </Grid>
            </div>
        </Fragment>
        //   </List>
    );
};
TermsConditionsList.defaultProps = {
    resource: 'terms-conditions',
    location: { search: '' },
};

export default TermsConditionsList;
