import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import RequestChargeList from './RequestChargeList';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

export const RequestChargesTab = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <RequestChargeList record={record} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

RequestChargesTab.propTypes = {
    record: PropTypes.object,
};

RequestChargesTab.defaultProps = {
    record: {},
};
