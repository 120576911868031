import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    emails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    email: {},
};

export const EmailTemplateFields = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.countryId === 1
                                ? 'UAE'
                                : record.countryId === 2
                                ? 'Kuwait'
                                : 'KSA'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Subject</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <p>{record.subject}</p>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>From</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.fromEmail}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>To</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.toEmails
                                    ? record.toEmails.map(email => (
                                          <span>{email}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>CC</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.ccEmails
                                    ? record.ccEmails.map(email => (
                                          <span>{email}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>BCC</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.bccEmails
                                    ? record.bccEmails.map(email => (
                                          <span>{email}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Categories</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.categories
                                    ? record.categories.map(category => (
                                          <span>{category.name}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Template Variables</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.templateVariables
                                    ? record.templateVariables.map(variable => (
                                          <span>{variable}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Has attachments</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.hasAttachment ? 'Yes' : 'No'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Attachment Types</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.attachmentTypes
                                    ? record.attachmentTypes.map(type => (
                                          <span>{type}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Subject variables</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.subjectVariables &&
                                record.subjectVariables.length > 0
                                    ? record.subjectVariables.map(variable => (
                                          <span>{variable}</span>
                                      ))
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

EmailTemplateFields.propTypes = {
    record: PropTypes.object,
};
