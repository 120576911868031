import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { SimpleForm } from 'react-admin';
import { leadFollowUp as leadFollowUpAction } from '../actions/LeadActions';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { Lead } from '../../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    record: Lead;
    close: () => void;
};

type State = {
    followUpAt: string;
    formOpen: boolean;
};

class LeadFollowUp extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            followUpAt: '',
            formOpen: props.open,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            typeof nextProps.open === 'boolean' &&
            this.state.formOpen !== null
        ) {
            this.setState({
                formOpen: nextProps.open,
            });
        }
    }

    render() {
        const { record, leadFollowUp } = this.props;

        return (
            <Dialog
                open={!!this.state.formOpen}
                onClose={() => {
                    this.setState({ formOpen: false });
                }}
            >
                <DialogTitle>Follow Up</DialogTitle>
                <SimpleForm toolbar={null}>
                    <TextField
                        style={{
                            width: '16rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                        type="date"
                        label="Date"
                        onChange={e =>
                            this.setState({ followUpAt: e.target.value })
                        }
                    />
                </SimpleForm>
                <DialogActions>
                    <Button onClick={() => this.setState({ formOpen: false })}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            this.setState({ formOpen: false });
                            leadFollowUp(
                                record.id,
                                record,
                                this.state.followUpAt
                            );
                            this.props.close();
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapDispatch = {
    leadFollowUp: leadFollowUpAction,
};

const connector = connect(null, mapDispatch);

export default connector(LeadFollowUp);
