import React from 'react';
import {
    required,
    Edit,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BoxitPhoneInput from '../components/BoxitPhoneInput';
import useLocalStorage from '../hooks/useLocalStorage';

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const CustomToolbar = props => (
    <Toolbar {...props} classes={useStyles()}>
        <SaveButton />
    </Toolbar>
);

const ProfileEdit = ({ staticContext, ...props }) => {
    const [currentUserId] = useLocalStorage('userId', null);

    return (
        <Edit
            id={currentUserId}
            resource="users"
            basePath="/my-profile"
            title="Account Settings"
            redirect={false} // I don't need any redirection here, there's no list page
            undoable={false}
            {...props}
        >
            <SimpleForm variant="outlined" toolbar={<CustomToolbar />}>
                <TextInput source="name" validate={required()} />
                <BoxitPhoneInput source="phone" />
                <TextInput
                    multiline
                    fullWidth={false}
                    source="emailSignature"
                    label="Email Signature"
                    parse={v => v && v.split('\n').join('<br/>')}
                    format={v => v && v.split('<br/>').join('\n')}
                />
            </SimpleForm>
        </Edit>
    );
};

export default ProfileEdit;
