import React, { FC } from 'react';
import {
    Show,
    ReferenceManyField,
    Datagrid,
    TextField,
    ShowButton,
    Tab,
    TabbedShowLayout,
} from 'react-admin';
import { WarehouseSummary, WarehouseShowButtons } from './components/';
import { FieldProps, Warehouse } from '../types';

const WarehouseLocationList = props => (
    <ReferenceManyField
        reference="locations"
        target="warehouseId"
        filter={{ warehouseId: props.record.id }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="area" />
            <TextField source="aisle" />
            <TextField source="x" />
            <TextField source="y" />
            <TextField source="z" />
            <TextField source="path" />
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
);

const WarehouseTitle: FC<FieldProps<Warehouse>> = ({ record }) => (
    <span>{record!.name ? `${record!.name}` : `Warehouse #${record!.id}`}</span>
);

const WarehouseShow = props => (
    <Show
        title={<WarehouseTitle />}
        actions={<WarehouseShowButtons />}
        {...props}
    >
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <WarehouseSummary />
            </Tab>
            <Tab label="Locations">
                <WarehouseLocationList />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default WarehouseShow;
