import React from 'react';
import moment from 'moment';
import Color from 'color';
import { Field } from 'react-final-form';
import BoxitSalesCalendar from './BoxitSalesCalendar';
import { getOrderColor } from '../orders/utils';
import { Order } from '../types';

function getOrderStyle(
    order: Order,
    start: Date,
    end: Date,
    isSelected: boolean
) {
    const orderColor = getOrderColor(order);

    return {
        style: {
            color: orderColor,
            backgroundColor: Color(orderColor).alpha(0.1),
        },
    };
}

const BoxitSalesCalendarInput = ({ source, ...props }) => (
    <Field
        name={source}
        component={({ input }) => (
            <BoxitSalesCalendar
                value={moment(input.value).format('YYYY-MM-DD')}
                onChange={input.onChange}
                views={['month']}
                countryId={props.countryId}
                eventPropGetter={getOrderStyle}
                dayPropGetter={(date: Date) => {
                    const time = input.value && moment(input.value).valueOf();
                    if (date && time && date.getTime() === time) {
                        return {
                            className: 'special-day',
                            style: {
                                backgroundColor: '#afa',
                            },
                        };
                    }
                    return {};
                }}
            />
        )}
        {...props}
    />
);

export default BoxitSalesCalendarInput;
