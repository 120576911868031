import React from 'react';
import {
    List,
    Filter,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import AddressGrid from './components/AddressGrid';
import BoxitPagination from '../components/BoxitPagination';
const AddressFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
            <ReferenceInput
                source="partnerId"
                reference="partners"
                resource="partners"
                label="Partner"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="userId"
                reference="users"
                resource="users"
                label="Customer"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>

            <ReferenceInput
                source="cityId"
                reference="city"
                resource="city"
                label="City"
            >
                <SelectInput />
            </ReferenceInput>
        </Filter>
    );
};

const AddressList = props => (
    <List
        title="Addresss"
        filters={<AddressFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<BoxitPagination />}
        perPage={20}
        {...props}
    >
        <AddressGrid />
    </List>
);

AddressList.defaultProps = {
    resource: 'addresss',
    location: { search: '' },
};

export default AddressList;
