import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import { OrderTabs } from './components/OrderTabs';
import OrderShowActions from './components/OrderShowActions';
import { ShowProps, Order } from '../types';
import { modelView, modelViewType } from '../util/modelView';

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const OrderShow: React.FC<ShowProps<Order>> = ({
    permissions = [],
    ...props
}) => {
    useEffect(() => {
        modelView(modelViewType.ORDERS, parseInt(props.id));
    }, [props.id]);
    return (
        <Show
            actions={<OrderShowActions permissions={permissions} {...props} />}
            {...sanitizeRestProps(props)}
        >
            <OrderTabs permissions={permissions} {...props} />
        </Show>
    );
};

export default OrderShow;
