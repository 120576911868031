import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification, crudGetManyReference } from 'react-admin';
import { ActionWithSideEffect } from '../../types';
import {
    ORDER_CONFIRM_SUCCESS,
    ORDER_CONFIRM_FAILURE,
    ORDER_REJECT_SUCCESS,
    ORDER_REJECT_FAILURE,
    ORDER_ONHOLD_SUCCESS,
    ORDER_ONHOLD_FAILURE,
    ORDER_SYNC_SUCCESS,
    ORDER_SYNC_FAILURE,
    ORDER_COMPLETE_SUCCESS,
    ORDER_COMPLETE_FAILURE,
    ORDER_CREATE_INVOICE_SUCCESS,
    ORDER_CREATE_INVOICE_FAILURE,
    ORDER_ADD_PHOTO_SUCCESS,
    ORDER_ADD_PHOTO_FAILURE,
} from './orderActions';

export default function* orderSaga() {
    yield all([
        takeEvery(ORDER_CONFIRM_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.orders.notification.confirmed_success'
                )
            );
        }),
        takeEvery(ORDER_CONFIRM_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.orders.notification.confirmed_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(ORDER_REJECT_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.orders.notification.rejected_success'
                )
            );
        }),
        takeEvery(ORDER_REJECT_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.orders.notification.rejected_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(ORDER_ONHOLD_SUCCESS, function* () {
            yield put(
                showNotification('resources.orders.notification.onhold_success')
            );
        }),
        takeEvery(ORDER_ONHOLD_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.orders.notification.onhold_error',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(ORDER_SYNC_SUCCESS, function* () {
            yield put(showNotification('Success'));
        }),
        takeEvery(ORDER_SYNC_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'Error in syncing storage rents',
                    'warning'
                )
            );
            console.error(error);
        }),
        takeEvery(ORDER_COMPLETE_SUCCESS, function* () {
            yield put(
                showNotification(
                    'resources.orders.notification.complete_success'
                )
            );
        }),
        takeEvery(ORDER_COMPLETE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            console.error(error);
            yield put(
                showNotification(
                    error || 'resources.orders.notification.complete_error',
                    'warning'
                )
            );
        }),
        takeEvery(ORDER_CREATE_INVOICE_SUCCESS, function* (
            action: ActionWithSideEffect
        ) {
            const orderId =
                action.payload && action.payload.data && action.payload.data.id;

            yield put(showNotification('Invoice created successfully'));
            window.location.reload();
            /*yield put(
                crudGetManyReference(
                    'invoices',
                    'orderId',
                    orderId
                    // relatedTo,
                    // pagination,
                    // sort,
                    // filter
                )
            );*/
        }),
        takeEvery(ORDER_CREATE_INVOICE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(showNotification(error || 'Error', 'warning'));
            console.error(error);
        }),
        takeEvery(ORDER_ADD_PHOTO_SUCCESS, function* () {
            yield put(showNotification('Upload Success'));
        }),
        takeEvery(ORDER_ADD_PHOTO_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(error || 'Error in uploading photo', 'warning')
            );
            console.error(error);
        }),
    ]);
}
