import React, { Component } from 'react';
import _ from 'lodash';
import capitalize from 'capitalize';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonGroup } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/MailOutline';
import SmsIcon from '@material-ui/icons/SmsOutlined';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import AssignIcon from '@material-ui/icons/AssignmentInd';
import SweetAlert from 'sweetalert-react';
import {
    ListButton,
    EditButton,
    TopToolbar,
    crudGetMany as crudGetManyAction,
} from 'react-admin';
import settings from '../../settings';
import {
    requestConfirm as requestConfirmAction,
    requestOnhold as requestOnholdAction,
    requestPickup as requestPickupAction,
    requestDeliver as requestDeliverAction,
    requestReceive as requestReceiveAction,
    requestDispatch as requestDispatchAction,
    requestComplete as requestCompleteAction,
    requestNotifyCustomer as requestNotifyCustomerAction,
    requestNotifyTeam as requestNotifyTeamAction,
    requestNotifyWarehouse as requestNotifyWarehouseAction,
    userAssign as requestUserAssign,
} from '../actions/requestActions';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import {
    OrderStatus,
    RequestStatus,
    StorageStatus,
    RequestType,
    StorageType,
} from '../../constants';
import RequestApprove from './RequestApprove';
import BoxStatusForm from './BoxStatusForm';
import RequestCancel from './RequestCancel';
import RequestResolve from './RequestResolve';
import DeleteButton from '../../../src/components/DeleteButton';
import { ActionProps, Request } from '../../types';
import feathersClient from '../../rest/feathersClient';
import AssignButton from './AssignButton';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

type Styles = {
    button: string;
    leftIcon: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ActionProps<Request> & {
        classes: Styles;
    };

type State = {
    approvalFormOpen: boolean;
    cancelFormOpen: boolean;
    resolveFormOpen: boolean;
    boxStatusFormOpen: boolean;
    showOrderIncompleteDialog: boolean;
    showSendNoteMailDialog: boolean;
    showSendNoteSMSDialog: boolean;
    isChargeLoading: boolean;
    currentUserId: string | null;
    showAssignToMeDialog: boolean;
};

class RequestShowActions extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            approvalFormOpen: false,
            cancelFormOpen: false,
            resolveFormOpen: false,
            boxStatusFormOpen: false,
            showOrderIncompleteDialog: false,
            showSendNoteMailDialog: false,
            showSendNoteSMSDialog: false,
            isChargeLoading: false,
            currentUserId: localStorage.getItem('userId'),
            showAssignToMeDialog: false,
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        const { crudGetMany, data } = this.props;
        const orderId = data && data.orderId;
        crudGetMany('orders', [orderId]);
    }

    handleApproveButton = () => {
        const { data, order } = this.props;
        const isOrderComplete = order && order.status === OrderStatus.complete;
        const isPrimary = data && data.isPrimary === true;
        const isSurvey = data && data.type === RequestType.survey;

        if (true || isOrderComplete || isPrimary || isSurvey) {
            if (data!.status === 'confirmed') {
                this.setState({
                    approvalFormOpen: true,
                });
            }
        } else {
            this.setState({
                showOrderIncompleteDialog: true,
            });
        }
    };

    handleCancelButton = () => {
        this.setState({
            cancelFormOpen: true,
        });
    };

    handleConfirm = () => {
        const { data, requestConfirm } = this.props;
        requestConfirm(data!.id, data);
    };

    handleOnhold = () => {
        const { data, requestOnhold } = this.props;
        requestOnhold(data!.id, data);
    };

    handlePickup = () => {
        const { data, requestPickup } = this.props;

        if (
            (data!.storages || []).some(s => s.storagetypeId === StorageType.BB)
        ) {
            this.setState({
                boxStatusFormOpen: true,
            });
        } else {
            requestPickup(data!.id, data);
        }
    };

    handleDeliver = () => {
        const { data, requestDeliver } = this.props;
        requestDeliver(data!.id, data);
    };

    handleReceive = () => {
        const { data, requestReceive } = this.props;

        if (
            data!.type === RequestType.drop &&
            data!.status === RequestStatus.approved &&
            (data!.storages || [])
                .filter(
                    s =>
                        s.status !== StorageStatus.closed &&
                        s.status !== StorageStatus.cancelled
                )
                .some(s => s.storagetypeId === StorageType.BB)
        ) {
            this.setState({
                boxStatusFormOpen: true,
            });
        } else {
            requestReceive(data!.id, data);
        }
    };

    handleDispatch = () => {
        const { data, requestDispatch } = this.props;
        requestDispatch(data!.id, data);
    };

    handleComplete = () => {
        const { data, requestComplete } = this.props;
        requestComplete(data!.id, data);
    };

    handleNotifyCustomer = () => {
        const { data, requestNotifyCustomer } = this.props;
        requestNotifyCustomer(data!.id);
    };

    handleNotifyTeam = () => {
        const { data, requestNotifyTeam } = this.props;
        requestNotifyTeam(data!.id);
    };

    handleNotifyWarehouse = () => {
        const { data, requestNotifyWarehouse } = this.props;
        requestNotifyWarehouse(data!.id);
    };

    handleResolve = () => {
        this.setState({
            resolveFormOpen: true,
        });
    };

    getOrdersData = async () => {
        const { data } = this.props;
        const requestLinesService = feathersClient.service('request-lines');
        const ordersData: any[] = [];

        this.setState({
            isChargeLoading: true,
        });
        if (data) {
            const requestLines = await requestLinesService.find({
                query: { requestId: data.id },
            });

            for (const line of requestLines.data) {
                if (!line.storage || line.storage.storagerecords.length === 0) {
                    continue;
                }

                if (
                    line.storage.status !== 'in' &&
                    line.storage.status !== 'stored'
                ) {
                    continue;
                }
                const storageSize = line.storage.size;

                const lineQty = line.qty;

                for (const record of line.storage.storagerecords) {
                    ordersData.push({
                        orderId: record.orderId,
                        orderSize: storageSize * lineQty,
                    });
                }
            }

            for (let i = 0; i < ordersData.length; i++) {
                for (let j = i + 1; j < ordersData.length; j++) {
                    if (ordersData[i].orderId === ordersData[j].orderId) {
                        ordersData[i].orderSize += ordersData[j].orderSize;
                        ordersData.splice(j, 1);
                    }
                }
            }

            this.setState({
                isChargeLoading: false,
            });

            if (ordersData.length > 0) {
                let largestOrder = ordersData[0];
                for (let i = 0; i < ordersData.length; i++) {
                    if (ordersData[i].orderSize > largestOrder.orderSize) {
                        largestOrder = ordersData[i];
                    }
                }
                return largestOrder.orderId;
            } else {
                return null;
            }
        }
    };

    navigateToCharge = async () => {
        const { data } = this.props;
        const orderId = await this.getOrdersData();
        localStorage.setItem('chargeUserId', data?.userId as any);
        localStorage.setItem('chargeRequestId', data?.id as any);
        localStorage.setItem('chargeRequestType', data?.type as any);
        localStorage.setItem(
            'chargeRequestOrderId',
            orderId ? (orderId as any) : (data?.orderId as any)
        );
        window.location.href = '/#/charges/create';
    };

    render() {
        const {
            classes,
            basePath,
            data,
            resource,
            taskCreate,
            permissions = [],
            assignUser,
        } = this.props;
        const isPrimary = data && data.isPrimary === true;
        const isNewbox = data && data.type === RequestType.newbox;
        const isPickup = data && data.type === RequestType.pickup;
        const isDelivery = data && data.type === RequestType.delivery;
        const isDrop = data && data.type === RequestType.drop;
        const isCollect = data && data.type === RequestType.collect;
        const isSurvey = data && data.type === RequestType.survey;
        const isMoving = data && data.type === RequestType.moving;
        const isScrap = data && data.type === RequestType.scrap;
        const isShipping = data && data.type === RequestType.shipping;
        const isOtherServices =
            data && data.type === RequestType.other_services;

        const isNew = data && data.status === RequestStatus.new;
        const isConfirmed = data && data.status === RequestStatus.confirmed;
        const isOnhold = data && data.status === RequestStatus.onhold;
        const isApproved = data && data.status === RequestStatus.approved;
        const isDelivered = data && data.status === RequestStatus.delivered;
        const isReceived = data && data.status === RequestStatus.received;
        const isTransit = data && data.status === RequestStatus.transit;
        const isComplete = data && data.status === RequestStatus.complete;
        const isCancelled = data && data.status === RequestStatus.cancelled;
        const isRejected = data && data.status === RequestStatus.rejected;

        if (!data) return null;

        return (
            <TopToolbar>
                <ButtonGroup size="small">
                    <Button
                        variant="contained"
                        color="secondary"
                        key={'customer'}
                        onClick={this.navigateToCharge}
                        disabled={this.state.isChargeLoading}
                    >
                        {this.state.isChargeLoading ? 'Loading' : 'Charge'}
                    </Button>
                </ButtonGroup>
                {false && isApproved && (
                    <ButtonGroup size="small">
                        <Button
                            color="secondary"
                            key={'customer'}
                            startIcon={<EmailIcon />}
                            onClick={this.handleNotifyCustomer}
                        >
                            Customer
                        </Button>
                        <Button
                            color="secondary"
                            key={'team'}
                            startIcon={<EmailIcon />}
                            onClick={this.handleNotifyTeam}
                        >
                            Team
                        </Button>
                        <Button
                            color="secondary"
                            key={'warehouse'}
                            startIcon={<EmailIcon />}
                            onClick={this.handleNotifyWarehouse}
                        >
                            Warehouse
                        </Button>
                    </ButtonGroup>
                )}

                {((isPickup && (isTransit || isReceived || isComplete)) ||
                    (isDrop && (isReceived || isComplete)) ||
                    (isDelivery && (isDelivered || isComplete)) ||
                    (isCollect && (isDelivered || isComplete))) && (
                    <>
                        <Button
                            color="secondary"
                            startIcon={<EmailIcon />}
                            onClick={() =>
                                this.setState({ showSendNoteMailDialog: true })
                            }
                        >
                            Send Note Mail
                        </Button>
                        <Button
                            color="secondary"
                            startIcon={<SmsIcon />}
                            onClick={() =>
                                this.setState({ showSendNoteSMSDialog: true })
                            }
                        >
                            Send Note SMS
                        </Button>
                    </>
                )}

                {((isPickup && (isTransit || isReceived || isComplete)) ||
                    (isDrop && (isReceived || isComplete)) ||
                    (isDelivery && (isTransit || isComplete)) ||
                    (isCollect && (isDelivered || isComplete))) && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        href={
                            data
                                ? `${settings.apiUrl}/pdf/note?id=${data.id}`
                                : ''
                        }
                        target="_blank"
                        startIcon={<FileIcon />}
                    >
                        {data.type} Note
                    </Button>
                )}

                {data.customerAction === 'disputed' && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleResolve}
                    >
                        Resolve Dispute
                    </Button>
                )}

                {isApproved && false && (
                    <Button
                        color="secondary"
                        key={'workorder'}
                        href={
                            data
                                ? `${settings.apiUrl}/pdf/work-order?id=${
                                      data!.id
                                  }`
                                : ''
                        }
                        target="_blank"
                    >
                        <FileIcon className={classes.leftIcon} />
                        Work Order
                    </Button>
                )}

                {(isNew || isOnhold || isCancelled || isRejected) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleConfirm}
                    >
                        Confirm
                    </Button>
                )}

                {(isNew ||
                    isConfirmed ||
                    isApproved ||
                    isCancelled ||
                    isRejected) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleOnhold}
                    >
                        Onhold
                    </Button>
                )}

                {(isNew ||
                    isOnhold ||
                    isConfirmed ||
                    isApproved ||
                    isTransit) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleCancelButton}
                    >
                        Cancel
                    </Button>
                )}

                {isConfirmed && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleApproveButton}
                    >
                        Approve
                    </Button>
                )}

                {isApproved && isPickup && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        key={'pickedup'}
                        className={classes.button}
                        onClick={this.handlePickup}
                    >
                        Picked Up
                    </Button>
                )}

                {/* {((isTransit && (isDelivery || isNewbox)) ||
                    (isCollect && isApproved)) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        key={'delivered'}
                        className={classes.button}
                        onClick={this.handleDeliver}
                    >
                        Delivered
                    </Button>
                )} */}

                {((isTransit && isPickup) || (isDrop && isApproved)) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleReceive}
                    >
                        Received
                    </Button>
                )}

                {/* {isApproved && (isDelivery || isNewbox) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleDispatch}
                    >
                        Dispatch
                    </Button>
                )} */}

                {(isDelivered ||
                    isReceived ||
                    ((isSurvey ||
                        isMoving ||
                        isScrap ||
                        isShipping ||
                        isOtherServices) &&
                        (isApproved || isTransit))) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className={classes.button}
                        onClick={this.handleComplete}
                    >
                        Complete
                    </Button>
                )}

                <ButtonGroup>
                    {data && data.assigneeId != this.state.currentUserId && (
                        <Button
                            color="secondary"
                            startIcon={<AssignIcon />}
                            onClick={() =>
                                this.setState({ showAssignToMeDialog: true })
                            }
                        >
                            Assign To Me
                        </Button>
                    )}
                    {data && <AssignButton record={data} />}
                </ButtonGroup>

                {(_.intersection(permissions, [
                    'admin',
                    'operations-manager',
                    'request-full-update',
                ]).length > 0 ||
                    (!isComplete && !isCancelled)) && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        className={classes.button}
                        record={data}
                    />
                )}

                {!isComplete && !isCancelled && (
                    <DeleteButton
                        basePath={basePath}
                        record={data}
                        resource={resource}
                        undoable={false}
                    />
                )}

                <ListButton color="secondary" basePath={basePath} />

                {((isPickup && (isTransit || isReceived || isComplete)) ||
                    (isDrop && (isReceived || isComplete)) ||
                    (isDelivery && (isDelivered || isComplete)) ||
                    (isCollect && (isDelivered || isComplete))) && (
                    <>
                        <SweetAlert
                            show={this.state.showSendNoteMailDialog}
                            title="Are you sure?"
                            text={`Do you want to send ${capitalize(
                                data.type
                            )} Note email to customer?`}
                            showCancelButton
                            onCancel={() =>
                                this.setState({ showSendNoteMailDialog: false })
                            }
                            onEscapeKey={() =>
                                this.setState({ showSendNoteMailDialog: false })
                            }
                            onOutsideClick={() =>
                                this.setState({ showSendNoteMailDialog: false })
                            }
                            onConfirm={() => {
                                this.setState({
                                    showSendNoteMailDialog: false,
                                });
                                taskCreate({
                                    type: 'SEND_REQUEST_NOTE_MAIL_TO_CUSTOMER',
                                    data: { requestId: data!.id },
                                });
                            }}
                        />
                        <SweetAlert
                            show={this.state.showSendNoteSMSDialog}
                            title="Are you sure?"
                            text={`Do you want to send ${capitalize(
                                data.type
                            )} Note SMS to customer?`}
                            showCancelButton
                            onCancel={() =>
                                this.setState({ showSendNoteSMSDialog: false })
                            }
                            onEscapeKey={() =>
                                this.setState({ showSendNoteSMSDialog: false })
                            }
                            onOutsideClick={() =>
                                this.setState({ showSendNoteSMSDialog: false })
                            }
                            onConfirm={() => {
                                this.setState({
                                    showSendNoteSMSDialog: false,
                                });
                                taskCreate({
                                    type: 'SEND_REQUEST_NOTE_SMS_TO_CUSTOMER',
                                    data: { requestId: data!.id },
                                });
                            }}
                        />
                    </>
                )}

                {data && (
                    <RequestApprove
                        open={this.state.approvalFormOpen}
                        onClose={() =>
                            this.setState({ approvalFormOpen: false })
                        }
                        record={data}
                    />
                )}

                {data && (
                    <RequestCancel
                        open={this.state.cancelFormOpen}
                        onClose={() => this.setState({ cancelFormOpen: false })}
                        record={data}
                    />
                )}

                {data && (
                    <RequestResolve
                        open={this.state.resolveFormOpen}
                        onClose={() =>
                            this.setState({ resolveFormOpen: false })
                        }
                        record={data}
                    />
                )}

                {data && (
                    <BoxStatusForm
                        open={this.state.boxStatusFormOpen}
                        onClose={() =>
                            this.setState({ boxStatusFormOpen: false })
                        }
                        record={data}
                    />
                )}
                <SweetAlert
                    show={this.state.showOrderIncompleteDialog}
                    title="Order is not complete!"
                    type="error"
                    text="Please complete the order and try again."
                    onConfirm={() =>
                        this.setState({ showOrderIncompleteDialog: false })
                    }
                />
                {data && this.state.currentUserId && (
                    <SweetAlert
                        show={this.state.showAssignToMeDialog}
                        title="Are you sure?"
                        text="Do you want to assign this user to you?"
                        showCancelButton
                        onCancel={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onEscapeKey={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onOutsideClick={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onConfirm={() => {
                            this.setState({ showAssignToMeDialog: false });
                            assignUser(
                                data.id,
                                data,
                                this.state.currentUserId!
                            );
                        }}
                    />
                )}
            </TopToolbar>
        );
    }
}

const mapState = (state, props) => {
    const { data } = props;
    const orderId = data && data.orderId;
    return { order: state.admin.resources.orders.data[orderId] };
};
const mapDispatch = {
    requestConfirm: requestConfirmAction,
    requestOnhold: requestOnholdAction,
    requestPickup: requestPickupAction,
    requestDeliver: requestDeliverAction,
    requestReceive: requestReceiveAction,
    requestDispatch: requestDispatchAction,
    requestComplete: requestCompleteAction,
    requestNotifyCustomer: requestNotifyCustomerAction,
    requestNotifyTeam: requestNotifyTeamAction,
    requestNotifyWarehouse: requestNotifyWarehouseAction,
    taskCreate: taskCreateAction,
    assignUser: requestUserAssign,
    crudGetMany: crudGetManyAction,
};

const connector = connect(mapState, mapDispatch);

const ConnectedRequestShowActions = connector(RequestShowActions);

export default props => {
    const classes = useStyles();
    return <ConnectedRequestShowActions classes={classes} {...props} />;
};
