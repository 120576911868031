import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    ChipField,
    EditButton,
    ShowButton,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanField,
} from 'react-admin';

import CityField from '../components/CityField';
import CustomSearchInput from '../components/CustomSearchInput';
const WarehouseFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="cityId"
            reference="city"
            resource="city"
            label="City"
        >
            <SelectInput optionText={choice => `${choice.name}`} />
        </ReferenceInput>
    </Filter>
);

const WarehouseList = props => (
    <List
        title="Warehouses"
        bulkActionButtons={false}
        filters={<WarehouseFilter />}
        sort={{ field: 'id', order: 'ASC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="name" />
            <CityField />
            <BooleanField source="active" />
            <EditButton color="secondary" style={{ padding: 0 }} />
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

WarehouseList.defaultProps = {
    resource: 'warehouses',
    location: { search: '' },
};

export default WarehouseList;
