import React, { useEffect } from 'react';
import {
    Datagrid,
    TextField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';
import { ReferenceField } from 'react-admin';

export const UserCommunicationsList = props => {
    useEffect(() => {
        localStorage.setItem(
            'currentComMod',
            `/users/${props.record.id}/show/8`
        );
    }, []);
    return (
        <ReferenceManyField
            reference="Communication-records"
            target="recipientId"
            filter={{ recipientId: props.record.id }}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="id" label="Communication Id" />
                <TextField source="taskStatus" label="Task Status" />
                <TextField source="taskType" label="Task Type" />
                <TextField source="templateName" label="Template Name" />
                <TextField source="senderEmail" label="Sender Email" />
                <TextField source="recipientEmail" label="Recipient Email" />
                <TextField source="channel" label="Channel" />
                <TextField source="deliveryStatus" label="Delivery Status" />
                <ShowButton />
            </Datagrid>
        </ReferenceManyField>
    );
};
