import React from 'react';
import _ from 'lodash';
import { Chip } from '@material-ui/core';
import { FieldProps } from '../types';
import { ReferenceField, TextField } from 'react-admin';

const StorageTypeField: React.FC<FieldProps<any>> = ({
    source = 'storagetypeId',
    record = {},
    ...props
}) => (
    <span>
        {!!source && _.get(record, source) && (
            <ReferenceField
                basePath="/storage-types"
                record={record}
                label="Type"
                source={source}
                resource="storage-types"
                reference="storage-types"
                link={false}
                {...props}
            >
                <TextField source="code" />
            </ReferenceField>
        )}

        {record.isPart && <Chip label="part" />}
    </span>
);

export default StorageTypeField;
