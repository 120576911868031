// component will return phone field with countrycode
// Used on react-admin and wesite forms

import React from 'react';
import { Field } from 'react-final-form';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        marginBottom: '30px',
        marginTop: '10px',
    },
    form: { padding: '10.5px 14px 10.5px 58px !important' },
});

const PhoneInput = ({ input, ...rest }) => {
    const classes = useStyles();
    // console.log(rest);
    console.log(rest);
    return (
        <ReactPhoneInput
            value={input.value}
            onChange={input.onChange}
            inputClass={classes.form}
            country={
                !!rest.country
                    ? rest.country === 1
                        ? 'ae'
                        : rest.country === 2
                        ? 'kw'
                        : 'sa'
                    : rest.defaultCountry
                    ? rest.defaultCountry === 1
                        ? 'ae'
                        : rest.defaultCountry === 2
                        ? 'kw'
                        : 'sa'
                    : 'ae'
            }
            preferredCountries={['ae', 'kw', 'sa']}
        />
    );
};

const BoxitPhoneInput = ({
    source = 'phone',
    placeholder = 'Enter phone number',
    ...props
}) => {
    return (
        <Field
            name={source}
            placeholder={placeholder}
            component={PhoneInput}
            {...props}
        />
    );
};

export default BoxitPhoneInput;
