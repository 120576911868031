import React, { FC, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

interface Props {
    title: string;
    count: string;
    items: any[];
}

const SurveyTable: FC<Props> = ({ title, count, items = [] }) => {
    return (
        <Fragment>
            <Grid key={title} item xs={12}>
                <table className="min-w-full border-collapse border border-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th
                                scope="col"
                                className="border border-gray-200 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {title}
                            </th>
                            <th
                                scope="col"
                                className="w-16 border border-gray-200 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {count}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, index) => (
                            <tr
                                key={item.name}
                                className={
                                    index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                                }
                            >
                                <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {item.name}
                                </td>
                                <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-gray-900">
                                    {item.count}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Grid>
        </Fragment>
    );
};

export default SurveyTable;
