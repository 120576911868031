import React from 'react';
import { Edit, TextInput, SimpleForm } from 'react-admin';
import { TitleProps, Box } from '../types';

const StorageRecordTitle: React.FC<TitleProps<Box>> = ({ record }) => (
    <span>Storage Record #{record ? `${record.id}` : ''}</span>
);

const StorageRecordEdit = props => {
    return (
        <Edit title={<StorageRecordTitle />} undoable={false} {...props}>
            <SimpleForm variant="outlined" redirect="show">
                <TextInput disabled source="id" />
                <TextInput source="qty" label="Qty" />
            </SimpleForm>
        </Edit>
    );
};

export default StorageRecordEdit;
