import React, { Component } from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import {
    crudGetList as crudGetListAction,
    crudGetMany as crudGetManyAction,
} from 'react-admin';
import { StorageStatus, OrderStatus } from '../../constants';
import ItemList from '../../components/ItemList';
import {
    convertItemListToItemCodeList,
    getItemListFromStorages,
    getItemCodeListFromStorages,
    diffItems,
} from '../../core/utils/order';
import { TableRow, TableCell } from '@material-ui/core';
import { AppState, Order, Storage, StorageType } from '../../types';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    record: Order;
    storagetypes: StorageType[];
    skipCurrent: boolean;
};

class ItemStorageList extends Component<Props> {
    componentDidMount() {
        this.fetchStorageTypes();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.record, this.props.record)) {
            this.fetchStorageTypes();
        }
    }

    fetchStorageTypes() {
        const { crudGetMany, record } = this.props;
        if (record) {
            const storagetypeIds = _.uniq(
                [
                    ...(record.items || []),
                    ...(record.actualItems || []),
                    ...(record.currentItems || []),
                ]
                    .map(c => c.storagetypeId)
                    .filter(i => i)
            );
            crudGetMany('storage-types', storagetypeIds);
        }
    }

    render() {
        const order = this.props.record;
        const skipCurrent = this.props.skipCurrent;

        const expectedItems =
            order && order.items
                ? convertItemListToItemCodeList(
                      order.items,
                      this.props.storagetypes
                  )
                : [];

        const actualItems =
            order && order.actualItems
                ? convertItemListToItemCodeList(
                      order.actualItems,
                      this.props.storagetypes
                  )
                : [];

        const currentItems =
            !skipCurrent && order && order.currentItems
                ? convertItemListToItemCodeList(
                      order.currentItems.filter(i => !!i.quantity),
                      this.props.storagetypes
                  )
                : [];

        const colors =
            order && order.status !== OrderStatus.new
                ? diffItems(expectedItems, actualItems)
                : {};

        return (
            <>
                <TableRow hover>
                    <TableCell>Items</TableCell>
                    <TableCell
                        style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                        <ItemList items={expectedItems} colors={colors} />
                    </TableCell>
                    <TableCell
                        style={{ textAlign: 'right', verticalAlign: 'top' }}
                    >
                        <ItemList items={actualItems} colors={colors} />
                    </TableCell>
                    {!skipCurrent && (
                        <TableCell
                            style={{ textAlign: 'right', verticalAlign: 'top' }}
                        >
                            <ItemList items={currentItems} colors={{}} />
                        </TableCell>
                    )}
                </TableRow>
            </>
        );
    }
}

const mapState = (state: AppState, props) => {
    const order: Order = props.record;

    const storagetypes: StorageType[] =
        order &&
        state.admin.resources['storage-types'] &&
        state.admin.resources['storage-types'].data &&
        Object.values(state.admin.resources['storage-types'].data);

    return {
        storagetypes,
    };
};

const mapDispatch = {
    crudGetList: crudGetListAction,
    crudGetMany: crudGetManyAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(ItemStorageList);
