import {
    Input,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Button,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import feathersClient from '../rest/feathersClient';
import { useNotify } from 'react-admin';
import { configCountries } from './types';
import { Title } from 'react-admin';

const EditConfig = () => {
    let { id } = useParams<any>();
    const [currentConfigKey, setCurrentConfigKey] = useState('');
    const [currentConfigValue, setCurrentConfigValue] = useState('');
    const [
        currentConfigOriginalValue,
        setCurrentConfigOriginalValue,
    ] = useState('');
    const [currentConfigCategory, setCurrentConfigCategory] = useState('');
    const [currentConfigCountry, setCurrentConfigCountry] = useState('');
    const notify = useNotify();

    const getCountryConfigs = () => {
        const configService = feathersClient.service('configurations');
        configService
            .find({
                query: { id },
            })
            .then(res => {
                setCurrentConfigKey(res.data[0].key);
                setCurrentConfigValue(res.data[0].value);
                setCurrentConfigOriginalValue(res.data[0].value);
                setCurrentConfigCategory(res.data[0].category);
                setCurrentConfigCountry(res.data[0].countryId);
            })
            .catch(err => {});
    };

    const saveChange = () => {
        const configService = feathersClient.service('configurations');
        configService
            .patch(id, {
                value: currentConfigValue,
            })
            .then(res => {
                notify(`Refreshed`, 'success');
                window.location.reload();
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };

    useEffect(() => {
        getCountryConfigs();
    }, [id]);

    return (
        <Table>
            <Title title={`Edit configurations #${id}`} />
            <TableBody style={{ backgroundColor: 'white' }}>
                <TableRow
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <TableCell
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '.5rem',
                        }}
                    >
                        <label>Country</label>
                    </TableCell>
                    <TableCell
                        style={{
                            textAlign: 'right',
                            maxWidth: '20rem',
                            whiteSpace: 'initial',
                            display: 'block',
                            wordWrap: 'break-word',
                        }}
                    >
                        {configCountries[`${currentConfigCountry}`]}
                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <TableCell
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '.5rem',
                        }}
                    >
                        <label>Category</label>
                    </TableCell>
                    <TableCell
                        style={{
                            textAlign: 'right',
                            maxWidth: '20rem',
                            whiteSpace: 'initial',
                            display: 'block',
                            wordWrap: 'break-word',
                        }}
                    >
                        {currentConfigCategory}
                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <TableCell
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '.5rem',
                        }}
                    >
                        <label>Key</label>
                    </TableCell>
                    <TableCell
                        style={{
                            textAlign: 'right',
                            maxWidth: '20rem',
                            whiteSpace: 'initial',
                            display: 'block',
                            wordWrap: 'break-word',
                        }}
                    >
                        {currentConfigKey}
                    </TableCell>
                </TableRow>
                <TableRow
                    style={{
                        display: 'flex',
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <TableCell
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '.5rem',
                        }}
                    >
                        <label>Value</label>
                    </TableCell>
                    <TableCell
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'flex-start',
                            gap: '.5rem',
                        }}
                    >
                        <Input
                            onChange={(e: any) => {
                                setCurrentConfigValue(e.target.value);
                            }}
                            value={currentConfigValue}
                            placeholder="Value"
                            style={{ minWidth: '25rem' }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Button
                            onClick={saveChange}
                            variant="contained"
                            color="primary"
                            disabled={
                                currentConfigOriginalValue ===
                                currentConfigValue
                            }
                        >
                            Save
                        </Button>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default EditConfig;
