import feathersClient from './feathersClient';
import { authClient as auth } from 'ra-data-feathers';
import { restClient as rest } from 'ra-data-feathers';
import dataMapper from './dataMapper';
import authMapper from './authMapper';

const authProviderOptions = {
    storageKey: 'feathers-jwt',
    authenticate: { strategy: 'local' },
};

const authProvider = authMapper(auth(feathersClient, authProviderOptions));
const dataProvider = dataMapper(rest(feathersClient, { usePatch: true }));

export { authProvider, dataProvider };
