import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { History } from '../../revisions';
import { InvoiceSummary } from './InvoiceSummary';
import { InvoiceChargesList } from './InvoiceChargesList';
import { InvoicesCommunicationsList } from './InvoicesCommunicationsList';

export const InvoiceTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <InvoiceSummary />
            </Tab>
            <Tab label="Charges">
                <InvoiceChargesList />
            </Tab>
            <Tab label="History">
                <History {...props} model="invoice" documentId={record.id} />
            </Tab>
            <Tab label="Communication">
                <InvoicesCommunicationsList />
            </Tab>
        </TabbedShowLayout>
    );
};

InvoiceTabs.propTypes = {
    record: PropTypes.object,
};

InvoiceTabs.defaultProps = {
    record: {},
};
