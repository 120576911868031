import React from 'react';
import { History } from '../revisions';
import { useParams } from 'react-router';
import { Show } from 'react-admin';
import { SimpleShowLayout } from 'react-admin';

const ConfigHistory = () => {
    let { id } = useParams<any>();
    return (
        <SimpleShowLayout>
            <History model="users" documentId={25346} />
        </SimpleShowLayout>
    );
};

export default ConfigHistory;
