import React, { useState } from 'react';
import { useNotify, useTranslate, useUpdate } from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { OrderStatus } from '../../constants';
import { Order } from '../../types';
import { useGetList } from 'react-admin';

// const useStyles = makeStyles(theme => ({
//     button: {
//         marginLeft: theme.spacing(1),
//         marginRight: theme.spacing(1),
//     },
// }));
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    })
);

type Props = {
    record: Order;
};

const RejectButton: React.FC<Props> = ({ record }) => {
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);
    const [reason, setReason] = useState('');
    const [comment, setComment] = useState('');
    const { ids, data, total, loaded } = useGetList(
        'reject-reasons',
        {},
        { field: 'id', order: 'DESC' },
        {}
    );
    const [rejectOrder, { loading }] = useUpdate(
        'orders',
        record.id,
        {
            status: OrderStatus.rejected,
            rejectReasonId: reason,
            reason: comment,
        },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify('resources.leads.notification.rejected_success', 'info');
                window.location.reload();
            },
            onFailure: () => {
                notify(
                    'resources.leads.notification.rejected_error',
                    'warning'
                );
            },
        }
    );

    const handleReasonChange = event => {
        // setReason(event.target.value.trim());
        setReason(event.target.value);
    };
    const handleCommentChange = event => {
        setComment(event.target.value);
    };
    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                // className={classes.button}
                disabled={loading}
                startIcon={<ClearIcon />}
                onClick={() => setIsOpen(true)}
            >
                {translate('action.reject')}
            </Button>

            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>
                    {translate('resources.leads.action.reject')}
                </DialogTitle>

                <DialogContent style={{ overflowX: 'hidden' }}>
                    {/* <TextField
                        autoFocus
                        margin="dense"
                        id="reason"
                        label={translate('fields.reason')}
                        multiline
                        required
                        fullWidth
                        variant="outlined"
                        onChange={handleReasonChange}
                    /> */}
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                    >
                        <InputLabel id="select-assigneeId-label">
                            {translate('fields.reason')}
                        </InputLabel>
                        <Select
                            labelId="select-assigneeId-label"
                            id="select-assigneeId"
                            autoFocus
                            required
                            onChange={handleReasonChange}
                            label={translate('fields.reason')}
                        >
                            {(ids || []).map(id => (
                                <MenuItem key={id} value={id}>
                                    {data[id].reason}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                    >
                        <TextField
                            onChange={handleCommentChange}
                            label="Comment"
                            inputProps={{
                                maxLength: 200,
                            }}
                        />
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>
                        {translate('action.close')}
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading || reason === ''}
                        onClick={() => {
                            rejectOrder();
                            setIsOpen(false);
                        }}
                    >
                        {translate('resources.leads.action.reject')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RejectButton;
