import React from 'react';
import PropTypes from 'prop-types';

export const Volume = ({ value, unit, showUnit }) => {
    return isNaN(parseFloat(value)) ? null : (
        <span>
            {parseFloat(value).toFixed(2)} <small>{showUnit ? unit : ''}</small>
        </span>
    );
};

Volume.propTypes = {
    value: PropTypes.number,
    unit: PropTypes.string.isRequired,
    showUnit: PropTypes.bool.isRequired,
};

Volume.defaultProps = {
    unit: 'CBM',
    showUnit: false,
};
