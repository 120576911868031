import React, { Component } from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import capitalize from 'capitalize';
import {
    crudGetList as crudGetListAction,
    crudGetMany as crudGetManyAction,
} from 'react-admin';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { RequestStatus, RequestStatusColor } from '../constants';
import { timeRange } from '../utils';
import { getRequestColor } from '../requests/utils';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
} from '@material-ui/core';
import { Request } from '../types';

BigCalendar.momentLocalizer(moment);

const DEFAULT_VIEW = 'month';
const API_GET_DATE_FORMAT = 'YYYY-MM-DD';

function getRequestStyle(request, start, end, isSelected) {
    const backgroundColor = getRequestColor(request);

    const style = {
        backgroundColor,
    };

    return { style };
}

function renderRequestStatusColor(status) {
    return (
        <ListItem key={status}>
            <ListItemIcon>
                <Icon style={{ background: RequestStatusColor[status] }} />
            </ListItemIcon>
            <ListItemText primary={status} />
        </ListItem>
    );
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Event = {
    title: string;
    start: string;
    end: string;
    id: number;
    date: string;
    time: string;
    status: string;
    type: string;
    city: number;
    customer: string;
};

type Props = PropsFromRedux & {
    events: Event;
};

type State = {
    currentDate: moment.Moment;
    currentView: string;
};

class JobCalendar extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            currentDate: moment().endOf(DEFAULT_VIEW),
            currentView: DEFAULT_VIEW,
        };

        this.bindScopes([
            'onView',
            'onNavigate',
            'fetchData',
            'updateRequests',
        ]);
    }

    componentDidMount() {
        this.updateRequests();
        this.fetchCity();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            !_.isEqual(
                (prevProps.cityIds || []).sort(),
                (this.props.cityIds || []).sort()
            )
        ) {
            this.fetchCity();
        }
    }

    onView(view) {
        this.setState({
            currentView: view,
        });

        this.updateRequests(this.state.currentDate, view);
    }

    onNavigate(date, view) {
        const newDate = moment(date);
        this.setState({
            currentDate: newDate,
        });

        this.updateRequests(newDate, view);
    }

    onSelectEvent(event) {
        const url = `/#/requests/${event.id}/show`;
        const win = window.open(url, '_blank');
        if (win) {
            win.focus();
        }
    }

    bindScopes(keys) {
        for (const key of keys) {
            this[key] = this[key].bind(this);
        }
    }

    fetchData(start, end) {
        const { crudGetList, crudGetMany, cityIds } = this.props;

        crudGetList('requests', null, null, {
            'date[$gte]': start,
            'date[$lte]': end,
            'status[$nin][0]': 'complete',
            'status[$nin][1]': 'rejected',
            'status[$nin][2]': 'cancelled',
        });
    }

    fetchCity() {
        const { crudGetMany, cityIds } = this.props;

        if (cityIds && cityIds.length) {
            crudGetMany('city', cityIds);
        }
    }

    updateRequests(
        date = this.state.currentDate,
        view = this.state.currentView
    ) {
        let start;
        let end;

        if (view === 'day') {
            start = moment(date).startOf('day');
            end = moment(date).endOf('day');
        } else if (view === 'week') {
            start = moment(date).startOf('isoWeek');
            end = moment(date).endOf('isoWeek');
        } else if (view === 'month') {
            start = moment(date).startOf('month').subtract(7, 'days');
            end = moment(date).endOf('month').add(7, 'days');
        }

        this.fetchData(
            start.format(API_GET_DATE_FORMAT),
            end.format(API_GET_DATE_FORMAT)
        );
    }

    updateTooltip = r => `
    Customer: ${r.customer}\n
    Req No: ${r.id}\n
    Type: ${r.type}\n
    Status: ${r.status}\n
    Date: ${r.date}\n
    Time: ${r.time}\n
    City: ${r.city}
  `;

    render() {
        return (
            <div>
                <BigCalendar
                    style={{ height: '100vh' }}
                    views={['month', 'day', 'week']}
                    onView={this.onView}
                    onNavigate={this.onNavigate}
                    onSelectEvent={this.onSelectEvent}
                    eventPropGetter={getRequestStyle}
                    events={this.props.events}
                    defaultDate={new Date()}
                    step={60}
                    tooltipAccessor={this.updateTooltip}
                />
                <h1>Legend</h1>
                <List>
                    {Object.values(RequestStatus).map(renderRequestStatusColor)}
                </List>
            </div>
        );
    }
}

const mapState = state => {
    const requests: Request[] =
        state.admin.resources.requests &&
        state.admin.resources.requests.data &&
        Object.values(state.admin.resources.requests.data);

    const cityIds = requests && _.uniq(requests.map(i => i.cityId));

    const events = (requests || []).map((request: Request) => {
        const city =
            state.admin.resources.city &&
            state.admin.resources.city.data &&
            state.admin.resources.city.data[request.cityId]
                ? state.admin.resources.city.data[request.cityId].name
                : request.cityId;
        const title = `${city} - ${capitalize(request.type)}`;
        const start = request.from
            ? moment(request.date).hour(request.from).toDate()
            : null;
        const end = request.to
            ? moment(request.date).hour(request.to).toDate()
            : null;

        return {
            title,
            start,
            end,
            id: request.id,
            date: request.date,
            time: timeRange(request.from, request.to),
            status: request.status,
            type: request.type,
            city,
            customer: request.user && request.user.name,
        };
    });

    return {
        events,
        cityIds,
    };
};

const mapDispatch = {
    crudGetList: crudGetListAction,
    crudGetMany: crudGetManyAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(JobCalendar);
