import React from 'react';

export default ({ items, colors = {} }) => (
    <table>
        <tbody>
            {items &&
                items
                    .sort((a, b) => {
                        if (a.storagetypeId < b.storagetypeId) return -1;
                        if (a.storagetypeId > b.storagetypeId) return 1;
                        return 0;
                    })
                    .map(
                        item =>
                            item &&
                            item.storagetypeId && (
                                <tr
                                    key={item.storagetypeId}
                                    style={{
                                        color: colors[item.storagetypeId],
                                    }}
                                >
                                    <td>{item.quantity}</td>
                                    <td>&nbsp;x&nbsp;</td>
                                    <td style={{ textAlign: 'left' }}>
                                        {item.storagetypeId}
                                    </td>
                                </tr>
                            )
                    )}
        </tbody>
    </table>
);
