import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ChipField,
    Filter,
    TextInput,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';

import UserReferenceField from '../components/UserReferenceField';
import BoxitChipField from '../components/BoxitChipField';
import CustomSearchInput from '../components/CustomSearchInput';
import { BoxStatus } from '../constants';
import CountryField from '../components/CountryField';
import { SelectArrayInput } from 'react-admin';

const BoxFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="partnerId"
            reference="partners"
            resource="partners"
            label="Partner"
        >
            <SelectInput />
        </ReferenceInput>

        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={Object.keys(BoxStatus).map(c => ({ id: c, name: c }))}
        />

        <ReferenceInput
            source="userId"
            reference="users"
            resource="users"
            label="Customer"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText={user => (user.name ? user.name : user.email)}
            />
        </ReferenceInput>

        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>
    </Filter>
);

const BoxList = props => (
    <List
        title="Boxes"
        bulkActionButtons={false}
        filters={<BoxFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="code" />
            <CountryField />
            <BoxitChipField record source="status" list="box" />
            <UserReferenceField label="Customer" />
            <ReferenceField
                label="Partner"
                source="partnerId"
                reference="partners"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <EditButton color="secondary" />
        </Datagrid>
    </List>
);

BoxList.defaultProps = {
    resource: 'boxes',
};

export default BoxList;
