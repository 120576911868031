const common = {
    sidebar: {
        width: 200, // default: 240
        closedWidth: 55, // default: 55
    },
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
};

export const darkTheme = {
    ...common,
    palette: {
        text: {
            primary: '#ADBAC7',
            secondary: '#768390',
            disabled: '#545D68',
        },
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#FBBA72',
        },
        background: {
            default: '#22272e',
        },
        type: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
        MuiAppBar: {
            colorSecondary: {
                color: '#ffffffb3',
                backgroundColor: '#2d333b',
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    // recreate a static ripple color
                    // use the currentColor to make it work both for outlined and contained buttons
                    // but to dim the background without dimming the text,
                    // put another element on top with a limited opacity
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
            },
        },
        MuiPaper: {
            elevation1: {
                background: '#22272e',
                border: '1px solid  #444c56',
                boxShadow: '0px 0px 10px rgba(0, 90, 159, 0.05)',
                borderRadius: '6px',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
};

export const lightTheme = {
    ...common,
    sidebar: {
        width: 200, // default: 240
        closedWidth: 55, // default: 55
    },
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
    },
    palette: {
        text: {
            primary: '#515B70',
            secondary: '#8492A6',
            disabled: '#8C8C8C',
            //secondary: '#8492A6',
            //primary: '#3C4858',
            //secondary: '#595959',
            //disabled: '#8C8C8C',
        },
        secondary: {
            main: '#2A3F54', // slate
            contrastText: '#fff',
        },
        primary: {
            main: '#1890ff',
        },
        success: {
            main: '#1de0af',
        },
        error: {
            main: '#ff4f6e',
        },
        // main: '#3acbee', // cyan
        background: {
            default: '#EFF4F9',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    overrides: {
        // Style sheet name ⚛️
        MuiAppBar: {
            // Name of the rule
            root: {
                //boxShadow: "inset 0px -1px 0px #D9D9D9"
                borderBottom: '1px solid #D9D9D9',
            },
            colorSecondary: {
                // Some CSS
                backgroundColor: '#fff',
                color: '#262626',
            },
        },
        // MuiToolbar: {
        //     root: {
        //         backgroundColor: '#fff',
        //     },
        // },
        MuiPaper: {
            elevation1: {
                background: '#FFFFFF',
                border: '1px solid #E5E9F2',
                boxShadow: '0px 0px 10px rgba(0, 90, 159, 0.05)',
                borderRadius: '6px',
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: '1em',
            },
        },
        // MuiFormControl: {
        //     root: {
        //         background: '#FFFFFF',
        //         borderRadius: '4px',
        //     },
        // },
        // RaLayout: { content: { backgroundColor: '##eff4f9' } },
    },
};
