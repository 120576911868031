import React from 'react';
import PropTypes from 'prop-types';
import { TextField, ReferenceField } from 'react-admin';

const CityField = ({ basePath, record, source }) => (
    <ReferenceField
        basePath={basePath}
        record={record}
        source={source}
        reference="city"
        link={false}
        allowEmpty
    >
        <TextField source="name" />
    </ReferenceField>
);

CityField.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    label: PropTypes.string,
};

CityField.defaultProps = {
    basePath: '',
    record: {},
    source: 'cityId',
    label: 'City',
};

export default CityField;
