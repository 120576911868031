import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { StorageRecordSummary } from './components/StorageRecordSummary';
import { TitleProps, Storage } from '../types';

const StorageRecordTitle: React.FC<TitleProps<Storage>> = ({ record }) => (
    <span>
        {record!.name ? `${record!.name}` : `Storage Record #${record!.id}`}
    </span>
);

const StorageRecordShow = props => (
    <Show title={<StorageRecordTitle />} {...props}>
        <SimpleShowLayout>
            <StorageRecordSummary />
        </SimpleShowLayout>
    </Show>
);

export default StorageRecordShow;
