import React from 'react';
import {
    ArrayInput,
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles({
    MuiFormControl: {
        top: '-73px',
        left: '300px',
        height: '0px',
    },
});

const ItemsInput = ({ formData, ...rest }) => {
    const classes = useStyles();
    const isSmall = useMediaQuery('(min-width:600px)');
    return (
        <ArrayInput variant="outlined" source="items" label="" fullWidth>
            <SimpleFormIterator>
                <ReferenceInput
                    label="type"
                    source="storagetypeId"
                    reference="storage-types"
                    resource="storage-types"
                    filter={{
                        ...(formData.userId
                            ? {
                                  $or: [
                                      { userId: formData.userId },
                                      { userId: 'null' },
                                  ],
                              }
                            : {
                                  userId: 'null',
                              }),
                    }}
                    validation={{ required: true }}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 1}
                        source="storagetypeId"
                    />
                </ReferenceInput>
                {isSmall ? (
                    <NumberInput
                        label="quantity"
                        source="quantity"
                        className={classes.MuiFormControl}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                    />
                ) : (
                    <NumberInput
                        label="quantity"
                        source="quantity"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                    />
                )}
            </SimpleFormIterator>
        </ArrayInput>
    );
};

export default ItemsInput;
