import React, { useState } from 'react';

import { Edit, TextInput, SimpleForm, SelectArrayInput } from 'react-admin';
import { Switch } from '@material-ui/core';
import { FunctionField } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import classes from './style.module.css';

const services = [
    'addresses',
    'affiliates',
    'billing',
    'boxes',
    'busyslots',
    'cards',
    'charges',
    'comments',
    'config',
    'coupons',
    'discounts',
    'invoices',
    'leads',
    'locations',
    'lost-reasons',
    'monthly-stats',
    'notify',
    'offer-usages',
    'offers',
    'orders',
    'partners',
    'payfort',
    'payments',
    'pdf',
    'photos',
    'user-photos',
    'requests',
    'request-full-update',
    'request-lines',
    'revisions',
    's3',
    'service-types',
    'services',
    'storage-charges',
    'storage-records',
    'storage-types',
    'storages',
    'tags',
    'task-runner',
    'tasklogs',
    'tasks',
    'teams',
    'thumbs',
    'timeslots',
    'users',
    'user-impersonation',
    'warehouses',
];

function getAllRoles() {
    const roles = [
        'admin',
        'business',
        'staff',
        'global-staff',
        'sales',
        'sales-staff',
        'finance',
        'finance-edit-old-invoice',
        'finance-delete-old-invoice',
        'operations',
        'operations-lead',
        'operations-manager',
        'operations-staff',
        'transport-staff',
        'warehouse-staff',
        'affiliate',
        'partner',
    ];

    services.forEach(i => {
        if (i !== 'user-impersonation' && i !== 'request-full-update') {
            roles.push(`${i}-view`);
            roles.push(`${i}-list`);
            roles.push(`${i}-create`);
            roles.push(`${i}-update`);
            roles.push(`${i}-remove`);
            roles.push(`${i}-manage`);
        } else {
            roles.push(i);
        }
    });

    return roles;
}

const transform = data => {
    const transformData = {
        ...data,
    };
    delete transformData.tags;

    return transformData;
};

const StaffEdit = props => {
    const [isUserRoles, setIsUserRoles] = useState<boolean>(false);
    return (
        <Edit
            title="Edit User Roles"
            undoable={false}
            {...props}
            transform={transform}
        >
            <SimpleForm variant="outlined">
                <TextInput disabled source="name" />
                <div>
                    <h3>Clone roles from another staff</h3>
                    <Switch
                        value={isUserRoles}
                        onChange={e => {
                            setIsUserRoles(prev => !prev);
                        }}
                    />
                </div>
                {!isUserRoles && (
                    <SelectArrayInput
                        label="Roles"
                        source="roles"
                        choices={getAllRoles().map(i => ({ id: i, name: i }))}
                    />
                )}
                {isUserRoles && (
                    <div className={classes.role}>
                        <ReferenceInput
                            variant="outlined"
                            label="Staff"
                            source="roles"
                            reference="staff"
                            resource="roles"
                            fullWidth={true}
                            style={{ width: '256px' }}
                            allowNull
                            format={v => (!v ? null : v)}
                            perPage={100}
                        >
                            <SelectInput
                                optionValue="roles"
                                optionText="name"
                            />
                        </ReferenceInput>
                    </div>
                )}
            </SimpleForm>
        </Edit>
    );
};

export default StaffEdit;
