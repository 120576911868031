import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';
import { Card } from '@material-ui/core';

const styles = {
    card: { flex: 1 },
    icon: {
        float: 'right',
        width: 64,
        height: 64,
        padding: 16,
        color: '#4caf50',
    },
};

const SalesGraph = props => {
    const today = moment().format('YYYY-MM-DD');
    const data = props.data
        .map(
            i =>
                i.date <= today && {
                    ...i,
                    date: i.date.substr(5).replace('-', '/'),
                }
        )
        .filter(i => i);

    return (
        <Card style={styles.card}>
            <LineChart
                width={1200}
                height={400}
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                    type="number"
                    domain={[0, 50]}
                    scale="sqrt"
                    label={{
                        value: 'Leads',
                        angle: -90,
                        position: 'insideLeft',
                    }}
                />
                <Tooltip />
                <Legend />

                <Line
                    type="monotone"
                    dataKey="created"
                    stroke="#8884d8"
                    name="Created"
                />
                <Line
                    type="monotone"
                    dataKey="confirmed"
                    stroke="#82ca9d"
                    name="Confirmed"
                />
            </LineChart>
        </Card>
    );
};

SalesGraph.propTypes = {
    data: PropTypes.array,
};

SalesGraph.defaultProps = {
    data: [],
};

export default SalesGraph;
