import { formatMoney } from '../core/utils/money';

export function getCurrencyString(record) {
    if (!record.amount || !record.currency) return null;
    const negative = record.type === 'discount' ? '-' : '';
    return `${record.currency} ${negative}${formatMoney(
        record.amount,
        record.currency
    )}`;
}
