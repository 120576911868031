export default {
    undefined: 'Undefined',
    User: 'User',
    City: 'City',
    Address: 'Address',
    StorageType: 'StorageType',
    Order: 'Order',
    Storages: 'Storages',
    Services: 'Services',
    Team: 'Team',
    Warehouse: 'Warehouse',

    action: {
        add: 'Add',
        approve: 'Approve',
        assign: 'Assign',
        cancel: 'Cancel',
        close: 'Close',
        confirm: 'Confirm',
        lost: 'Lost',
        onhold: 'Hold',
        reject: 'Reject',
        show: 'Show',
        update: 'Update',
    },

    fields: {
        reason: 'Reason',
        city: 'City',
        channel: 'Channel',
        date: 'Pickup Date',
        dropDate: 'Drop Date',
        addressId: 'Address',
        invoiceId: 'Invoice Id',
        customer: 'Edit Customer ',
    },

    errors: {
        required: 'Required',
    },

    resources: {
        storages: {
            fields: {
                storage_type: 'Storage Type',
            },
            notification: {
                create_success: 'Storage has been created',
                update_success: 'Storage has been updated',
            },
        },
        users: {
            fields: {
                storages: 'Storages',
            },
            action: {
                edit: 'Edit Customer',
            },
            notification: {
                activate_success: 'Customer active',
                activate_error: 'Error: Could not activate customer',
                deactivate_success: 'Customer inactive',
                deactivate_error: 'Error: Could not deactivate customer',
                email_exist:
                    "An account already exists with the email '%{email}'",
                rejected_success: 'Customer rejected',
                rejected_error: 'Error: Customer not rejected',
                onhold_success: 'Customer put on hold',
                onhold_error: 'Error: Customer put on hold',
                suspend_success: 'User has been suspended',
                suspend_error: 'Error: User could not be suspended',
                bill_success: 'Customer has been billed successfully',
                bill_nothing: 'Nothing to Invoice',
                bill_error: 'Error in billing customer',
                reset_password_success:
                    'Reset password email has been sent successfully',
                reset_password_error: 'Error in sending reset password email',
                magic_link_success: 'Magic link has been sent successfully',
                magic_link_error: 'Error in sending magic link',
                update_error: 'Error in updating customer',
                update_success: 'Customer has been updated',
                create_success: 'Customer has been created',
            },
        },
        leads: {
            action: {
                assign: 'Assign Lead',
                confirm: 'Confirm Lead',
                lost: 'Lost Lead',
                reject: 'Reject Lead',
                onhold: 'Hold Lead',
                restore: 'Restore',
                customer: 'Customer',
            },
            notification: {
                assign_error: 'Error: Could not assign the lead',
                assign_success: 'Lead has been assigned',
                confirmed_success: 'Lead confirmed',
                confirmed_error: 'Error: Lead not confirmed',
                lost_error: 'Error: Could not mark the lead as lost',
                lost_success: 'Lead has been marked as lost',
                rejected_success: 'Lead rejected',
                rejected_error: 'Error: Lead not rejected',
                onhold_success: 'Lead put on onhold',
                onhold_error: 'Error: Lead could not be put on onhold',
                update_success: 'Lead has been updated',
                create_success: 'Lead has been created',
                update_customer_success: 'Customer has been updated',
                update_customer_error: 'Error:Customer not updated',
            },
            errors: {
                countryId_missing: 'Country is required',
                cityId_missing: 'City is required',
                userId_missing: 'Customer is required',
                name_missing: 'Name is required',
                email_missing: 'Email is required',
                phone_missing: 'Phone is required',
                channel_missing: 'Channel is required',
                need_missing: 'Reason for storage is required',
                persona_missing: 'Customer persona is required',
            },
        },
        orders: {
            action: {
                cancel: 'Cancel Order',
            },
            notification: {
                cancelled_error: 'Error: Order has not been cancelled',
                cancelled_success: 'Order has been cancelled',
                restore_error: 'Order has not been restored',
                restore_success: 'Order has been restored',
                complete_error: 'Error: Order not completed',
                complete_success: 'Order has been completed',
                confirmed_error: 'Error: Order not confirmed',
                confirmed_success: 'Order confirmed',
                create_error: 'Error: Order create has failed',
                create_success: 'Order has been created',
                onhold_error: 'Error: Order could not be put on onhold',
                onhold_success: 'Order put on onhold',
                rejected_success: 'Order rejected',
                rejected_error: 'Error: Order not rejected',
                update_success: 'Order has been updated',
            },
        },
        requests: {
            notification: {
                confirmed_success: 'Request confirmed',
                confirmed_error: 'Error: Request not confirmed',
                onhold_success: 'Request put on hold',
                onhold_error: 'Error: Request was not put on hold',
                approved_success: 'Request approved',
                approved_error: 'Error: Request not approved',
                pickedup_success: 'Request pickedup',
                pickedup_error: 'Error: Request not pickedup',
                delivered_success: 'Request delivered',
                delivered_error: 'Error: Request not delivered',
                received_success: 'Request received',
                received_error: 'Error: Request not received',
                dispatched_success: 'Request dispatched',
                dispatched_error: 'Error: Request not dispatched',
                completed_success: 'Request completed',
                completed_error: 'Error: Request not completed',
                cancelled_success: 'Request cancelled',
                cancelled_error: 'Error: Request not cancelled',
                notify_customer_success: 'Customer has been notified',
                notify_customer_error: 'Error: Could not notify Customer',
                notify_team_success: 'Team has been notified',
                notify_team_error: 'Error: Could not notify Team',
                notify_warehouse_success: 'Warehouse has been notified',
                notify_warehouse_error: 'Error: Could not notify Warehouse',
            },
        },
        comments: {
            created_success: 'Comment added',
            created_error: 'Error: Comment addition failed',
        },
        tasks: {
            created_success: 'Email has been sent successfully',
            created_error: 'Error: Task addition failed',
        },
        invoices: {
            notification: {
                capture_success: 'Credit Card capture success',
                capture_error: 'Error: Credit Card capture failed',
                sync_success: 'Invoice has been synced successfully',
                sync_error: 'Error: sync invoice failed',
            },

            fields: {
                charge: {
                    no: 'No',
                    description: 'Description',
                    amount: 'Amount',
                },
            },
        },
        charges: {
            notification: {
                update_success: 'Charge has been updated',
            },
        },
        storagetypes: {
            notification: {
                update_success: 'StorageType has been updated',
                create_success: 'StorageType has been created',
            },
            errors: {
                userId_required: 'Customer is required',
                name_required: 'Name is required',
                size_required: 'Size is required',
                code_required: 'Code is required',
            },
        },
    },

    pos: {
        menu: {
            sales: 'Sales',
            customers: 'Customers',
            operations: 'Operations',
            accounting: 'Accounting',
            marketing: 'Marketing',
            analytics: 'Analytics',
            admin: 'Admin',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'Monthly Orders',
            pending_requests: 'Pending Requests',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
        },
        filter: {
            date: 'Date',
            status: 'Status',
            type: 'Type',
        },
    },
};
