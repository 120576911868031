import React from 'react';
import moment from 'moment';

import {
    Create,
    DateInput,
    SimpleForm,
    TextInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

const CouponCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="id" />
            <BooleanInput source="reusable" />
            <DateInput
                source="expiry"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
            <TextInput source="userId" />
            <TextInput source="offerId" />

            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default CouponCreate;
