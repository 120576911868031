import React from 'react';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { useGetOne } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import { BooleanInput } from 'react-admin';
import { TextInput, Create, SimpleForm } from 'react-admin';
import useLocalStorage from '../hooks/useLocalStorage';
import { CreateProps } from '../types';

const EmailPlaceholderCreate: React.FC<CreateProps<any>> = ({
    permissions = [],
    ...props
}) => {
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);
    const validate = values => {
        const errors: any = {};

        if (!values.name) {
            errors.name = ['Name is required'];
        }

        return errors;
    };
    return (
        <Create {...props}>
            <SimpleForm validate={validate} redirect="show">
                <TextInput label="Name" source="name" />
                <TextInput label="Description" source="description" />
                <ReferenceInput
                    label="Country"
                    source="countryId"
                    reference="country"
                    resource="country"
                    defaultValue={currentUser?.countryId}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ record, ...rest }) => {
                        return (
                            !record.isGeneric && (
                                <ArrayInput label="Emails" source="emails">
                                    <SimpleFormIterator>
                                        <TextInput
                                            disabled={record.isGeneric}
                                            label="Email"
                                        />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            )
                        );
                    }}
                </FormDataConsumer>
                <BooleanInput
                    defaultValue={false}
                    disabled
                    label="Is generic?"
                    source="isGeneric"
                />
            </SimpleForm>
        </Create>
    );
};

export default EmailPlaceholderCreate;
