import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import compose from 'recompose/compose';
import SweetAlert from 'sweetalert-react';
import { EditButton, ListButton, RefreshButton, TopToolbar } from 'react-admin';
import {
    Button,
    ButtonBase,
    ButtonGroup,
    Input,
    makeStyles,
} from '@material-ui/core';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/MailOutline';
import CardIcon from '@material-ui/icons/CreditCard';
import DollarIcon from '@material-ui/icons/AttachMoney';
import KeyIcon from '@material-ui/icons/VpnKey';
import {
    userActivate as userActivateAction,
    userDeactivate as userDeactivateAction,
    userReject as userRejectAction,
    userSuspend as userSuspendAction,
    userImpersonate as userImpersonateAction,
    userAssign as userAssignAction,
} from '../actions/userActions';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import { captureCreate as captureCreateAction } from '../../invoices/actions/invoiceActions';
import { getCards as getCardsAction } from '../../cards/actions/cardActions';
import {
    userResetPassword as userResetPasswordAction,
    userMagicLink as userMagicLinkAction,
} from '../../users/actions/userActions';
import settings from '../../settings';
import { UserStatus } from '../../constants';
import PaymentLinkDialog from './PaymentLinkDialog';
import { PaymentMode } from '../../constants';
import { ActionProps, Card } from '../../types';
import AssignIcon from '@material-ui/icons/AssignmentInd';
import AssignButton from './AssignButton';
import Popup from '../../components/popup';
import styles from './chargePopup.module.css';
import feathersClient from '../../rest/feathersClient';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

type Styles = {
    button: string;
    leftIcon: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ActionProps & {
        classes: Styles;
        cards: Card[];
    };

type State = {
    showChargeCardDialog: boolean;
    showPaymentLinkDialog: boolean;
    showPaymentReminderDialog: boolean;
    showResetPasswordDialog: boolean;
    showMagicLinkDialog: boolean;
    showAssignToMeDialog: boolean;
    currentUserId: string | null;
    chargeAmount: number | null;
    surveyLink: string | null;
    currentSurveyId: string | null;
};

class UserShowActions extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showChargeCardDialog: false,
            showPaymentLinkDialog: false,
            showPaymentReminderDialog: false,
            showResetPasswordDialog: false,
            showMagicLinkDialog: false,
            showAssignToMeDialog: false,
            currentUserId: localStorage.getItem('userId'),
            chargeAmount: null,
            surveyLink: null,
            currentSurveyId: null,
        };
    }

    componentDidMount() {
        const { getCards, data } = this.props;
        if (data && data.id) {
            getCards(data.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            const { getCards, data } = nextProps;
            if (data && data.id) {
                getCards(data.id);
            }
        }
    }

    generateSurvey(record) {
        const surveys = feathersClient.service('surveys');
        surveys
            .create({
                type: 'customerSurvey',
                status: 'new',
                userId: record.id,
                countryId: record.countryId,
            })
            .then(res => {
                let query = '';
                for (const [key, value] of Object.entries(res.queryData)) {
                    query = query + `${key}=${value}&`;
                }
                query = query.slice(0, -1);
                if (process.env.REACT_APP_ENV === 'staging') {
                    this.setState({
                        currentSurveyId: res.id,
                        surveyLink: `https://app.staging.boxitstorage.com/survey?${query}&userId=${record.id}&partnerId=${record.partnerId}&countryId=${record.countryId}`
                            .split(' ')
                            .join('%20'),
                    });
                } else {
                    this.setState({
                        currentSurveyId: res.id,
                        surveyLink: `https://app.boxitstorage.com/survey?${query}&userId=${record.id}&partnerId=${record.partnerId}&countryId=${record.countryId}`
                            .split(' ')
                            .join('%20'),
                    });
                }
            })
            .catch(err => {});
    }

    setSurveyAsSent() {
        navigator.clipboard.writeText(this.state.surveyLink || '');
        const surveys = feathersClient.service('surveys');
        surveys
            .patch(this.state.currentSurveyId, { command: 'SEND-SURVEY' })
            .then(res => {});
    }

    render() {
        const {
            basePath,
            data,
            classes,
            cards,
            hasEdit,
            userActivate,
            userDeactivate,
            userReject,
            userSuspend,
            taskCreate,
            captureCreate,
            userResetPassword,
            userMagicLink,
            userImpersonate,
            permissions = [],
            userAssign,
        } = this.props;

        if (permissions.indexOf('partner') !== -1) {
            return (
                <TopToolbar>
                    {hasEdit && (
                        <EditButton
                            color="secondary"
                            basePath={basePath}
                            record={data}
                        />
                    )}
                </TopToolbar>
            );
        }

        const canImpersonate =
            (JSON.parse(
                localStorage.getItem('permissions') as string
            ) as string[]).findIndex(item => item === 'user-impersonation') !==
            -1;

        return (
            <TopToolbar>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    onClick={() => this.generateSurvey(data)}
                >
                    Generate Survey
                </Button>
                {data && canImpersonate && (
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={() => userImpersonate(data.id, data)}
                    >
                        Impersonate customer
                    </Button>
                )}
                <ButtonGroup size="small">
                    {data && data.status !== UserStatus.active && (
                        <Button
                            color="secondary"
                            onClick={() => userActivate(data.id, data)}
                        >
                            Activate
                        </Button>
                    )}

                    {data && data.status !== UserStatus.inactive && (
                        <Button
                            color="secondary"
                            onClick={() => userDeactivate(data.id, data)}
                        >
                            Deactivate
                        </Button>
                    )}

                    {data && data.status !== UserStatus.rejected && (
                        <Button
                            color="secondary"
                            onClick={() => userReject(data.id, data)}
                        >
                            Reject
                        </Button>
                    )}

                    {data &&
                        data.status !== UserStatus.suspended &&
                        _.intersection(permissions, ['admin', 'finance']) && (
                            <Button
                                color="secondary"
                                onClick={() => userSuspend(data.id, data)}
                            >
                                Suspend
                            </Button>
                        )}
                    {data && data.assigneeId != this.state.currentUserId && (
                        <Button
                            color="secondary"
                            startIcon={<AssignIcon />}
                            onClick={() =>
                                this.setState({ showAssignToMeDialog: true })
                            }
                        >
                            Assign To Me
                        </Button>
                    )}
                    {data && <AssignButton record={data} />}
                </ButtonGroup>

                {data && cards.length > 0 && (
                    <span>
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            className={classes.button}
                            startIcon={<CardIcon />}
                            onClick={() =>
                                this.setState({ showChargeCardDialog: true })
                            }
                        >
                            Charge
                        </Button>

                        {/*<SweetAlert
                            show={this.state.showChargeCardDialog}
                            title="Charge Credit Card"
                            button="Charge"
                            type="input"
                            inputPlaceholder={`Amount ${
                                data.countryId === 1 ? 'AED' : 'KWD'
                            }`}
                            confirmButtonText="Charge"
                            showCancelButton
                            onCancel={() =>
                                this.setState({ showChargeCardDialog: false })
                            }
                            onEscapeKey={() =>
                                this.setState({ showChargeCardDialog: false })
                            }
                            onOutsideClick={() =>
                                this.setState({ showChargeCardDialog: false })
                            }
                            onConfirm={inputValue => {
                                if (inputValue) {
                                    captureCreate(
                                        data.countryId === 1
                                            ? PaymentMode.payfort
                                            : PaymentMode.tap,
                                        {
                                            userId: data.id,
                                            amount: inputValue * 10000,
                                        }
                                    );
                                }
                                this.setState({ showChargeCardDialog: false });
                            }}
                        />*/}
                        {this.state.showChargeCardDialog ? (
                            <Popup
                                cancel={() =>
                                    this.setState({
                                        showChargeCardDialog: false,
                                    })
                                }
                            >
                                <div className={styles.charge}>
                                    <h2 className={styles.title}>
                                        Charge Credit Card
                                    </h2>
                                    <Input
                                        className={styles.input}
                                        onChange={e => {
                                            if (
                                                parseFloat(e.target.value) > 0
                                            ) {
                                                this.setState({
                                                    chargeAmount: parseFloat(
                                                        e.target.value
                                                    ),
                                                });
                                            } else {
                                                this.setState({
                                                    chargeAmount: null,
                                                });
                                            }
                                        }}
                                        type="number"
                                        placeholder={`Amount ${
                                            data.countryId === 1
                                                ? 'AED'
                                                : data.countryId === 2
                                                ? 'KWD'
                                                : 'SAR'
                                        }`}
                                    />
                                    <div className={styles.actions}>
                                        <button
                                            className={[
                                                styles.btn,
                                                styles.btn_cancel,
                                            ].join(' ')}
                                            onClick={() =>
                                                this.setState({
                                                    showChargeCardDialog: false,
                                                })
                                            }
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className={[
                                                styles.btn,
                                                styles.btn_charge,
                                            ].join(' ')}
                                            disabled={!this.state.chargeAmount}
                                            onClick={() => {
                                                if (this.state.chargeAmount) {
                                                    captureCreate(
                                                        data.countryId === 2
                                                            ? PaymentMode.tap
                                                            : PaymentMode.payfort,
                                                        {
                                                            userId: data.id,
                                                            amount:
                                                                this.state
                                                                    .chargeAmount *
                                                                10000,
                                                        }
                                                    );
                                                }
                                                this.setState({
                                                    showChargeCardDialog: false,
                                                    chargeAmount: null,
                                                });
                                            }}
                                        >
                                            Charge
                                        </button>
                                    </div>
                                </div>
                            </Popup>
                        ) : null}
                    </span>
                )}

                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    onClick={() =>
                        this.setState({ showPaymentLinkDialog: true })
                    }
                >
                    Payment Link
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    href={
                        data
                            ? `${settings.apiUrl}/pdf/statement?id=${data.id}`
                            : ''
                    }
                    target="_blank"
                >
                    <FileIcon className={classes.leftIcon} />
                    SOA
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    startIcon={<EmailIcon />}
                    onClick={() =>
                        this.setState({ showPaymentReminderDialog: true })
                    }
                >
                    Send Invoices
                </Button>

                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    startIcon={<EmailIcon />}
                    onClick={() =>
                        this.setState({ showResetPasswordDialog: true })
                    }
                >
                    Reset Password
                </Button>

                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    startIcon={<EmailIcon />}
                    onClick={() => this.setState({ showMagicLinkDialog: true })}
                >
                    Magic Link
                </Button>

                {hasEdit && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        record={data}
                    />
                )}

                <ListButton color="secondary" basePath={basePath} />

                <PaymentLinkDialog
                    open={this.state.showPaymentLinkDialog}
                    onClose={() =>
                        this.setState({ showPaymentLinkDialog: false })
                    }
                    record={data}
                />

                <SweetAlert
                    show={this.state.showPaymentReminderDialog}
                    title="Are you sure?"
                    text={
                        'Do you want to send pending invoices to this customer?'
                    }
                    showCancelButton
                    onCancel={() =>
                        this.setState({ showPaymentReminderDialog: false })
                    }
                    onEscapeKey={() =>
                        this.setState({ showPaymentReminderDialog: false })
                    }
                    onOutsideClick={() =>
                        this.setState({ showPaymentReminderDialog: false })
                    }
                    onConfirm={() => {
                        this.setState({ showPaymentReminderDialog: false });
                        taskCreate({
                            type: 'SEND_PENDING_INVOICES_TO_CUSTOMER',
                            data: { userId: data!.id },
                        });
                    }}
                />

                <SweetAlert
                    show={this.state.showResetPasswordDialog}
                    title="Are you sure?"
                    text={
                        'Do you want to send reset password email to this customer?'
                    }
                    showCancelButton
                    onCancel={() =>
                        this.setState({ showResetPasswordDialog: false })
                    }
                    onEscapeKey={() =>
                        this.setState({ showResetPasswordDialog: false })
                    }
                    onOutsideClick={() =>
                        this.setState({ showResetPasswordDialog: false })
                    }
                    onConfirm={() => {
                        this.setState({ showResetPasswordDialog: false });
                        userResetPassword(data!.email);
                    }}
                />

                <SweetAlert
                    show={this.state.showMagicLinkDialog}
                    title="Are you sure?"
                    text={'Do you want to send magic link to this customer?'}
                    showCancelButton
                    onCancel={() =>
                        this.setState({ showMagicLinkDialog: false })
                    }
                    onEscapeKey={() =>
                        this.setState({ showMagicLinkDialog: false })
                    }
                    onOutsideClick={() =>
                        this.setState({ showMagicLinkDialog: false })
                    }
                    onConfirm={() => {
                        this.setState({ showMagicLinkDialog: false });
                        userMagicLink(data!.email);
                    }}
                />
                {data && this.state.currentUserId && (
                    <SweetAlert
                        show={this.state.showAssignToMeDialog}
                        title="Are you sure?"
                        text="Do you want to assign this user to you?"
                        showCancelButton
                        onCancel={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onEscapeKey={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onOutsideClick={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onConfirm={() => {
                            this.setState({ showAssignToMeDialog: false });
                            userAssign(
                                data.id,
                                data,
                                this.state.currentUserId!
                            );
                        }}
                    />
                )}

                {this.state.surveyLink && (
                    <Popup cancel={() => this.setState({ surveyLink: null })}>
                        <div
                            style={{
                                padding: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                minWidth: '20rem',
                                gap: '1rem',
                            }}
                        >
                            <h1 style={{ fontSize: '2rem' }}>Survey link</h1>
                            <p
                                style={{
                                    width: '100%',
                                    overflowWrap: 'break-word',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {this.state.surveyLink}
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: '1rem',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        this.setSurveyAsSent();
                                    }}
                                >
                                    Copy
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        this.setState({ surveyLink: null })
                                    }
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Popup>
                )}
            </TopToolbar>
        );
    }
}

const mapState = (state, props) => ({
    cards:
        (props.data &&
            state.admin.resources.cards &&
            Object.values(
                _.pickBy(
                    state.admin.resources.cards.data,
                    v => v.userId === props.data.id && v.status === 'active'
                )
            )) ||
        [],
});

const mapDispatch = {
    userActivate: userActivateAction,
    userDeactivate: userDeactivateAction,
    userReject: userRejectAction,
    userSuspend: userSuspendAction,
    taskCreate: taskCreateAction,
    captureCreate: captureCreateAction,
    getCards: getCardsAction,
    userResetPassword: userResetPasswordAction,
    userMagicLink: userMagicLinkAction,
    userImpersonate: userImpersonateAction,
    userAssign: userAssignAction,
};

const connector = connect(mapState, mapDispatch);

//const enhance = compose(connector);

//export default enhance(withStyles(styles)(UserShowActions));
const ConnectedUserShowActions = connector(UserShowActions);

export default props => {
    const classes = useStyles();
    return <ConnectedUserShowActions classes={classes} {...props} />;
};
