import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    ChipField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';
import CityField from '../../components/CityField';
import { Chip } from '@material-ui/core';
import { FunctionField } from 'react-admin';
import humanize from '../../util/humanize';

export const OrderRequestsList = props => (
    <ReferenceManyField
        reference="requests"
        target="orderId"
        filter={{ orderId: props.record.id }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <DateField locales="en-IN" source="date" />
            <TextField source="status" />
            {/*}ChipField source="type" />*/}
            <FunctionField
                label="Type"
                render={record => {
                    return <Chip label={humanize(record.type)} />;
                }}
            />
            <CityField />
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
);
