import React from 'react';
import capitalize from 'capitalize';
import {
    required,
    Create,
    FormDataConsumer,
    SelectInput,
    SimpleForm,
    DateInput,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    CheckboxGroupInput,
} from 'react-admin';

import {
    CountryCode,
    CurrencyByCountryId,
    PaymentMode,
    PaymentType,
} from '../constants';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';
import { moneyString } from '../core/utils/money';
import useLocalStorage from '../hooks/useLocalStorage';
import { useGetOne } from 'react-admin';
import { useSelector } from 'react-redux';

function invoiceNo(invoice) {
    return `IN${CountryCode[invoice.countryId]}${invoice.id}`;
}

function invoiceText(invoice) {
    const amountStr = invoice.amountPaid
        ? `${moneyString(
              invoice.amount - invoice.amountPaid,
              invoice.currency
          )} (Balance)`
        : `${moneyString(invoice.amount, invoice.currency)}`;

    return `[${invoiceNo(invoice)}] : ${invoice.status} - ${amountStr}`;
}

const isRequired = required();

const PaymentCreate = props => {
    const [currentUserId] = useLocalStorage('userId', null);
    const { data: currentUser } = useGetOne('users', currentUserId);

    const selectedCustomer = useSelector((state: any) => {
        const customerId =
            state.admin?.resources?.payments?.list?.params?.filter?.userId;
        const customerDto = state.admin?.resources?.users.data?.[customerId];
        return customerDto;
    });

    return (
        <Create {...props}>
            <SimpleForm variant="outlined" redirect="show">
                <TextInput
                    disabled
                    style={{ display: 'none' }}
                    source="status"
                    defaultValue="complete"
                />
                <ReferenceInput
                    label="User"
                    source="userId"
                    reference="users"
                    resource="users"
                    validate={isRequired}
                    perPage={100}
                    allowEmpty
                    defaultValue={selectedCustomer?.id}
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                        defaultValue={selectedCustomer?.id}
                    />
                </ReferenceInput>

                <SelectInput
                    source="type"
                    label="Payment Type"
                    defaultValue="payment"
                    validate={isRequired}
                    choices={Object.keys(PaymentType).map(c => ({
                        id: c,
                        name: capitalize(c),
                    }))}
                />

                <SelectInput
                    source="mode"
                    label="Payment Mode"
                    validate={isRequired}
                    choices={[
                        { id: 'cod', name: 'COD' },
                        { id: 'cheque', name: 'Cheque' },
                        { id: 'bank_deposit', name: 'Bank Deposit' },
                        { id: 'bank_transfer', name: 'Bank Transfer' },
                        { id: 'credits', name: 'Take from Credits' },
                        { id: 'deposit', name: 'Take from Deposit' },
                    ]}
                />
                <DateInput
                    source="paidAt"
                    label="Payment Date"
                    validate={isRequired}
                />

                <CurrencyInput
                    source="currency"
                    validate={isRequired}
                    defaultValue={CurrencyByCountryId[currentUser?.countryId]}
                />

                <MoneyInput source="amount" validate={isRequired} />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId &&
                        formData.type === PaymentType.payment && (
                            <ReferenceArrayInput
                                label="Invoices"
                                source="invoiceIds"
                                reference="invoices"
                                filter={{
                                    userId: formData.userId,
                                    status: { $in: ['unpaid', 'partial'] },
                                }}
                                allowEmpty
                            >
                                <CheckboxGroupInput
                                    {...props}
                                    source="invoiceIds"
                                    optionText={invoiceText}
                                />
                            </ReferenceArrayInput>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default PaymentCreate;
