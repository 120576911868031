import React from 'react';
import _ from 'lodash';
import AdminDashboard from './AdminDashboard';
import AffiliateDashboard from './AffiliateDashboard';
import PartnerDashboard from './PartnerDashboard';

export const Dashboard = ({ permissions = [] }: { permissions: string[] }) => {
    if (permissions.indexOf('affiliate') !== -1) return <AffiliateDashboard />;

    if (permissions.indexOf('partner') !== -1) return <PartnerDashboard />;

    if (
        _.intersection(permissions, [
            'admin',
            'staff',
            'global-staff',
            'transport-staff',
            'warehouse-staff',
        ]).length
    )
        return <AdminDashboard />;

    return null;
};
