import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

export const RequestLineSummary = props => {
    const { record } = props;

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <label>ID</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.id}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Request</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.requestId}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Storage</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.storage.code}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Qty Line</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.qty}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Qty Done</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.qtyDone}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Qty WH</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.qtyInOut}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

RequestLineSummary.propTypes = {
    record: PropTypes.object,
};
