import React from 'react';
import { required } from 'react-admin';
import { SelectInput } from 'react-admin';

import { Create, SimpleForm, TextInput } from 'react-admin';
import { handleTagsError, handleUserError } from '../users/error';
import { useNotify } from 'react-admin';

const RejectReasonsCreate = props => {
    const notify = useNotify();
    return (
        <Create
            {...props}
            title="Reject reason"
            onFailure={error => handleTagsError(error, notify)}
        >
            <SimpleForm variant="outlined" redirect="show">
                <TextInput source="reason" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};

export default RejectReasonsCreate;
