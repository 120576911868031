import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { crudUpdateMany, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionEdit from '@material-ui/icons/Edit';
import ActionCheck from '@material-ui/icons/CheckCircle';
import AlertError from '@material-ui/icons/ErrorOutline';
import classnames from 'classnames';
import { StorageStatus } from '../../constants';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    FormControl,
    InputLabel,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import { BulkEditProps } from '../../types';

const sanitizeRestProps: (any) => any = ({
    basePath,
    classes,
    crudUpdateMany,
    filterValues,
    label,
    resource,
    selectedIds,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: 10,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    editButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    contentText: {
        minWidth: 400,
    },
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}));

type Styles = {
    input: string;
    textField: string;
    container: string;
    contentText: string;
    editButton: string;
    iconPaddingStyle: string;
    confirmPrimary: string;
    confirmWarning: string;
    actionButton: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    BulkEditProps & {
        classes: Styles;
        cancel: string;
        label: string;
        icon: object;
        confirm: string;
        confirmColor: string;
        translate: (a: string, b?: object) => string;
    };

type State = {
    isOpen: boolean;
    loading: boolean;
    status: string;
    fromDate: string;
    toDate: string;
};

class BulkEdit extends Component<Props, State> {
    state = {
        isOpen: false,
        loading: false,
        status: '',
        fromDate: '',
        toDate: '',
    };

    handleChange = name => event => {
        // @ts-ignore
        this.setState({
            [name]: event.target.value,
        });
    };

    handleClick = e => {
        this.setState({ isOpen: true });
        e.stopPropagation();
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    handleConfirm = () => {
        const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
        const { fromDate, toDate, status } = this.state;
        const data = {
            ...(fromDate && { from: fromDate }),
            ...(toDate && { to: toDate }),
            ...(status && { status }),
        };

        if (!_.isEmpty(data)) {
            this.setState({ loading: true });
            crudUpdateMany(resource, selectedIds, data, basePath);
        }
    };

    render() {
        const {
            label = 'ra.action.edit',
            cancel = 'ra.action.cancel',
            confirm = 'ra.action.confirm',
            confirmColor = 'primary',
            icon = <ActionEdit />,
            classes,
            translate,
            resource,
            selectedIds,
            ...rest
        } = this.props;
        const { isOpen, loading } = this.state;

        return (
            <Fragment>
                <Button
                    onClick={this.handleClick}
                    className={classes.editButton}
                    {...sanitizeRestProps(rest)}
                >
                    {icon}
                    {translate(label, { _: label })}
                </Button>

                <Dialog open={isOpen} onClose={this.handleDialogClose}>
                    <DialogTitle>Bulk Edit</DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.contentText}>
                            All fields are optional.
                            <br />
                            Fill only those fields that needs to be updated.
                        </DialogContentText>

                        <form
                            className={classes.container}
                            noValidate
                            autoComplete="off"
                        >
                            <FormControl>
                                <TextField
                                    id="fromDate"
                                    label="From"
                                    type="date"
                                    value={this.state.fromDate}
                                    className={classes.textField}
                                    onChange={this.handleChange('fromDate')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ id: 'fromDate' }}
                                />
                            </FormControl>

                            <FormControl>
                                <TextField
                                    id="toDate"
                                    label="To"
                                    type="date"
                                    value={this.state.toDate}
                                    className={classes.textField}
                                    onChange={this.handleChange('toDate')}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ id: 'toDate' }}
                                />
                            </FormControl>

                            <FormControl>
                                <InputLabel htmlFor="status">Status</InputLabel>
                                <Select
                                    value={this.state.status}
                                    onChange={this.handleChange('status')}
                                >
                                    {Object.keys(StorageStatus).map(s => (
                                        <MenuItem key={s} value={s}>
                                            {s}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loading}
                            onClick={this.handleDialogClose}
                        >
                            <AlertError className={classes.iconPaddingStyle} />
                            {translate(cancel, { _: cancel })}
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={this.handleConfirm}
                            className={classnames('ra-confirm', {
                                [classes.confirmWarning]:
                                    confirmColor === 'warning',
                                [classes.confirmPrimary]:
                                    confirmColor === 'primary',
                            })}
                            autoFocus
                        >
                            <ActionCheck className={classes.iconPaddingStyle} />
                            {translate(confirm, { _: confirm })}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

const mapDispatch = {
    crudUpdateMany,
};

const connector = connect(null, mapDispatch);

const ConnectedBulkEdit = connector(BulkEdit);

export default props => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <ConnectedBulkEdit classes={classes} translate={translate} {...props} />
    );
};
