import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import { RequestSummary } from './RequestSummary';
import { History } from '../../revisions';
import { Comments } from '../../comments';
import RequestLineList from './RequestLineList';
import RequestStorageList from './RequestStorageList';
import PhotoGallery from '../../components/PhotoGallery';
import RequestChargeList from './RequestChargeList';
import { RequestChargesTab } from './RequestChargesTab';
import { RequestsCommunicationsList } from './RequestsCommunicationsList';
import AddRequestPhoto from './AddRequestPhoto';
import NewPhotoGallery from '../../components/NewPhotoGallery';
import AddVideo from '../../components/AddVideo';
import { RequestStatus } from '../../constants';

export const RequestTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <RequestSummary />
            </Tab>
            <Tab label="Storages">
                <RequestStorageList />
            </Tab>
            <Tab label="Lines">
                <RequestLineList />
            </Tab>
            <Tab label="Charges">
                <RequestChargesTab />
            </Tab>
            <Tab label="Photos">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                    }}
                >
                    {/*<AddRequestPhoto record={record} />*/}
                    {record.status !== RequestStatus.complete && (
                        <AddVideo record={record} />
                    )}
                </div>
                {/*<PhotoGallery
                    {...props}
                    target="requestId"
                    reference="photos"
    />*/}
                <NewPhotoGallery record={record} />
            </Tab>
            <Tab label="Comments">
                <Comments
                    {...props}
                    model="request"
                    documentId={record.id}
                    userId={record.userId}
                />
            </Tab>
            <Tab label="History">
                <History {...props} model="request" documentId={record.id} />
            </Tab>
            <Tab label="Communication">
                <RequestsCommunicationsList />
            </Tab>
        </TabbedShowLayout>
    );
};

RequestTabs.propTypes = {
    record: PropTypes.object,
};

RequestTabs.defaultProps = {
    record: {},
};
