import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Volume } from './Volume';
import { FieldProps } from '../types';

type Props = FieldProps & {
    showUnit?: boolean;
};

const VolumeField = ({ record, source, showUnit = false }: Props) => {
    if (!record) return null;
    const value = get(record, source);
    if (value === null) return null;

    return <Volume value={value} showUnit={showUnit} />;
};

VolumeField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    showUnit: PropTypes.bool,
};

VolumeField.defaultProps = {
    record: null,
    showUnit: false,
};

export default VolumeField;
