import React from 'react';
import PropTypes from 'prop-types';
import { Tab, TabbedShowLayout } from 'react-admin';
import CommunicationDetails from './CommunicationDetails';
import { CommunicationBody } from './CommunicationBody';

export const CommunicationTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="Details">
                <CommunicationDetails />
            </Tab>
            <Tab label="Body">
                <CommunicationBody />
            </Tab>
        </TabbedShowLayout>
    );
};

CommunicationTabs.propTypes = {
    record: PropTypes.object,
};

CommunicationTabs.defaultProps = {
    record: {},
};
