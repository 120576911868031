import React from 'react';
import moment from 'moment';
import { DateField } from 'react-admin';

type Props = {
    label?: string;
    record?: {
        year: number;
        month: number;
        startDate: number;
    };
};

const ChargeDateField: React.FC<Props> = ({ record }) => {
    return record ? (
        <DateField
            locales="en-IN"
            record={{
                date: moment({
                    year: record.year,
                    month: record.month - 1,
                    date: record.startDate,
                }),
            }}
            source="date"
        />
    ) : null;
};

ChargeDateField.defaultProps = { label: 'Date' };

export default ChargeDateField;
