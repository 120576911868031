import { UPDATE } from 'react-admin';
import { CREATE } from 'react-admin';
import { DELETE } from 'react-admin';
import { RequestStatus, RequestType } from '../../constants';

export const REQUEST_CONFIRM = 'REQUEST_CONFIRM';
export const REQUEST_CONFIRM_LOADING = 'REQUEST_CONFIRM_LOADING';
export const REQUEST_CONFIRM_FAILURE = 'REQUEST_CONFIRM_FAILURE';
export const REQUEST_CONFIRM_SUCCESS = 'REQUEST_CONFIRM_SUCCESS';

export const requestConfirm = (id, data) => ({
    type: REQUEST_CONFIRM,
    payload: { id, data: { ...data, status: RequestStatus.confirmed } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_ONHOLD = 'REQUEST_ONHOLD';
export const REQUEST_ONHOLD_LOADING = 'REQUEST_ONHOLD_LOADING';
export const REQUEST_ONHOLD_FAILURE = 'REQUEST_ONHOLD_FAILURE';
export const REQUEST_ONHOLD_SUCCESS = 'REQUEST_ONHOLD_SUCCESS';

export const requestOnhold = (id, data) => ({
    type: REQUEST_ONHOLD,
    payload: { id, data: { ...data, status: RequestStatus.onhold } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_APPROVE = 'REQUEST_APPROVE';
export const REQUEST_APPROVE_LOADING = 'REQUEST_APPROVE_LOADING';
export const REQUEST_APPROVE_FAILURE = 'REQUEST_APPROVE_FAILURE';
export const REQUEST_APPROVE_SUCCESS = 'REQUEST_APPROVE_SUCCESS';

export const requestApprove = (id, data) => ({
    type: REQUEST_APPROVE,
    payload: { id, data: { ...data, status: RequestStatus.approved } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_CANCEL = 'REQUEST_CANCEL';
export const REQUEST_CANCEL_LOADING = 'REQUEST_CANCEL_LOADING';
export const REQUEST_CANCEL_FAILURE = 'REQUEST_CANCEL_FAILURE';
export const REQUEST_CANCEL_SUCCESS = 'REQUEST_CANCEL_SUCCESS';

export const requestCancel = (id, data) => ({
    type: REQUEST_CANCEL,
    payload: { id, data: { ...data, status: RequestStatus.cancelled } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_RESOLVE = 'REQUEST_RESOLVE';
export const REQUEST_RESOLVE_LOADING = 'REQUEST_RESOLVE_LOADING';
export const REQUEST_RESOLVE_FAILURE = 'REQUEST_RESOLVE_FAILURE';
export const REQUEST_RESOLVE_SUCCESS = 'REQUEST_RESOLVE_SUCCESS';

export const requestResolve = (id, data) => ({
    type: REQUEST_RESOLVE,
    payload: { id, data: { ...data, customerAction: 'resolved' } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_PICKUP = 'REQUEST_PICKUP';
export const REQUEST_PICKUP_LOADING = 'REQUEST_PICKUP_LOADING';
export const REQUEST_PICKUP_FAILURE = 'REQUEST_PICKUP_FAILURE';
export const REQUEST_PICKUP_SUCCESS = 'REQUEST_PICKUP_SUCCESS';

export const requestPickup = (id, data) => ({
    type: REQUEST_PICKUP,
    payload: { id, data: { ...data, status: RequestStatus.transit } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_DELIVER = 'REQUEST_DELIVER';
export const REQUEST_DELIVER_LOADING = 'REQUEST_DELIVER_LOADING';
export const REQUEST_DELIVER_FAILURE = 'REQUEST_DELIVER_FAILURE';
export const REQUEST_DELIVER_SUCCESS = 'REQUEST_DELIVER_SUCCESS';

export const requestDeliver = (id, data) => ({
    type: REQUEST_DELIVER,
    payload: { id, data: { ...data, status: RequestStatus.delivered } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_RECEIVE = 'REQUEST_RECEIVE';
export const REQUEST_RECEIVE_LOADING = 'REQUEST_RECEIVE_LOADING';
export const REQUEST_RECEIVE_FAILURE = 'REQUEST_RECEIVE_FAILURE';
export const REQUEST_RECEIVE_SUCCESS = 'REQUEST_RECEIVE_SUCCESS';

export const requestReceive = (id, data) => ({
    type: REQUEST_RECEIVE,
    payload: { id, data: { ...data, status: RequestStatus.received } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_DISPATCH = 'REQUEST_DISPATCH';
export const REQUEST_DISPATCH_LOADING = 'REQUEST_DISPATCH_LOADING';
export const REQUEST_DISPATCH_FAILURE = 'REQUEST_DISPATCH_FAILURE';
export const REQUEST_DISPATCH_SUCCESS = 'REQUEST_DISPATCH_SUCCESS';

export const requestDispatch = (id, data) => ({
    type: REQUEST_DISPATCH,
    payload: { id, data: { ...data, status: RequestStatus.transit } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_COMPLETE = 'REQUEST_COMPLETE';
export const REQUEST_COMPLETE_LOADING = 'REQUEST_COMPLETE_LOADING';
export const REQUEST_COMPLETE_FAILURE = 'REQUEST_COMPLETE_FAILURE';
export const REQUEST_COMPLETE_SUCCESS = 'REQUEST_COMPLETE_SUCCESS';

export const requestComplete = (id, data) => ({
    type: REQUEST_COMPLETE,
    payload: { id, data: { ...data, status: RequestStatus.complete } },
    meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
});

export const REQUEST_NOTIFY_CUSTOMER = 'REQUEST_NOTIFY_CUSTOMER';
export const REQUEST_NOTIFY_CUSTOMER_LOADING =
    'REQUEST_NOTIFY_CUSTOMER_LOADING';
export const REQUEST_NOTIFY_CUSTOMER_FAILURE =
    'REQUEST_NOTIFY_CUSTOMER_FAILURE';
export const REQUEST_NOTIFY_CUSTOMER_SUCCESS =
    'REQUEST_NOTIFY_CUSTOMER_SUCCESS';

export const requestNotifyCustomer = id => ({
    type: REQUEST_NOTIFY_CUSTOMER,
    payload: { data: { id, type: 'REQUEST_NOTIFY_CUSTOMER' } },
    meta: { resource: 'notify', fetch: CREATE, cancelPrevious: false },
});

export const REQUEST_NOTIFY_TEAM = 'REQUEST_NOTIFY_TEAM';
export const REQUEST_NOTIFY_TEAM_LOADING = 'REQUEST_NOTIFY_TEAM_LOADING';
export const REQUEST_NOTIFY_TEAM_FAILURE = 'REQUEST_NOTIFY_TEAM_FAILURE';
export const REQUEST_NOTIFY_TEAM_SUCCESS = 'REQUEST_NOTIFY_TEAM_SUCCESS';

export const requestNotifyTeam = id => ({
    type: REQUEST_NOTIFY_TEAM,
    payload: { data: { id, type: 'REQUEST_NOTIFY_TEAM' } },
    meta: { resource: 'notify', fetch: CREATE, cancelPrevious: false },
});

export const REQUEST_NOTIFY_WAREHOUSE = 'REQUEST_NOTIFY_WAREHOUSE';
export const REQUEST_NOTIFY_WAREHOUSE_LOADING =
    'REQUEST_NOTIFY_WAREHOUSE_LOADING';
export const REQUEST_NOTIFY_WAREHOUSE_FAILURE =
    'REQUEST_NOTIFY_WAREHOUSE_FAILURE';
export const REQUEST_NOTIFY_WAREHOUSE_SUCCESS =
    'REQUEST_NOTIFY_WAREHOUSE_SUCCESS';

export const requestNotifyWarehouse = id => ({
    type: REQUEST_NOTIFY_WAREHOUSE,
    payload: { data: { id, type: 'REQUEST_NOTIFY_WAREHOUSE' } },
    meta: { resource: 'notify', fetch: CREATE, cancelPrevious: false },
});

export const REQUEST_ADD_PHOTO = 'REQUEST_ADD_PHOTO';
export const REQUEST_ADD_PHOTO_LOADING = 'REQUEST_ADD_PHOTO_LOADING';
export const REQUEST_ADD_PHOTO_FAILURE = 'REQUEST_ADD_PHOTO_FAILURE';
export const REQUEST_ADD_PHOTO_SUCCESS = 'REQUEST_ADD_PHOTO_SUCCESS';

export const addRequestPhoto = (userId, requestId, file) => ({
    type: REQUEST_ADD_PHOTO,
    payload: { data: { userId, requestId, file } },
    meta: { resource: 'photos', fetch: CREATE, cancelPrevious: false },
});

export const USER_REQUEST_ASSIGN = 'USER_REQUEST_ASSIGN';
export const USER_REQUEST_ASSIGN_LOADING = 'USER_REQUEST_ASSIGN_LOADING';
export const USER_REQUEST_ASSIGN_FAILURE = 'USER_REQUEST_ASSIGN_FAILURE';
export const USER_REQUEST_ASSIGN_SUCCESS = 'USER_REQUEST_ASSIGN_SUCCESS';

export const userAssign = (
    id: string | number,
    data: any,
    assigneeId: string | number
) => {
    return {
        type: USER_REQUEST_ASSIGN,
        payload: {
            id,
            data: {
                assigneeId,
            },
        },
        meta: { resource: 'requests', fetch: UPDATE, cancelPrevious: false },
    };
};
