import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    Filter,
    DateField,
    SelectInput,
    ShowButton,
} from 'react-admin';

import { StorageRecordStatus, StorageRecordType } from '../constants';
import BoxitChipField from '../components/BoxitChipField';
import { SelectArrayInput } from 'react-admin';

const StorageRecordFilter = props => (
    <Filter variant="outlined" {...props}>
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={Object.keys(StorageRecordStatus).map(c => ({
                id: c,
                name: c,
            }))}
        />
        <ReferenceInput
            source="storageId"
            reference="storages"
            resource="storages"
            label="Storage"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText="code"
            />
        </ReferenceInput>
        <ReferenceInput
            source="warehouseId"
            reference="warehouses"
            resource="warehouses"
            label="Warehouse"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
            />
        </ReferenceInput>
        <ReferenceInput
            source="locationId"
            reference="locations"
            resource="locations"
            label="Locations"
        >
            <AutocompleteInput
                shouldRenderSuggestions={val => val.trim().length > 2}
                optionText="path"
            />
        </ReferenceInput>
        <SelectInput
            source="type"
            choices={Object.keys(StorageRecordType).map(c => ({
                id: c,
                name: c,
            }))}
        />
    </Filter>
);

const StorageRecordList = props => (
    <List
        title="Storage Records"
        bulkActionButtons={false}
        filters={<StorageRecordFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <ReferenceField
                label="Order"
                source="orderId"
                reference="orders"
                link="show"
            >
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField
                label="Storage"
                source="storageId"
                reference="storages"
                link="show"
            >
                <TextField source="code" />
            </ReferenceField>
            <ReferenceField
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField locales="en-IN" source="from" label="IN" />
            <DateField locales="en-IN" source="to" label="OUT" />
            <ReferenceField
                label="Location"
                source="locationId"
                reference="locations"
                link="show"
                allowEmpty
            >
                <TextField source="path" />
            </ReferenceField>
            <BoxitChipField record source="status" list="storage_record" />
            <DateField locales="en-IN" source="activeAt" label="Stored At" />
            <ReferenceField
                label="In By"
                source="inById"
                reference="users"
                link="show"
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Stored By"
                source="activeById"
                reference="users"
                link="show"
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Out By"
                source="outById"
                reference="users"
                link="show"
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </List>
);

StorageRecordList.defaultProps = {
    resource: 'storage-records',
    storagerecord: { search: '' },
};

export default StorageRecordList;
