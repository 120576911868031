import React from 'react';

import {
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    ReferenceInput,
    BooleanInput,
} from 'react-admin';

const TeamCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="partnerId" defaultValue="1" />
            <TextInput source="name" />
            <TextInput source="id" />
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
                allowEmpty
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput source="active" />
        </SimpleForm>
    </Create>
);

export default TeamCreate;
