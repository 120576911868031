import React from 'react';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { EditButton, ListButton, TopToolbar } from 'react-admin';
import EmailIcon from '@material-ui/icons/MailOutline';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import settings from '../../settings';
import { PaymentMode } from '../../constants';
import DeleteButton from '../../../src/components/DeleteButton';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import { ActionProps, Payment } from '../../types';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ActionProps<Payment>;

const PaymentShowActions: React.FC<Props> = ({
    basePath,
    data,
    hasEdit,
    permissions = [],
    resource,
    taskCreate,
}) => {
    const classes = useStyles();
    return (
        <TopToolbar>
            {data && data.status === 'complete' && (
                <Button
                    color="secondary"
                    onClick={() =>
                        taskCreate({
                            type: 'MAIL_RECEIPT_TO_CUSTOMER',
                            data: { paymentId: data.id },
                        })
                    }
                >
                    <EmailIcon className={classes.leftIcon} />
                    Send Receipt
                </Button>
            )}

            {data && data.status === 'complete' && (
                <Button
                    color="secondary"
                    href={
                        data
                            ? `${settings.apiUrl}/pdf/receipt?id=${data.id}`
                            : ''
                    }
                    target="_blank"
                >
                    <FileIcon className={classes.leftIcon} />
                    Receipt
                </Button>
            )}

            {data && data.status !== 'complete' && (
                <EditButton
                    color="secondary"
                    basePath={basePath}
                    record={data}
                />
            )}

            {hasEdit &&
                data &&
                _.intersection(permissions, ['admin', 'finance']) &&
                ['payfort', 'tap'].indexOf(data.mode) === -1 && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        record={data}
                    />
                )}

            {data &&
                _.intersection(permissions, ['admin', 'finance']) &&
                ['payfort', 'tap'].indexOf(data.mode) === -1 && (
                    <DeleteButton
                        basePath={basePath}
                        record={data}
                        resource={resource}
                        undoable={false}
                    />
                )}

            <ListButton color="secondary" basePath={basePath} />
        </TopToolbar>
    );
};

const mapDispatch = {
    taskCreate: taskCreateAction,
};

const connector = connect(null, mapDispatch);

export default connector(PaymentShowActions);
