import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import { LeadTabs } from './components/LeadTabs';
import LeadShowActions from './components/LeadShowActions';
import { ShowProps, Order } from '../types';
import { modelView, modelViewType } from '../util/modelView';

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

const LeadShow: React.FC<ShowProps<Order>> = ({
    permissions = [],
    ...props
}) => {
    useEffect(() => {
        modelView(modelViewType.ORDERS, parseInt(props.id));
    }, [props.id]);
    return (
        <Show
            actions={<LeadShowActions {...props} />}
            {...sanitizeRestProps(props)}
        >
            <LeadTabs />
        </Show>
    );
};

export default LeadShow;
