import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { orderAddPhoto as orderAddPhotoAction } from '../actions/orderActions';
import AddPhoto from '../../components/AddPhoto';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    record: any;
    caption: string;
};

type State = {};

class AddOrderChecklistPhoto extends React.Component<Props, State> {
    handleSubmit = (base64: string) => {
        const { record, caption, orderAddPhoto } = this.props;
        orderAddPhoto(record.userId, record.id, base64, caption);
    };

    render() {
        return (
            <div className="mb-8">
                <span style={{ fontSize: '0.875rem' }}>
                    {this.props.caption}
                </span>
                <AddPhoto
                    record={this.props.record}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }
}

const mapDispatch = {
    orderAddPhoto: orderAddPhotoAction,
};

const connector = connect(null, mapDispatch);

export default connector(AddOrderChecklistPhoto);
