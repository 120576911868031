import * as React from 'react';

function SvgDollarSign(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 8a1 1 0 011 1v22a1 1 0 11-2 0V9a1 1 0 011-1z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.318 13.318A4.5 4.5 0 0117.5 12H25a1 1 0 110 2h-7.5a2.5 2.5 0 000 5h5a4.5 4.5 0 110 9H14a1 1 0 110-2h8.5a2.5 2.5 0 000-5h-5a4.5 4.5 0 01-3.182-7.682z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgDollarSign;
