// in LatLongInput.js
import { useInput } from 'react-admin';
import React, { useEffect, useRef, useState } from 'react';
import DropDownList from '../../components/dropdownlist';

const MappingInput = props => {
    const [mappingValues, setMappingValues] = useState([]);
    const [options, setOptions] = useState([]);
    const {
        input: { name, onChange, ...rest },
        meta: { touched, error },
        isRequired,
    } = useInput(props);

    useEffect(() => {
        setMappingValues(props.record.watiTemplateVariablesMapping || []);
        setOptions(prev => {
            let result: any = [];
            if (Array.isArray(props.record.templateVariables)) {
                result = props.record.templateVariables;
            }
            if (
                Array.isArray(props.record.attachmentTypes) &&
                props.record.hasAttachment
            ) {
                result = [...result, ...props.record.attachmentTypes];
            }
            return result;
        });
    }, []);

    const setMapping = (addValue, watiname) => {
        console.log({ addValue, watiname });
        console.log(mappingValues);

        setMappingValues(prev => {
            const newVal: any = prev;
            const changeIndex = newVal.findIndex(
                record => record.watiVariableName === watiname
            );
            newVal[changeIndex].variableName = addValue;
            onChange(newVal);
            return newVal;
        });
    };

    return (
        <div
            style={{
                paddingBottom: '15rem',
                display: 'flex',
                flexDirection: 'column',
                width: '25rem',
                gap: '.5rem',
            }}
        >
            {props.record.watiTemplateVariablesMapping &&
                props.record.watiTemplateVariablesMapping.map(
                    variableRecord => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '1rem',
                                }}
                                key={variableRecord.watiVariableName}
                            >
                                <h3
                                    style={{
                                        width: '10rem',
                                    }}
                                >
                                    {variableRecord.watiVariableName}
                                </h3>
                                <DropDownList
                                    label="Select..."
                                    additional={variableRecord.watiVariableName}
                                    onChange={setMapping}
                                    defaultValue={variableRecord.variableName}
                                    options={options.map(option => {
                                        return {
                                            text: option,
                                            value: option,
                                        };
                                    })}
                                />
                            </div>
                        );
                    }
                )}
        </div>
    );
};

export default MappingInput;
