import React from 'react';
import PropTypes from 'prop-types';
import { DateField, ReferenceField, TextField } from 'react-admin';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import UserReferenceField from '../../components/UserReferenceField';

export const StorageRecordSummary = props => {
    const { record } = props;

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <label>ID</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.id}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.orderId}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Storage</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.storage.code}
                    </TableCell>
                </TableRow>
                <TableRow hover>
                    <TableCell>From</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <DateField
                            locales="en-IN"
                            record={record}
                            source="from"
                            showTime
                        />
                    </TableCell>
                </TableRow>
                <TableRow hover>
                    <TableCell>To</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <DateField
                            locales="en-IN"
                            record={record}
                            source="to"
                            showTime
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Warehouse</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <ReferenceField
                            label="Warehouse"
                            record={record}
                            source="warehouseId"
                            reference="warehouses"
                            basePath="/warehouses"
                            link="show"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <ReferenceField
                            record={record}
                            basePath={'/locations'}
                            resource={'locations'}
                            label="Location"
                            source="locationId"
                            reference="locations"
                            link="show"
                            allowEmpty
                        >
                            <TextField source="path" />
                        </ReferenceField>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <label>Qty</label>
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        {record.qty}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <span className="tags-chip">{record.status}</span>
                    </TableCell>
                </TableRow>
                <TableRow hover>
                    <TableCell>In By</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <UserReferenceField
                            source="inById"
                            {...props}
                            label="In By"
                        />
                    </TableCell>
                </TableRow>
                <TableRow hover>
                    <TableCell>Out By</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <UserReferenceField
                            source="outById"
                            {...props}
                            label="Out By"
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

StorageRecordSummary.propTypes = {
    record: PropTypes.object,
};
