import React from 'react';
import { useFormState } from 'react-final-form';
import { ReferenceInput, SelectInput } from 'react-admin';

export default props => {
    const { values } = useFormState();
    return (
        <ReferenceInput
            variant="outlined"
            label="City"
            source="cityId"
            reference="city"
            resource="city"
            fullWidth={true}
            style={{ width: '256px' }}
            filter={{ countryId: values.countryId }}
            {...(props.defaultValue
                ? {
                      defaultValue: props.defaultValue,
                  }
                : {})}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    );
};
