import React from 'react';
import get from 'lodash.get';
import { makeStyles } from '@material-ui/core/styles';
import { ChipField } from 'react-admin';

const useStyles = makeStyles({
    main: { display: 'flex', flexWrap: 'wrap' },
    chip: { margin: 4 },
});

export default ({ record = {}, source, ...props }) => {
    const classes = useStyles();
    return record && get(record, source) ? (
        <ChipField {...{ record, source, ...props }} className={classes.chip} />
    ) : null;
};
