import * as React from 'react';

function SvgAlignLeft(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 41"
            fill="none"
            {...props}
        >
            <path
                d="M11 13.503a1 1 0 100 2h18a1 1 0 100-2H11zM11 17.503a1 1 0 100 2h14a1 1 0 100-2H11zM10 22.503a1 1 0 011-1h18a1 1 0 110 2H11a1 1 0 01-1-1zM11 25.503a1 1 0 100 2h14a1 1 0 100-2H11z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgAlignLeft;
