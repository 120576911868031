import React, { useState, FC } from 'react';
import _ from 'lodash';
import {
    useNotify,
    useRefresh,
    useTranslate,
    useUpdateMany,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionEdit from '@material-ui/icons/Edit';
import ActionCheck from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormControl,
    TextField,
} from '@material-ui/core';
import { BulkEditProps } from '../../types';

const sanitizeRestProps: (any) => any = ({
    basePath,
    classes,
    crudUpdateMany,
    filterValues,
    label,
    resource,
    selectedIds,
    ...rest
}) => rest;

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        marginTop: 10,
    },
    editButton: {
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.primary.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    confirmPrimary: {
        color: theme.palette.primary.main,
    },
    confirmWarning: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
    iconPaddingStyle: {
        paddingRight: '0.5em',
    },
}));

type Styles = {
    input: string;
    container: string;
    editButton: string;
    iconPaddingStyle: string;
    confirmPrimary: string;
    confirmWarning: string;
    actionButton: string;
};

type Props = BulkEditProps & {
    classes: Styles;
    cancel: string;
    label: string;
    icon: object;
    confirm: string;
    confirmColor: string;
    translate: (a: string, b?: object) => string;
    crudUpdateMany: (
        resource: string,
        selectedIds: number[],
        data: any,
        basePath: string
    ) => void;
};

const BulkEdit: FC<Props> = ({
    label = 'ra.action.edit',
    cancel = 'ra.action.cancel',
    confirm = 'ra.action.confirm',
    confirmColor = 'primary',
    icon = <ActionEdit />,
    basePath,
    crudUpdateMany,
    resource,
    selectedIds,
    ...rest
}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);
    const [submit, { submitting }] = useUpdateMany(
        'charges',
        selectedIds,
        {
            invoiceId,
        },
        {
            onSuccess: ({ data }) => {
                refresh();
                notify('resources.charges.notification.update_success');
            },
            onFailure: error =>
                notify(`Error in bulk update: ${error.message}`, 'warning'),
        }
    );

    const handleInvoiceIdChange = event => {
        setInvoiceId(event.target.value.trim());
    };

    const handleConfirm = () => {
        if (invoiceId) {
            setLoading(true);
            submit();
        }
    };

    return (
        <React.Fragment>
            <Button
                onClick={() => setIsOpen(true)}
                className={classes.editButton}
                {...sanitizeRestProps(rest)}
            >
                {icon}
                {translate(label, { _: label })}
            </Button>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                <DialogTitle>Bulk Edit</DialogTitle>
                <DialogContent>
                    <form
                        className={classes.container}
                        noValidate
                        autoComplete="off"
                    >
                        <FormControl>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="invoiceId"
                                label={translate('fields.invoiceId')}
                                required
                                fullWidth
                                variant="outlined"
                                onChange={handleInvoiceIdChange}
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading || !invoiceId}
                        onClick={handleConfirm}
                        className={classnames('ra-confirm', {
                            [classes.confirmWarning]:
                                confirmColor === 'warning',
                            [classes.confirmPrimary]:
                                confirmColor === 'primary',
                        })}
                        autoFocus
                    >
                        <ActionCheck className={classes.iconPaddingStyle} />
                        {translate(confirm, { _: confirm })}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default BulkEdit;
