import { CREATE } from 'react-admin';

export const TASK_CREATE = 'TASK_CREATE';
export const TASK_CREATE_LOADING = 'TASK_CREATE_LOADING';
export const TASK_CREATE_FAILURE = 'TASK_CREATE_FAILURE';
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS';

export const taskCreate = task => ({
    type: TASK_CREATE,
    payload: { data: task },
    meta: { resource: 'tasks', fetch: CREATE, cancelPrevious: false },
});
