import React, { useState, useEffect, useCallback, FC, Fragment } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { useDataProvider, Title } from 'react-admin';
import UsageGraph from './components/UsageGraph';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import LeadIcon from '../icons/Send';
import StatWidget from '../dashboard/components/StatWidget';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    icon: { width: '2em', height: '2em' },
    welcome: { marginBottom: '2em' },
    flex: { display: 'flex', marginBottom: '1em' },
    leftCol: { flex: 1, paddingRight: '1em' },
    rightCol: { flex: 1 },
    textField: {
        marginLeft: '1em',
        marginRight: '1em',
        width: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
}));

interface Status {
    isMeasureUsageRunning: boolean;
}

const Analytics: FC = () => {
    const classes = useStyles();

    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const dataProvider = useDataProvider();
    const [countryId, setCountryId] = useState('1');
    const [usage, setUsage] = useState([]);
    const [status, setStatus] = useState<Status>({
        isMeasureUsageRunning: false,
    });
    const [focus, setFocus] = useState(null);

    const fetchData = useCallback(async () => {
        dataProvider
            .getList('analytics', {
                filter: {
                    id: 'status',
                    countryId,
                    startDate: startDate.format(),
                    endDate: endDate.format(),
                },
            })
            .then(({ data }) => {
                if (data && data.length) {
                    console.log(data[0]);
                    setStatus(data[0]);
                }
            });

        dataProvider
            .getList('analytics', {
                filter: {
                    id: 'usage',
                    countryId,
                    startDate: startDate.format(),
                    endDate: endDate.format(),
                },
            })
            .then(({ data }) => {
                console.log(data);
                setUsage(data);
            });
    }, [dataProvider, startDate, endDate, countryId]);

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, countryId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <Title title="Usage" />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <form className={classes.container} noValidate>
                            <TextField
                                select
                                id="countryId"
                                label="Country"
                                size="small"
                                variant="outlined"
                                value={countryId}
                                onChange={event =>
                                    setCountryId(event.target.value)
                                }
                                className={classes.textField}
                            >
                                <MenuItem key={1} value={1}>
                                    UAE
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    Kuwait
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    KSA
                                </MenuItem>
                            </TextField>
                            <DateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                startDateId={'startDate'}
                                endDateId={'endDate'}
                                onDatesChange={({ startDate, endDate }) => {
                                    if (startDate) {
                                        setStartDate(
                                            moment(startDate).startOf('day')
                                        );
                                    }
                                    if (endDate) {
                                        setEndDate(
                                            moment(endDate).endOf('day')
                                        );
                                    }
                                }}
                                focusedInput={focus}
                                onFocusChange={focus => setFocus(focus)}
                                isOutsideRange={day =>
                                    day.isAfter(moment().add('1', 'days'))
                                }
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <UsageGraph data={usage} />
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
};

export default Analytics;
