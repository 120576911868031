import React from 'react';
import { Route } from 'react-router-dom';
import { JobCalendar } from './jobs';
import { SalesCalendar } from './sales-calendar';
import WarehouseView from './warehouses/WarehouseView';
import ProfileEdit from './profile/ProfileEdit';
import {
    SalesAnalytics,
    FinanceAnalytics,
    StorageAnalytics,
    MarketingAnalytics,
    UsageAnalytics,
    OperationAnalytics,
    LeadAnalytics,
    SurveyAnalytics,
    WarehouseAnalytics,
} from './analytics';
import ForgotPassword from './layout/ForgotPassword';
import ConfigsCountries from './configurations/ConfigsCountries';
import CountryConfigs from './configurations/CountryConfigs';
import EditConfig from './configurations/EditConfig';
import ConfigHistory from './configurations/ConfigHistory';

export default [
    <Route exact path="/sales-calendar" component={SalesCalendar} />,
    <Route exact path="/jobs" component={JobCalendar} />,
    <Route
        exact
        path="/warehouses/:warehouseId/view"
        component={WarehouseView}
    />,
    <Route key="my-profile" path="/my-profile" component={ProfileEdit} />,
    <Route exact path="/analytics/sales" component={SalesAnalytics} />,
    <Route exact path="/analytics/storage" component={StorageAnalytics} />,
    <Route exact path="/analytics/finance" component={FinanceAnalytics} />,
    <Route exact path="/analytics/usage" component={UsageAnalytics} />,
    <Route exact path="/analytics/operations" component={OperationAnalytics} />,
    <Route exact path="/analytics/warehouse" component={WarehouseAnalytics} />,
    <Route exact path="/analytics/lead" component={LeadAnalytics} />,
    <Route exact path="/analytics/survey" component={SurveyAnalytics} />,
    /*<Route exact path="/analytics/marketing" component={MarketingAnalytics} />,*/
    <Route path="/forgot-password" component={ForgotPassword} noLayout />,
    <Route path="/configurations/:id/history" component={ConfigHistory} />,
    <Route path="/configurations/:id/edit" component={EditConfig} />,
    <Route path="/configurations/:country" component={CountryConfigs} />,
    <Route path="/configurations" component={ConfigsCountries} />,
];
