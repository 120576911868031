import React from 'react';
import {
    ChipField,
    Show,
    ReferenceManyField,
    SimpleShowLayout,
    Datagrid,
    TextField,
    DateField,
} from 'react-admin';
import { ShowProps, Task } from '../types';

const TaskShow: React.FC<ShowProps<Task>> = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="type" />
            <ChipField source="status" />
            <DateField source="date" showTime />
            <DateField source="createdAt" showTime />
            <TextField source="data" />
            <ReferenceManyField
                label="Logs"
                reference="tasklogs"
                target="taskId"
            >
                <Datagrid>
                    <TextField source="serial" sortable={false} />
                    <TextField source="date" />
                    <TextField source="type" />
                    <TextField source="message" />
                </Datagrid>
            </ReferenceManyField>
        </SimpleShowLayout>
    </Show>
);

export default TaskShow;
