export const handleUserError = (error, notify) => {
    if (
        error.code == 400 &&
        error.message === 'Validation error' &&
        (error.errors || []).find(
            i => i.path === 'email' && i.message === 'email must be unique'
        )
    ) {
        const email = (error.errors || []).find(
            i => i.path === 'email' && i.message === 'email must be unique'
        ).value;

        notify('resources.users.notification.email_exist', 'warning', {
            email,
        });
    } else {
        notify(
            error.message || 'resources.users.notification.update_error',
            'warning'
        );
    }
};

export const handleTagsError = (error, notify) => {
    console.log(error);
    if (
        error.code == 400 &&
        error.message === 'Validation error' &&
        (error.errors || []).find(
            i => i.path === 'name' && i.message === 'name must be unique'
        )
    ) {
        const name = (error.errors || []).find(
            i => i.path === 'name' && i.message === 'name must be unique'
        ).value;

        notify('name must be unique', 'warning', {
            name,
        });
    } else {
        notify(
            error.message || 'resources.users.notification.update_error',
            'warning'
        );
    }
};
