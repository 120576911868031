import crypto from 'crypto';
import { AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { LegacyAuthProvider, AuthActionType } from 'ra-core';

type Params = {
    password?: string;
};

const authMapper = (authClient: LegacyAuthProvider) => (
    type: AuthActionType,
    params: Params
) => {
    if (type === AUTH_LOGIN && params && params.password) {
        var shasum = crypto.createHash('sha256');
        shasum.update(params.password);
        params.password = shasum.digest('hex');

        return authClient(type, params).then(authInfo => {
            const { user } = authInfo;
            if (user) {
                localStorage.setItem('userId', user.id);
                localStorage.setItem('countryId', user.countryId);
            }
            return authInfo;
        });
    } else if (type === AUTH_LOGOUT) {
        return authClient(type, params).then(() => Promise.resolve());
    }

    return authClient(type, params);
};

export default authMapper;
