import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { SelectInput } from 'react-admin';
import { Form } from 'react-final-form';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { requestApprove as requestApproveAction } from '../actions/requestActions';
import { getWarehouses as getWarehousesAction } from '../../warehouses/actions/warehouseActions';
import { getTeams as getTeamsAction } from '../../teams/actions/teamActions';
import { isTransportRequest } from '../../core/utils/request';
import { RequestType } from '../../constants';
import MoneyInput from '../../components/MoneyInput';
import { AppState, Request, Warehouse, Team } from '../../types';
import { isTransportRequestLocal } from '../utils';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    record: Request;
    warehouses: Warehouse[];
    teams: Team[];
    onClose: () => void;
};

class RequestApproveForm extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.approveRequest = this.approveRequest.bind(this);
    }

    componentDidMount() {
        const { record, getWarehouses, getTeams } = this.props;
        if (record) {
            getWarehouses(record.countryId);
            getTeams(record.countryId);
        }
    }

    approveRequest(values) {
        const { requestApprove, record } = this.props;

        requestApprove(record.id, {
            ...record,
            ...values,
        });

        this.props.onClose();
    }

    render() {
        const { record, warehouses, teams, open = false } = this.props;

        const compare = (a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        };

        const warehouseList = warehouses.sort(compare);
        const teamList = teams.sort(compare);
        const needTransport = isTransportRequestLocal(record);
        const needWarehouse =
            [
                RequestType.newbox,
                RequestType.pickup,
                RequestType.delivery,
                RequestType.drop,
                RequestType.collect,
            ].indexOf(record.type) !== -1;

        return (
            <Dialog
                open={!!open}
                onClose={() => {
                    this.props.onClose();
                }}
            >
                <DialogTitle>Approve Request</DialogTitle>

                <DialogContent>
                    <Form
                        onSubmit={this.approveRequest}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                {needWarehouse && (
                                    <SelectInput
                                        source="warehouseId"
                                        label="Warehouse"
                                        choices={warehouseList}
                                        translateChoice={false}
                                        fullWidth
                                    />
                                )}
                                <br />
                                {needTransport && (
                                    <SelectInput
                                        source="teamId"
                                        label="Team"
                                        choices={teamList}
                                        translateChoice={false}
                                        fullWidth
                                    />
                                )}
                                <MoneyInput source="amount" label="Payment" />
                                <Button onClick={() => this.props.onClose()}>
                                    Cancel
                                </Button>
                                &nbsp; &nbsp; &nbsp;
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={
                                        submitting ||
                                        (needWarehouse &&
                                            !values.warehouseId) ||
                                        (needTransport && !values.teamId)
                                    }
                                >
                                    Approve
                                </Button>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

const mapState = (state: AppState, props) => {
    const { record } = props;
    return {
        warehouses:
            state.admin.resources.warehouses &&
            state.admin.resources.warehouses.data &&
            _.values(state.admin.resources.warehouses.data).filter(
                i => i.countryId === record.countryId
            ),
        teams:
            state.admin.resources.teams &&
            state.admin.resources.teams.data &&
            _.values(state.admin.resources.teams.data).filter(
                i => i.countryId === record.countryId
            ),
    };
};

const mapDispatch = {
    requestApprove: requestApproveAction,
    getWarehouses: getWarehousesAction,
    getTeams: getTeamsAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(RequestApproveForm);
