import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';

const templateCategoriesList = props => {
    return (
        <List
            title="Templates Categories"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <TextField source="name" label="Name" />
                <TextField source="description" label="description" />
                <EditButton />
            </Datagrid>
        </List>
    );
};

templateCategoriesList.defaultProps = {
    resource: 'template-categories',
    location: { search: '' },
};

export default templateCategoriesList;
