import React from 'react';

import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

import { BoxStatus } from '../constants';

const BoxCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="code" />
            <ReferenceInput
                label="Customer"
                source="userId"
                reference="users"
                resource="users"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="Partner"
                source="partnerId"
                reference="partners"
                resource="partners"
            >
                <SelectInput />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput
                source="status"
                choices={Object.values(BoxStatus).map(i => ({
                    id: i,
                    name: i,
                }))}
            />
        </SimpleForm>
    </Create>
);

export default BoxCreate;
