import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import './tailwind.output.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

// @link https://github.com/callemall/material-ui/issues/4670#issuecomment-235031917
import injectTapEventPlugin from 'react-tap-event-plugin';
try {
    injectTapEventPlugin();
} catch (e) {
    // do nothing
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
