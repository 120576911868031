import * as React from 'react';

function SvgClipboard(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 13a1 1 0 00-1 1v14a1 1 0 001 1h12a1 1 0 001-1V14a1 1 0 00-1-1h-2a1 1 0 110-2h2a3 3 0 013 3v14a3 3 0 01-3 3H14a3 3 0 01-3-3V14a3 3 0 013-3h2a1 1 0 110 2h-2z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 11a2 2 0 012-2h6a2 2 0 012 2v2a2 2 0 01-2 2h-6a2 2 0 01-2-2v-2zm8 0h-6v2h6v-2z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgClipboard;
