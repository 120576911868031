import * as React from 'react';

function SvgArchive(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 14a1 1 0 011 1v12h16V15a1 1 0 112 0v13a1 1 0 01-1 1H11a1 1 0 01-1-1V15a1 1 0 011-1z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 10a1 1 0 011-1h22a1 1 0 011 1v5a1 1 0 01-1 1H9a1 1 0 01-1-1v-5zm2 1v3h20v-3H10zM17 19a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgArchive;
