import React, { FC } from 'react';
import Analytics from './Analytics';

const OperationAnalytics: FC = () => {
    return (
        <Analytics
            title={'Operation'}
            items={[
                {
                    title: 'Requests Created',
                    data: [
                        {
                            title: 'Requests Created',
                            key: 'operations.requests_created',
                        },
                        {
                            title: 'Requests Created (B2C)',
                            key: 'operations.requests_created_b2c',
                        },
                        {
                            title: 'Requests Created (B2B)',
                            key: 'operations.requests_created_b2b',
                        },
                    ],
                },
                {
                    title: 'Requests Cancelled',
                    data: [
                        {
                            title: 'Requests Cancelled',
                            key: 'operations.requests_cancelled',
                        },
                        {
                            title: 'Requests Cancelled (B2C)',
                            key: 'operations.requests_cancelled_b2c',
                        },
                        {
                            title: 'Requests Cancelled (B2B)',
                            key: 'operations.requests_cancelled_b2b',
                        },
                    ],
                },
                {
                    title: 'Requests Completed',
                    data: [
                        {
                            title: 'Requests Completed',
                            key: 'operations.requests_completed',
                        },
                        {
                            title: 'Requests Completed (B2C)',
                            key: 'operations.requests_completed_b2c',
                        },
                        {
                            title: 'Requests Completed (B2B)',
                            key: 'operations.requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Pickup Requests Completed',
                    data: [
                        {
                            title: 'Pickup Requests Completed',
                            key: 'operations.pickup_requests_completed',
                        },
                        {
                            title: 'Pickup Requests Completed (B2C)',
                            key: 'operations.pickup_requests_completed_b2c',
                        },
                        {
                            title: 'Pickup Requests Completed (B2B)',
                            key: 'operations.pickup_requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Delivery Requests Completed',
                    data: [
                        {
                            title: 'Delivery Requests Completed',
                            key: 'operations.delivery_requests_completed',
                        },
                        {
                            title: 'Delivery Requests Completed (B2C)',
                            key: 'operations.delivery_requests_completed_b2c',
                        },
                        {
                            title: 'Delivery Requests Completed (B2B)',
                            key: 'operations.delivery_requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Drop Requests Completed',
                    data: [
                        {
                            title: 'Drop Requests Completed',
                            key: 'operations.drop_requests_completed',
                        },
                        {
                            title: 'Drop Requests Completed (B2C)',
                            key: 'operations.drop_requests_completed_b2c',
                        },
                        {
                            title: 'Drop Requests Completed (B2B)',
                            key: 'operations.drop_requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Collect Requests Completed',
                    data: [
                        {
                            title: 'Collect Requests Completed',
                            key: 'operations.collect_requests_completed',
                        },
                        {
                            title: 'Collect Requests Completed (B2C)',
                            key: 'operations.collect_requests_completed_b2c',
                        },
                        {
                            title: 'Collect Requests Completed (B2B)',
                            key: 'operations.collect_requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Requests Completed (New Customers)',
                    data: [
                        {
                            title: 'Requests Completed (New Customers)',
                            key: 'new_customers.requests_completed',
                        },
                        {
                            title: 'Requests Completed (New Customers) B2C ',
                            key: 'new_customers.requests_completed_b2c',
                        },
                        {
                            title: 'Requests Completed (New Customers) B2B ',
                            key: 'new_customers.requests_completed_b2b',
                        },
                    ],
                },
                {
                    title: 'Requests Completed (Existing Customers) ',
                    data: [
                        {
                            title: 'Requests Completed (Existing Customers)',
                            key: 'existing_customers.requests_completed',
                        },
                        {
                            title:
                                'Requests Completed (Existing Customers) B2C ',
                            key: 'existing_customers.requests_completed_b2c',
                        },
                        {
                            title:
                                'Requests Completed (Existing Customers) B2B ',
                            key: 'existing_customers.requests_completed_b2b',
                        },
                    ],
                },
            ]}
        />
    );
};

export default OperationAnalytics;
