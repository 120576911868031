import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

const useStyles = makeStyles({
    card: {
        float: 'left',
        margin: '-20px 20px 0 15px',
        zIndex: 100,
        borderRadius: 3,
    },
    icon: {
        //float: 'right',
        //width: 54,
        //height: 54,
        //padding: 14,
        //color: '#fff',
    },
});

export default ({ Icon, bgColor }) => {
    const classes = useStyles();

    return <Icon className={classes.icon} />;
};
