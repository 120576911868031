import React, { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { CheckboxGroupInput, ReferenceArrayInput } from 'react-admin';

const SelectStorageInput = ({ formData, ...props }) => {
    const { record } = props;
    const [choices, setChoices] = useState<any[]>([]);
    const [lastStorages, setLastStorages] = useState<any[]>([]);
    const dataProvider = useDataProvider();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasNextPage, setHasNextPage] = useState<boolean>(false);
    const [hasPrevPage, setHasPrevPage] = useState<boolean>(false);
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
    const [numOfPages, setNumOfPages] = useState<number>(0);

    useEffect(() => {
        setIsLoading(true);
        dataProvider
            .getList('storages', {
                filter: {
                    userId: formData.userId,
                    ...(formData.orderId && {
                        orderId: formData.orderId,
                    }),
                    status: { $nin: ['cancelled', 'closed'] },
                },
                pagination: { page: currentPage, perPage: 50 },
            })
            .then(response => {
                setHasNextPage(
                    Math.ceil(response.total / response.limit) !== currentPage
                );
                setHasPrevPage(currentPage !== 1);
                if (response.data && response.data.length) {
                    response.data.unshift({
                        id: 'select',
                        name: 'Select All in this page',
                    });
                }
                setNumOfPages(Math.ceil(response.total / response.limit));
                setChoices(response.data);
                if (response.data.length === 0) {
                    setHasNextPage(false);
                    setHasPrevPage(false);
                }
                setIsLoading(false);
            });
    }, [dataProvider, formData.userId, formData.orderId, currentPage]);

    return (
        <div>
            {!isLoading ? (
                <ReferenceArrayInput
                    label="Storages"
                    source="request_lines"
                    reference="storages"
                    resource="storages"
                    record={record}
                    format={request_lines =>
                        (request_lines || []).map(i => i && i.storageId)
                    }
                    parse={storageIds => {
                        let found = storageIds.includes('select');
                        let dataStorage: any[] = [];
                        if (found) {
                            let oldData = storageIds.map(item => ({
                                storageId: item,
                            }));

                            let newDataStorage = choices.map(ele => ({
                                storageId: ele.id,
                                ...(record.request_lines &&
                                    record.request_lines.find(
                                        l => l.storageId === ele.id
                                    )),
                            }));

                            if (
                                newDataStorage.every(
                                    v =>
                                        oldData.findIndex(
                                            i => v.storageId === i.storageId
                                        ) !== -1
                                ) &&
                                oldData.length !== 0
                            ) {
                                dataStorage = oldData.filter(function (el) {
                                    return (
                                        newDataStorage.findIndex(
                                            item =>
                                                el.storageId === item.storageId
                                        ) === -1
                                    );
                                });
                            } else {
                                newDataStorage = [
                                    ...oldData,
                                    ...newDataStorage,
                                ];
                                newDataStorage.filter(element => {
                                    if (
                                        element.storageId &&
                                        element.storageId !== 'select'
                                    ) {
                                        const isDuplicate = dataStorage.includes(
                                            element.storageId
                                        );

                                        if (!isDuplicate) {
                                            dataStorage.push({
                                                storageId: element.storageId,
                                            });

                                            return true;
                                        }
                                    }
                                    return false;
                                });
                            }
                            /*const selectIndex = dataStorage.findIndex(
                                item => item.storageId === 'select'
                            );
                            dataStorage.splice(selectIndex, 1);*/
                        } else {
                            let difference = lastStorages.filter(
                                x => !storageIds.includes(x.storageId)
                            );
                            if (
                                !difference.length ||
                                (difference &&
                                    difference[0] &&
                                    difference[0].storageId != 'select')
                            ) {
                                dataStorage = storageIds.map(id => ({
                                    storageId: id,
                                    ...(record.request_lines &&
                                        record.request_lines.find(
                                            l => l.storageId === id
                                        )),
                                }));
                            }
                        }
                        setLastStorages(dataStorage);
                        return dataStorage;
                    }}
                    filter={{
                        userId: formData.userId,
                        ...(formData.orderId && {
                            orderId: formData.orderId,
                        }),
                        status: { $nin: ['cancelled', 'closed'] },
                    }}
                    perPage={200}
                    page={currentPage}
                    allowEmpty
                >
                    <CheckboxGroupInput
                        source="storageId"
                        choices={choices}
                        row={false}
                        optionText={s =>
                            s.id == 'select'
                                ? `${s.name}`
                                : `${s.code || '<unassigned>'} - ${
                                      s.storagetype.name
                                  } - ${s.status}`
                        }
                    />
                </ReferenceArrayInput>
            ) : (
                <h3>Loading...</h3>
            )}
            {choices.length > 0 && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                    }}
                >
                    {hasPrevPage && (
                        <button
                            style={{
                                backgroundColor: '#ECECEC',
                                padding: '.5rem 1rem',
                                marginRight: '1rem',
                                borderRadius: '4px',
                            }}
                            onClick={e => {
                                e.preventDefault();
                                setCurrentPage(prev => prev - 1);
                            }}
                        >
                            back
                        </button>
                    )}
                    <h3>{`Page ${currentPage} of ${numOfPages} pages`}</h3>
                    {hasNextPage && (
                        <button
                            style={{
                                backgroundColor: '#ECECEC',
                                padding: '.5rem 1rem',
                                borderRadius: '4px',
                            }}
                            onClick={e => {
                                e.preventDefault();
                                setCurrentPage(prev => prev + 1);
                            }}
                        >
                            next
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default SelectStorageInput;
