import React from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    ChipField,
    ReferenceField,
    ReferenceManyField,
    ShowButton,
} from 'react-admin';
import StorageTypeField from './StorageTypeField';

const StorageRecords = ({ target = 'storageId', ...props }) => (
    <ReferenceManyField
        reference="storage-records"
        target={target}
        {...props}
        addLabel={false}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <ReferenceField
                label="Order"
                source="orderId"
                reference="orders"
                link="show"
            >
                <TextField source="id" />
            </ReferenceField>
            <ReferenceField
                label="Storage"
                source="storageId"
                reference="storages"
                link="show"
            >
                <TextField source="code" />
            </ReferenceField>
            <ReferenceField
                label="Type"
                source="storageId"
                reference="storages"
                link={false}
            >
                <StorageTypeField source="storagetypeId" label="Type" />
            </ReferenceField>
            <TextField source="qty" />
            <ReferenceField
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField locales="en-IN" source="from" label="IN" />
            <DateField locales="en-IN" source="to" label="OUT" />
            <ReferenceField
                label="Location"
                source="locationId"
                reference="locations"
                link="show"
                allowEmpty
            >
                <TextField source="path" />
            </ReferenceField>
            <ChipField source="status" />
            <ReferenceField
                label="In By"
                source="inById"
                reference="users"
                link="show"
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField
                label="Out By"
                source="outById"
                reference="users"
                link="show"
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <ShowButton />
        </Datagrid>
    </ReferenceManyField>
);

StorageRecords.defaultProps = {
    resource: 'storage-records',
    location: { search: '' },
};

export default StorageRecords;
