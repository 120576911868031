import React, { useState } from 'react';
import { Field } from 'react-final-form';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import 'react-phone-input-2/lib/material.css';

const PlaceAutoComplete = ({ source = 'area', ...props }) => {
    const { record } = props;
    const [value, setValue] = useState({
        label: record ? record.area : '',
        value: {},
    });
    return (
        <Field name="area">
            {({ input }) => (
                <>
                    <label style={{ color: '#8492A6' }}>Area</label>
                    <div style={{ width: '256px', paddingBottom: '20px' }}>
                        <GooglePlacesAutocomplete
                            selectProps={{
                                value: value,
                                onChange: e => {
                                    const area = e.label.split(/,|-/);
                                    e.label = area[0];
                                    setValue(e);
                                    input.onChange(area[0]);
                                },
                                placeholder: 'Area',
                            }}
                            autocompletionRequest={{
                                componentRestrictions: {
                                    country:
                                        record && record.countryId === 1
                                            ? 'ae'
                                            : record && record.countryId === 2
                                            ? 'kw'
                                            : record && record.countryId === 3
                                            ? 'sa'
                                            : ['ae', 'kw', 'sa'],
                                },
                            }}
                        />
                    </div>
                </>
            )}
        </Field>
    );
};

export default PlaceAutoComplete;
