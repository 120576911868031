import React from 'react';
import { ReferenceField, TextField } from 'react-admin';
import { FieldProps, Storage } from '../../types';

const Location: React.FC<FieldProps<Storage>> = ({ record }) => (
    <ReferenceField
        record={record}
        basePath={'/locations'}
        resource={'locations'}
        label="Location"
        source="locationId"
        reference="locations"
        link="show"
        allowEmpty
    >
        <TextField source="path" />
    </ReferenceField>
);

export default Location;
