import React from 'react';

import { Show, SimpleShowLayout } from 'react-admin';

import ReasonsSummary from './components/ReasonsSummary';
import ReasonsShowActions from './components/ReasonsShowActions';

const RejectReasonTitle = ({ record }) => (
    <span>{record ? `${record.name}` : `Reason #${record.id}`}</span>
);

const RejectReasonShow = props => (
    <Show {...props} actions={<ReasonsShowActions />}>
        <SimpleShowLayout>
            <ReasonsSummary />
        </SimpleShowLayout>
    </Show>
);

export default RejectReasonShow;
