import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import { StorageTypeSummary } from './components/StorageTypeSummary';
import { TitleProps, Storage } from '../types';

const StorageTypeTitle: React.FC<TitleProps<Storage>> = ({ record }) => (
    <span>
        {record!.name ? `${record!.name}` : `StorageType #${record!.id}`}
    </span>
);

const StorageTypeShow = props => (
    <Show title={<StorageTypeTitle />} {...props}>
        <SimpleShowLayout>
            <StorageTypeSummary />
        </SimpleShowLayout>
    </Show>
);

export default StorageTypeShow;
