import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    Datagrid,
    TextField,
    ReferenceField,
    ReferenceManyField,
    ShowButton,
    EditButton,
    ChipField,
    DateField,
} from 'react-admin';

import StorageLocation from '../../storages/components/StorageLocation';
import MoneyField from '../../components/MoneyField';
import StorageTypeField from '../../components/StorageTypeField';
import DamageField from '../../components/DamageField';

const TypeField = props => (
    <StorageTypeField record={props.record.storage} link={false} />
);

const LocationField = props => (
    <StorageLocation record={props.record.storage} />
);

const StorageList = props => {
    const ids = props.ids || [];
    const data = props.data ? _.values(_.pick(props.data, ids)) : [];

    const parts = _.groupBy(
        data.filter(i => i.storage.isPart),
        'parentId'
    );
    const lines = data
        .filter(i => !i.storage.isPart)
        .sort((a, b) => {
            if (a.storage.code < b.storage.code) return -1;
            if (b.storage.code < a.storage.code) return 1;
            return 0;
        });
    const orphanParts = _.flatten(
        _.values(
            _.omit(
                parts,
                lines.map(i => i.id)
            )
        )
    );
    lines.push(...orphanParts);

    const tableRows: any[] = [];

    lines.forEach((line, i) => {
        const index = parts[line.id] ? `${i + 1}.1` : `${i + 1}`;

        tableRows.push({
            ...line,
            index,
        });

        if (parts[line.id]) {
            parts[line.id]
                .sort((a, b) => {
                    if (a.storage.code < b.storage.code) return -1;
                    if (b.storage.code < a.storage.code) return 1;
                    return 0;
                })
                .forEach((part, j) => {
                    tableRows.push({
                        ...part,
                        index: `${i + 1}.${j + 2}`,
                    });
                });
        }
    });

    const tableIds: number[] = tableRows.map(i => i.id);
    const tableData: any = tableRows.reduce(
        (obj, i) => ({ ...obj, [i.id]: i }),
        {}
    );

    return (
        <Datagrid ids={tableIds} data={tableData}>
            <TextField source="serial" sortable={false} />
            <TextField source="index" label="No" />
            <ReferenceField
                label="Code"
                source="storage.id"
                reference="storages"
                link={false}
            >
                <TextField source="code" label="Code" />
            </ReferenceField>
            <TypeField label="Type" />
            <DamageField source="storage.damage" label="Damage" />
            <TextField source="storage.name" label="Name" />
            <TextField source="storage.size" label="CBM" />
            <ChipField source="storage.status" label="Status" />
            <DateField locales="en-IN" source="storage.from" label="From" />
            <DateField locales="en-IN" source="storage.to" label="To" />
            <LocationField label="Loc" />
            <ReferenceField
                label=""
                source="storage.id"
                reference="storages"
                link="show"
            >
                <ViewLink />
            </ReferenceField>
        </Datagrid>
    );
};

const ViewLink = () => <span>Show</span>;

const RequestStorageList = ({ record, ...props }: { record?: any }) => {
    return record ? (
        <ReferenceManyField
            {...props}
            filter={{ requestId: record.id }}
            sort={{ field: 'createdAt', order: 'DESC' }}
            reference="request-lines"
            target="requestId"
            perPage={1000}
        >
            <StorageList orderId={record.orderId} />
        </ReferenceManyField>
    ) : null;
};

export default RequestStorageList;
