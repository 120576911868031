import React from 'react';
import { ReferenceManyField } from 'react-admin';
import ChargeGrid from '../../charges/components/ChargeGrid';

export const InvoiceChargesList = props => (
    <ReferenceManyField
        reference="charges"
        target="invoiceId"
        filter={{ invoiceId: props.record.id }}
        {...props}
    >
        <ChargeGrid {...props} />
    </ReferenceManyField>
);
