import React from 'react';
import {
    Edit,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    NumberInput,
} from 'react-admin';
import { TitleProps, ServiceType } from '../types';

const ServiceTypeTitle: React.FC<TitleProps<ServiceType>> = ({ record }) => (
    <span>ServiceType {record ? `"${record.name}"` : ''}</span>
);

const ServiceTypeEdit = props => (
    <Edit title={<ServiceTypeTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput source="name" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export default ServiceTypeEdit;
