import { CREATE, UPDATE, GET_ONE, DELETE } from 'react-admin';
import { UserStatus } from '../../constants';

export const USER_ACTIVATE = 'USER_ACTIVATE';
export const USER_ACTIVATE_LOADING = 'USER_ACTIVATE_LOADING';
export const USER_ACTIVATE_FAILURE = 'USER_ACTIVATE_FAILURE';
export const USER_ACTIVATE_SUCCESS = 'USER_ACTIVATE_SUCCESS';

export const userActivate = (id, data) => ({
    type: USER_ACTIVATE,
    payload: {
        id,
        data: {
            ...data,
            tags: data.tags.map(tag => (tag.id ? tag.id : tag)),
            status: UserStatus.active,
        },
    },
    meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
});

export const USER_DEACTIVATE = 'USER_DEACTIVATE';
export const USER_DEACTIVATE_LOADING = 'USER_DEACTIVATE_LOADING';
export const USER_DEACTIVATE_FAILURE = 'USER_DEACTIVATE_FAILURE';
export const USER_DEACTIVATE_SUCCESS = 'USER_DEACTIVATE_SUCCESS';

export const userDeactivate = (id, data) => ({
    type: USER_DEACTIVATE,
    payload: {
        id,
        data: {
            ...data,
            tags: data.tags.map(tag => (tag.id ? tag.id : tag)),
            status: UserStatus.inactive,
        },
    },
    meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
});

export const USER_REJECT = 'USER_REJECT';
export const USER_REJECT_LOADING = 'USER_REJECT_LOADING';
export const USER_REJECT_FAILURE = 'USER_REJECT_FAILURE';
export const USER_REJECT_SUCCESS = 'USER_REJECT_SUCCESS';

export const userReject = (id, data) => ({
    type: USER_REJECT,
    payload: {
        id,
        data: {
            ...data,
            tags: data.tags.map(tag => (tag.id ? tag.id : tag)),
            status: UserStatus.rejected,
        },
    },
    meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
});

export const USER_ASSIGN = 'USER_ASSIGN';
export const USER_ASSIGN_LOADING = 'USER_ASSIGN_LOADING';
export const USER_ASSIGN_FAILURE = 'USER_ASSIGN_FAILURE';
export const USER_ASSIGN_SUCCESS = 'USER_ASSIGN_SUCCESS';

export const userAssign = (
    id: string | number,
    data: any,
    assigneeId: string | number
) => {
    console.log('helllooo');
    return {
        type: USER_ASSIGN,
        payload: {
            id,
            data: {
                ...data,
                tags: data.tags.map(tag => (tag.id ? tag.id : tag)),
                assigneeId,
            },
        },
        meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
    };
};

export const USER_SUSPEND = 'USER_SUSPEND';
export const USER_SUSPEND_LOADING = 'USER_SUSPEND_LOADING';
export const USER_SUSPEND_FAILURE = 'USER_SUSPEND_FAILURE';
export const USER_SUSPEND_SUCCESS = 'USER_SUSPEND_SUCCESS';

export const userSuspend = (id, data) => ({
    type: USER_SUSPEND,
    payload: {
        id,
        data: {
            ...data,
            tags: data.tags.map(tag => (tag.id ? tag.id : tag)),
            status: UserStatus.suspended,
        },
    },
    meta: { resource: 'users', fetch: UPDATE, cancelPrevious: false },
});

export const getUserStats = id => ({
    type: GET_ONE,
    payload: { id },
    meta: { resource: 'user-stats', fetch: GET_ONE, cancelPrevious: false },
});

export const USER_BILL = 'USER_BILL';
export const USER_BILL_LOADING = 'USER_BILL_LOADING';
export const USER_BILL_FAILURE = 'USER_BILL_FAILURE';
export const USER_BILL_SUCCESS = 'USER_BILL_SUCCESS';

export const userBill = data => ({
    type: USER_BILL,
    payload: { data },
    meta: { resource: 'billing', fetch: CREATE, cancelPrevious: false },
});

export const USER_RESET_PASSWORD = 'USER_RESET_PASSWORD';
export const USER_RESET_PASSWORD_LOADING = 'USER_RESET_PASSWORD_LOADING';
export const USER_RESET_PASSWORD_FAILURE = 'USER_RESET_PASSWORD_FAILURE';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';

export const userResetPassword = email => ({
    type: USER_RESET_PASSWORD,
    payload: { data: { action: 'sendResetPwd', value: { email } } },
    meta: { resource: 'authManagement', fetch: CREATE, cancelPrevious: false },
});

export const USER_MAGIC_LINK = 'USER_MAGIC_LINK';
export const USER_MAGIC_LINK_LOADING = 'USER_MAGIC_LINK_LOADING';
export const USER_MAGIC_LINK_FAILURE = 'USER_MAGIC_LINK_FAILURE';
export const USER_MAGIC_LINK_SUCCESS = 'USER_MAGIC_LINK_SUCCESS';

export const userMagicLink = email => ({
    type: USER_MAGIC_LINK,
    payload: { data: { action: 'sendResetPwd', value: { email } } },
    meta: { resource: 'magic-link', fetch: CREATE, cancelPrevious: false },
});

export const USER_ADD_PHOTO = 'USER_ADD_PHOTO';
export const USER_ADD_PHOTO_LOADING = 'USER_ADD_PHOTO_LOADING';
export const USER_ADD_PHOTO_FAILURE = 'USER_ADD_PHOTO_FAILURE';
export const USER_ADD_PHOTO_SUCCESS = 'USER_ADD_PHOTO_SUCCESS';

export const addUserPhoto = (userId, file) => ({
    type: USER_ADD_PHOTO,
    payload: { data: { userId, file } },
    meta: { resource: 'user-photos', fetch: CREATE, cancelPrevious: false },
});

export const USER_DELETE_PHOTO = 'USER_DELETE_PHOTO';
export const USER_DELETE_PHOTO_LOADING = 'USER_DELETE_PHOTO_LOADING';
export const USER_DELETE_PHOTO_FAILURE = 'USER_DELETE_PHOTO_FAILURE';
export const USER_DELETE_PHOTO_SUCCESS = 'USER_DELETE_PHOTO_SUCCESS';

const deleteUserPhoto = id => ({
    type: USER_DELETE_PHOTO,
    payload: { data: { id } },
    meta: { resource: 'user-photos', fetch: DELETE, cancelPrevious: false },
});

export const USER_IMPERSONATE = 'USER_IMPERSONATE';
export const USER_IMPERSONATE_LOADING = 'USER_IMPERSONATE_LOADING';
export const USER_IMPERSONATE_FAILURE = 'USER_IMPERSONATE_FAILURE';
export const USER_IMPERSONATE_SUCCESS = 'USER_IMPERSONATE_SUCCESS';

export const userImpersonate = (id, data) => ({
    // to be decided
    type: USER_IMPERSONATE,
    payload: { id, data: { userId: id } },
    meta: {
        resource: 'user-impersonation',
        fetch: CREATE,
        cancelPrevious: false,
    },
});
