import * as React from 'react';

function SvgCreditCard(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 13a1 1 0 00-1 1v12a1 1 0 001 1h18a1 1 0 001-1V14a1 1 0 00-1-1H11zm-3 1a3 3 0 013-3h18a3 3 0 013 3v12a3 3 0 01-3 3H11a3 3 0 01-3-3V14z"
                fill="#8492A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 18a1 1 0 011-1h22a1 1 0 110 2H9a1 1 0 01-1-1z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgCreditCard;
