import React from 'react';
import {
    Datagrid,
    DateField,
    ReferenceManyField,
    TextField,
} from 'react-admin';

import UserReferenceField from '../components/UserReferenceField';
import Revision from './Revision';

const History = props => {
    const { model, documentId } = props;

    return (
        <ReferenceManyField
            {...props}
            filter={{ model, documentId }}
            sort={{ field: 'createdAt', order: 'DESC' }}
            reference="revisions"
            target="documentId"
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <DateField
                    locales="en-IN"
                    label="Date"
                    source="createdAt"
                    showTime
                />
                <UserReferenceField label="User" link={false} />
                <Revision />
            </Datagrid>
        </ReferenceManyField>
    );
};

export default History;
