import React, { FC } from 'react';
import Analytics from './Analytics';

const SalesAnalytics: FC = () => {
    return (
        <Analytics
            title={'Marketing'}
            items={[
                {
                    title: 'CLTV',
                    data: [
                        {
                            title: 'CLTV',
                            key: 'marketing.cltv',
                        },
                        {
                            title: 'CLTV (B2C)',
                            key: 'marketing.cltv_b2c',
                        },
                        {
                            title: 'CLTV (B2B)',
                            key: 'marketing.cltv_b2b',
                        },
                    ],
                },
            ]}
        />
    );
};

export default SalesAnalytics;
