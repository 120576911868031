import React from 'react';
import classes from './popup.module.css';

const Popup = (props: any) => {
    return (
        <div className={classes.popup_container}>
            <div className={classes.popup_back} onClick={props.cancel}></div>
            <div className={classes.popup}>{props.children}</div>
        </div>
    );
};

export default Popup;
