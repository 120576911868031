import React from 'react';
import { SingleFieldList, ImageField, ReferenceManyField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useImageFieldStyles = makeStyles(theme => ({
    image: {
        margin: '3px',
        maxHeight: '14rem',
    },
}));

const PhotoGallery = props => {
    const imageFieldClasses = useImageFieldStyles();
    const { target, reference } = props;
    return (
        <ReferenceManyField
            {...props}
            addLabel={false}
            reference={reference}
            target={target}
        >
            <SingleFieldList linkType="show">
                <ImageField
                    classes={imageFieldClasses}
                    source="thumbnail"
                    label="caption"
                />
            </SingleFieldList>
        </ReferenceManyField>
    );
};

PhotoGallery.defaultProps = {
    location: { search: '' },
};

export default PhotoGallery;
