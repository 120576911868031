import React from 'react';
import {
    ReferenceField,
    ReferenceManyField,
    SingleFieldList,
    TextField,
} from 'react-admin';
import { Id } from '../types';
import { StorageRecordStatus } from '../constants';

const StorageRecordField = ({
    orderId,
    storageId,
    ...props
}: {
    orderId?: Id;
    storageId?: Id;
    label?: string;
}) => {
    return storageId ? (
        <ReferenceManyField
            {...props}
            reference="storage-records"
            target="storageId"
            filter={{ orderId, storageId, status: StorageRecordStatus.in }}
            sort={{ field: 'createdAt', order: 'DESC' }}
        >
            <SingleFieldList>
                <ReferenceField
                    label="Loc"
                    source="locationId"
                    reference="locations"
                    link="show"
                >
                    <TextField source="path" />
                </ReferenceField>
            </SingleFieldList>
        </ReferenceManyField>
    ) : null;
};

export default StorageRecordField;
