import React, { Component } from 'react';
import moment from 'moment';
import {
    DateRangePicker,
    SingleDatePicker,
    DayPickerRangeController,
} from 'react-dates';
import { AppBar, GET_LIST, SimpleForm, DateInput } from 'react-admin';
import { Currency } from '../constants';
import { SalesGraph, MonthlyRevenue, NbNewOrders } from './components/';
import { dataProvider } from '../rest';
import feathersClient from '../rest/feathersClient';
import { withWidth } from '@material-ui/core';

const styles = {
    welcome: { marginBottom: '2em' },
    flex: { display: 'flex', marginBottom: '1em' },
    leftCol: { flex: 1, paddingRight: '1em' },
    rightCol: { flex: 1, paddingLeft: '1em' },
};

class Dashboard extends Component {
    state = {
        fromDate: moment().add(-1, 'months').toDate(),
        toDate: moment().toDate(),
        focusedInput: null,
        totalOrders: 0,
        totalCommissions: 0,
        affiliateAnalytics: null,
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const { fromDate, toDate } = this.state;

        localStorage.getItem('feathers-jwt') &&
            feathersClient.authenticate().then(() => {
                dataProvider(GET_LIST, 'affiliate-commissions', {
                    filter: {
                        createdAt: {
                            $gte: fromDate.toISOString(),
                            $lte: toDate.toISOString(),
                        },
                    },
                    sort: { field: 'createdAt', order: 'DESC' },
                    pagination: { page: 1, perPage: 50 },
                }).then(response => {
                    if (!response.data || response.data.length === 0) {
                        this.setState({ totalCommissions: '0' });
                    } else {
                        let total = response.data
                            .map(c => c.amount)
                            .reduce((a, b) => a + b, 0);
                        const currency = response.data[0].currency; // assuming one country
                        let divisor;
                        if (currency === Currency.uae) {
                            divisor = 100;
                        } else if (currency === Currency.kuwait) {
                            divisor = 1000;
                        }

                        total = +(total / divisor).toFixed(2);
                        this.setState({
                            totalCommissions: `${currency} ${total}`,
                        });
                    }
                });

                dataProvider(GET_LIST, 'orders', {
                    filter: {
                        createdAt: {
                            $gte: fromDate.toISOString(),
                            $lte: toDate.toISOString(),
                        },
                    },
                    sort: { field: 'createdAt', order: 'DESC' },
                    pagination: { page: 1, perPage: 50 },
                }).then(response => {
                    const totalOrders = response.data.filter(
                        order => order.status !== 'cancelled'
                    ).length;
                    this.setState({ totalOrders });
                });

                dataProvider(GET_LIST, 'affiliate-analytics', {
                    filter: {
                        fromDate: fromDate.toISOString(),
                        toDate: toDate.toISOString(),
                    },
                }).then(response => {
                    this.setState({ affiliateAnalytics: response.data });
                });
            });
    }

    render() {
        const {
            totalOrders,
            totalCommissions,
            fromDate,
            toDate,
            affiliateAnalytics,
        } = this.state;
        return (
            <div>
                <AppBar title="Boxit Affiliate Panel" />
                <div style={styles.flex}>
                    <div style={styles.leftCol} />
                    <div style={styles.rightCol}>
                        <DateRangePicker
                            startDate={moment(fromDate)}
                            endDate={moment(toDate)}
                            startDateId={'startDate'}
                            endDateId={'endDate'}
                            onDatesChange={({ startDate, endDate }) =>
                                this.setState(
                                    {
                                        ...(startDate && {
                                            fromDate: startDate.toDate(),
                                        }),
                                        ...(endDate && {
                                            toDate: endDate.toDate(),
                                        }),
                                    },
                                    this.fetchData
                                )
                            }
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput =>
                                this.setState({ focusedInput })
                            }
                            isOutsideRange={day =>
                                day.isAfter(moment().add('1', 'days'))
                            }
                        />
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <div style={styles.flex}>
                            <NbNewOrders value={totalOrders} />
                        </div>
                    </div>
                    <div style={styles.rightCol}>
                        <div style={styles.flex}>
                            <MonthlyRevenue value={totalCommissions} />
                        </div>
                    </div>
                </div>
                <div style={styles.flex}>
                    <div style={styles.leftCol}>
                        <SalesGraph data={affiliateAnalytics} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
