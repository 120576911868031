import React from 'react';
import _ from 'lodash';
import {
    ChipField,
    Datagrid,
    DateField,
    ReferenceField,
    ReferenceManyField,
    ShowButton,
    TextField,
    EditButton,
} from 'react-admin';

import StorageTypeField from '../../components/StorageTypeField';
import StorageRecordField from '../../components/StorageRecordField';
import { RequestLine, Id } from '../../types';
import { FunctionField } from 'react-admin';

const LocationField = ({
    label,
    record,
    orderId,
    ...props
}: {
    label: string;
    record?: RequestLine;
    orderId?: Id;
    props?: any;
}) =>
    record ? (
        <StorageRecordField
            label={label}
            storageId={record.storage.id}
            orderId={orderId}
            {...props}
        />
    ) : null;

const StorageTypeWrapper = ({
    label,
    record,
    ...props
}: {
    label: string;
    record?: RequestLine;
    props?: any;
}) =>
    record ? (
        <StorageTypeField
            label={label}
            record={record.storage}
            source="storagetypeId"
            {...props}
        />
    ) : null;

const ShowStorageButton = ({
    record,
    ...props
}: {
    record?: RequestLine;
    props?: any;
}) =>
    record ? (
        <ShowButton record={record.storage} {...props} style={{ padding: 0 }} />
    ) : null;

const LineList = ({ orderId, ...props }) => {
    const ids = props.ids || [];
    const data = props.data ? _.values(_.pick(props.data, ids)) : [];

    const parts = _.groupBy(
        data.filter(i => i.storage && i.storage.isPart),
        'parentId'
    );
    const storages = data
        .filter(i => i.storage && !i.storage.isPart)
        .sort((a, b) => {
            if (a.storage.code < b.storage.code) return -1;
            if (b.storage.code < a.storage.code) return 1;
            return 0;
        });
    const orphanParts = _.flatten(
        _.values(
            _.omit(
                parts,
                storages.map(i => i.storage.id)
            )
        )
    );
    storages.push(...orphanParts);

    const tableRows: any[] = [];

    storages.forEach((storage, i) => {
        const index = parts[storage.storage.id] ? `${i + 1}.1` : `${i + 1}`;

        tableRows.push({
            ...storage,
            index,
        });

        if (parts[storage.storage.id]) {
            parts[storage.storage.id]
                .sort((a, b) => {
                    if (a.storage.code < b.storage.code) return -1;
                    if (b.storage.code < a.storage.code) return 1;
                    return 0;
                })
                .forEach((part, j) => {
                    tableRows.push({
                        ...part,
                        index: `${i + 1}.${j + 2}`,
                    });
                });
        }
    });

    const tableIds: number[] = tableRows.map(i => i.id);
    const tableData: any = tableRows.reduce(
        (obj, i) => ({ ...obj, [i.id]: i }),
        {}
    );

    return (
        <Datagrid {...props} ids={tableIds} data={tableData}>
            <TextField source="serial" sortable={false} />
            <TextField source="index" label="No" />
            <TextField source="id" label="Id" />
            <ReferenceField
                label="Storage"
                source="storage.id"
                reference="storages"
                link="show"
            >
                <TextField source="code" label="Code" />
            </ReferenceField>
            <StorageTypeWrapper label="Type" />
            <TextField source="qty" label="Qty Line" />
            <TextField source="qtyDone" label="Qty Customer" />
            <TextField source="qtyInOut" label="Qty WH" />
            <TextField source="storage.size" label="CBM" />
            <FunctionField
                label="Total Size"
                render={record =>
                    record.storage.size && record.qtyDone
                        ? record.storage.size * record.qtyDone
                        : '0'
                }
            />
            <ChipField source="storage.status" label="Status" />
            <DateField locales="en-IN" source="storage.from" label="From" />
            <DateField locales="en-IN" source="storage.to" label="To" />
            <LocationField label="Location" orderId={orderId} />
            <EditButton color="secondary" style={{ padding: 0 }} />
        </Datagrid>
    );
};

const RequestLineList = ({ record, ...props }: { record?: any }) => {
    return record ? (
        <ReferenceManyField
            {...props}
            filter={{ requestId: record.id }}
            sort={{ field: 'createdAt', order: 'DESC' }}
            reference="request-lines"
            target="requestId"
            perPage={1000}
        >
            <LineList orderId={record.orderId} />
        </ReferenceManyField>
    ) : null;
};

export default RequestLineList;
