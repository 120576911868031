import React from 'react';
import capitalize from 'capitalize';
import { timeRange } from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import {
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemText,
    Avatar,
    Typography,
} from '@material-ui/core';
import { Request } from '../../types';

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));

export default ({
    requests = [],
    count = 0,
}: {
    requests: Request[];
    count: number;
}) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardHeader
                title={`${translate(
                    'pos.dashboard.pending_requests'
                )} (${count})`}
            />
            <List dense={true}>
                {requests.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/requests/${record.id}/show`}
                    >
                        <Avatar>{capitalize(record.type[0])}</Avatar>
                        <ListItemText
                            primary={`${capitalize(
                                record.status
                            )} - ${capitalize(record.type)}`}
                            secondary={
                                <React.Fragment>
                                    <Typography component="span">
                                        {`${record.date}, ${timeRange(
                                            record.from,
                                            record.to
                                        )}`}
                                    </Typography>
                                </React.Fragment>
                            }
                            style={{ paddingRight: 0 }}
                        />
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};
