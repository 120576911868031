import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
    EditButton,
    ListButton,
    showNotification as showNotificationAction,
    TopToolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'sweetalert-react';
import EmailIcon from '@material-ui/icons/Email';
import ConfirmIcon from '@material-ui/icons/ThumbUp';
import EditIcon from '@material-ui/icons/Edit';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import CalendarIcon from '@material-ui/icons/DateRange';
import AssignIcon from '@material-ui/icons/AssignmentInd';
import RestoreIcon from '@material-ui/icons/Restore';
import settings from '../../settings';
import AssignButton from './AssignButton';
import LeadFollowUp from './LeadFollowUp';
import LostButton from './LostButton';
import RejectButton from './RejectButton';
import OnHoldButton from './OnHoldButton';
import RestoreButton from './RestoreButton';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import {
    leadSend as leadSendAction,
    leadConfirm as leadConfirmAction,
    leadAssign as leadAssignAction,
    leadRejectQuote as leadRejectQuoteAction,
} from '../actions/LeadActions';
import { OrderStatus, OrderType } from '../../constants';
import { Button, ButtonGroup } from '@material-ui/core';
import { ActionProps, Order } from '../../types';
import feathersClient from '../../rest/feathersClient';
import Popup from '../../components/popup';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

type Styles = {
    button: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ActionProps<Order> & {
        classes: Styles;
        history: any;
    };

type State = {
    showSendQuoteDialog: boolean;
    showEditDialog: boolean;
    showFollowUpDialog: boolean;
    showAssignToMeDialog: boolean;
    showRestoreDialog: boolean;
    showRejectQouteDialog: boolean;
    currentUserId: string | null;
    surveyLink: string | null;
    currentSurveyId: string | null;
};

class LeadShowActions extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            showSendQuoteDialog: false,
            showEditDialog: false,
            showFollowUpDialog: false,
            showAssignToMeDialog: false,
            showRestoreDialog: false,
            showRejectQouteDialog: false,
            currentUserId: localStorage.getItem('userId'),
            surveyLink: null,
            currentSurveyId: null,
        };
    }

    generateSurvey(record) {
        const surveys = feathersClient.service('surveys');
        surveys
            .create({
                type: 'leadSurvey',
                status: 'new',
                orderId: record.id,
                userId: record.userId,
                countryId: record.countryId,
            })
            .then(res => {
                let query = '';
                for (const [key, value] of Object.entries(res.queryData)) {
                    query = query + `${key}=${value}&`;
                }
                query = query.slice(0, -1);
                if (process.env.REACT_APP_ENV === 'staging') {
                    this.setState({
                        currentSurveyId: res.id,
                        surveyLink: `https://app.staging.boxitstorage.com/survey?${query}&userId=${record.userId}&partnerId=${record.partnerId}&countryId=${record.countryId}`
                            .split(' ')
                            .join('%20'),
                    });
                } else {
                    this.setState({
                        currentSurveyId: res.id,
                        surveyLink: `https://app.boxitstorage.com/survey?${query}&userId=${record.userId}&partnerId=${record.partnerId}&countryId=${record.countryId}`
                            .split(' ')
                            .join('%20'),
                    });
                }
            })
            .catch(err => {});
    }

    setSurveyAsSent() {
        navigator.clipboard.writeText(this.state.surveyLink || '');
        const surveys = feathersClient.service('surveys');
        surveys
            .patch(this.state.currentSurveyId, { command: 'SEND-SURVEY' })
            .then(res => {});
    }

    render() {
        const {
            classes,
            basePath,
            data,
            hasEdit,
            taskCreate,
            leadSend,
            leadConfirm,
            leadAssign,
            leadRejectQuote,
            showNotification,
        } = this.props;

        const isNew =
            data &&
            (data.status === OrderStatus.new ||
                data.status === OrderStatus.renew);
        const isQuoteSent = data && data.status === OrderStatus.quote_sent;
        const isConfirmed = data && data.status === OrderStatus.confirmed;
        const isLost = data && data.status === OrderStatus.lost;
        const isComplete = data && data.status === OrderStatus.complete;
        const isOnHold = data && data.status === OrderStatus.onhold;
        const isCancelled = data && data.status === OrderStatus.cancelled;
        const isRejected = data && data.status === OrderStatus.rejected;
        const isQuoteAccepted =
            data && data.status === OrderStatus.quote_accepted;
        const isQuoteRejected =
            data && data.status === OrderStatus.quote_rejected;

        return data ? (
            <TopToolbar>
                <ButtonGroup size="small">
                    <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={() => this.generateSurvey(data)}
                    >
                        Generate Survey
                    </Button>
                </ButtonGroup>
                <ButtonGroup size="small">
                    <Button
                        color="secondary"
                        href={
                            data
                                ? `${settings.apiUrl}/pdf/quote?id=${data.id}`
                                : ''
                        }
                        target="_blank"
                        startIcon={<FileIcon />}
                    >
                        View Quote
                    </Button>

                    {(isNew ||
                        isQuoteSent ||
                        isQuoteAccepted ||
                        isQuoteRejected ||
                        isConfirmed ||
                        isLost ||
                        isOnHold) && (
                        <Button
                            color="secondary"
                            startIcon={<EmailIcon />}
                            onClick={() => {
                                if (!data.assigneeId) {
                                    showNotification(
                                        'Lead is not assigned to any one yet',
                                        'error'
                                    );
                                } else if (!data.cityId) {
                                    showNotification(
                                        'City is required',
                                        'error'
                                    );
                                } else {
                                    this.setState({
                                        showSendQuoteDialog: true,
                                    });
                                }
                            }}
                        >
                            Send Quote
                        </Button>
                    )}

                    {(isNew ||
                        isQuoteSent ||
                        isQuoteAccepted ||
                        isLost ||
                        isOnHold ||
                        isCancelled) && (
                        <Button
                            color="secondary"
                            startIcon={<ConfirmIcon />}
                            onClick={() => {
                                if (data.cityId === undefined) {
                                    showNotification(
                                        'City is required',
                                        'error'
                                    );
                                } else if (data.date === undefined) {
                                    if (data.pickupRequired) {
                                        showNotification(
                                            'Pickup Date is required',
                                            'error'
                                        );
                                    } else {
                                        showNotification(
                                            'Drop Date is required',
                                            'error'
                                        );
                                    }
                                } else if (
                                    data.pickupRequired &&
                                    data.addressId === undefined
                                ) {
                                    showNotification(
                                        'Address is required',
                                        'error'
                                    );
                                } else if (data.channel === undefined) {
                                    showNotification(
                                        'Channel is required',
                                        'error'
                                    );
                                } else if (data.paymentMode === undefined) {
                                    showNotification(
                                        'Payment mode is required',
                                        'error'
                                    );
                                } else if (
                                    data.type === OrderType.storage &&
                                    !data.need
                                ) {
                                    showNotification(
                                        'Reason is required',
                                        'error'
                                    );
                                } else if (
                                    !data.source ||
                                    data.source === undefined
                                ) {
                                    showNotification(
                                        'Source is required',
                                        'error'
                                    );
                                } else if (
                                    data.type === OrderType.storage &&
                                    (!data.warehouseType ||
                                        data.warehouseType.length == 0)
                                ) {
                                    showNotification(
                                        'Warehouse Type is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.buildingType === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Building Type is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.permitRequired === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Is entry permit required?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.movingInsuranceRequired ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Is moving insurance required?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.parkingSlotAvailable ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Is parking slot available?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.parkingSlotAvailable &&
                                    data.checklist?.isSlotInsideOrOutside ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Is parking slot inside or outside the building?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.parkingSlotAvailable &&
                                    data.checklist
                                        ?.maximumAllowedLoadingTime ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - What is the maximum allowed loading time?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.buildingFloor === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Building Floor is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.liftAvailable === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Is lift availabile?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.liftAvailable &&
                                    data.checklist?.maximumAllowedLiftTime ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - What is the maximum allowed time for using the lift?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.liftAvailable &&
                                    data.checklist?.anyItemsNotFitinsideLift ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Are there items that would not fit inside the lift?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.anyItemsNotFitinsideLift &&
                                    data.checklist?.liftNotFitDetails ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Details of bulky items is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.authorizeOtherPerson ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Does customer wants to authorize someone?',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.authorizeOtherPerson &&
                                    data.checklist?.authorizedName === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Authorized Person Name is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.authorizeOtherPerson &&
                                    data.checklist?.authorizedPhone ===
                                        undefined
                                ) {
                                    showNotification(
                                        'Checklist - Authorized Person Phone is required',
                                        'error'
                                    );
                                } else if (
                                    data.pickupRequired &&
                                    data.checklist?.authorizeOtherPerson &&
                                    data.checklist?.authorizedID === undefined
                                ) {
                                    showNotification(
                                        'Checklist - Authorized Person ID Number is required',
                                        'error'
                                    );
                                } else {
                                    leadConfirm(data.id, data);
                                }
                            }}
                        >
                            Confirm
                        </Button>
                    )}

                    {(isCancelled ||
                        isLost ||
                        isRejected ||
                        isQuoteRejected) && (
                        <Button
                            color="secondary"
                            startIcon={<RestoreIcon />}
                            onClick={() =>
                                this.setState({ showRestoreDialog: true })
                            }
                        >
                            Restore
                        </Button>
                    )}

                    {(isCancelled ||
                        isLost ||
                        isRejected ||
                        isQuoteRejected) && (
                        <RestoreButton
                            record={data}
                            isOpen={this.state.showRestoreDialog}
                            setIsOpen={() =>
                                this.setState({ showRestoreDialog: false })
                            }
                        />
                    )}
                    {!isCancelled &&
                        !isComplete &&
                        !isRejected &&
                        !isQuoteRejected && (
                            <Button
                                color="secondary"
                                onClick={() =>
                                    this.setState({
                                        showRejectQouteDialog: true,
                                    })
                                }
                            >
                                Reject Qoute
                            </Button>
                        )}
                </ButtonGroup>

                {!isCancelled &&
                    !isComplete &&
                    !isRejected &&
                    !isQuoteRejected && (
                        <SweetAlert
                            show={this.state.showRejectQouteDialog}
                            title="Are you sure?"
                            text="Do you want to reject this quote?"
                            showCancelButton
                            onCancel={() =>
                                this.setState({ showRejectQouteDialog: false })
                            }
                            onEscapeKey={() =>
                                this.setState({ showRejectQouteDialog: false })
                            }
                            onOutsideClick={() =>
                                this.setState({ showRejectQouteDialog: false })
                            }
                            onConfirm={() => {
                                this.setState({ showRejectQouteDialog: false });
                                console.log('tesdsssst');
                                leadRejectQuote(data.id);
                            }}
                        />
                    )}

                <ButtonGroup className={classes.button} size="small">
                    {!isConfirmed && !isComplete && (
                        <Button
                            color="secondary"
                            startIcon={<CalendarIcon />}
                            onClick={() =>
                                this.setState({ showFollowUpDialog: true })
                            }
                        >
                            Follow Up
                        </Button>
                    )}
                    {data.assigneeId != this.state.currentUserId &&
                    (JSON.parse(
                        localStorage.getItem('permissions') || '[]'
                    )?.includes('users-update') ||
                        JSON.parse(
                            localStorage.getItem('permissions') || '[]'
                        )?.includes('admin')) ? (
                        <Button
                            color="secondary"
                            startIcon={<AssignIcon />}
                            onClick={() =>
                                this.setState({ showAssignToMeDialog: true })
                            }
                        >
                            Assign To Me
                        </Button>
                    ) : null}
                </ButtonGroup>

                {JSON.parse(
                    localStorage.getItem('permissions') || '[]'
                )?.includes('orders-update') ||
                JSON.parse(
                    localStorage.getItem('permissions') || '[]'
                )?.includes('admin') ? (
                    <AssignButton record={data} />
                ) : null}

                {(isNew || isQuoteSent || isQuoteAccepted) && (
                    <OnHoldButton record={data} />
                )}

                {(isQuoteSent ||
                    isQuoteAccepted ||
                    isConfirmed ||
                    isOnHold) && <LostButton record={data} />}

                {isNew && <RejectButton record={data} />}

                {!isNew &&
                    !isComplete &&
                    data &&
                    data.quoteVersion === data.quoteVersionSent && (
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            className={classes.button}
                            startIcon={<EditIcon />}
                            href={
                                data
                                    ? `/#/leads/${data.id}?quoteVersion=${
                                          data.quoteVersion + 1
                                      }`
                                    : ''
                            }
                        >
                            Revise
                        </Button>
                    )}

                {(isNew ||
                    (!isComplete &&
                        data.quoteVersion !== data.quoteVersionSent)) && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        record={data}
                    />
                )}

                <ListButton color="secondary" basePath={basePath} />

                {hasEdit && (isConfirmed || isLost) && (
                    <SweetAlert
                        show={this.state.showEditDialog}
                        title="Are you sure?"
                        text="Editing this lead will delete the corresponding order"
                        showCancelButton
                        onCancel={() =>
                            this.setState({ showEditDialog: false })
                        }
                        onEscapeKey={() =>
                            this.setState({ showEditDialog: false })
                        }
                        onOutsideClick={() =>
                            this.setState({ showEditDialog: false })
                        }
                        onConfirm={() => {
                            this.setState({ showEditDialog: false });
                            this.props.history.push(`/leads/${data.id}`);
                        }}
                    />
                )}

                {(isNew ||
                    isQuoteSent ||
                    isQuoteAccepted ||
                    isQuoteRejected ||
                    isConfirmed ||
                    isLost ||
                    isOnHold) && (
                    <SweetAlert
                        show={this.state.showSendQuoteDialog}
                        title="Are you sure?"
                        text="Do you want to send this quote?"
                        showCancelButton
                        onCancel={() =>
                            this.setState({ showSendQuoteDialog: false })
                        }
                        onEscapeKey={() =>
                            this.setState({ showSendQuoteDialog: false })
                        }
                        onOutsideClick={() =>
                            this.setState({ showSendQuoteDialog: false })
                        }
                        onConfirm={() => {
                            this.setState({ showSendQuoteDialog: false });
                            leadSend(data.id, data);
                            taskCreate({
                                type: 'SEND_QUOTE_TO_CUSTOMER',
                                data: { leadId: data.id },
                            });
                        }}
                    />
                )}

                {data && (
                    <LeadFollowUp
                        open={this.state.showFollowUpDialog}
                        close={() =>
                            this.setState({ showFollowUpDialog: false })
                        }
                        record={data}
                    />
                )}

                {data && !isComplete && this.state.currentUserId && (
                    <SweetAlert
                        show={this.state.showAssignToMeDialog}
                        title="Are you sure?"
                        text="Do you want to assign this lead to you?"
                        showCancelButton
                        onCancel={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onEscapeKey={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onOutsideClick={() =>
                            this.setState({ showAssignToMeDialog: false })
                        }
                        onConfirm={() => {
                            this.setState({ showAssignToMeDialog: false });
                            leadAssign(
                                data.id,
                                data,
                                this.state.currentUserId!
                            );
                        }}
                    />
                )}

                {this.state.surveyLink && (
                    <Popup cancel={() => this.setState({ surveyLink: null })}>
                        <div
                            style={{
                                padding: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                minWidth: '20rem',
                                gap: '1rem',
                            }}
                        >
                            <h1 style={{ fontSize: '2rem' }}>Survey link</h1>
                            <p
                                style={{
                                    width: '100%',
                                    overflowWrap: 'break-word',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    fontSize: '0.8rem',
                                }}
                            >
                                {this.state.surveyLink}
                            </p>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    gap: '1rem',
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                        this.setSurveyAsSent();
                                    }}
                                >
                                    Copy
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        this.setState({ surveyLink: null })
                                    }
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Popup>
                )}
            </TopToolbar>
        ) : null;
    }
}

const mapDispatch = {
    taskCreate: taskCreateAction,
    leadSend: leadSendAction,
    leadConfirm: leadConfirmAction,
    leadAssign: leadAssignAction,
    leadRejectQuote: leadRejectQuoteAction,
    showNotification: showNotificationAction,
};

const connector = connect(null, mapDispatch);

const ConnectedLeadShowActions = connector(LeadShowActions);

export default props => {
    const classes = useStyles();
    return <ConnectedLeadShowActions classes={classes} {...props} />;
};
