import React, { useState } from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { usePermissions, DashboardMenuItem, MenuItemLink } from 'react-admin';
import { stringify } from 'query-string';
import { useMediaQuery, Theme } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import { UserIcon } from '../users';
import SubMenu from './SubMenu';
import { AppState } from '../types';
import './Menu.css';
import GridIcon from '../icons/grid.svg';
import Icon from '@ant-design/icons';
import CustomersIcon from '../icons/User';
import CustomerIcon from '../icons/User';
import SalesIcon from '../icons/ShoppingCart';
import LeadIcon from '../icons/Heart';
import OrderIcon from '../icons/ShoppingCart';
import OperationsIcon from '../icons/Activity';
import RequestIcon from '../icons/List';
import TeamIcon from '../icons/Truck';
import StorageIcon from '../icons/Box';
import WarehouseIcon from '../icons/Home';
import BlockedSlotIcon from '../icons/XOctagon';
import AccountingIcon from '../icons/DollarSign';
import ChargeIcon from '../icons/DollarSign';
import PaymentIcon from '../icons/DollarSign';
import InvoiceIcon from '../icons/FileText';
import CardIcon from '../icons/CreditCard';
import MarketingIcon from '../icons/BarChart';
import OfferIcon from '../icons/Star';
import CouponIcon from '../icons/Coupon';
import AffiliateIcon from '../icons/UserPlus';
import StaffIcon from '../icons/UserPlus';
import PartnerIcon from '../icons/Users';
import LocationIcon from '../icons/Trello';
import TaskIcon from '../icons/Clipboard';
import StorageRecordIcon from '../icons/AlignLeft';
import AdminIcon from '../icons/Settings';
import StorageTypeIcon from '../icons/Square1';
import AffiliateCommissionsIcon from '../icons/DollarSign';
import AnalyticsIcon from '../icons/BarChart2';
import AddressIcon from '../icons/MapPin';
import PendingLeadIcon from '../icons/AlertCircle';
import PendingOrderIcon from '../icons/AlertOctagon';
import PendingRequestIcon from '../icons/AlertTriangle';
import BoxIcon from '../icons/Archive';
import CalendarIcon from '../icons/Calendar';
import DashboardIcon from '../icons/Grid';
import MailIcon from '../icons/Mail';
// import TermsAndConditions from '../icons/TermsAndConditions';

const styles = {
    menuIcon: { fontSize: '32px' },
};

const IconWrapper = ({
    icon,
    style = styles.menuIcon,
    titleAccess = null,
    ...props
}) => createElement(icon, { style, ...props });

type MenuName =
    | 'menuSales'
    | 'menuCustomers'
    | 'menuOperations'
    | 'menuAccounting'
    | 'menuMarketing'
    | 'menuAnalytics'
    | 'menuAdmin';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: React.FC<Props> = ({ onMenuClick, dense, logout }) => {
    const [state, setState] = useState({
        menuSales: false,
        menuCustomers: false,
        menuOperations: false,
        menuAccounting: false,
        menuMarketing: false,
        menuAnalytics: false,
        menuAdmin: false,
    });
    const { permissions = [] } = usePermissions();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            <div>&nbsp;</div>
            <DashboardMenuItem
                to="/"
                leftIcon={<IconWrapper icon={DashboardIcon} />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {_.intersection(permissions, [
                'admin',
                'sales-staff',
                'staff',
                'partner',
            ]).length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuSales')}
                    isOpen={state.menuSales}
                    sidebarIsOpen={open}
                    name="pos.menu.sales"
                    icon={<SalesIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/leads"
                        primaryText="Leads"
                        leftIcon={<IconWrapper icon={LeadIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/leads?${stringify({
                            perPage: 10,
                            sort: 'date',
                            order: 'ASC',
                            filter: JSON.stringify({
                                status: ['new', 'onhold', 'quote_sent'],
                            }),
                        })}`}
                        primaryText="Pending Leads"
                        leftIcon={<IconWrapper icon={PendingLeadIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/orders"
                        primaryText="Orders"
                        leftIcon={<IconWrapper icon={OrderIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/orders?${stringify({
                            perPage: 10,
                            sort: 'date',
                            order: 'ASC',
                            filter: JSON.stringify({ status: ['confirmed'] }),
                        })}`}
                        primaryText="Pending Orders"
                        leftIcon={<IconWrapper icon={PendingOrderIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/sales-calendar"
                        primaryText="Calendar"
                        leftIcon={<CalendarIcon style={styles.menuIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, [
                'admin',
                'sales-staff',
                'staff',
                'partner',
            ]).length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuCustomers')}
                    isOpen={state.menuCustomers}
                    sidebarIsOpen={open}
                    name="pos.menu.customers"
                    icon={<CustomersIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to={`/users?${stringify({
                            filter: JSON.stringify({ status: ['active'] }),
                        })}`}
                        primaryText="Customers"
                        leftIcon={<IconWrapper icon={CustomerIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/addresses"
                        primaryText="Addresses"
                        leftIcon={<IconWrapper icon={AddressIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/Communication-records"
                        primaryText="Communication"
                        leftIcon={<IconWrapper icon={PartnerIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, ['admin', 'operations-staff', 'staff'])
                .length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuOperations')}
                    isOpen={state.menuOperations}
                    sidebarIsOpen={open}
                    name="pos.menu.operations"
                    icon={<OperationsIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/jobs"
                        primaryText="Jobs"
                        leftIcon={<IconWrapper icon={CalendarIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/requests"
                        primaryText="Requests"
                        leftIcon={<IconWrapper icon={RequestIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to={`/requests?${stringify({
                            perPage: 10,
                            sort: 'date',
                            order: 'ASC',
                            filter: JSON.stringify({
                                status: [
                                    'pending',
                                    'new',
                                    'confirmed',
                                    'onhold',
                                    'approved',
                                    'transit',
                                    'received',
                                    'delivered',
                                ],
                            }),
                        })}`}
                        primaryText="Pending Requests"
                        leftIcon={<IconWrapper icon={PendingRequestIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/blocked-slots"
                        primaryText="Blocked Slots"
                        leftIcon={<IconWrapper icon={BlockedSlotIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/storages"
                        primaryText="Storages"
                        leftIcon={<IconWrapper icon={StorageIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/boxes"
                        primaryText="Boxes"
                        leftIcon={<IconWrapper icon={BoxIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/storage-types"
                        primaryText="Storage Types"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/locations"
                        primaryText="Locations"
                        leftIcon={<IconWrapper icon={LocationIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/storage-records"
                        primaryText="Storage Records"
                        leftIcon={<IconWrapper icon={StorageRecordIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, ['admin', 'accounts-staff', 'staff'])
                .length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuAccounting')}
                    isOpen={state.menuAccounting}
                    sidebarIsOpen={open}
                    name="pos.menu.accounting"
                    icon={<AccountingIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/charges"
                        primaryText="Charges"
                        leftIcon={<IconWrapper icon={ChargeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/invoices"
                        primaryText="Invoices"
                        leftIcon={<IconWrapper icon={InvoiceIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/payments"
                        primaryText="Payments"
                        leftIcon={<IconWrapper icon={PaymentIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/cards"
                        primaryText="Cards"
                        leftIcon={<IconWrapper icon={CardIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, ['admin', 'marketing-staff', 'staff'])
                .length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuMarketing')}
                    isOpen={state.menuMarketing}
                    sidebarIsOpen={open}
                    name="pos.menu.marketing"
                    icon={<MarketingIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/offers"
                        primaryText="Offers"
                        leftIcon={<IconWrapper icon={OfferIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/coupons"
                        primaryText="Coupons"
                        leftIcon={<IconWrapper icon={CouponIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/affiliates"
                        primaryText="Affiliates"
                        leftIcon={<IconWrapper icon={AffiliateIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/affiliate-commissions"
                        primaryText="Commissions"
                        leftIcon={
                            <IconWrapper icon={AffiliateCommissionsIcon} />
                        }
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, ['admin', 'business']).length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuAnalytics')}
                    isOpen={state.menuAnalytics}
                    sidebarIsOpen={open}
                    name="pos.menu.analytics"
                    icon={<AnalyticsIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/analytics/sales"
                        primaryText="Sales"
                        leftIcon={<IconWrapper icon={SalesIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/analytics/storage"
                        primaryText="Storage"
                        leftIcon={<IconWrapper icon={StorageIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/analytics/finance"
                        primaryText="Finance"
                        leftIcon={<IconWrapper icon={AccountingIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    {/* <MenuItemLink
                        to="/analytics/marketing"
                        primaryText="Marketing"
                        leftIcon={<IconWrapper icon={MarketingIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    /> */}

                    <MenuItemLink
                        to="/analytics/usage"
                        primaryText="Usage"
                        leftIcon={<IconWrapper icon={AnalyticsIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/analytics/operations"
                        primaryText="Operations"
                        leftIcon={<IconWrapper icon={AnalyticsIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/analytics/warehouse"
                        primaryText="Warehouse"
                        leftIcon={<IconWrapper icon={WarehouseIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/analytics/lead"
                        primaryText="Lead"
                        leftIcon={<IconWrapper icon={LeadIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />

                    <MenuItemLink
                        to="/analytics/survey"
                        primaryText="Survey"
                        leftIcon={<IconWrapper icon={StorageIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
            {_.intersection(permissions, ['admin']).length > 0 && (
                <SubMenu
                    handleToggle={() => handleToggle('menuAdmin')}
                    isOpen={state.menuAdmin}
                    sidebarIsOpen={open}
                    name="pos.menu.admin"
                    icon={<AdminIcon style={styles.menuIcon} />}
                    dense={dense}
                >
                    <MenuItemLink
                        to="/staff"
                        primaryText="Staff"
                        leftIcon={<IconWrapper icon={StaffIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/tasks"
                        primaryText="Tasks"
                        leftIcon={<IconWrapper icon={TaskIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/service-types"
                        primaryText="Service Types"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/partners"
                        primaryText="Partners"
                        leftIcon={<IconWrapper icon={PartnerIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/warehouses"
                        primaryText="Warehouses"
                        leftIcon={<IconWrapper icon={WarehouseIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/teams"
                        primaryText="Teams"
                        leftIcon={<IconWrapper icon={TeamIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/photos"
                        primaryText="Photots"
                        leftIcon={<IconWrapper icon={TeamIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/terms-conditions"
                        primaryText="T&C"
                        leftIcon={<IconWrapper icon={TeamIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/communication-templates"
                        primaryText="Communication Templates"
                        leftIcon={<IconWrapper icon={MailIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/template-categories"
                        primaryText="Template categories"
                        leftIcon={<IconWrapper icon={MailIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/email-placeholder"
                        primaryText="Email placeholders"
                        leftIcon={<IconWrapper icon={MailIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/tags"
                        primaryText="Tags"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/lost-reasons"
                        primaryText="Lost Reasons"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/hold-reasons"
                        primaryText="Hold Reasons"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/reject-reasons"
                        primaryText="Reject Reasons"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/cancel-reasons"
                        primaryText="Cancel Reasons"
                        leftIcon={<IconWrapper icon={StorageTypeIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/user-logs"
                        primaryText="User Logs"
                        leftIcon={<IconWrapper icon={PartnerIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/configurations"
                        primaryText="Configurations"
                        leftIcon={<AdminIcon style={styles.menuIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/surveys"
                        primaryText="Surveys"
                        leftIcon={<IconWrapper icon={PartnerIcon} />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
                </SubMenu>
            )}
        </div>
    );
};

export default Menu;
