import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    redSubText: {
        color: red[500],
    },
    greenSubText: {
        color: green[500],
    },
}));

export default ({ value, total }: { value: number; total: number }) => {
    const classes = useStyles();
    const percent = Math.round((value / total) * 100);

    if (percent == undefined || isNaN(percent)) return <span>&nbsp;</span>;
    return (
        <span
            className={percent > 90 ? classes.greenSubText : classes.redSubText}
        >
            {percent}%
        </span>
    );
};
