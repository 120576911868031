import * as React from 'react';

function SvgAlertOctagon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.293 15.153A1 1 0 009 15.86v8.28a1 1 0 00.293.707l5.86 5.86a1 1 0 00.707.293h8.28a1 1 0 00.707-.293l5.86-5.86A1 1 0 0031 24.14v-8.28a1 1 0 00-.293-.707l-5.86-5.86A1 1 0 0024.14 9h-8.28a.998.998 0 00-.707.293l-5.86 5.86zM11 23.726v-7.452L16.274 11h7.452L29 16.274v7.452L23.726 29h-7.452L11 23.726zM21 16a1 1 0 10-2 0v4a1 1 0 102 0v-4zm0 8a1 1 0 11-2 0 1 1 0 012 0z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgAlertOctagon;
