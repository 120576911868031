import React from 'react';
import moment from 'moment';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
} from 'react-admin';
import TimeSlotField from '../components/TimeSlotField';
import CityField from '../components/CityField';
import CountryField from '../components/CountryField';
import DeleteButton from '../components/DeleteButton';

const BlockedSlotFilter = props => (
    <Filter variant="outlined" {...props}>
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>
        {/*<ReferenceInput
            source="cityId"
            reference="city"
            resource="city"
            label="City"
        >
            <SelectInput />
</ReferenceInput>*/}
        <DateInput
            source="from[$gte]"
            label="Date From"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
        <DateInput
            source="to[$lte]"
            label="Date To"
            parse={v => v && moment(v).format('YYYY-MM-DD')}
        />
    </Filter>
);

const BlockedSlotList = props => (
    <List
        title="Blocked Slots"
        bulkActionButtons={false}
        filters={<BlockedSlotFilter />}
        sort={{ field: 'from', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <DateField locales="en-IN" source="from" label="From Date" />
            <DateField locales="en-IN" source="to" label="To Date" />
            {/*<CityField />*/}
            <CountryField />
            <DeleteButton undoable={false} />
        </Datagrid>
    </List>
);

BlockedSlotList.defaultProps = {
    resource: 'blockedslots',
};

export default BlockedSlotList;
