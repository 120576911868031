import React, { useEffect } from 'react';
import { Show } from 'react-admin';
import { InvoiceTabs } from './components/InvoiceTabs';
import InvoiceShowButtons from './components/InvoiceShowButtons';
import { modelView, modelViewType } from '../util/modelView';

const InvoiceShow = ({ permissions = [], ...props }) => {
    useEffect(() => {
        modelView(modelViewType.INVOICES, parseInt(props.id));
    }, [props.id]);
    return (
        <Show
            actions={<InvoiceShowButtons permissions={permissions} />}
            {...props}
        >
            <InvoiceTabs />
        </Show>
    );
};

export default InvoiceShow;
