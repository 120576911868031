import React from 'react';
import {
    Datagrid,
    ImageField,
    List,
    ShowButton,
    TextField,
    AutocompleteInput,
    Filter,
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import BoxitPagination from '../components/BoxitPagination';
import { ListProps, Photo } from '../types';
import CustomSearchInput from '../components/CustomSearchInput';

const LeadFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />
            <ReferenceInput
                source="userId"
                reference="users"
                resource="users"
                label="Customer"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={user => (user.name ? user.name : user.email)}
                />
            </ReferenceInput>
            <ReferenceInput
                source="requestId"
                reference="requests"
                resource="requests"
                label="Requests"
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText={req => (req.id ? JSON.stringify(req.id) : null)}
                />
            </ReferenceInput>
        </Filter>
    );
};

const PhotoList: React.FC<ListProps<Photo>> = ({
    permissions = [],
    ...props
}) => (
    <List
        title="Photos"
        filters={<LeadFilter />}
        pagination={<BoxitPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <ImageField source="photo" label="Logo" />
            <ShowButton style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

PhotoList.defaultProps = {
    resource: 'photos',
    location: { search: '' },
};

export default PhotoList;
