import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyField, Tab, TabbedShowLayout } from 'react-admin';
import { History } from '../../revisions';
import { Comments } from '../../comments';
import UserSummary from './UserSummary';
import UserBillTillDateForm from './UserBillTillDateForm';
import UserUsage from './UserUsage';
import AddressGrid from '../../addresses/components/AddressGrid';
import UserDocs from './UserDocs';
import AddUserPhoto from './AddUserPhoto';
import PhotoGallery from '../../components/PhotoGallery';
import CommunicationList from '../../communication-records/CommunicationList';
import { UserCommunicationsList } from './UserCommunicationsList';

export const UserTabs = props => {
    const { record } = props;

    return (
        <TabbedShowLayout {...props}>
            <Tab label="summary">
                <UserSummary />
            </Tab>
            <Tab label="Comments">
                <Comments
                    {...props}
                    model="user"
                    documentId={record.id}
                    userId={record.id}
                />
            </Tab>
            <Tab label="Photos">
                <PhotoGallery
                    {...props}
                    target="userId"
                    reference="user-photos"
                />
                <AddUserPhoto record={props.record} />
            </Tab>
            <Tab label="Documents">
                <UserDocs {...props} />
            </Tab>
            <Tab label="Addresses">
                <ReferenceManyField
                    reference="addresses"
                    target="userId"
                    filter={{ userId: record.id }}
                    {...props}
                >
                    <AddressGrid />
                </ReferenceManyField>
            </Tab>
            <Tab label="Billing">
                <UserBillTillDateForm {...props} />
            </Tab>
            <Tab label="Usage">
                <UserUsage {...props} />
            </Tab>
            <Tab label="History">
                <History {...props} model="user" documentId={record.id} />
            </Tab>
            <Tab label="Communication">
                <UserCommunicationsList />
            </Tab>
        </TabbedShowLayout>
    );
};

UserTabs.propTypes = {
    record: PropTypes.object,
};

UserTabs.defaultProps = {
    record: {},
};
