import React from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    ChipField,
    ShowButton,
    ReferenceManyField,
} from 'react-admin';

export const PartnerCommissionList = props => (
    <ReferenceManyField
        reference="partner-commission-slabs"
        target="partnerId"
        sort={{ field: 'id', order: 'ASC' }}
        perPage={200}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="service_category.name" label="Type" />
            <TextField source="target" label="More Than (CBM)" />
            <TextField source="commissionPercent" label="Commission (%)" />
        </Datagrid>
    </ReferenceManyField>
);
