import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import BillingIcon from '@material-ui/icons/AttachMoney';
import Money from './Money';

import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Typography,
} from '@material-ui/core';

const styles = theme => ({
    card: {
        maxWidth: 400,
        '& a': {
            textDecoration: 'none',
        },
    },
    avatar: {
        backgroundColor: theme.palette.background.primary,
    },
});

const BillingCard = ({ classes, billing }) => (
    <Card style={{ flex: 1 }}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <BillingIcon />
                </Avatar>
            }
            title={
                <Typography variant="h6" gutterBottom>
                    Billing
                </Typography>
            }
        />

        <CardContent>
            {billing && (
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    textAlign: 'left',
                                    ...(billing.amountOutstanding > 20000 && {
                                        color: 'red',
                                    }),
                                }}
                            >
                                Outstanding
                            </td>
                            <td
                                style={{
                                    textAlign: 'right',
                                    ...(billing.amountOutstanding > 20000 && {
                                        color: 'red',
                                    }),
                                }}
                            >
                                {billing.amountOutstanding && (
                                    <Money
                                        amount={billing.amountOutstanding}
                                        currency={billing.currency}
                                        showCurrency
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'left' }}>Rent</td>
                            <td style={{ textAlign: 'right' }}>
                                {billing.rent && (
                                    <Money
                                        amount={billing.rent}
                                        currency={billing.currency}
                                        showCurrency
                                    />
                                )}
                            </td>
                        </tr>
                        {billing.countryId === 1 && (
                            <tr>
                                <td style={{ textAlign: 'left' }}>
                                    Rent (incl. vat)
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    {billing.rent && (
                                        <Money
                                            amount={billing.rent * 1.05}
                                            currency={billing.currency}
                                            showCurrency
                                        />
                                    )}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td style={{ textAlign: 'left' }}>Total Billed</td>
                            <td style={{ textAlign: 'right' }}>
                                {billing.amountBilled && (
                                    <Money
                                        amount={billing.amountBilled}
                                        currency={billing.currency}
                                        showCurrency
                                    />
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'left' }}>Total Paid</td>
                            <td style={{ textAlign: 'right' }}>
                                {billing.amountPaid && (
                                    <Money
                                        amount={billing.amountPaid}
                                        currency={billing.currency}
                                        showCurrency
                                    />
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
        </CardContent>
    </Card>
);

BillingCard.propTypes = {
    billing: PropTypes.object,
};

BillingCard.defaultProps = {
    billing: {},
};

export default translate(withStyles(styles)(BillingCard));
