import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { addStoragePhoto as addStoragePhotoAction } from '../actions/storageActions';
import AddPhoto from '../../components/AddPhoto';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    record: any;
};

type State = {};

class AddStoragePhoto extends React.Component<Props, State> {
    handleSubmit = (base64: string) => {
        const { record, addStoragePhoto } = this.props;
        addStoragePhoto(record.userId, record.id, base64);
    };

    render() {
        return (
            <AddPhoto record={this.props.record} onSubmit={this.handleSubmit} />
        );
    }
}

const mapDispatch = {
    addStoragePhoto: addStoragePhotoAction,
};

const connector = connect(null, mapDispatch);

export default connector(AddStoragePhoto);
