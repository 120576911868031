import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import moment from 'moment';
import {
    Edit,
    TextInput,
    SimpleForm,
    DateInput,
    CheckboxGroupInput,
    SelectInput,
    crudGetList as crudGetListAction,
    ReferenceArrayInput,
    ReferenceInput,
    SaveButton,
    Toolbar,
    FormDataConsumer,
    AutocompleteInput,
} from 'react-admin';
import { InvoiceStatus, InvoiceType } from '../constants';
import { getCurrencyString } from '../charges/utils';
import { AppState, EditProps, Charge } from '../types';
import CurrencyInput from '../components/CurrencyInput';
import MoneyInput from '../components/MoneyInput';

const InvoiceEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    EditProps & {
        charges: Charge[];
    };
const CheckboxGroup = props => (
    <CheckboxGroupInput
        {...props}
        source="chargeIds"
        optionText={rec =>
            `[${rec.type}]: ${getCurrencyString(rec)} - ${rec.description}`
        }
        choices={props.choices || []}
    />
);
class EditForm extends Component<Props> {
    componentDidMount() {
        //this.fetchCharges();
    }

    fetchCharges() {
        const { crudGetList, record } = this.props;

        crudGetList(
            'charges',
            { page: 0, pageSize: 200 },
            {},
            {
                userId: record.userId,
                $or: [{ invoiceId: record.id }, { invoiceId: 'null' }],
            }
        );
    }

    render() {
        const { crudGetList, ...props } = this.props;
        const { record, charges, permissions = [] } = this.props;

        return (
            <SimpleForm
                toolbar={<InvoiceEditToolbar />}
                variant="outlined"
                redirect="show"
                {...props}
            >
                <TextInput disabled source="id" style={{ display: 'none' }} />

                {permissions.indexOf('staff') !== -1 && (
                    <ReferenceInput
                        label="Partner"
                        source="partnerId"
                        reference="partners"
                        resource="partners"
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}

                <ReferenceInput
                    label="Order"
                    source="orderId"
                    reference="orders"
                    resource="orders"
                    filter={{ userId: record.userId }}
                    allowEmpty
                >
                    <SelectInput
                        source="orderId"
                        optionValue="id"
                        optionText={record => `Order #${record.id}`}
                    />
                </ReferenceInput>

                <ReferenceInput
                    label="User"
                    source="userId"
                    reference="users"
                    resource="users"
                >
                    <AutocompleteInput
                        shouldRenderSuggestions={val => val.trim().length > 2}
                        optionText={user => `${user.name}   (${user.id})`}
                    />
                </ReferenceInput>

                <SelectInput
                    source="status"
                    choices={Object.keys(InvoiceStatus).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />

                <SelectInput
                    source="type"
                    choices={Object.keys(InvoiceType).map(c => ({
                        id: c,
                        name: c,
                    }))}
                />

                <DateInput
                    source="date"
                    parse={v => v && moment(v).format('YYYY-MM-DD')}
                />

                <CurrencyInput />

                <MoneyInput source="amountPaid" />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        !!formData.userId && (
                            <ReferenceArrayInput
                                label="Charges"
                                source="chargeIds"
                                reference="charges"
                                resource="charges"
                                filter={{
                                    userId: formData.userId,
                                    ...(formData.type === 'creditNote'
                                        ? {
                                              type: 'credit',
                                          }
                                        : {
                                              type: { $ne: 'credit' },
                                          }),
                                }}
                                allowEmpty
                            >
                                <CheckboxGroup />
                            </ReferenceArrayInput>
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
        );
    }
}

const mapState = (state: AppState, props) => {
    const { record } = props;

    const charges: Charge[] =
        state.admin.resources.charges.data &&
        Object.values(state.admin.resources.charges.data).filter(
            (i: Charge) =>
                i.userId === record.userId &&
                (i.invoiceId === null || i.invoiceId === record.id)
        );
    return {
        charges,
    };
};

const mapDispatch = {
    crudGetList: crudGetListAction,
};

const connector = connect(mapState, mapDispatch);

const InvoiceEditForm = connector(EditForm);

const InvoiceEdit = ({ permissions = [], ...props }) => (
    <Edit undoable={false} {...props}>
        <InvoiceEditForm permissions={permissions} />
    </Edit>
);

export default InvoiceEdit;
