import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import capitalize from 'capitalize';
import { useGetMany, DateField, ReferenceField, TextField } from 'react-admin';
import VolumeField from '../../components/VolumeField';
import TimeSlotField from '../../components/TimeSlotField';
import Address from '../../components/Address';
import UserReferenceField from '../../components/UserReferenceField';
import RequestChargeList from './RequestChargeList';
import { isTransportRequest } from '../../core/utils/request';
import Money from '../../components/Money';
import { RequestType, StorageStatus } from '../../constants';
import ItemStorageList from '../../orders/components/ItemStorageList';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from '@material-ui/core';
import humanize from '../../util/humanize';
import { isTransportRequestLocal } from '../utils';

export const RequestSummary = props => {
    const { record } = props;
    const storages = (record.request_lines || [])
        .map(l => ({
            ...l.storage,
            qty: l.qty,
        }))
        .filter(i => i.status !== StorageStatus.cancelled);

    const numPieces = storages.length;
    const numStorages = storages.filter(i => !i.isPart).length;
    const numParts = storages.filter(i => i.isPart).length;
    const needTransport = isTransportRequestLocal(record);
    const needStorages =
        [
            RequestType.newbox,
            RequestType.pickup,
            RequestType.delivery,
            RequestType.drop,
            RequestType.collect,
            RequestType.transfer,
        ].indexOf(record.type) !== -1;
    const needWarehouse = needStorages;

    /*
    const storagetypeIds = _.uniq(
        (record.items || []).map(c => c.storagetypeId).filter(i => i)
    );
    const { data: storagetypes } = useGetMany('storage-types', storagetypeIds);
    */

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Request No</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                record={record}
                                label="Customer"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer Action</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.customerAction === 'disputed' ? (
                                <span className="inline-block px-2 py-1 bg-red-200 text-red-800 rounded-full">
                                    Disputed
                                </span>
                            ) : record.customerAction === 'accepted' ? (
                                <span className="inline-block px-2 py-1 bg-green-200 text-green-800 rounded-full">
                                    Accepted
                                </span>
                            ) : record.customerAction === 'resolved' ? (
                                <span className="inline-block px-2 py-1 bg-blue-200 text-blue-800 rounded-full">
                                    Resolved
                                </span>
                            ) : (
                                ''
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow hover>
                        <TableCell>Assignee</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <UserReferenceField
                                source="assigneeId"
                                {...props}
                                label="Assignee"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <span className="tags-chip">
                                {humanize(record.type)}
                            </span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <span className="tags-chip">
                                {capitalize(record.status)}
                            </span>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <DateField
                                locales="en-IN"
                                record={record}
                                source="date"
                                options={{
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                }}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Time Slot</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TimeSlotField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>City</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="City"
                                record={record}
                                source="cityId"
                                reference="city"
                                basePath="/city"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>

                    {needTransport && (
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.addressId && (
                                    <Address id={record.addressId} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}

                    {record.type === RequestType.moving && (
                        <TableRow>
                            <TableCell>Destination Address</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.destAddressId && (
                                    <Address id={record.destAddressId} />
                                )}
                            </TableCell>
                        </TableRow>
                    )}

                    {record.orderId && (
                        <TableRow>
                            <TableCell>Order</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    label="Order"
                                    record={record}
                                    source="orderId"
                                    reference="orders"
                                    basePath="/orders"
                                    link="show"
                                >
                                    <TextField source="id" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    )}

                    <TableRow>
                        <TableCell>Team</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Team"
                                record={record}
                                source="teamId"
                                reference="teams"
                                basePath="/teams"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>

                    {needWarehouse && (
                        <TableRow>
                            <TableCell>Warehouse</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <ReferenceField
                                    label="Warehouse"
                                    record={record}
                                    source="warehouseId"
                                    reference="warehouses"
                                    basePath="/warehouses"
                                    link="show"
                                >
                                    <TextField source="name" />
                                </ReferenceField>
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Partner</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                label="Partner"
                                record={record}
                                source="partnerId"
                                reference="partners"
                                basePath="/partners"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Payment Mode</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.paymentMode &&
                                capitalize(record.paymentMode)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Payment To Collect</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.amount && record.currency ? (
                                <Money
                                    amount={record.amount}
                                    currency={record.currency}
                                    showCurrency
                                />
                            ) : (
                                ''
                            )}
                        </TableCell>
                    </TableRow>

                    {record.services && (
                        <TableRow>
                            <TableCell colSpan={2}>
                                <strong>Services</strong>
                            </TableCell>
                        </TableRow>
                    )}

                    {record.services &&
                        record.services.map(
                            i =>
                                i &&
                                i.id && (
                                    <TableRow key={i.id}>
                                        <TableCell>
                                            {i.servicetype.name}
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: 'right' }}
                                        >
                                            <Money
                                                amount={i.amount || 0}
                                                currency={i.currency}
                                                showCurrency
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                        )}

                    <TableRow>
                        <TableCell colSpan={2}>
                            <strong>Charges</strong>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>
                            <RequestChargeList record={record} />
                        </TableCell>
                    </TableRow>

                    {needStorages && (
                        <TableRow>
                            <TableCell>
                                <strong>Storages</strong>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <span>{numStorages} items</span>
                                {numParts > 0 && (
                                    <span>
                                        <br />
                                        {numParts} parts
                                    </span>
                                )}
                                {numPieces > 0 && (
                                    <span>
                                        <br />
                                        {numPieces} pieces
                                    </span>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {record &&
                        record.isPrimary &&
                        record.type != RequestType.moving && (
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Expected</TableCell>
                                                <TableCell>Actual</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>
                                                    {record && record.qty}
                                                </TableCell>
                                                <TableCell>
                                                    {record && record.actualQty}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Size</TableCell>
                                                <TableCell>
                                                    <VolumeField
                                                        record={record}
                                                        source="size"
                                                        showUnit
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <VolumeField
                                                        record={record}
                                                        source="actualSize"
                                                        showUnit
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <ItemStorageList
                                                record={record}
                                                skipCurrent={true}
                                            />
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        )}

                    {record.type !== RequestType.moving && (
                        <TableRow>
                            <TableCell>Total Size</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                <VolumeField
                                    record={record}
                                    source="actualSize"
                                    showUnit
                                />
                            </TableCell>
                        </TableRow>
                    )}
                    {record.type !== RequestType.moving && (
                        <TableRow>
                            <TableCell>Total Rent</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.rent && record.currency ? (
                                    <Money
                                        amount={record.rent}
                                        currency={record.currency}
                                        showCurrency
                                    />
                                ) : (
                                    ''
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell>Customer Action</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.customerAction === 'disputed' ? (
                                <span className="inline-block px-2 py-1 bg-red-200 text-red-800 rounded-full">
                                    Disputed
                                </span>
                            ) : record.customerAction === 'accepted' ? (
                                <span className="inline-block px-2 py-1 bg-green-200 text-green-800 rounded-full">
                                    Accepted
                                </span>
                            ) : record.customerAction === 'resolved' ? (
                                <span className="inline-block px-2 py-1 bg-blue-200 text-blue-800 rounded-full">
                                    Resolved
                                </span>
                            ) : (
                                ''
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Customer Complaint</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.customerComplaint}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Rating</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.rating}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Feedback</TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.feedback}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

RequestSummary.propTypes = {
    record: PropTypes.object,
};

RequestSummary.defaultProps = {
    record: {},
};
