import React from 'react';
import {
    Edit,
    TextInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    AutocompleteInput,
} from 'react-admin';
import { BoxStatus } from '../constants';
import { TitleProps, Box } from '../types';

const BoxTitle: React.FC<TitleProps<Box>> = ({ record }) => (
    <span>Box {record ? `"${record.code}"` : ''}</span>
);

const BoxEdit = props => (
    <Edit title={<BoxTitle />} undoable={false} {...props}>
        <SimpleForm variant="outlined">
            <TextInput disabled source="partnerId" />
            <TextInput disabled source="id" />
            <TextInput disabled source="code" />
            <ReferenceInput
                label="Customer"
                source="userId"
                reference="users"
                resource="users"
                allowEmpty
            >
                <AutocompleteInput
                    shouldRenderSuggestions={val => val.trim().length > 2}
                    optionText="name"
                    translateChoice={false}
                />
            </ReferenceInput>
            <ReferenceInput
                label="Warehouse"
                source="warehouseId"
                reference="warehouses"
                resource="warehouses"
            >
                <SelectInput optionText="name" translateChoice={false} />
            </ReferenceInput>
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" translateChoice={false} />
            </ReferenceInput>
            <SelectInput
                source="status"
                choices={Object.values(BoxStatus).map(i => ({
                    id: i,
                    name: i,
                }))}
            />
        </SimpleForm>
    </Edit>
);

export default BoxEdit;
