import React, { FC } from 'react';
import Analytics from './Analytics';

const SalesAnalytics: FC = () => {
    return (
        <Analytics
            title={'Sales'}
            items={[
                {
                    title: 'Leads',
                    data: [
                        {
                            title: 'Total Leads',
                            key: 'lead.num_leads',
                        },
                        {
                            title: 'Leads (B2C)',
                            key: 'lead.num_leads_b2c',
                        },
                        {
                            title: 'Leads (B2B)',
                            key: 'lead.num_leads_b2b',
                        },
                    ],
                },
                {
                    title: 'Orders',
                    data: [
                        {
                            title: 'Total Orders',
                            key: 'order.num_orders',
                        },
                        {
                            title: 'Orders (B2C)',
                            key: 'order.num_orders_b2c',
                        },
                        {
                            title: 'Orders (B2B)',
                            key: 'order.num_orders_b2b',
                        },
                    ],
                },
                {
                    title: 'Customers',
                    data: [
                        {
                            title: 'Customers',
                            key: 'order.num_customers',
                        },
                        {
                            title: 'Customers (B2C)',
                            key: 'order.num_customers_b2c',
                        },
                        {
                            title: 'Customers (B2B)',
                            key: 'order.num_customers_b2b',
                        },
                    ],
                },
                {
                    title: 'Volume',
                    data: [
                        {
                            title: 'Total Volume',
                            key: 'order.volume',
                            unit: 'CBM',
                        },
                        {
                            title: 'Volume (B2C)',
                            key: 'order.volume_b2c',
                            unit: 'CBM',
                        },
                        {
                            title: 'Volume (B2B)',
                            key: 'order.volume_b2b',
                            unit: 'CBM',
                        },
                    ],
                },
                {
                    title: 'Sales',
                    country: true,
                    data: [
                        {
                            title: 'Total Sales',
                            currency: 'order.currency',
                            key: 'order.total_amount',
                        },
                        {
                            title: 'Sales (B2C)',
                            currency: 'order.currency',
                            key: 'order.total_amount_b2c',
                        },
                        {
                            title: 'Sales (B2B)',
                            currency: 'order.currency',
                            key: 'order.total_amount_b2b',
                        },
                    ],
                },
                {
                    title: 'Storage Sales',
                    country: true,
                    data: [
                        {
                            title: 'Storage Sales',
                            currency: 'order.currency',
                            key: 'order.storage_amount',
                        },
                        {
                            title: 'Storage Sales (B2C)',
                            currency: 'order.currency',
                            key: 'order.storage_amount_b2c',
                        },
                        {
                            title: 'Storage Sales (B2B)',
                            currency: 'order.currency',
                            key: 'order.storage_amount_b2b',
                        },
                    ],
                },
                {
                    title: 'Service Sales',
                    country: true,
                    data: [
                        {
                            title: 'Service Sales',
                            currency: 'order.currency',
                            key: 'order.service_amount',
                        },
                        {
                            title: 'Service Sales (B2C)',
                            currency: 'order.currency',
                            key: 'order.service_amount_b2c',
                        },
                        {
                            title: 'Service Sales (B2B)',
                            currency: 'order.currency',
                            key: 'order.service_amount_b2b',
                        },
                    ],
                },
                {
                    title: 'Avg. Order Value',
                    country: true,
                    data: [
                        {
                            title: 'Avg. Order Value',
                            currency: 'order.currency',
                            key: 'order.avg_amount',
                        },
                        {
                            title: 'Avg. Order Value (B2C)',
                            currency: 'order.currency',
                            key: 'order.avg_amount_b2c',
                        },
                        {
                            title: 'Avg. Order Value (B2B)',
                            currency: 'order.currency',
                            key: 'order.avg_amount_b2b',
                        },
                    ],
                },
                {
                    title: 'Avg. Selling Price',
                    country: true,
                    data: [
                        {
                            title: 'Avg. Selling Price',
                            currency: 'order.currency',
                            key: 'order.avg_selling_price_per_cbm',
                        },
                        {
                            title: 'Avg. Selling Price (B2C)',
                            currency: 'order.currency',
                            key: 'order.avg_selling_price_per_cbm_b2c',
                        },
                        {
                            title: 'Avg. Selling Price (B2B)',
                            currency: 'order.currency',
                            key: 'order.avg_selling_price_per_cbm_b2b',
                        },
                    ],
                },
                {
                    title: 'Total Registered Users',
                    country: true,
                    data: [
                        {
                            title: 'Total Registered Users',
                            currency: 'order.currency',
                            key: 'user.total_count',
                        },
                        {
                            title: 'Total Registered Users (B2C)',
                            currency: 'order.currency',
                            key: 'user.total_count_b2c',
                        },
                        {
                            title: 'Total Registered Users (B2B)',
                            currency: 'order.currency',
                            key: 'user.total_count_b2b',
                        },
                    ],
                },
                {
                    title: 'Total Active Customers',
                    country: true,
                    data: [
                        {
                            title: 'Total Active Customers',
                            currency: 'order.currency',
                            key: 'user.total_active_count',
                        },
                        {
                            title: 'Total Active Customers (B2C)',
                            currency: 'order.currency',
                            key: 'user.total_active_count_b2c',
                        },
                        {
                            title: 'Total Active Customers (B2B)',
                            currency: 'order.currency',
                            key: 'user.total_active_count_b2b',
                        },
                    ],
                },
            ]}
        />
    );
};

export default SalesAnalytics;
