import React from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';

const ShowCategoryConfigs = props => {
    const { children, value, index, categoryConfigs, ...other } = props;

    const navigateToEdit = (id: string) => {
        window.location.href = `/#/configurations/${id}/edit`;
    };

    const navigateToHistory = (id: string) => {
        window.location.href = `/#/configurations/${id}/history`;
    };

    console.log(categoryConfigs);

    return value === index ? (
        <Table>
            <TableBody style={{ backgroundColor: 'white' }}>
                {categoryConfigs.map(item => (
                    <TableRow
                        style={{
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <TableCell
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                gap: '.5rem',
                            }}
                        >
                            <label>{item.key}</label>
                            <Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    navigateToEdit(item.id);
                                }}
                            >
                                Edit
                            </Button>
                            {/*<Button
                                variant="contained"
                                size="small"
                                onClick={() => {
                                    navigateToHistory(item.id);
                                }}
                            >
                                History
                            </Button>*/}
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                maxWidth: '60rem',
                                whiteSpace: 'initial',
                                display: 'block',
                                wordWrap: 'break-word',
                            }}
                        >
                            {item.value}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ) : null;
};

export default ShowCategoryConfigs;
