import React from 'react';
import { connect } from 'react-redux';
import { ListButton, EditButton, RefreshButton, TopToolbar } from 'react-admin';
import DeleteButton from '../../../src/components/DeleteButton';
import { ActionProps, Charge, AppState } from '../../types';
import moment from 'moment';

const ChargeShowActions: React.FC<ActionProps<Charge>> = ({
    basePath,
    data,
    resource,
    invoice,
}) => {
    return (
        <TopToolbar>
            {data &&
                data.invoiceId &&
                invoice &&
                invoice.date &&
                moment(invoice.date)
                    .endOf('day')
                    .isSameOrAfter(moment().endOf('day')) && (
                    <DeleteButton
                        basePath={basePath}
                        record={data}
                        resource={resource}
                        undoable={false}
                    />
                )}
            {data &&
                data.invoiceId &&
                invoice &&
                invoice.date &&
                moment(invoice.date)
                    .endOf('day')
                    .isSameOrAfter(moment().endOf('day')) && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        record={data}
                    />
                )}
            <ListButton color="secondary" basePath={basePath} />
            <RefreshButton color="secondary" />
        </TopToolbar>
    );
};

const mapStateToProps = (state: AppState, props) => {
    const invoiceId = props.data && props.data.invoiceId;

    return {
        invoice:
            state.admin.resources.invoices &&
            state.admin.resources.invoices.data &&
            state.admin.resources.invoices.data[invoiceId],
    };
};
const connector = connect(mapStateToProps);

export default connector(ChargeShowActions);
