import React, { useState, useEffect } from 'react';
import {
    useNotify,
    useTranslate,
    useUpdate,
    useDataProvider,
} from 'react-admin';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    MenuItem,
    makeStyles,
} from '@material-ui/core';
import { CustomerPersona } from '../../constants';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            width: '31ch',
            marginLeft: '0px',
            paddingTop: '5px',
        },
    },
    button: {
        marginBottom: '20px',
    },
    selectPad: {
        paddingBottom: '10px',
    },
}));

type Props = {
    record: any;
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
};

const UserQuickEditButton: React.FC<Props> = ({
    record,
    isOpen,
    setIsOpen,
}) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const translate = useTranslate();

    const [user, setUser] = useState();
    const [loadinguser, setLoading] = useState(true);
    const [error, setError] = useState();
    const [persona, setPersona] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        dataProvider
            .getOne('users', { id: record.userId })
            .then(({ data }) => {
                setUser(data);
                setLoading(false);
                setPersona(data.persona);
                setName(data.name);
                setEmail(data.email);
                setPhone(data.phone);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const [customerUpdate, { loading }] = useUpdate(
        'users',
        record.userId,
        { name, email, phone, persona },
        record,
        {
            undoable: false,
            onSuccess: () => {
                notify(
                    'resources.leads.notification.update_customer_success',
                    'info'
                );
            },
            onFailure: () => {
                notify(
                    'resources.leads.notification.update_customer_error',
                    'warning'
                );
            },
        }
    );

    return (
        <>
            <Dialog
                fullWidth={true}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <DialogTitle>
                    {translate('resources.users.action.edit')}
                </DialogTitle>

                <DialogContent>
                    <TextField
                        label="Name"
                        className={classes.root}
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />
                    <TextField
                        label="Email"
                        className={classes.root}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <TextField
                        label="Phone"
                        className={classes.root}
                        value={phone}
                        onChange={event => setPhone(event.target.value)}
                    />
                    <TextField
                        select
                        className={classes.root}
                        label="Customer Persona"
                        value={persona}
                        onChange={event => setPersona(event.target.value)}
                    >
                        {Object.keys(CustomerPersona).map(option => (
                            <MenuItem
                                key={option}
                                className={classes.selectPad}
                                value={CustomerPersona[option]}
                            >
                                {CustomerPersona[option]}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => setIsOpen(false)}>
                        {translate('action.close')}
                    </Button>

                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={() => {
                            customerUpdate();
                            setIsOpen(false);
                        }}
                    >
                        {translate('action.update')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserQuickEditButton;
