import React from 'react';
import moment from 'moment';
import Color from 'color';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    ShowButton,
    Filter,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    DateInput,
} from 'react-admin';
import CustomSearchInput from '../components/CustomSearchInput';
import { OrderStatus, OrderStatusColor, OrderType, Source } from '../constants';
import ChipField from '../components/ChipField';
import BoxitChipField from '../components/BoxitChipField';
import CityField from '../components/CityField';
import BoxitPagination from '../components/BoxitPagination';
import UserReferenceField from '../components/UserReferenceField';
import { getOrderColor, getButtonColor } from '../orders/utils';
import { SelectArrayInput } from 'react-admin';

const rowStyle = record => {
    if (
        [
            OrderStatus.complete,
            OrderStatus.rejected,
            OrderStatus.cancelled,
            OrderStatus.lost,
        ].indexOf(record.status) !== -1
    ) {
        return {};
    }

    let leadColor = getOrderColor(record);

    return {
        backgroundColor: Color(leadColor).alpha(0.1),
    };
};
const LeadFilter = props => {
    return (
        <Filter variant="outlined" {...props}>
            <CustomSearchInput alwaysOn />

            <SelectInput
                source="type"
                choices={Object.keys(OrderType).map(c => ({
                    id: c,
                    name: c,
                }))}
            />

            <ReferenceInput
                source="assigneeId"
                reference="users"
                resource="users"
                label="Assignee"
                filter={{ 'roles[$contains]': ['sales-staff'] }}
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="partnerId"
                reference="partners"
                resource="partners"
                label="Partner"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="countryId"
                reference="country"
                resource="country"
                label="Country"
            >
                <SelectInput />
            </ReferenceInput>

            <ReferenceInput
                source="cityId"
                reference="city"
                resource="city"
                label="City"
            >
                <SelectInput />
            </ReferenceInput>

            <SelectArrayInput
                style={{ minWidth: '10rem' }}
                source="status"
                choices={Object.keys(OrderStatus).map(c => ({
                    id: c,
                    name: c,
                }))}
            />

            <DateInput
                source="date[$gte]"
                label="Date From"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />
            <DateInput
                source="date[$lte]"
                label="Date To"
                parse={v => v && moment(v).format('YYYY-MM-DD')}
            />

            <DateInput
                source="quoteSentAt[$gte]"
                label="Quote Sent From"
                parse={v => v && moment(v).startOf('day').format()}
            />
            <DateInput
                source="quoteSentAt[$lte]"
                label="Quote Sent To"
                parse={v => v && moment(v).endOf('day').format()}
            />

            <DateInput
                source="createdAt[$gte]"
                label="Created From"
                parse={v => v && moment(v).startOf('day').format()}
            />
            <DateInput
                source="createdAt[$lte]"
                label="Created To"
                parse={v => v && moment(v).endOf('day').format()}
            />
            <SelectInput
                source="source"
                choices={Object.keys(Source).map(c => ({
                    id: c,
                    name: Source[c],
                }))}
            />
        </Filter>
    );
};

const LeadList = props => {
    return (
        <List
            title="Leads"
            bulkActionButtons={false}
            filters={<LeadFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<BoxitPagination />}
            perPage={10}
            {...props}
        >
            <Datagrid rowStyle={rowStyle}>
                <TextField source="serial" sortable={false} />
                <TextField source="id" />
                <DateField
                    locales="en-IN"
                    source="createdAt"
                    label="Created"
                    showTime
                />
                <ReferenceField
                    label="Partner"
                    source="partnerId"
                    reference="partners"
                    link="show"
                >
                    <TextField source="name" />
                </ReferenceField>
                <UserReferenceField source="assigneeId" label="Assignee" />
                <UserReferenceField label="Customer" />
                <TextField source="user.phone" label="Phone" />

                <CityField />
                <ChipField source="type" />
                <ChipField source="storageType" />
                <DateField locales="en-IN" source="date" label="Date" />
                <DateField
                    locales="en-IN"
                    source="quoteSentAt"
                    label="Quote Sent"
                />
                <DateField
                    locales="en-IN"
                    source="followUpAt"
                    label="Follow Up"
                />
                <BoxitChipField record source="status" list="order" />
                <ChipField source="source" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};

LeadList.defaultProps = {
    resource: 'leads',
    location: { search: '' },
};

export default LeadList;
