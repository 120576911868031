import React from 'react';
import _ from 'lodash';
import capitalize from 'capitalize';
import { connect, ConnectedProps } from 'react-redux';
import { Form } from 'react-final-form';
import { ReferenceArrayInput, CheckboxGroupInput } from 'react-admin';
import { orderCreateInvoice as orderCreateInvoiceAction } from '../actions/orderActions';
import { moneyString } from '../../core/utils/money';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import EstimateTable from './EstimateTable';
import { Order, Service } from '../../types';
import humanize from '../../util/humanize';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    open: boolean;
    record: Order;
    onClose: () => void;
    services: Service[];
};

class OrderCreateInvoiceForm extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.createInvoice = this.createInvoice.bind(this);
    }

    componentDidMount() {}

    createInvoice(values) {
        const { orderCreateInvoice, record } = this.props;
        const { serviceIds } = values;

        orderCreateInvoice(record.id, { serviceIds });
        this.props.onClose();
    }

    render() {
        const { record, services, open = false } = this.props;
        const order = record;
        const isPartnerOrder = order.partnerId && order.partnerId !== 1;
        const chargableServices = (order.services || [])
            .filter(
                i =>
                    ['2020OFFER', 'JUNE2021'].indexOf(record.couponId) === -1 ||
                    !(
                        ((i.servicetype.name === 'Pickup' ||
                            i.servicetype.name === 'Pickup and Packing') &&
                            record.months >= 3) ||
                        (i.servicetype.name === 'Delivery' &&
                            record.months >= 6)
                    )
            )
            .map(i => i.id);

        let storageChargeDescription: string;
        if (
            ['moving', 'packing', 'shipping', 'other_services'].indexOf(
                order.type
            ) !== -1
        ) {
            storageChargeDescription = `${capitalize(
                humanize(order.type)
            )} charges - ${moneyString(order.amount, order.currency)}`;
        } else if (order.months === 1) {
            storageChargeDescription = `Storage rental for 1 month - ${moneyString(
                order.rent,
                order.currency
            )}`;
        } else {
            storageChargeDescription = `Storage rental for ${
                order.months
            } months @${moneyString(
                order.rent,
                order.currency,
                true
            )}/month - ${moneyString(
                order.rent * order.months,
                order.currency
            )}`;
        }

        return (
            <Dialog
                open={!!open}
                onClose={() => {
                    this.props.onClose();
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {isPartnerOrder ? 'Create Charges' : 'Create Invoice'}
                </DialogTitle>

                <DialogContent>
                    <Form
                        onSubmit={this.createInvoice}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            values,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Main Charges{' '}
                                        {isPartnerOrder &&
                                            `@ ${
                                                100 -
                                                order.partnerStorageCommissionPercent
                                            }%`}
                                    </FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={true}
                                                    value="storage"
                                                />
                                            }
                                            label={storageChargeDescription}
                                        />
                                    </FormGroup>
                                </FormControl>
                                {chargableServices.length > 0 && (
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            <ReferenceArrayInput
                                                record={record}
                                                label="Service Charges"
                                                source="serviceIds"
                                                reference="services"
                                                resource="services"
                                                sort={{
                                                    field: 'id',
                                                    order: 'ASC',
                                                }}
                                                filter={{
                                                    userId: record.userId,
                                                    orderId: record.id,
                                                    id: {
                                                        $in: chargableServices,
                                                    },
                                                }}
                                                allowEmpty
                                            >
                                                <CheckboxGroupInput
                                                    label="Service Charges"
                                                    source="serviceIds"
                                                    row={false}
                                                    optionText={rec =>
                                                        `${
                                                            rec.servicetype.name
                                                        } - ${moneyString(
                                                            rec.amount,
                                                            rec.currency
                                                        )}`
                                                    }
                                                />
                                            </ReferenceArrayInput>
                                        </FormGroup>
                                    </FormControl>
                                )}
                                <hr />
                                <EstimateTable
                                    order={order}
                                    services={services}
                                    values={values}
                                />
                                <hr />
                                <Button onClick={() => this.props.onClose()}>
                                    Cancel
                                </Button>
                                &nbsp; &nbsp; &nbsp;
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    disabled={submitting}
                                >
                                    Create
                                </Button>
                            </form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        );
    }
}

const mapState = state => {
    return {
        services:
            state.admin.resources.services &&
            state.admin.resources.services.data,
    };
};

const mapDispatch = {
    orderCreateInvoice: orderCreateInvoiceAction,
};

const connector = connect(mapState, mapDispatch);

export default connector(OrderCreateInvoiceForm);
