import React from 'react';
import { Tab, TabbedShowLayout } from 'react-admin';
import Charges from '../../components/Charges';
import StorageRecords from '../../components/StorageRecords';
import { History } from '../../revisions';
import { Comments } from '../../comments';
import AddStoragePhoto from './AddStoragePhoto';
import StorageSummary from './StorageSummary';
import { ShowProps, Storage } from '../../types';
import { StorageRecordStatus } from '../../constants';
import PhotoGallery from '../../components/PhotoGallery';

const sanitizeRestProps: (any) => any = ({
    hasCreate,
    hasEdit,
    hasList,
    hasShow,
    ...rest
}) => rest;

export const StorageTabs: React.FC<ShowProps<Storage>> = ({
    permissions = [],
    ...props
}) => {
    const { record } = props;

    return (
        <TabbedShowLayout {...sanitizeRestProps(props)}>
            <Tab label="summary">
                <StorageSummary />
            </Tab>
            {permissions.indexOf('staff') !== -1 && (
                <Tab label="Charges">
                    <Charges
                        {...props}
                        filter={{ storageId: props.record.id }}
                    />
                </Tab>
            )}
            <Tab label="Photos">
                <PhotoGallery
                    {...props}
                    target="storageId"
                    reference="photos"
                />
                <AddStoragePhoto record={props.record} />
            </Tab>
            {permissions.indexOf('staff') !== -1 && (
                <Tab label="Locations">
                    <StorageRecords
                        {...props}
                        filter={{
                            status: StorageRecordStatus.in,
                            storageId: props.record.id,
                        }}
                    />
                </Tab>
            )}
            {permissions.indexOf('staff') !== -1 && (
                <Tab label="Records">
                    <StorageRecords
                        {...props}
                        filter={{ storageId: props.record.id }}
                    />
                </Tab>
            )}
            <Tab label="Comments">
                <Comments
                    {...props}
                    model="storage"
                    documentId={record.id}
                    userId={record.userId}
                />
            </Tab>
            <Tab label="History">
                <History {...props} model="storage" documentId={record.id} />
            </Tab>
        </TabbedShowLayout>
    );
};
