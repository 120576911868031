import React, { useState } from 'react';

import {
    Edit,
    TabbedForm,
    FormTab,
    SimpleFormIterator,
    NumberInput,
    ArrayInput,
    TextInput,
    useNotify,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
import AceCodeInput from './components/AceCodeInput';
import { BooleanInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import settings from '../settings';
import feathersClient from '../rest/feathersClient';
import DropDownList from '../components/dropdownlist';
import MappingInput from './components/MappingInput';
import { Toolbar } from 'react-admin';
import { SaveButton } from 'react-admin';

const optionText = category => `${category.name} (${category.id})`;

const TemplateEditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const EmailTemplateEdit = props => {
    const notify = useNotify();
    const transform = data => {
        return {
            ...data,
            categories: data.categories
                ? data.categories.map(category => category.id)
                : null,
        };
    };

    const validate = values => {
        const errors: any = {};

        if (!values.subject) {
            errors.subject = ['Subject is required'];
        }

        if (!values.description) {
            errors.description = ['Description is required'];
        }

        if (!values.templateName) {
            errors.templateName = ['Template name is required'];
        }

        if (!values.body) {
            errors.body = ['Body is required'];
        }

        if (!values.toEmails || values.toEmails.length < 1) {
            errors.toEmails = ['at least one email is required'];
        }

        if (!values.fromEmail || values.fromEmail.length < 1) {
            errors.fromEmail = ['at least one email is required'];
        }

        return errors;
    };

    const refresh = id => {
        console.log(notify);
        const emailTemplate = feathersClient.service('communication-templates');
        emailTemplate
            .patch(id, {
                command: 'UPDATE_WATI_VARIABLES',
            })
            .then(res => {
                notify(`Refreshed`, 'success');
                window.location.reload();
            })
            .catch(err => {
                notify(err.message, 'error');
            });
    };

    return (
        <Edit {...props} transform={transform}>
            <TabbedForm
                toolbar={<TemplateEditToolbar />}
                variant="outlined"
                validate={validate}
                redirect="show"
            >
                <FormTab label="Details">
                    <TextInput label="Template name" source="templateName" />
                    <TextInput
                        label="Description"
                        source="description"
                        multiline
                    />
                </FormTab>
                <FormTab label="Fields">
                    <TextInput label="Subject" source="subject" />
                    <TextInput label="From Email" source="fromEmail" />
                    <ArrayInput label="To Emails" source="toEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="CC Emails" source="ccEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="BCC Emails" source="bccEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="Categories" source="categories">
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="Category"
                                source="id"
                                reference="template-categories"
                                resource="template-categories"
                            >
                                <SelectInput optionText={optionText} />
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                    {/*<SelectArrayInput
                        label="Roles"
                        source="roles"
                        choices={getAllRoles().map(i => ({ id: i, name: i }))}
                                />*/}
                </FormTab>
                <FormTab label="Body HTML">
                    <AceCodeInput source="body" />
                </FormTab>
                <FormTab label="Whatsapp">
                    <BooleanInput
                        label="Is WATI enabled?"
                        source="isWatiEnabled"
                    />
                    <TextInput
                        label="WATI Template name"
                        source="watiTempName"
                    />
                    <FunctionField
                        label="Refresh"
                        render={record => (
                            <button
                                style={{
                                    backgroundColor: '#1791FF',
                                    width: '7.5rem',
                                    color: 'white',
                                    height: '2.4rem',
                                    borderRadius: '2px',
                                    boxShadow:
                                        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                                }}
                                onClick={() => refresh(record.id)}
                            >
                                Refresh
                            </button>
                        )}
                    />
                    <MappingInput source="watiTemplateVariablesMapping" />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default EmailTemplateEdit;
