import React from 'react';
import s from './PhotoBox.module.css';
import ImageContainer from './imgContainer/imgContainer';
import ReactPlayer from 'react-player';

type State = {
    currentPhotoIndex: number;
};

class PhotoBox extends React.PureComponent<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentPhotoIndex: this.props.initialPhotoIndex,
        };
    }

    componentDidMount(): void {
        console.log(this.props.photos);
        document.onkeydown = (evt: any) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.props.cancel();
            } else if (evt.keyCode == 37) {
                if (this.state.currentPhotoIndex !== 0) {
                    this.setState({
                        currentPhotoIndex: this.state.currentPhotoIndex - 1,
                    });
                }
            } else if (evt.keyCode == 39) {
                if (
                    this.state.currentPhotoIndex !==
                    this.props.photos.length - 1
                ) {
                    this.setState({
                        currentPhotoIndex: this.state.currentPhotoIndex + 1,
                    });
                }
            }
        };

        console.log({
            index: this.state.currentPhotoIndex,
        });
    }

    render() {
        return (
            <div className={s.photobox}>
                <button className={s.cancelBtn} onClick={this.props.cancel}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.828"
                        height="17.828"
                    >
                        <path d="m2.828 17.828 6.086-6.086L15 17.828 17.828 15l-6.086-6.086 6.086-6.086L15 0 8.914 6.086 2.828 0 0 2.828l6.085 6.086L0 15l2.828 2.828z" />
                    </svg>
                </button>
                {this.state.currentPhotoIndex !== 0 ? (
                    <button
                        className={s.navBtnprev}
                        onClick={() =>
                            this.setState({
                                currentPhotoIndex:
                                    this.state.currentPhotoIndex - 1,
                            })
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                        >
                            <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
                        </svg>
                    </button>
                ) : null}
                {this.props.photos[this.state.currentPhotoIndex].mimetype ===
                'video' ? (
                    <ReactPlayer
                        url={
                            this.props.photos[this.state.currentPhotoIndex]
                                .photo
                        }
                        width={'40rem'}
                        height={'40rem'}
                        controls={true}
                    />
                ) : (
                    <ImageContainer
                        photo={
                            this.props.photos[this.state.currentPhotoIndex]
                                .photo
                        }
                    />
                )}
                {this.state.currentPhotoIndex !==
                this.props.photos.length - 1 ? (
                    <button
                        className={s.navBtnnext}
                        onClick={() =>
                            this.setState({
                                currentPhotoIndex:
                                    this.state.currentPhotoIndex + 1,
                            })
                        }
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                        >
                            <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                        </svg>
                    </button>
                ) : null}
            </div>
        );
    }
}

export default PhotoBox;
