import React from 'react';
import moment from 'moment';
import {
    AutocompleteInput,
    ChipField,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    Filter,
    List,
    Pagination,
    ReferenceInput,
    ShowButton,
    TextField,
    TextInput,
} from 'react-admin';
import UserReferenceField from '../components/UserReferenceField';
import CustomSearchInput from '../components/CustomSearchInput';
import BoxitChipField from '../components/BoxitChipField';
import { SelectInput } from 'react-admin';
import { FunctionField } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
const SurveyFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={[
                {
                    id: 'new',
                    name: 'new',
                },
                {
                    id: 'sentToCustomer',
                    name: 'sentToCustomer',
                },
                {
                    id: 'completed',
                    name: 'completed',
                },
                {
                    id: 'closed',
                    name: 'closed',
                },
            ]}
        />
        <SelectInput
            source="type"
            choices={[
                {
                    id: 'leadSurvey',
                    name: 'leadSurvey',
                },
                {
                    id: 'customerSurvey',
                    name: 'customerSurvey',
                },
            ]}
        />
    </Filter>
);

const ListPagination = props => (
    <Pagination rowsPerPageOptions={[10, 20, 50, 100]} {...props} />
);

const SurveyList = props => (
    <List
        title="Surveys"
        filters={<SurveyFilter />}
        pagination={<ListPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <FunctionField
                label="Order"
                render={record => {
                    return (
                        <div
                            style={{
                                width: '8rem',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                            }}
                        >
                            <button
                                style={{
                                    cursor: 'pointer',
                                    color: '#1890ff',
                                    outline: 'none',
                                }}
                                onClick={() => {
                                    window.location.href = `/#/orders/${record.orderId}/show`;
                                }}
                            >
                                {record.orderId}
                            </button>
                        </div>
                    );
                }}
            />
            <UserReferenceField source="userId" label="User" />
            {/*<DateField locales="en-IN" source="submittedAt" showTime />*/}
            <TextField source="type" />
            <TextField source="status" />
            <ShowButton color="secondary" />
        </Datagrid>
    </List>
);

SurveyList.defaultProps = {
    resource: 'surveys',
    location: { search: '' },
};

export default SurveyList;
