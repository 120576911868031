import { CREATE, DELETE } from 'react-admin';

export const STORAGE_ADD_PHOTO = 'STORAGE_ADD_PHOTO';
export const STORAGE_ADD_PHOTO_LOADING = 'STORAGE_ADD_PHOTO_LOADING';
export const STORAGE_ADD_PHOTO_FAILURE = 'STORAGE_ADD_PHOTO_FAILURE';
export const STORAGE_ADD_PHOTO_SUCCESS = 'STORAGE_ADD_PHOTO_SUCCESS';

export const addStoragePhoto = (userId, storageId, file) => ({
    type: STORAGE_ADD_PHOTO,
    payload: { data: { userId, storageId, file } },
    meta: { resource: 'photos', fetch: CREATE, cancelPrevious: false },
});

export const STORAGE_DELETE_PHOTO = 'STORAGE_DELETE_PHOTO';
export const STORAGE_DELETE_PHOTO_LOADING = 'STORAGE_DELETE_PHOTO_LOADING';
export const STORAGE_DELETE_PHOTO_FAILURE = 'STORAGE_DELETE_PHOTO_FAILURE';
export const STORAGE_DELETE_PHOTO_SUCCESS = 'STORAGE_DELETE_PHOTO_SUCCESS';

const deleteStoragePhoto = id => ({
    type: STORAGE_DELETE_PHOTO,
    payload: { data: { id } },
    meta: { resource: 'photos', fetch: DELETE, cancelPrevious: false },
});
