import React, { useState, useEffect, useCallback, FC, Fragment } from 'react';
import moment from 'moment';
import { useVersion, useDataProvider, useQuery, Title } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import LeadIcon from '../icons/Send';
import OrderIcon from '../icons/ShoppingCart';
import StatWidget from './components/StatWidget';
import { Lead, Order, User } from '../types';
import { DollarOutlined } from '@ant-design/icons';
import { CurrencyByCountryId } from '../constants';
import GraphWidget from '../analytics/components/GraphWidget';
import Percent from './components/Percent';
import PresetDateRangePicker from '../components/date/PresetDateRangePicker';

// Function will add currency to price based on country Id
function formatMoney(amount: number, countryId: number): string {
    var currencyCode = CurrencyByCountryId[countryId];
    return amount
        ? amount.toLocaleString(undefined, {
              style: 'currency',
              currency: currencyCode,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
          })
        : '';
}

interface State {
    numLeads: number;
    numUnattendedLeads: number;
    numAttendedLeads: number;
    numConfirmedLeads: number;
    numConfirmedOrders: number;
    nbPendingLeads: number;
    nbPendingOrders: number;
    pendingLeads: Lead[];
    pendingOrders: Order[];
    pendingLeadsUsers: User[];
    pendingOrdersUsers: User[];
    confirmedLeadRevenue: string;
    confirmedOrderRevenue: string;
    invoicedOrderRevenue: string;
    totalInvoicedRevenue: string;
    totalVolume: string;
    leadData: any[];
    items: any[];
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1 /*marginRight: '1em'*/ },
    rightCol: { flex: 1 /*marginLeft: '1em'*/ },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    icon: { width: '2em', height: '2em' },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

const Dashboard: FC = () => {
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const [countryId, setCountryId] = useState(1);
    const handleStartDateChange = event => setStartDate(event.target.value);
    const handleEndDateChange = event => setEndDate(event.target.value);
    const handleCountryChange = event => setCountryId(event.target.value);
    const [plotData, setPlotData] = useState([]);
    const [state, setState] = useState<State>({} as State);
    const version = useVersion();
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const fetchOrderStats = useCallback(async () => {
        const { data } = await dataProvider.getList('monthly-stats', {
            filter: {
                countryId,
                startDate: startDate.format(),
                endDate: endDate.format(),
            },
        });
        const stats = data && data.length && data[0];

        const numConfirmedOrders = stats['order.num_confirmed_orders'];
        const confirmedOrderRevenue = stats['order.revenue_confirmed_orders'];
        const numLeads = stats['lead.num_leads'];
        const numUnattendedLeads = stats['lead.num_leads_new'];
        const numAttendedLeads = numLeads - numUnattendedLeads;
        const numConfirmedLeads =
            stats['lead.num_leads_confirmed'] +
            stats['lead.num_leads_completed'];
        const confirmedLeadRevenue = stats['lead.total_amount'];
        const invoicedOrderRevenue = stats['invoice.new_revenue'];
        const totalInvoicedRevenue = stats['invoice.total_revenue'];
        const totalVolume = `${Math.round(stats.totalVolume)} CBM`;

        setState(state => ({
            ...state,
            numConfirmedOrders,
            confirmedOrderRevenue: formatMoney(
                confirmedOrderRevenue,
                countryId
            ),
            numLeads,
            numUnattendedLeads,
            numAttendedLeads,
            numConfirmedLeads,
            confirmedLeadRevenue: formatMoney(confirmedLeadRevenue, countryId),
            invoicedOrderRevenue: formatMoney(invoicedOrderRevenue, countryId),
            totalInvoicedRevenue: formatMoney(totalInvoicedRevenue, countryId),
            totalVolume,
            leadData: stats.leadData,
        }));
        setPlotData(stats.leadData);
    }, [dataProvider, startDate, endDate, countryId]);

    useEffect(() => {
        fetchOrderStats();
    }, [version, startDate, endDate, countryId]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        numConfirmedLeads,
        numConfirmedOrders,
        // numCompletedOrders,
        numLeads,
        numUnattendedLeads,
        numAttendedLeads,
        nbPendingLeads,
        nbPendingOrders,
        pendingLeads,
        pendingOrders,
        pendingLeadsUsers,
        pendingOrdersUsers,
        confirmedLeadRevenue,
        confirmedOrderRevenue,
        invoicedOrderRevenue,
        totalInvoicedRevenue,
        totalVolume,
        leadData,
    } = state;

    return (
        <Fragment>
            <Title title="Boxit OMS" />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <form className={classes.container} noValidate>
                            <TextField
                                select
                                id="countryId"
                                label="Country"
                                size="small"
                                variant="outlined"
                                value={countryId}
                                onChange={handleCountryChange}
                                className={classes.textField}
                            >
                                <MenuItem key={1} value={1}>
                                    UAE
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    Kuwait
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    KSA
                                </MenuItem>
                            </TextField>
                            <PresetDateRangePicker
                                startDate={startDate}
                                endDate={endDate}
                                startDateId={'startDate'}
                                endDateId={'endDate'}
                                onDatesChange={({ startDate, endDate }) => {
                                    if (startDate) {
                                        setStartDate(
                                            moment(startDate).startOf('day')
                                        );
                                    }
                                    if (endDate) {
                                        setEndDate(
                                            moment(endDate).endOf('day')
                                        );
                                    }
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Total Leads"
                            value={numLeads}
                            icon={<LeadIcon className={classes.icon} />}
                            subtext="Created"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Attended Leads"
                            value={numAttendedLeads}
                            icon={<LeadIcon className={classes.icon} />}
                            subtext={
                                <Percent
                                    value={numAttendedLeads}
                                    total={numLeads}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Confirmed Leads"
                            value={numConfirmedLeads}
                            icon={<OrderIcon className={classes.icon} />}
                            subtext={
                                <Percent
                                    value={numConfirmedLeads}
                                    total={numAttendedLeads}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Total Orders"
                            value={numConfirmedOrders}
                            icon={<OrderIcon className={classes.icon} />}
                            subtext="Including past leads"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Total Revenue"
                            value={totalInvoicedRevenue}
                            icon={<DollarOutlined className={classes.icon} />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="New Order Revenue"
                            value={invoicedOrderRevenue}
                            icon={<DollarOutlined className={classes.icon} />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Confirmed Sales"
                            value={confirmedLeadRevenue}
                            icon={<DollarOutlined className={classes.icon} />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <StatWidget
                            title="Total Sales"
                            value={confirmedOrderRevenue}
                            icon={<DollarOutlined className={classes.icon} />}
                            subtext="Including past sales"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <GraphWidget
                            plots={[
                                'lead.num_leads',
                                'lead.num_leads_confirmed',
                            ]}
                            plotData={plotData}
                            items={[
                                {
                                    title: 'Leads',
                                    data: [
                                        {
                                            title: 'Total Leads',
                                            key: 'lead.num_leads',
                                        },
                                        {
                                            title: 'Leads Confirmed',
                                            key: 'lead.num_leads_confirmed',
                                        },
                                    ],
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
};

export default Dashboard;
