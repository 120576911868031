import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Filter,
    TextInput,
    SelectInput,
    ReferenceInput,
} from 'react-admin';

import TagsField from '../components/TagsField';
import CustomSearchInput from '../components/CustomSearchInput';

const ServiceTypeFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
    </Filter>
);

const ServiceTypeList = props => (
    <List
        title="ServiceTypess"
        bulkActionButtons={false}
        filters={<ServiceTypeFilter />}
        sort={{ field: 'id', order: 'ASC' }}
        {...props}
    >
        <Datagrid>
            <TextField source="serial" sortable={false} />
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="description" />
            <EditButton color="secondary" style={{ padding: 0 }} />
        </Datagrid>
    </List>
);

ServiceTypeList.defaultProps = {
    resource: 'service-types',
    location: { search: '' },
};

export default ServiceTypeList;
