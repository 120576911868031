import React, { useState } from 'react';
import _ from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect, ConnectedProps } from 'react-redux';
import {
    Confirm,
    EditButton,
    ListButton,
    crudGetList as crudGetListAction,
    crudGetManyReference as crudGetManyReferenceAction,
    showNotification as showNotificationAction,
    TopToolbar,
    useQueryWithStore,
    useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import SweetAlert from 'sweetalert-react';
import EmailIcon from '@material-ui/icons/MailOutline';
import SyncIcon from '@material-ui/icons/Sync';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import UpdateIcon from '@material-ui/icons/Update';
import { taskCreate as taskCreateAction } from '../../tasks/actions/taskActions';
import {
    orderConfirm as orderConfirmAction,
    orderReject as orderRejectAction,
    orderOnHold as orderOnHoldAction,
    orderComplete as orderCompleteAction,
    orderUpdateItems as orderUpdateItemsAction,
    orderCreateInvoice as orderCreateInvoiceAction,
    orderSync as orderSyncAction,
    orderSend as orderSendAction,
} from '../actions/orderActions';
import settings from '../../settings';
import {
    OrderType,
    OrderStatus,
    StorageStatus,
    StorageRecordStatus,
} from '../../constants';
import CancelButton from './CancelButton';
import UpdateCurrentSizeButton from './UpdateCurrentSizeButton';
import UpdateActualSizeButton from './UpdateActualSizeButton';
import OrderCreateInvoice from './OrderCreateInvoice';
import { moneyString } from '../../core/utils/money';
import { getItemListFromStorages, diffItems } from '../../core/utils/order';
import { Button } from '@material-ui/core';
import {
    ActionProps,
    AppState,
    Charge,
    Order,
    Storage,
    Invoice,
} from '../../types';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

type Styles = {
    button: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ActionProps;

type State = {
    showSendOrderThankyouDialog: boolean;
    showSendOrderConfirmationDialog: boolean;
    showCreateInvoiceForm: boolean;
    showOrderMissingSizeDialog: boolean;
    showOrderChangedDialog: boolean;
    showCompleteOrderDialog: boolean;
    showReviseRentDialog: boolean;
    showSyncConfirmDialog: boolean;
    showUpdateQuoteDialog: boolean;
};

export function OrderShowActions(props: Props): JSX.Element | null {
    const {
        basePath,
        data,
        hasEdit,
        taskCreate,
        orderConfirm,
        orderReject,
        orderOnHold,
        orderComplete,
        orderUpdateItems,
        orderCreateInvoice,
        orderSync,
        permissions = [],
        showNotification,
        orderSend,
    } = props;
    const order = data;

    const [
        showSendOrderThankyouDialog,
        setShowSendOrderThankyouDialog,
    ] = useState(false);
    const [
        showSendOrderConfirmationDialog,
        setShowSendOrderConfirmationDialog,
    ] = useState(false);
    const [showCreateInvoiceForm, setShowCreateInvoiceForm] = useState(false);
    const [
        showOrderMissingSizeDialog,
        setShowOrderMissingSizeDialog,
    ] = useState(false);
    const [showOrderChangedDialog, setShowOrderChangedDialog] = useState(false);
    const [showCompleteOrderDialog, setShowCompleteOrderDialog] = useState(
        false
    );
    const [showReviseRentDialog, setShowReviseRentDialog] = useState(false);
    const [showSyncConfirmDialog, setShowSyncConfirmDialog] = useState(false);
    const [showUpdateQuoteDialog, setShowUpdateQuoteDialog] = useState(false);
    const [showSendQuoteDialog, setShowSendQuoteDialog] = useState(false);

    const notify = useNotify();

    const {
        loaded: storageRecordsLoaded,
        data: storageRecordsData,
    } = useQueryWithStore({
        type: 'getList',
        resource: 'storage-records',
        payload: {
            filter: {
                status: StorageRecordStatus.in,
                orderId: order && order.id,
            },
        },
    });

    const { loaded: invoicesLoaded, data: invoicesData } = useQueryWithStore({
        type: 'getList',
        resource: 'invoices',
        payload: { filter: { orderId: order && order.id } },
    });

    const { loaded: chargesLoaded, data: chargesData } = useQueryWithStore({
        type: 'getList',
        resource: 'charges',
        payload: { filter: { orderId: order && order.id } },
    });

    const invoices = invoicesLoaded ? invoicesData : [];
    const charges = chargesLoaded ? chargesData : [];
    const storages = storageRecordsLoaded
        ? storageRecordsData
              .map(i => Array(i.qty).fill(i.storage))
              .flat()
              .filter(i => i)
        : [];

    const isPartnerOrder = order && order.partnerId && order.partnerId !== 1;
    const isNew =
        order &&
        (order.status === OrderStatus.new ||
            order.status === OrderStatus.renew);
    const isQuoteSent = data && data.status === OrderStatus.quote_sent;
    const isQuoteAccepted = data && data.status === OrderStatus.quote_accepted;
    const isQuoteRejected = data && data.status === OrderStatus.quote_rejected;
    const isConfirmed = order && order.status === OrderStatus.confirmed;
    const isComplete = order && order.status === OrderStatus.complete;
    const isOnhold = order && order.status === OrderStatus.onhold;
    const isCancelled = order && order.status === OrderStatus.cancelled;
    const isRejected = order && order.status === OrderStatus.rejected;
    const isLost = data && data.status === OrderStatus.lost;

    const diff = order && diffItems(order.items, order.currentItems);
    const hasOrderChangedFromLead = !_.isEmpty(diff);

    if (permissions.indexOf('partner') !== -1) {
        return (
            <TopToolbar>
                {hasEdit && (
                    <EditButton
                        color="secondary"
                        basePath={basePath}
                        record={data}
                    />
                )}
            </TopToolbar>
        );
    }

    return order ? (
        <TopToolbar>
            <Button
                variant="outlined"
                size="small"
                color="secondary"
                href={data ? `${settings.apiUrl}/pdf/quote?id=${data.id}` : ''}
                target="_blank"
                startIcon={<FileIcon />}
            >
                View Quote
            </Button>

            {(isNew ||
                isQuoteSent ||
                isQuoteAccepted ||
                isQuoteRejected ||
                isConfirmed ||
                isLost ||
                isOnhold) && (
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<EmailIcon />}
                    onClick={() => {
                        if (!data?.assigneeId) {
                            showNotification(
                                'Order is not assigned to any one yet',
                                'error'
                            );
                        } else if (!data.cityId) {
                            showNotification('City is required', 'error');
                        } else {
                            setShowSendQuoteDialog(true);
                        }
                    }}
                >
                    Send Quote
                </Button>
            )}

            {(isComplete || isConfirmed) &&
                permissions.indexOf('admin') !== -1 && (
                    <UpdateActualSizeButton record={order} />
                )}
            {(isComplete || isConfirmed) &&
                permissions.indexOf('admin') !== -1 && (
                    <UpdateCurrentSizeButton record={order} />
                )}

            {!isComplete && hasOrderChangedFromLead && (
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<UpdateIcon />}
                    onClick={() => setShowUpdateQuoteDialog(true)}
                >
                    Update Item List
                </Button>
            )}

            {
                /*!isComplete &&*/ <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<SyncIcon />}
                    onClick={() => {
                        if (order.actualSize != null) {
                            setShowSyncConfirmDialog(true);
                        } else {
                            notify(
                                'Order is missing the actual size',
                                'warning'
                            );
                        }
                    }}
                >
                    Sync Storages
                </Button>
            }

            {isNew && (
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<EmailIcon />}
                    onClick={() => setShowSendOrderThankyouDialog(true)}
                >
                    Send Thank You
                </Button>
            )}

            {isConfirmed && (
                <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    startIcon={<EmailIcon />}
                    onClick={() => setShowSendOrderConfirmationDialog(true)}
                >
                    Send Confirmation
                </Button>
            )}

            {/*
            {(isNew || isConfirmed || isApproved) && (
            <Button color="secondary" onClick={() => orderOnHold(order.id)}>Onhold</Button>
            )}
          */}

            {/*
            {(isNew || isOnhold) && (
            <Button variant="contained" color="secondary" onClick={() => orderConfirm(order.id)}>Confirm</Button>
            )}
          */}

            {isNew && (
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => orderReject(order!.id, order)}
                >
                    Reject
                </Button>
            )}

            {!isComplete && !isNew && !isCancelled && !isRejected && (
                <CancelButton record={order} />
            )}

            {!isPartnerOrder &&
                (isConfirmed || isComplete) &&
                !invoices.length &&
                (order!.type === OrderType.moving ||
                    order!.type === OrderType.shipping ||
                    order!.type === OrderType.other_services ||
                    (order!.months > 0 &&
                        order!.amount > 0 &&
                        order!.size > 0)) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setShowCreateInvoiceForm(true)}
                    >
                        Create Invoice
                    </Button>
                )}

            {isPartnerOrder &&
                (isConfirmed || isComplete) &&
                !charges.length &&
                order!.months > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => setShowCreateInvoiceForm(true)}
                    >
                        Create Charges
                    </Button>
                )}

            {isConfirmed &&
                (invoices.length ||
                    (isPartnerOrder && !!charges.length) ||
                    order.amount == 0) && (
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        onClick={() => {
                            //if (hasOrderChangedFromLead) {
                            //setShowOrderChangedDialog(true);
                            if (order.size > 0) {
                                setShowCompleteOrderDialog(true);
                            } else {
                                setShowOrderMissingSizeDialog(true);
                            }
                        }}
                    >
                        Complete
                    </Button>
                )}

            {hasEdit && !isCancelled && !isRejected && (
                <EditButton
                    color="secondary"
                    basePath={basePath}
                    record={order}
                />
            )}

            <ListButton color="secondary" basePath={basePath} record={data} />

            <OrderCreateInvoice
                open={showCreateInvoiceForm}
                onClose={() => setShowCreateInvoiceForm(false)}
                record={order}
            />

            <SweetAlert
                show={showOrderMissingSizeDialog}
                title="Order is missing size!"
                type="error"
                text="Please set a size and try again."
                onConfirm={() => setShowOrderMissingSizeDialog(false)}
            />

            <SweetAlert
                show={showOrderChangedDialog}
                title="Order has changed!"
                type="error"
                text="Order items have changed from the quoted items. Please update the quote first."
                onConfirm={() => setShowOrderChangedDialog(false)}
            />

            <SweetAlert
                show={showUpdateQuoteDialog}
                title="Are you sure?"
                text={
                    'Do you want to update the item list as per the storages?'
                }
                showCancelButton
                onCancel={() => setShowUpdateQuoteDialog(false)}
                onEscapeKey={() => setShowUpdateQuoteDialog(false)}
                onOutsideClick={() => setShowUpdateQuoteDialog(false)}
                onConfirm={() => {
                    setShowUpdateQuoteDialog(false);
                    orderUpdateItems(order!.id, order, order.currentItems);
                }}
            />

            <Confirm
                isOpen={showCompleteOrderDialog}
                title="Are you sure?"
                content="Do you want to complete this order?"
                onClose={() => setShowCompleteOrderDialog(false)}
                onConfirm={() => {
                    setShowCompleteOrderDialog(false);
                    orderComplete(order!.id, order);
                }}
            />

            <SweetAlert
                show={showReviseRentDialog}
                title="Are you sure about the Rent?"
                html
                text={renderToStaticMarkup(
                    <p>
                        Order size: <strong>{order!.currentSize}</strong> CBM
                        <br />
                        Quote size: <strong>{order!.size}</strong> CBM
                        <br />
                        <br />
                        Order size has changed from the quote size.
                        <br />
                        Do you want to proceed with the old rent of
                        <strong>
                            {' '}
                            {moneyString(order!.rent, order!.currency, true)}
                        </strong>{' '}
                        ? <br />( @{' '}
                        {moneyString(
                            order!.rent / order!.currentSize,
                            order!.currency,
                            true
                        )}{' '}
                        / CBM / month )
                    </p>
                )}
                showCancelButton
                onCancel={() => setShowReviseRentDialog(false)}
                onEscapeKey={() => setShowReviseRentDialog(false)}
                onOutsideClick={() => setShowReviseRentDialog(false)}
                onConfirm={() => {
                    setShowReviseRentDialog(false);
                    setShowCreateInvoiceForm(true);
                }}
            />

            {isNew && (
                <SweetAlert
                    show={showSendOrderThankyouDialog}
                    title="Are you sure?"
                    text={
                        'Do you want to send an order thank you mail to customer?'
                    }
                    showCancelButton
                    onCancel={() => setShowSendOrderThankyouDialog(false)}
                    onEscapeKey={() => setShowSendOrderThankyouDialog(false)}
                    onOutsideClick={() => setShowSendOrderThankyouDialog(false)}
                    onConfirm={() => {
                        setShowSendOrderThankyouDialog(false);
                        taskCreate({
                            type: 'SEND_ORDER_THANKYOU_MAIL_TO_CUSTOMER',
                            data: { orderId: order!.id },
                        });
                    }}
                />
            )}

            {isConfirmed && (
                <SweetAlert
                    show={showSendOrderConfirmationDialog}
                    title="Are you sure?"
                    text={
                        'Do you want to send an order confirmation mail to customer?'
                    }
                    showCancelButton
                    onCancel={() => setShowSendOrderConfirmationDialog(false)}
                    onEscapeKey={() =>
                        setShowSendOrderConfirmationDialog(false)
                    }
                    onOutsideClick={() =>
                        setShowSendOrderConfirmationDialog(false)
                    }
                    onConfirm={() => {
                        setShowSendOrderConfirmationDialog(false);
                        taskCreate({
                            type: 'SEND_ORDER_CONFIRMED_MAIL_TO_CUSTOMER',
                            data: { orderId: order!.id },
                        });
                    }}
                />
            )}

            <SweetAlert
                show={showSyncConfirmDialog}
                title="Are you sure?"
                text={'Do you want to update storages as per the item list?'}
                showCancelButton
                onCancel={() => setShowSyncConfirmDialog(false)}
                onEscapeKey={() => setShowSyncConfirmDialog(false)}
                onOutsideClick={() => setShowSyncConfirmDialog(false)}
                onConfirm={() => {
                    setShowSyncConfirmDialog(false);
                    orderSync(data!.id, data);
                }}
            />

            {(isNew ||
                isQuoteSent ||
                isQuoteAccepted ||
                isQuoteRejected ||
                isConfirmed ||
                isLost ||
                isOnhold) && (
                <SweetAlert
                    show={showSendQuoteDialog}
                    title="Are you sure?"
                    text="Do you want to send this quote?"
                    showCancelButton
                    onCancel={() => setShowSendQuoteDialog(false)}
                    onEscapeKey={() => setShowSendQuoteDialog(false)}
                    onOutsideClick={() => setShowSendQuoteDialog(false)}
                    onConfirm={() => {
                        if (data?.id) {
                            setShowSendQuoteDialog(false);
                            setShowSendQuoteDialog(false);
                            orderSend(data.id, data);
                            taskCreate({
                                type: 'SEND_QUOTE_TO_CUSTOMER',
                                data: { leadId: data.id },
                            });
                        }
                    }}
                />
            )}
        </TopToolbar>
    ) : null;
}

const mapDispatch = {
    taskCreate: taskCreateAction,
    orderConfirm: orderConfirmAction,
    orderReject: orderRejectAction,
    orderOnHold: orderOnHoldAction,
    orderComplete: orderCompleteAction,
    orderUpdateItems: orderUpdateItemsAction,
    orderCreateInvoice: orderCreateInvoiceAction,
    crudGetList: crudGetListAction,
    crudGetManyReference: crudGetManyReferenceAction,
    orderSync: orderSyncAction,
    showNotification: showNotificationAction,
    orderSend: orderSendAction,
};

const connector = connect(null, mapDispatch);
export default connector(OrderShowActions);
