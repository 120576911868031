import { UPDATE, CREATE } from 'react-admin';
import { OrderStatus } from '../../constants';

export const LEAD_SEND = 'LEAD_SEND';
export const LEAD_SEND_LOADING = 'LEAD_SEND_LOADING';
export const LEAD_SEND_FAILURE = 'LEAD_SEND_FAILURE';
export const LEAD_SEND_SUCCESS = 'LEAD_SEND_SUCCESS';

export const leadSend = (id, data) => ({
    type: 'LEAD_SEND',
    payload: {
        id,
        data: {
            ...data,
            status: OrderStatus.quote_sent,
            quoteSentAt: new Date(),
            quoteVersionSent: data.quoteVersion,
        },
    },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_CONFIRM = 'LEAD_CONFIRM';
export const LEAD_CONFIRM_LOADING = 'LEAD_CONFIRM_LOADING';
export const LEAD_CONFIRM_FAILURE = 'LEAD_CONFIRM_FAILURE';
export const LEAD_CONFIRM_SUCCESS = 'LEAD_CONFIRM_SUCCESS';

export const leadConfirm = (id, data) => ({
    type: 'LEAD_CONFIRM',
    payload: {
        id,
        data: {
            ...data,
            status: OrderStatus.confirmed,
            confirmedAt: new Date(),
        },
    },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_LOST = 'LEAD_LOST';
export const LEAD_LOST_LOADING = 'LEAD_LOST_LOADING';
export const LEAD_LOST_FAILURE = 'LEAD_LOST_FAILURE';
export const LEAD_LOST_SUCCESS = 'LEAD_LOST_SUCCESS';

export const leadLost = (id, data) => ({
    type: 'LEAD_LOST',
    payload: { id, data: { ...data, status: OrderStatus.lost } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_REJECT = 'LEAD_REJECT';
export const LEAD_REJECT_LOADING = 'LEAD_REJECT_LOADING';
export const LEAD_REJECT_FAILURE = 'LEAD_REJECT_FAILURE';
export const LEAD_REJECT_SUCCESS = 'LEAD_REJECT_SUCCESS';

export const leadReject = (id, data) => ({
    type: 'LEAD_REJECT',
    payload: { id, data: { ...data, status: OrderStatus.rejected } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_ONHOLD = 'LEAD_ONHOLD';
export const LEAD_ONHOLD_LOADING = 'LEAD_ONHOLD_LOADING';
export const LEAD_ONHOLD_FAILURE = 'LEAD_ONHOLD_FAILURE';
export const LEAD_ONHOLD_SUCCESS = 'LEAD_ONHOLD_SUCCESS';

export const leadOnHold = (id, data) => ({
    type: 'LEAD_ONHOLD',
    payload: { id, data: { ...data, status: OrderStatus.onhold } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_UPDATE_STATUS = 'LEAD_UPDATE_STATUS';
export const LEAD_UPDATE_STATUS_LOADING = 'LEAD_UPDATE_STATUS_LOADING';
export const LEAD_UPDATE_STATUS_FAILURE = 'LEAD_UPDATE_STATUS_FAILURE';
export const LEAD_UPDATE_STATUS_SUCCESS = 'LEAD_UPDATE_STATUS_SUCCESS';

export const leadUpdateStatus = (id, data, status) => ({
    type: 'LEAD_UPDATE_STATUS',
    payload: { id, data: { ...data, status } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_FOLLOW_UP = 'LEAD_FOLLOW_UP';
export const LEAD_FOLLOW_UP_LOADING = 'LEAD_FOLLOW_UP_LOADING';
export const LEAD_FOLLOW_UP_FAILURE = 'LEAD_FOLLOW_UP_FAILURE';
export const LEAD_FOLLOW_UP_SUCCESS = 'LEAD_FOLLOW_UP_SUCCESS';

export const leadFollowUp = (id, data, followUpAt) => ({
    type: 'LEAD_FOLLOW_UP',
    payload: { id, data: { ...data, followUpAt } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_ASSIGN = 'LEAD_ASSIGN';
export const LEAD_ASSIGN_LOADING = 'LEAD_ASSIGN_LOADING';
export const LEAD_ASSIGN_FAILURE = 'LEAD_ASSIGN_FAILURE';
export const LEAD_ASSIGN_SUCCESS = 'LEAD_ASSIGN_SUCCESS';

export const leadAssign = (
    id: string | number,
    data: any,
    assigneeId: string | number
) => ({
    type: 'LEAD_ASSIGN',
    payload: { id, data: { ...data, assigneeId } },
    meta: { resource: 'leads', fetch: UPDATE, cancelPrevious: false },
});

export const LEAD_REJECT_QUOTE = 'LEAD_REJECT_QUOTE';
export const LEA_REJECT_QUOTE_LOADING = 'LEAD_REJECT_QUOTE_LOADING';
export const LEAD_REJECT_QUOTE_FAILURE = 'LEAD_REJECT_QUOTE_FAILURE';
export const LEAD_REJECT_QUOTE_SUCCESS = 'LEAD_REJECT_QUOTE_SUCCESS';

export const leadRejectQuote = (id: string | number) => ({
    type: 'LEAD_REJECT_QUOTE',
    payload: { id, data: { status: 'quote_rejected' } },
    meta: { resource: 'orders', fetch: UPDATE, cancelPrevious: false },
});
