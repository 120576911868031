import React from 'react';
import capitalize from 'capitalize';
import { Field } from 'react-final-form';
import { FormDataConsumer, NumberInput } from 'react-admin';
import { InputAdornment } from '@material-ui/core';

const MoneyInput = ({
    source,
    baseUnitMultiplier = 10000,
    label = '',
    ...props
}) => (
    <FormDataConsumer>
        {({ formData, ...rest }) => (
            <NumberInput
                variant="outlined"
                source={source}
                label={label || capitalize(source)}
                parse={v => v * baseUnitMultiplier}
                format={v =>
                    v >= 0
                        ? +(v / baseUnitMultiplier).toFixed(
                              formData.currency === 'KWD' ? 3 : 2
                          )
                        : ''
                }
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {formData.currency}
                        </InputAdornment>
                    ),
                }}
                {...props}
            />
        )}
    </FormDataConsumer>
);

export default MoneyInput;
