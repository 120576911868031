import React from 'react';
import PropTypes from 'prop-types';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import ReactHtmlParser from 'react-html-parser';

import settings from '../../settings';
import { add, format, parseISO } from 'date-fns';

const styles = {
    chip: {
        margin: 4,
    },
    wrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
};

export const CommunicationBody = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    {record.channel === 'email' && (
                        <TableRow>
                            <TableCell>
                                <label>Subject</label>
                            </TableCell>
                            <TableCell style={{ textAlign: 'right' }}>
                                {record.subject}
                            </TableCell>
                        </TableRow>
                    )}
                    <TableRow>
                        <TableCell style={{ textAlign: 'right' }}>
                            <div>{ReactHtmlParser(record.body)}</div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Attachments</label>
                        </TableCell>
                        <TableCell
                            style={{
                                textAlign: 'right',
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >
                                {record.attachmentTypesAndIds
                                    ? record.attachmentTypesAndIds.map(
                                          attachment => (
                                              <div
                                                  style={{
                                                      display: 'flex',
                                                      gap: '1rem',
                                                      marginBottom: '1rem',
                                                  }}
                                              >
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'estimate' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/quote?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'quotation' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/quote?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() ===
                                                  'requestnote' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${settings.apiUrl}/pdf/note?id=${attachment.attachmentId}`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                                  {attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'estimate' &&
                                                  attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'quotation' &&
                                                  attachment.attachmentType
                                                      .slice(0, -3)
                                                      .toLowerCase() !==
                                                      'requestnote' ? (
                                                      <a
                                                          style={{
                                                              color: '#1890ff',
                                                          }}
                                                          href={`${
                                                              settings.apiUrl
                                                          }/pdf/${attachment.attachmentType
                                                              .slice(0, -3)
                                                              .toLowerCase()}?id=${
                                                              attachment.attachmentId
                                                          }`}
                                                      >
                                                          {
                                                              attachment.attachmentId
                                                          }
                                                      </a>
                                                  ) : null}
                                              </div>
                                          )
                                      )
                                    : '-'}
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

CommunicationBody.propTypes = {
    record: PropTypes.object,
};
