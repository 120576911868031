import React from 'react';
import { Show } from 'react-admin';
import { EmailPlaceholderTabs } from './components/EmailPlaceholderTabs';

const EmailPlaceholderShow = props => (
    <Show {...props}>
        <EmailPlaceholderTabs />
    </Show>
);

export default EmailPlaceholderShow;
