import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { TASK_CREATE_SUCCESS, TASK_CREATE_FAILURE } from './taskActions';
import { ActionWithSideEffect } from '../../types';

export default function* taskSaga() {
    yield all([
        takeEvery(TASK_CREATE_SUCCESS, function* () {
            yield put(showNotification('resources.tasks.created_success'));
        }),
        takeEvery(TASK_CREATE_FAILURE, function* ({
            error,
        }: ActionWithSideEffect) {
            yield put(
                showNotification(
                    error || 'resources.tasks.created_error',
                    'warning'
                )
            );
            console.error(error);
        }),
    ]);
}
