import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
} from 'recharts';
import { Card } from '@material-ui/core';

const styles = {
    card: { flex: 1 },
    icon: {
        float: 'right',
        width: 64,
        height: 64,
        padding: 16,
        color: '#4caf50',
    },
};

function getCurrencyKey(dataKey: string) {
    return `${dataKey.split('.')[0]}.currency`;
}

function getItem(items: any[], key: string) {
    for (let i = 0; i < items.length; ++i) {
        const section = items[i].data;
        for (let j = 0; j < section.length; ++j) {
            if (section[j].key === key) return section[j];
        }
    }
    return null;
}

function getLabel(items: any[], key: string) {
    const item = getItem(items, key);
    return item ? item.title : key;
}

type Props = {
    plots: string[];
    plotData: any[];
    items?: any[];
};

const GraphWidget = (props: Props) => {
    const strokes = [
        '#6366F1',
        '#10B981',
        '#3B82F6',
        '#EF4444',
        '#F59E0B',
        '#8B5CF6',
        '#EC4899',
        '#F87171',
        '#7F1D1D',
        '#064E3B',
        '#4C1D95',
        '#831843',
        '#F472B6',
        '#A78BFA',
        '#312E81',
    ];
    const plots = props.plots || [];
    const items = props.items || [];
    const data = props.plotData || [];

    return (
        <Card style={styles.card}>
            <LineChart
                width={1200}
                height={600}
                data={data}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            >
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    tickFormatter={tick => {
                        return moment(tick).format('D');
                    }}
                />
                <YAxis
                    type="number"
                    domain={[0, 50]}
                    scale="sqrt"
                    label={{
                        angle: -90,
                        position: 'insideLeft',
                    }}
                    tickFormatter={tick => {
                        return tick.toLocaleString();
                    }}
                />
                <Tooltip
                    labelFormatter={date => moment(date).format('DD-MM-YYYY')}
                    formatter={(value, name, props) => {
                        const { dataKey, payload } = props;
                        const item = getItem(items, dataKey);
                        if (item) {
                            if (item.currency) {
                                const currencyKey = getCurrencyKey(dataKey);
                                if (payload[currencyKey]) {
                                    return value.toLocaleString(undefined, {
                                        style: 'currency',
                                        currency: payload[currencyKey],
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                    });
                                }
                            } else if (item.unit) {
                                return `${value.toLocaleString()} ${item.unit}`;
                            }
                        }
                        return value.toLocaleString();
                    }}
                />
                <Legend />

                {plots.map((i, index) => (
                    <Line
                        type="monotone"
                        key={i}
                        dataKey={i}
                        stroke={strokes[index % strokes.length]}
                        name={getLabel(items, i)}
                    />
                ))}
            </LineChart>
        </Card>
    );
};

GraphWidget.propTypes = {
    data: PropTypes.array,
};

GraphWidget.defaultProps = {
    data: [],
};

export default GraphWidget;
