import React from 'react';
import { SimpleFormIterator } from 'react-admin';
import {
    Create,
    FormTab,
    TabbedForm,
    NumberInput,
    ArrayInput,
    TextInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import './styles.css';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { SelectInput } from 'react-admin';

const optionText = category => `${category.name} (${category.id})`;

const EmailTemplateCreate = props => {
    const transform = data => {
        console.log('hellllo');
        return {
            ...data,
            categories: data.categories
                ? data.categories.map(category => category.id)
                : null,
        };
    };

    const validate = values => {
        const errors: any = {};

        if (!values.subject) {
            errors.subject = ['Subject is required'];
        }

        if (!values.description) {
            errors.description = ['Description is required'];
        }

        if (!values.templateName) {
            errors.templateName = ['Template name is required'];
        }

        if (!values.body) {
            errors.body = ['Body is required'];
        }

        if (!values.toEmails || values.toEmails.length < 1) {
            errors.toEmails = ['at least one email is required'];
        }

        if (!values.fromEmail || values.fromEmail.length < 1) {
            errors.fromEmail = ['at least one email is required'];
        }

        return errors;
    };
    return (
        <Create {...props} transform={transform}>
            <TabbedForm variant="outlined" validate={validate} redirect="show">
                <FormTab label="Details">
                    <TextInput
                        label="Description"
                        source="description"
                        multiline
                    />
                    <TextInput label="Template name" source="templateName" />
                </FormTab>
                <FormTab label="Fields">
                    <TextInput
                        disabled
                        source="countryId"
                        defaultValue={localStorage.getItem('countryId')}
                        style={{ display: 'none' }}
                    />
                    <TextInput label="Subject" source="subject" />
                    <TextInput label="From Email" source="fromEmail" />
                    <ArrayInput label="To Emails" source="toEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="CC Emails" source="ccEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="BCC Emails" source="bccEmails">
                        <SimpleFormIterator>
                            <TextInput label="Email" />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="Categories" source="categories">
                        <SimpleFormIterator>
                            <ReferenceInput
                                label="Category"
                                source="id"
                                reference="template-categories"
                                resource="template-categories"
                            >
                                <SelectInput optionText={optionText} />
                            </ReferenceInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput
                        label="Template Variables"
                        source="templateVariables"
                    >
                        <SimpleFormIterator>
                            <TextInput helperText={false} label="Variable" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="Body">
                    <RichTextInput label="Body" source="body" />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default EmailTemplateCreate;
