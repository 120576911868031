import { Button, Card } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Title } from 'react-admin';
import classes from './config.module.css';

const ConfigsCountries = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const navigateToCountryConfigs = (country: string) => {
        window.location.href = `/#/configurations/${country}`;
    };
    return (
        <>
            <Title title="Configurations" />
            <Card className={classes.configsCard}>
                <h3>Click on country to view or edit configurations</h3>
                <div className={classes.conutriesContainer}>
                    <button
                        onClick={() => navigateToCountryConfigs('1')}
                        className={classes.country_btn}
                    >
                        UAE
                    </button>
                    <button
                        onClick={() => navigateToCountryConfigs('2')}
                        className={classes.country_btn}
                    >
                        Kuwait
                    </button>
                    <button
                        onClick={() => navigateToCountryConfigs('3')}
                        className={classes.country_btn}
                    >
                        KSA
                    </button>
                </div>
            </Card>
        </>
    );
};

export default ConfigsCountries;
