import * as React from 'react';

function SvgAlertCircle(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 40 40"
            fill="none"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 20c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11S9 26.075 9 20zm11-9a9 9 0 100 18 9 9 0 000-18zm0 4a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zm0 10a1 1 0 100-2 1 1 0 000 2z"
                fill="#8492A6"
            />
        </svg>
    );
}

export default SvgAlertCircle;
