import React, { Component, Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import capitalize from 'capitalize';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import classnames from 'classnames';
import { crudUpdate, startUndoable } from 'ra-core';
import IconCancel from '@material-ui/icons/Cancel';
import { Button } from 'react-admin';
import { ListProps } from '../types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    defaultButton: {
        color: theme.palette.info.main,
        '&:hover': {
            backgroundColor: fade(theme.palette.info.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },
}));

type Styles = {
    defaultButton: string;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
    ListProps & {
        titleSource: string;
        label: string;
        classes: Styles;
        className: string;
        record: any;
        basePath: string;
        redirect: string;
        undoable: boolean;
    };

class SetDefaultButton extends Component<Props> {
    state = {
        showDialog: false,
    };

    handleClick = () => {
        this.setState({ showDialog: true });
    };

    handleCloseClick = () => {
        this.setState({ showDialog: false });
    };

    handleUpdate = event => {
        event.preventDefault();
        this.setState({ showDialog: false });
        const {
            dispatchCrudUpdate,
            startUndoable,
            resource,
            record,
            basePath,
            redirect = 'cards',
            undoable = false,
        } = this.props;
        record.default = true;
        if (undoable) {
            startUndoable(
                crudUpdate(resource, record.id, record, basePath, redirect)
            );
        } else {
            dispatchCrudUpdate(resource, record.id, record, basePath, redirect);
        }
    };

    render() {
        const { showDialog } = this.state;
        const {
            label = 'Default',
            classes,
            className,
            resource,
            record,
            titleSource,
        } = this.props;

        return record ? (
            <Fragment>
                {record && !record.default && record.status !== 'inactive' && (
                    <Button
                        onClick={this.handleClick}
                        label={label}
                        className={classnames(
                            'ra-default-button',
                            classes.defaultButton,
                            className
                        )}
                        key="button"
                    >
                        <CheckCircleOutlineIcon />
                    </Button>
                )}
                <Dialog
                    fullWidth
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Are you sure?"
                >
                    <DialogTitle>
                        Setting “
                        {titleSource
                            ? record[titleSource]
                            : record.title
                            ? record.title
                            : record.cardNumber
                            ? record.cardNumber
                            : `${capitalize(resource.replace(/s$/, ''))} #${
                                  record.id
                              }`}
                        ” as Default
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            Are you sure you want make this card as the default
                            card?
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleUpdate}
                            label={label}
                            className={classnames(
                                'ra-default-button',
                                classes.defaultButton,
                                className
                            )}
                            key="button"
                        >
                            <CheckCircleOutlineIcon />
                        </Button>
                        <Button
                            label="ra.action.cancel"
                            onClick={this.handleCloseClick}
                        >
                            <IconCancel />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        ) : null;
    }
}

const mapDispatch = {
    startUndoable,
    dispatchCrudUpdate: crudUpdate,
};

const connector = connect(null, mapDispatch);

const SetDefaultButtonComponent = connector(SetDefaultButton);

export default props => {
    const classes = useStyles();

    return <SetDefaultButtonComponent classes={classes} {...props} />;
};
