import React from 'react';
import { Edit } from 'react-admin';
import { required } from 'react-admin';
import { SelectInput } from 'react-admin';

import { SimpleForm, TextInput } from 'react-admin';
import { handleTagsError, handleUserError } from '../users/error';
import { useNotify } from 'react-admin';

const ReasonsTitle = ({ record }: { record?: any }) => (
    <span>Edit {record ? `"${record.name}"` : ''}</span>
);

const HoldReasonsEdit = props => {
    const notify = useNotify();
    return (
        <Edit
            title={<ReasonsTitle />}
            undoable={false}
            {...props}
            onFailure={error => handleTagsError(error, notify)}
        >
            <SimpleForm variant="outlined" redirect="show">
                <TextInput source="reason" validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
};

export default HoldReasonsEdit;
