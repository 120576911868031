import React from 'react';
import capitalize from 'capitalize';
import { useGetOne } from 'react-admin';
import _ from 'lodash';
import { moneyString } from '../../core/utils/money';
import humanize from '../../util/humanize';

export default ({ order, services, values, ...props }) => {
    const { data: country } = useGetOne('country', order.countryId);
    const isPartnerOrder = order.partnerId && order.partnerId !== 1;
    const selectedServices = Object.values(_.pick(services, values.serviceIds));
    const discountService = selectedServices.find(
        i => i.servicetype.name === 'Discount'
    );
    const discountAmount = discountService ? discountService.amount : 0;
    const servicesToCharge = selectedServices.filter(
        i => i.servicetype.name !== 'Discount'
    );
    const totalServiceChargesBeforeDiscount = servicesToCharge.reduce(
        (sum, s) => sum + s.amount,
        0
    );
    const totalServiceCharges =
        totalServiceChargesBeforeDiscount - discountAmount;
    const totalServiceChargesBoxit = order.partnerServiceCommissionPercent
        ? totalServiceCharges *
          ((100 - order.partnerServiceCommissionPercent) / 100)
        : totalServiceCharges;

    const totalStorageChargesBoxit =
        ['moving', 'packing', 'shipping', 'other_services'].indexOf(
            order.type
        ) !== -1
            ? order.amount
            : order.rent * order.months;
    const subTotal = totalStorageChargesBoxit + totalServiceChargesBoxit;

    return (
        <table>
            <tbody>
                <tr>
                    <td colSpan={2}>
                        <strong>Summary</strong>
                    </td>
                </tr>
                <tr>
                    <td>
                        {[
                            'moving',
                            'packing',
                            'shipping',
                            'other_services',
                        ].indexOf(order.type) !== -1
                            ? `${capitalize(humanize(order.type))} Charges`
                            : 'Storage Charges'}{' '}
                        {isPartnerOrder
                            ? `@ ${
                                  100 - order.partnerStorageCommissionPercent
                              }%`
                            : ''}
                    </td>
                    <td>&nbsp;</td>
                    <td>{`${moneyString(
                        totalStorageChargesBoxit,
                        order.currency
                    )}`}</td>
                </tr>
                <tr>
                    <td>
                        Service Charges{' '}
                        {isPartnerOrder
                            ? `@ ${
                                  100 - order.partnerServiceCommissionPercent
                              }%`
                            : ''}
                    </td>
                    <td>&nbsp;</td>
                    <td>{`${moneyString(
                        totalServiceChargesBoxit,
                        order.currency
                    )}`}</td>
                </tr>
                {(order.countryId == 1 || order.countryId == 3) && (
                    <>
                        <tr>
                            <td>SubTotal</td>
                            <td>&nbsp;</td>
                            <td>{`${moneyString(
                                subTotal,
                                order.currency
                            )}`}</td>
                        </tr>
                        <tr>
                            <td>VAT</td>
                            <td>&nbsp;</td>
                            <td>{`${moneyString(
                                subTotal * country.vat,
                                order.currency
                            )}`}</td>
                        </tr>
                    </>
                )}
                <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>
                        <strong>Total</strong>
                    </td>
                    <td>&nbsp;</td>
                    <td>
                        <strong>{`${moneyString(
                            subTotal * (1 + country.vat),
                            order.currency
                        )}`}</strong>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
