import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { addUserPhoto as addUserPhotoAction } from '../actions/userActions';
import AddPhoto from '../../components/AddPhoto';

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    record: any;
};

type State = {};

class AddUserPhoto extends React.Component<Props, State> {
    handleSubmit = (base64: string) => {
        const { record, addUserPhoto } = this.props;
        addUserPhoto(record.id, base64);
    };

    render() {
        return (
            <AddPhoto record={this.props.record} onSubmit={this.handleSubmit} />
        );
    }
}

const mapDispatch = {
    addUserPhoto: addUserPhotoAction,
};

const connector = connect(null, mapDispatch);

export default connector(AddUserPhoto);
