import React from 'react';
import PropTypes from 'prop-types';
import JsonTable from 'ts-react-json-table';
import './Revision.css';

const Revision = props => (
    <JsonTable
        columns={[
            { key: 'kind', label: 'action' },
            { key: 'path', label: 'field' },
            { key: 'lhs', label: 'old' },
            { key: 'rhs', label: 'new' },
        ]}
        rows={
            props.record &&
            (props.record.revisionchanges || []).map(i => {
                const document = JSON.parse(i.document);

                if (document.path === 'comments')
                    return { action: 'commented' };

                if (
                    document.path &&
                    document.path.constructor === Array &&
                    document.path.length
                ) {
                    document.path = document.path[0];
                }

                if (document.lhs && typeof document.lhs !== 'string') {
                    document.lhs = JSON.stringify(document.lhs);
                }

                if (document.rhs && typeof document.rhs !== 'string') {
                    document.rhs = JSON.stringify(document.rhs);
                }

                return document;
            })
        }
    />
);

Revision.propTypes = {
    record: PropTypes.object,
};

Revision.defaultProps = {
    record: {},
};

export default Revision;
