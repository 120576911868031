import React from 'react';

import {
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

const PartnerCreate = props => (
    <Create {...props}>
        <SimpleForm variant="outlined" redirect="show">
            <TextInput source="name" />
            <TextInput source="logoUrl" label="Logo" />
            <ReferenceInput
                label="Country"
                source="countryId"
                reference="country"
                resource="country"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default PartnerCreate;
