import React, { useState, useEffect, FC, Fragment } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Title, useDataProvider } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import StatCell from './components/StatCell';
import GraphWidget from './components/GraphWidget';
import PresetDateRangePicker from '../components/date/PresetDateRangePicker';
import isSameDay from '../components/date/utils/isSameDay';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    icon: { width: '2em', height: '2em' },
    welcome: { marginBottom: '2em' },
    flex: { display: 'flex', marginBottom: '1em' },
    leftCol: { flex: 1, paddingRight: '1em' },
    rightCol: { flex: 1 },
    textField: {
        marginLeft: '1em',
        marginRight: '1em',
        width: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
}));

interface Analytics {
    current: any;
    previous: any;
}

interface Props {
    title: string;
    items: any[];
    needCountry?: boolean;
}

const Analytics: FC<Props> = ({ title, items, needCountry }) => {
    const classes = useStyles();
    const dataProvider = useDataProvider();

    const presets = [
        {
            text: 'Today',
            start: moment().startOf('day'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('day').subtract(1, 'day'),
            endPrev: moment().endOf('day').subtract(1, 'day'),
        },
        {
            text: 'This Month',
            start: moment().startOf('month'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('month').subtract(1, 'month'),
            endPrev: moment().endOf('day').subtract(1, 'month'),
        },
        {
            text: 'Last Month',
            start: moment().subtract(1, 'month').startOf('month'),
            end: moment().subtract(1, 'month').endOf('month'),
            startPrev: moment().subtract(2, 'month').startOf('month'),
            endPrev: moment().subtract(2, 'month').endOf('month'),
        },
        {
            text: 'This Year',
            start: moment().startOf('year'),
            end: moment().endOf('day'),
            startPrev: moment().startOf('year').subtract(1, 'year'),
            endPrev: moment().endOf('day').subtract(1, 'year'),
        },
    ];
    const initialPreset =
        presets.find(i => i.text === 'This Month') || presets[0];

    const [startDate, setStartDate] = useState(initialPreset.start);
    const [endDate, setEndDate] = useState(initialPreset.end);
    const [startDatePrev, setStartDatePrev] = useState(initialPreset.startPrev);
    const [endDatePrev, setEndDatePrev] = useState(initialPreset.endPrev);
    const [compareTo, setCompareTo] = useState('previous');
    const [countryId, setCountryId] = useState('1');
    const [analytics, setAnalytics] = useState<Analytics>({} as Analytics);
    const [plots, setPlots] = useState<string[]>([items[0].data[0].key]);
    const [plotData, setPlotData] = useState([]);
    const preset = presets.find(
        i => isSameDay(i.start, startDate) && isSameDay(i.end, endDate)
    );

    useEffect(() => {
        if (startDate && endDate && endDate.isAfter(startDate)) {
            dataProvider
                .getList('analytics', {
                    filter: {
                        id: 'snapshot',
                        metrics: items.map(i => i.data.map(j => j.key)).flat(),
                        ...(countryId &&
                            Number(countryId) > 0 && { countryId }),
                        startDate: startDate.format(),
                        endDate: endDate.format(),
                        startDatePrev: startDatePrev.format(),
                        endDatePrev: endDatePrev.format(),
                    },
                })
                .then(({ data }) => {
                    if (data && data.length) {
                        setAnalytics(data[0]);
                    }
                });
        }
    }, [
        dataProvider,
        startDate,
        endDate,
        startDatePrev,
        endDatePrev,
        countryId,
    ]);

    useEffect(() => {
        if (
            startDate &&
            endDate &&
            endDate.isAfter(startDate) &&
            plots.length > 0
        ) {
            dataProvider
                .getList('analytics', {
                    filter: {
                        id: 'graph',
                        metrics: plots,
                        ...(countryId &&
                            Number(countryId) > 0 && { countryId }),
                        startDate: startDate.format(),
                        endDate: endDate.format(),
                    },
                })
                .then(({ data }) => {
                    if (data) {
                        setPlotData(data);
                    }
                });
        }
    }, [dataProvider, startDate, endDate, countryId, plots]);

    return (
        <Fragment>
            <Title title={title} />
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <form className="flex justify-end" noValidate>
                            <div>
                                <div className="flex">
                                    <TextField
                                        select
                                        id="countryId"
                                        label="Country"
                                        size="small"
                                        variant="outlined"
                                        className={classes.textField}
                                        value={countryId}
                                        onChange={event => {
                                            setCountryId(event.target.value);
                                        }}
                                    >
                                        {!needCountry && (
                                            <MenuItem key={0} value={0}>
                                                All
                                            </MenuItem>
                                        )}
                                        <MenuItem key={1} value={1}>
                                            UAE
                                        </MenuItem>
                                        <MenuItem key={2} value={2}>
                                            Kuwait
                                        </MenuItem>
                                        <MenuItem key={3} value={3}>
                                            KSA
                                        </MenuItem>
                                    </TextField>
                                    <PresetDateRangePicker
                                        startDate={startDate}
                                        endDate={endDate}
                                        startDateId={'startDate'}
                                        endDateId={'endDate'}
                                        onDatesChange={({
                                            startDate,
                                            endDate,
                                        }) => {
                                            if (startDate) {
                                                setStartDate(
                                                    moment(startDate).startOf(
                                                        'day'
                                                    )
                                                );
                                            }
                                            if (endDate) {
                                                setEndDate(
                                                    moment(endDate).endOf('day')
                                                );
                                            }

                                            if (startDate && endDate) {
                                                const preset = presets.find(
                                                    i =>
                                                        isSameDay(
                                                            i.start,
                                                            startDate
                                                        ) &&
                                                        isSameDay(
                                                            i.end,
                                                            endDate
                                                        )
                                                );

                                                if (preset) {
                                                    setStartDatePrev(
                                                        preset.startPrev
                                                    );
                                                    setEndDatePrev(
                                                        preset.endPrev
                                                    );
                                                }
                                            }
                                        }}
                                        isOutsideRange={day =>
                                            day.isAfter(moment().endOf('day'))
                                        }
                                        presets={presets}
                                    />
                                </div>
                                <div className="flex justify-end mt-3">
                                    <TextField
                                        select
                                        id="compareTo"
                                        label="Compare To"
                                        size="small"
                                        variant="standard"
                                        className={classes.textField}
                                        value={compareTo}
                                        onChange={event => {
                                            setCompareTo(event.target.value);
                                        }}
                                    >
                                        <MenuItem
                                            key={'previous'}
                                            value={'previous'}
                                        >
                                            Previous Period
                                        </MenuItem>
                                        <MenuItem
                                            key={'custom'}
                                            value={'custom'}
                                        >
                                            Custom
                                        </MenuItem>
                                    </TextField>

                                    {compareTo === 'custom' && (
                                        <PresetDateRangePicker
                                            startDate={startDatePrev}
                                            endDate={endDatePrev}
                                            startDateId={'startDatePrev'}
                                            endDateId={'endDatePrev'}
                                            onDatesChange={({
                                                startDate,
                                                endDate,
                                            }) => {
                                                if (startDate) {
                                                    setStartDatePrev(
                                                        moment(
                                                            startDate
                                                        ).startOf('day')
                                                    );
                                                }
                                                if (endDate) {
                                                    setEndDatePrev(
                                                        moment(endDate).endOf(
                                                            'day'
                                                        )
                                                    );
                                                }
                                            }}
                                            isOutsideRange={day =>
                                                day.isAfter(
                                                    moment().endOf('day')
                                                )
                                            }
                                            presets={presets}
                                        />
                                    )}
                                </div>
                            </div>
                        </form>
                    </Grid>
                    <Grid key="graph" item xs={12}>
                        <h3 className="mb-4 text-lg leading-6 font-medium text-gray-900">
                            {preset ? preset.text : 'Custom Range'}
                        </h3>
                        <GraphWidget
                            plots={plots}
                            plotData={plotData}
                            items={items}
                        />
                    </Grid>
                    <Grid key={title} item xs={12}>
                        <table className="min-w-full border-collapse border border-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        className="border border-gray-200 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="border border-gray-200 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        All
                                    </th>
                                    <th
                                        scope="col"
                                        className="border border-gray-200 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        B2C
                                    </th>
                                    <th
                                        scope="col"
                                        className="border border-gray-200 px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        B2B
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-x divide-gray-200">
                                {items
                                    .filter(
                                        line =>
                                            !line.country ||
                                            (countryId && Number(countryId) > 0)
                                    )
                                    .map(
                                        (line: {
                                            title: string;
                                            indent?: number;
                                            data: any[];
                                        }) => (
                                            <tr key={line.title} className="">
                                                <td className="border border-gray-200 px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    {Array(line.indent || 0)
                                                        .fill(null)
                                                        .map(i => (
                                                            <span>
                                                                <svg
                                                                    className="inline h-5 w-5 text-gray-300 mx-4"
                                                                    viewBox="0 0 20 20"
                                                                    fill="currentColor"
                                                                >
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                                        clip-rule="evenodd"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        ))}
                                                    {line.title}
                                                </td>
                                                {line.data.map(i => {
                                                    const currency =
                                                        i.currency &&
                                                        _.get(
                                                            analytics.current,
                                                            i.currency
                                                        );
                                                    const current =
                                                        _.get(
                                                            analytics.current,
                                                            i.key
                                                        ) || 0;
                                                    const previous = _.get(
                                                        analytics.previous,
                                                        i.key
                                                    );

                                                    return (
                                                        <StatCell
                                                            key={i.key}
                                                            title={i.title}
                                                            current={
                                                                currency
                                                                    ? current.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                              style:
                                                                                  'currency',
                                                                              currency,
                                                                              minimumFractionDigits: 0,
                                                                              maximumFractionDigits: 0,
                                                                          }
                                                                      )
                                                                    : i.unit
                                                                    ? `${current} ${i.unit}`
                                                                    : current
                                                            }
                                                            previous={
                                                                currency &&
                                                                previous
                                                                    ? previous.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                              style:
                                                                                  'currency',
                                                                              currency,
                                                                              minimumFractionDigits: 0,
                                                                              maximumFractionDigits: 0,
                                                                          }
                                                                      )
                                                                    : i.unit
                                                                    ? `${previous} ${i.unit}`
                                                                    : previous
                                                            }
                                                            percent={Math.round(
                                                                ((current -
                                                                    previous) *
                                                                    100) /
                                                                    (previous ||
                                                                        1)
                                                            )}
                                                            currency={currency}
                                                            reverse={i.reverse}
                                                            selected={
                                                                plots.indexOf(
                                                                    i.key
                                                                ) !== -1
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    plots.indexOf(
                                                                        i.key
                                                                    ) === -1
                                                                ) {
                                                                    setPlots([
                                                                        ...plots,
                                                                        i.key,
                                                                    ]);
                                                                } else {
                                                                    setPlots(
                                                                        plots.filter(
                                                                            p =>
                                                                                p !=
                                                                                i.key
                                                                        )
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </tr>
                                        )
                                    )}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div>
        </Fragment>
    );
};

export default Analytics;
