import React from 'react';
import PropTypes from 'prop-types';

import { ReferenceField, TextField, LongTextField } from 'react-admin';

import CityField from '../../components/CityField';
import CountryField from '../../components/CountryField';

import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';

const AddressSummary = props => {
    const { record } = props;

    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <label>Customer</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <ReferenceField
                                {...props}
                                label="Customer"
                                source="userId"
                                reference="users"
                                link="show"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Address Id</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.id}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Name</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Line</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <TextField
                                record={record}
                                source="line1"
                                label="Line"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Area</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            {record.area}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>City</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CityField record={record} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <label>Country</label>
                        </TableCell>
                        <TableCell style={{ textAlign: 'right' }}>
                            <CountryField record={record} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

AddressSummary.propTypes = {
    record: PropTypes.object,
};

export default AddressSummary;
