import React from 'react';
import { SelectInput } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { TextInput } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
import { ReferenceArrayInput } from 'react-admin';
import { ReferenceInput, useListContext } from 'react-admin';
import {
    ShowButton,
    FunctionField,
    List,
    Datagrid,
    TextField,
    Filter,
} from 'react-admin';
import BoxitPagination from '../components/BoxitPagination';
import CustomSearchInput from '../components/CustomSearchInput';

const optionText = category => `${category.name} (${category.id})`;

const EmailTemplateFilter = props => (
    <Filter variant="outlined" {...props}>
        <CustomSearchInput alwaysOn />
        <ReferenceInput
            source="countryId"
            reference="country"
            resource="country"
            label="Country"
        >
            <SelectInput />
        </ReferenceInput>
        <SelectArrayInput
            style={{ minWidth: '10rem' }}
            source="status"
            choices={[
                {
                    id: 'active',
                    name: 'active',
                },
                {
                    id: 'inActive',
                    name: 'inActive',
                },
            ]}
        />
        <ReferenceArrayInput
            label="Category"
            source="categories"
            reference="template-categories"
            style={{ minWidth: '10rem' }}
        >
            <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput label="Whatsapp Template name" source="watiTempName" />
        <SelectArrayInput
            label="Attachment Type"
            source="attachmentTypes"
            style={{ minWidth: '12rem' }}
            choices={[
                {
                    id: 'InvoicePDF',
                    name: 'InvoicePDF',
                },
                {
                    id: 'QuotationPDF',
                    name: 'QuotationPDF',
                },
                {
                    id: 'StorageContractAE-PDF',
                    name: 'StorageContractAE-PDF',
                },
                {
                    id: 'EstimatePDF',
                    name: 'EstimatePDF',
                },
                {
                    id: 'RefundPdf',
                    name: 'RefundPdf',
                },
                {
                    id: 'ReceiptPDF',
                    name: 'ReceiptPDF',
                },
                {
                    id: 'requestNotePDF',
                    name: 'requestNotePDF',
                },
                {
                    id: 'Delivery/PickupNotePDF',
                    name: 'Delivery/PickupNotePDF',
                },
            ]}
        />
    </Filter>
);

const EmailTemplateList = props => {
    return (
        <List
            title="Communication Templates"
            bulkActionButtons={false}
            filters={<EmailTemplateFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<BoxitPagination />}
            perPage={10}
            {...props}
        >
            <Datagrid>
                <TextField source="serial" sortable={false} />
                <FunctionField
                    label="Country"
                    render={record => {
                        return record.countryId === 1
                            ? 'UAE'
                            : record.countryId === 2
                            ? 'Kuwait'
                            : 'KSA';
                    }}
                />
                ;
                <TextField source="templateName" label="Template Name" />
                {/*<TextField
                    makeStyles={{ width: '10rem' }}
                    source="subject"
                    label="Email subject"
                />*/}
                <FunctionField
                    label="Email subject"
                    render={record => (
                        <div style={{ width: '14rem' }}>{record.subject}</div>
                    )}
                />
                <TextField label="From Email" source="fromEmail" />
                <TextField label="Status" source="status" />
                <FunctionField
                    label="To"
                    render={record => {
                        if (record.toEmails && record.toEmails.length) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.toEmails.map(email => (
                                        <span>{email}</span>
                                    ))}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <FunctionField
                    label="CC"
                    render={record => {
                        if (record.ccEmails && record.ccEmails.length) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.ccEmails.map(email => (
                                        <span>{email}</span>
                                    ))}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <FunctionField
                    label="BCC"
                    render={record => {
                        if (record.bccEmails && record.bccEmails.length) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.bccEmails.map(email => (
                                        <span>{email}</span>
                                    ))}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <FunctionField
                    label="Email Categories"
                    render={record => {
                        if (record.categories && record.categories.length) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.categories.map(category => (
                                        <span>{category.name}</span>
                                    ))}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <FunctionField
                    label="Attachment types"
                    render={record => {
                        if (
                            record.attachmentTypes &&
                            record.attachmentTypes.length
                        ) {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    {record.attachmentTypes
                                        ? record.attachmentTypes.map(type => (
                                              <span>{type}</span>
                                          ))
                                        : '-'}
                                </div>
                            );
                        } else {
                            return <span>-</span>;
                        }
                    }}
                />
                <TextField
                    source="watiTempName"
                    label="Whatsapp Template Name"
                />
                {/*<EditButton color="secondary" style={{ padding: 0 }} />*/}
                <ShowButton />
            </Datagrid>
        </List>
    );
};

EmailTemplateList.defaultProps = {
    resource: 'email-templates',
    location: { search: '' },
};

export default EmailTemplateList;
