import React from 'react';

import { Show, SimpleShowLayout } from 'react-admin';
import { CommunicationTabs } from './components/CommunicationsTabs';
import { TopToolbar } from 'react-admin';
import { ListButton } from 'react-admin';

const CommunicationTitle = ({ record }) => (
    <span>{record ? `${record.name}` : `Address #${record.id}`}</span>
);

const CommunicationShow = props => (
    <Show
        {...props}
        actions={
            <TopToolbar>
                <ListButton
                    color="secondary"
                    basePath={
                        localStorage.getItem('currentComMod')
                            ? localStorage.getItem('currentComMod')
                            : props.basePath
                    }
                />
            </TopToolbar>
        }
    >
        <SimpleShowLayout>
            <CommunicationTabs />
        </SimpleShowLayout>
    </Show>
);

export default CommunicationShow;
